import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Popover, message, Popconfirm, Divider, Tooltip } from "antd";

import REQUESTS from "../api/requests";

import { setAppBranding, setProfile } from "../store/features/profileSlice";

import useTranslate from "../hooks/translator";

import { PATHS } from "../config";
import ICONS from "../config/icons";
import IMAGES from "../config/images";
import { JOIN_COMMUNITY, TELEGRAM, SUPPORT_EMAIl } from "../config/config";
import { themesForProvider, APP_FOR } from "../config/themesConfigs";

import AccountModal from "./accounts/AccountModal";

export default function MultiAccounts() {
  const dispatch = useDispatch();

  const { profile, appBranding } = useSelector((state) => state.profile);

  const [accountsList, setAccountsList] = useState([]);

  const translate = useTranslate();

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    getAccounts();
  }, []);

  const getAccounts = () => {
    REQUESTS.MULTI_ACCOUNT.GET()
      .then((res) => {
        setAccountsList(res);

        const selected = res.find((item) => item.is_selected === true);

        if (selected) {
          dispatch(setProfile(selected));
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const getAppBranding = () => {
    REQUESTS.APP_BRANDINGS.GET()
      .then((data) => {
        if (data) {
          dispatch(setAppBranding(data));
        }
      })
      .catch((err) => {});
  };

  const onSelect = (item) => {
    const body = {
      id: item.id,
    };
    if (item.id !== profile?.id) {
      REQUESTS.MULTI_ACCOUNT.SELECT(body)
        .then((res) => {
          localStorage.setItem("TOKEN", res.token);

          dispatch(setProfile(item));

          navigate(PATHS.DASHBOARD);

          getAccounts();

          getAppBranding();
        })
        .catch((err) => {
          if (typeof err == "string") {
            message.error(err);
          }
        });
    }
  };

  const onDelete = (id) => {
    const body = {
      id: id,
    };
    if (id !== profile?.id) {
      REQUESTS.MULTI_ACCOUNT.DELETE(body)
        .then((res) => {
          getAccounts();
          message.success(res);
        })
        .catch((err) => {
          if (typeof err == "string") {
            message.error(err);
          }
        });
    }
  };

  return (
    <>
      <div
        className="multi-accounts-page"
        style={{ backgroundColor: themesForProvider[APP_FOR]?.secondaryColor }}
      >
        <div className="multi-accounts">
          {accountsList?.length == 0 ? (
            <div
              className="account"
              style={{
                border: `2px solid ${themesForProvider[APP_FOR]?.menuItemColor}`,
              }}
            >
              <Popover
                placement="right"
                content={
                  <span style={{ color: "#8C8C8C" }}>
                    {translate("You can't select yourself")}{" "}
                  </span>
                }
                overlayClassName="account-popover"
              >
                <img
                  src={appBranding?.logo}
                  onError={(e) => (e.target.src = IMAGES.MAN)}
                  alt="profile"
                  style={{
                    width: `${
                      APP_FOR == "xcloud"
                        ? themesForProvider[APP_FOR].logo_size / 1.8
                        : themesForProvider[APP_FOR].logo_size / 2.5
                    }rem`,
                  }}
                />
              </Popover>
            </div>
          ) : (
            accountsList?.map((item) => {
              return (
                <div
                  className="account"
                  onClick={() => onSelect(item)}
                  key={item.id}
                  style={{
                    border: item.is_selected
                      ? `2px solid ${themesForProvider[APP_FOR]?.menuItemColor}`
                      : "2px solid #979797",
                  }}
                >
                  <Popover
                    placement="right"
                    content={
                      item.is_selected ? (
                        <span style={{ color: "#8C8C8C" }}>
                          {translate("You can't select yourself")}{" "}
                        </span>
                      ) : (
                        <>
                          <Divider
                            style={{
                              color: "grey",
                              borderBottom: "1px solid grey",
                            }}
                          >
                            {item.brand_name}
                          </Divider>
                          <div
                            onClick={() => onSelect(item)}
                            className="account-select"
                          >
                            <span>{translate("Select")}</span>
                          </div>
                          <div
                            className="account-del"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <Popconfirm
                              title={`${translate(
                                "Are you sure to delete this account"
                              )}?`}
                              onConfirm={() => onDelete(item.id)}
                              okText="Yes"
                              cancelText="No"
                            >
                              <span>{translate("Delete")}</span>
                            </Popconfirm>
                          </div>
                        </>
                      )
                    }
                    overlayClassName="account-popover"
                  >
                    <img
                      src={item.app_branding?.logo}
                      //   APP_FOR == "xcloud"
                      //     ? appBranding?.logo
                      //     : themesForProvider[APP_FOR]?.logo
                      // }
                      onError={(e) =>
                        (e.target.src =
                          themesForProvider[APP_FOR]?.logo || IMAGES.ERROR)
                      }
                      alt=""
                    />
                  </Popover>
                </div>
              );
            })
          )}

          <Button icon={ICONS.ADD} onClick={() => setOpen(true)}></Button>
        </div>
        {!profile?.parent_id && (
          <div className="social-links">
            <Tooltip title={translate("Join community")} placement="right">
              <a
                href={JOIN_COMMUNITY}
                target="_blank"
                className="join-community"
                rel="noreferrer"
              >
                <img src={IMAGES.JOIN_COMMUNITY} alt="join" width={"27px"} />
              </a>
            </Tooltip>
            <Tooltip title="Telegram" placement="right">
              <a
                href={TELEGRAM}
                target="_blank"
                className="social-anim telegram"
                rel="noreferrer"
              >
                {ICONS.TELEGRAM}
              </a>
            </Tooltip>
            <Tooltip title={translate("Support")} placement="right">
              <a
                href={`mailto:${SUPPORT_EMAIl}`}
                target={"_blank"}
                className="social-anim message-social-anim"
                rel="noreferrer"
              >
                <img src={IMAGES.MESSAGE} alt="MESSAGE" />
              </a>
            </Tooltip>
          </div>
        )}
      </div>
      <AccountModal
        open={open}
        onCancel={() => setOpen(false)}
        getData={getAccounts}
      />
    </>
  );
}
