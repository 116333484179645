import React from "react";
import { Tooltip } from "antd";

import useTranslate from "../../../hooks/translator";

import { ICONS, IMAGES } from "../../../config";

import FILES from "../../../config/downloads";
import { themesForProvider, APP_FOR } from "../../../config/themesConfigs";

export default function Platform({ item, availablePlatforms }) {
  const translate = useTranslate();

  const returnAvailablePlatforms = (type) => {
    if (availablePlatforms && availablePlatforms?.length > 0) {
      switch (type) {
        case "tizen":
          return availablePlatforms[0]?.tizen;
        case "webos":
          return availablePlatforms[0]?.webos;
        case "androidTv":
          return availablePlatforms[0]?.androidtv;
        case "fire":
          return availablePlatforms[0]?.fire;
        case "tv os":
          return availablePlatforms[0]?.tv_os;
        case "roku":
          return availablePlatforms[0]?.roku;
        case "vidda":
          return availablePlatforms[0]?.vidda;
        case "foxxum":
          return availablePlatforms[0]?.foxxum;
        case "zessen":
          return availablePlatforms[0]?.zessen;
        case "vizio":
          return availablePlatforms[0]?.vizio;
        case "ios":
          return availablePlatforms[0]?.ios;
        case "androidMobile":
          return availablePlatforms[0]?.androidmobile;
        default:
          return true;
      }
    } else {
      return true;
    }
  };

  const returnIcon = (type) => {
    switch (type) {
      case "tizen":
        return IMAGES.TIZEN;
      case "webos":
        return IMAGES.WEBOS;
      case "androidTv":
        return IMAGES.ANDROID_TV;
      case "fire":
        return IMAGES.FIRE_TV;
      case "tv os":
        return IMAGES.TV_OS;
      case "roku":
        return IMAGES.ROKU;
      case "vidda":
        return IMAGES.VIDDA;
      case "foxxum":
        return IMAGES.FOXXUM;
      case "zessen":
        return IMAGES.ZESSEN;
      case "vizio":
        return IMAGES.VIZIO;
      case "ios":
        return IMAGES.IOS;
      case "androidMobile":
        return IMAGES.ANDROID_MOBILE;
      default:
        return "";
    }
  };

  const downloadFile = (device) => {
    switch (device) {
      case "androidTv":
        return FILES.APK_FILE;

      case "androidMobile":
        return FILES.ANDROID_MOBILE_FILE;

      case "tizen":
        return FILES.USER_WIDGET_ZIP;

      case "ios":
        return "";

      default:
        return "";
    }
  };

  return (
    <Tooltip
      title={
        returnAvailablePlatforms(item.device_os)
          ? ""
          : translate([
              "This platform is currently unavailable to you because your partner has not subscribed yet. If you want to access this platform, please reach out to your partner"
            ])
      }
    >
      <div
        className="platform-card"
        style={{ backgroundColor: themesForProvider[APP_FOR]?.primaryColor }}
      >
        <div
          className="platform-wrap"
          style={{
            backgroundImage: `url(${IMAGES.PLATFORM_BG})`,
            background: item.is_released
              ? `radial-gradient(circle, ${themesForProvider[APP_FOR]?.cardColor} -100%, ${themesForProvider[APP_FOR]?.primaryColor} 100%)`
              : `radial-gradient(circle, ${themesForProvider[APP_FOR]?.cardColor} -100%, ${themesForProvider[APP_FOR]?.primaryColor} 100%)`
          }}
        >
          <div className="platform-img">
            {" "}
            <img src={returnIcon(item.device_os) || item.icon} alt="platform" />
          </div>
          <div className="platform-body">
            <div className="platform-name">
              {item.name == "Antroid Mobile" ? "Android Mobile" : item.name}
            </div>
            {item.is_released && (
              <div className="count">{item.device_count || 0}</div>
            )}
          </div>

          <div className="platform-footer">
            <div className="apk">
              {item?.device_os == "androidTv" && (
                <a href={downloadFile(item?.device_os)} download>
                  {ICONS.DOWNLOAD_APK} APK
                </a>
              )}
              {item?.device_os == "androidMobile" && (
                <a href={downloadFile(item?.device_os)} download>
                  {ICONS.DOWNLOAD_APK} APK
                </a>
              )}
              {item?.device_os == "tizen" && (
                <a href={downloadFile(item?.device_os)}>
                  {ICONS.DOWNLOAD_APK} File
                </a>
              )}
            </div>
            {item.is_released ? (
              <div className="available">
                {translate("Available")} {ICONS.AVAILABLE}
              </div>
            ) : (
              <div className="soon">{translate("Coming soon")}</div>
            )}
          </div>
        </div>
      </div>
    </Tooltip>
  );
}
