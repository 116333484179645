import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Button } from "antd";

import REQUESTS from "../api/requests";

import useTranslate from "../hooks/translator";

import { ICONS, PATHS } from "../config";
import { themesForProvider, APP_FOR } from "../config/themesConfigs";

import { setProfile, setAppBranding } from "../store/features/profileSlice";

import EditProfileDrawer from "./EditProfileDrawer";
import TokenRefresh from "./TokenRefresh";

import "../styles/header.scss";

let isFeildRefreshTimeout = null;

export default function Profile() {
  const navigate = useNavigate();
  const translate = useTranslate();
  const dispatch = useDispatch();

  const { profile, appBranding } = useSelector((state) => state.profile);

  const [isOpenProfileDrawer, setIsOpenProfileDrawer] = useState(false);

  const [isOpenRefrashTokenModal, setIsOpenRefrashTokenModal] = useState(false);

  const tokenRefresh = JSON.parse(localStorage.getItem("refresh_token"));

  useEffect(() => {
    const leftTime = new Date(tokenRefresh?.start_time + 3480000) - new Date(); //780000
    const feildTime = new Date(tokenRefresh?.start_time + 3600000) - new Date(); //900000

    const timeout = setTimeout(() => {
      setIsOpenRefrashTokenModal(true);
    }, leftTime);

    isFeildRefreshTimeout = setTimeout(() => {
      localStorage.clear();
      dispatch(setProfile(null));
      navigate(PATHS.LOGIN);
    }, feildTime);

    return () => {
      clearTimeout(timeout);
      clearTimeout(isFeildRefreshTimeout);
    };
  }, [profile, tokenRefresh]);

  useEffect(() => {
    clearTimeout(isFeildRefreshTimeout);
  }, []);

  const getAppBranding = () => {
    REQUESTS.APP_BRANDINGS.GET()
      .then((data) => {
        if (data) {
          dispatch(setAppBranding(data));
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (APP_FOR == "xcloud") {
      getAppBranding();
    }
  }, [APP_FOR]);

  return (
    <>
      <div className="profile-block">
        <div className="profile-image-container">
          <img
            src={
              APP_FOR == "xcloud"
                ? appBranding?.logo
                : themesForProvider[APP_FOR]?.logo
            }
            alt="profile"
            style={{ width: `${themesForProvider[APP_FOR]?.logo_size}rem` }}
            onError={(e) => (e.target.src = themesForProvider[APP_FOR]?.logo)}
          />
        </div>

        <div className="profile-text">
          <p className="name-surname">
            {profile?.name} {profile?.surname}
          </p>
          <p style={{ margin: "3px 0" }}>{profile?.brand_name}</p>
          <Button
            type="link"
            onClick={setIsOpenProfileDrawer}
            className="edit-profile"
            style={{
              color: themesForProvider[APP_FOR]?.primaryColor,
              background: themesForProvider[APP_FOR]?.profileBtn
            }}
          >
            <span style={{ fill: themesForProvider[APP_FOR]?.primaryColor }}>
              {ICONS.EDIT}
            </span>
            {translate("Edit profile")}
          </Button>
        </div>
      </div>

      <EditProfileDrawer
        open={isOpenProfileDrawer}
        onClose={() => setIsOpenProfileDrawer(false)}
      />

      <TokenRefresh
        open={isOpenRefrashTokenModal}
        onSuccess={() => clearTimeout(isFeildRefreshTimeout)}
        onCancel={() => setIsOpenRefrashTokenModal(false)}
      />
    </>
  );
}
