const translations = {
  //a
  "Are you sure to reset this branding": {
    en: "Are you sure to reset this branding?",
    pt: "Tem certeza de que deseja redefinir esta marca?",
    de: "Sind Sie sicher, dass Sie dieses Branding zurücksetzen möchten?",
    es: "¿Está seguro de que desea restablecer esta marca?",
    fr: "Êtes-vous sûr de vouloir réinitialiser ce branding?",
    it: "Sei sicuro di voler reimpostare questo branding?",
    sq: "A jeni të sigurt që dëshironi të rivendosni këtë markë?",
    tr: "Bu markalamayı sıfırlamak istediğinizden emin misiniz?",
    sw: "Je, uko tayari kufuta alama hii?",
    am: "እባክህ እርስዎ ይህን ታማኝነት እንደገና እንደምትቀይሩ?",
    om: "Akka itti deebi'ee mul'atu mirkaneessuu?",
    ig: "Ị jụrụ ịtọghachi akara a?",
    so: "Ma hubtaa inaad dib u dejiso astaan-kan?",
    ha: "Shin kana tabbatar da sake saita wannan alamar?",
    mnk: "I jaama le ka taamu kafu brandi le?",
    ff: "Aɓe ɗa ngorgo haa taƴe brandi wono?",
    yo: "Ṣe o dawọle lati tunto ami yi?"
  },

  "Applications default language": {
    en: "Applications default language",
    pt: "Idioma padrão do aplicativo",
    de: "Standard-Sprache der Anwendung",
    es: "Idioma predeterminado de la aplicación",
    fr: "Langue par défaut de l'application",
    it: "Lingua predefinita dell'applicazione",
    sq: "Gjuha e paracaktuar e aplikacionit",
    tr: "Uygulamanın varsayılan dili",
    sw: "Lugha ya chaguo la programu",
    am: "ተግባራዊ እትም ቋንቋ",
    om: "Afaanii kootii application",
    ig: "Asụsụ ndabara nke ngwa",
    so: "Luqadda hore ee barnaamijka",
    ha: "Harshe na tsoho na aikace-aikace",
    mnk: "Laba kafu application",
    ff: "Jumlaa luugal ngorgo",
    yo: "Ede aiyipada ohun elo"
  },

  "Add Image": {
    en: "Add Image",
    es: "Agregar imagen",
    fr: "Ajouter une image",
    de: "Bild hinzufügen",
    pt: "Adicionar imagem",
    it: "Aggiungi immagine",
    sq: "Shto imazh",
    tr: "Resim Ekle",
    sw: "Ongeza Picha",
    am: "ምስል አክል",
    om: "Suura Dabaluu",
    ig: "Tinye Ihe osise",
    so: "Kudarso Sawir",
    ha: "Ƙara Hoton",
    mnk: "Jɛkɔ Fɛɛri",
    ff: "Ngorgo Siffo",
    yo: "Fi Aworan kun"
  },
  "Add Video": {
    en: "Add Video",
    es: "Agregar video",
    fr: "Ajouter une vidéo",
    de: "Video hinzufügen",
    pt: "Adicionar vídeo",
    it: "Aggiungi video",
    sq: "Shto video",
    tr: "Video Ekle",
    sw: "Ongeza Video",
    am: "ቪዲዮ አክል",
    om: "Viidiyoo Dabaluu",
    ig: "Tinye Vidiyo",
    so: "Kudarso Video",
    ha: "Ƙara Bidiyo",
    mnk: "Jɛkɔ Video",
    ff: "Ngorgo Video",
    yo: "Fi Fidio kun"
  },
  Active: {
    en: "Active",
    pt: "Ativo",
    de: "Aktiv",
    es: "Activo",
    fr: "Actif",
    it: "Attivo",
    sq: "Aktiv",
    tr: "Aktif",
    sw: "Hai",
    am: "እርምጃ",
    om: "Hojii",
    ig: "Na-arụ ọrụ",
    so: "Hawlgalin",
    ha: "Aiki",
    mnk: "Fɔlɔ",
    ff: "Raawdi",
    yo: "Ṣiṣe"
  },
  "Activate devices": {
    en: "Activate devices",
    pt: "Ativar dispositivos",
    de: "Geräte aktivieren",
    es: "Activar dispositivos",
    fr: "Activer les appareils",
    it: "Attivare dispositivi",
    sq: "Aktivizoni pajisjet",
    tr: "Cihazları etkinleştir",
    sw: "Washa vifaa",
    am: "መሣሪያዎችን አንቀሳቅስ",
    om: "Meeshaalee Hojii Irra Oolchu",
    ig: "Mepụta ngwaọrụ",
    so: "Kicil waxaale",
    ha: "Kafaɗa na'urorin",
    mnk: "Wuloo jɔkoolu",
    ff: "Raawdi eɓe",
    yo: "Ṣiṣẹ awọn ẹrọ"
  },
  "Add New Subreseller": {
    en: "Add New Subreseller",
    pt: "Adicionar Novo Subrevendedor",
    de: "Neuen Unterwiederverkäufer hinzufügen",
    es: "Agregar Nuevo Subrevendedor",
    fr: "Ajouter un Nouveau Sous-revendeur",
    it: "Aggiungi Nuovo Subrivenditore",
    sq: "Shtoni Sub-Rivenditorin e Ri",
    tr: "Yeni Alt Bayi Ekle",
    sw: "Ongeza Subreseller Mpya",
    am: "አንደኛ ሰባት ማሻሻያ አክል",
    om: "Subreseller Haaraa Dabaluu",
    ig: "Tinye Subreseller Ọhụrụ",
    so: "Kudarso Subreseller Cusub",
    ha: "Ƙara Sabon Subreseller",
    mnk: "Jɛkɔ Subreseller Mbaru",
    ff: "Ɓe ngorgo Subreseller",
    yo: "Fi Subreseller Tuntun kun"
  },
  "Allow resellers to make use of DNS": {
    en: "Allow resellers to make use of DNS",
    pt: "Permitir que os revendedores usem DNS",
    de: "Erlauben Sie Wiederverkäufern, DNS zu verwenden",
    es: "Permitir a los revendedores hacer uso de DNS",
    fr: "Autoriser les revendeurs à utiliser le DNS",
    it: "Consenti ai rivenditori di utilizzare il DNS",
    sq: "Lejo shitësit të përdorin DNS",
    tr: "Bayiilerin DNS kullanmasına izin ver",
    sw: "Ruhusu wauzaji tena kutumia DNS",
    am: "እባክህ ምርቶች እንዲጠቀሙ ወታደሮችን እንደ DNS ተጠቀም",
    om: "Hammamtoonni DNS fayyadamuuf ni hayyamama",
    ig: "Kwe ka ndị na-ere ere jiri DNS",
    so: "U oggolaaya in iibiyeyaashu in ay u isticmaalaan DNS",
    ha: "Ba wa masu siye suyi amfani da DNS",
    mnk: "Rungilaa fɔ resellers la yi faa DNS",
    ff: "Laaɓe eɓe ngorgo DNS",
    yo: "Gba awọn olutaja laaye lati lo DNS"
  },
  "All Rights Reserved": {
    en: "All Rights Reserved",
    pt: "Todos os Direitos Reservados",
    de: "Alle Rechte vorbehalten",
    es: "Todos los derechos reservados",
    fr: "Tous droits réservés",
    it: "Tutti i diritti riservati",
    sq: "Të gjitha të drejtat e rezervuara",
    tr: "Tüm Hakları Saklıdır",
    sw: "Haki Zote Zimehifadhiwa",
    am: "ሁሉም መብቶች ተያዙ",
    om: "Mirga Hunda Eeggachaa Jira",
    ig: "Akwụkwọ niile echekwara",
    so: "Dhammaan Xuquuqda waa la hayaa",
    ha: "Dukkan Hakkin An karba",
    mnk: "Hɔrɔnya bɛ fɔ kɛra",
    ff: "Leyɗe ɓe toɓɓi",
    yo: "Gbogbo awọn ẹtọ wa ni idaabobo"
  },
  All: {
    en: "All",
    pt: "Todos",
    de: "Alle",
    es: "Todos",
    fr: "Tous",
    it: "Tutti",
    sq: "Të gjitha",
    tr: "Hepsi",
    sw: "Yote",
    am: "ሁሉም",
    om: "Hundee",
    ig: "Ihe niile",
    so: "Dhammaan",
    ha: "Duka",
    mnk: "Fama",
    ff: "Eɓe",
    yo: "Gbogbo"
  },
  adults: {
    en: "adults",
    pt: "adultos",
    de: "Erwachsene",
    es: "adultos",
    fr: "adultes",
    it: "adulti",
    sq: "Të rriturit",
    tr: "yetişkinler",
    sw: "Watu wazima",
    am: "ሕጋዊ ሰዎች",
    om: "Dargaggoota",
    ig: "Ndụrịsịrị",
    so: "Dadweynaha",
    ha: "Manya",
    mnk: "Nyaamudo",
    ff: "Ɓe toɓɓi",
    yo: "Awọn agba"
  },
  Amount: {
    en: "Amount",
    pt: "Quantidade",
    de: "Betrag",
    es: "Cantidad",
    fr: "Montant",
    it: "Importo",
    sq: "Shuma",
    tr: "Miktar",
    sw: "Kiasi",
    am: "መጠን",
    om: "Baay'ee",
    ig: "Ọnụ",
    so: "Qaddar",
    ha: "Yawan",
    mnk: "Kafu",
    ff: "Leyɗe",
    yo: "Iye"
  },
  Actives: {
    en: "Actives",
    pt: "Ativos",
    de: "Aktiv",
    es: "Activos",
    fr: "Actifs",
    it: "Attivi",
    sq: "Aktivë",
    tr: "Aktifler",
    sw: "Waliounganishwa",
    am: "አንቀሳቅሶች",
    om: "Raawwiiwwan",
    ig: "Ndị ọrụ",
    so: "Hawlgalinta",
    ha: "Masu aiki",
    mnk: "Jɛkɔ kafu",
    ff: "Raawdi eɓe",
    yo: "Awọn iṣẹ"
  },
  "Activated by": {
    en: "Activated by",
    pt: "Ativado por",
    de: "Aktiviert durch",
    es: "Activado por",
    fr: "Activé par",
    it: "Attivato da",
    sq: "Aktivuar nga",
    tr: "Tarafından etkinleştirildi",
    sw: "Imewezeshwa na",
    am: "በማን እንቅስቃሴ",
    om: "Raawwatameen",
    ig: "Kwụpụtara site na",
    so: "Hawlgalinta waxaa sameeyay",
    ha: "Anfani kafaɗa",
    mnk: "Jɛkɔ kafu le",
    ff: "Raawdi eɓe",
    yo: "Ṣiṣẹ nipasẹ"
  },
  Action: {
    en: "Action",
    pt: "Ação",
    de: "Aktion",
    es: "Acción",
    fr: "Action",
    it: "Azione",
    sq: "Veprim",
    tr: "Eylem",
    sw: "Hatua",
    am: "እርምጃ",
    om: "Hojii",
    ig: "Ihu",
    so: "Tallaabo",
    ha: "Aiki",
    mnk: "Fɔlɔ",
    ff: "Toɓɓi",
    yo: "Iṣe"
  },
  Activate: {
    en: "Activate",
    pt: "Ativar",
    de: "Aktivieren",
    es: "Activar",
    fr: "Activer",
    it: "Attivare",
    sq: "Aktivizo",
    tr: "Etkinleştir",
    sw: "Anzisha",
    am: "አንቀሳቅስ",
    om: "Raawwachuu",
    ig: "Mepụta",
    so: "Hawlgeli",
    ha: "Kaddamar",
    mnk: "Jɛkɔ",
    ff: "Raawi",
    yo: "Ṣiṣẹ"
  },
  Activation: {
    en: "Activation",
    pt: "Ativação",
    de: "Aktivierung",
    es: "Activación",
    fr: "Activation",
    it: "Attivazione",
    sq: "Aktivizim",
    tr: "Aktivasyon",
    sw: "Uanzishaji",
    am: "እንቅስቃሴ",
    om: "Raawwii",
    ig: "Mmepe",
    so: "Hawlgelin",
    ha: "Kaddamarwa",
    mnk: "Kafu",
    ff: "Raawdi",
    yo: "Iṣiṣẹ"
  },
  "Activate now or add free trial to device": {
    en: "Activate now or add free trial to device",
    pt: "Ativar agora ou adicionar teste gratuito ao dispositivo",
    de: "Jetzt aktivieren oder kostenlose Testversion zum Gerät hinzufügen",
    es: "Activar ahora o agregar prueba gratuita al dispositivo",
    fr: "Activer maintenant ou ajouter un essai gratuit au périphérique",
    it: "Attiva ora o aggiungi una prova gratuita al dispositivo",
    sq: "Aktivo tani ose shtoni provë falas në pajisje",
    tr: "Şimdi etkinleştir veya cihaza ücretsiz deneme ekle",
    sw: "Aktifisha sasa au ongeza majaribio bure kwenye kifaa",
    am: "አሁን አንቀሳቅስ ወይም ለኪፋ ማንነት ሙከራ በስተቀር",
    om: "Ammaa amma taja mijecha bilisii gara meeshaa",
    ig: "Gbaa ngwa ugbu a ma ọ bụ tinye ule efu na ngwaọrụ",
    so: "Hadda dhaqaaji ama ku dar tijaabo bila lacag ah qalabka",
    ha: "A kunna yanzu ko ƙara gwaji kyauta zuwa na'ura",
    mnk: "Jɛkɔ kafu kɔ fee naŋ ka addɔ free trial",
    ff: "Hakkunde eɓe so ɓe addi trial laa ko so",
    yo: "Muu ṣiṣẹ bayi tabi fi idanwo ọfẹ kun ẹrọ"
  },
  Approve: {
    en: "Approve",
    pt: "Aprovar",
    de: "Genehmigen",
    es: "Aprobar",
    fr: "Approuver",
    it: "Approvare",
    sq: "Mirato",
    tr: "Onayla",
    sw: "Idhinisha",
    am: "ፈቃድ አድርግ",
    om: "Eeyyama",
    ig: "Kwado",
    so: "Ogola",
    ha: "Amince",
    mnk: "Fɛli",
    ff: "Jooɗi",
    yo: "Fọwọsi"
  },
  "App name": {
    en: "App name",
    pt: "Nome do aplicativo",
    de: "App-Name",
    es: "Nombre de la aplicación",
    fr: "Nom de l'application",
    it: "Nome dell'app",
    sq: "Emri i aplikacionit",
    tr: "Uygulama adı",
    sw: "Jina la App",
    am: "ስም አፕሊኬሽን",
    om: "Maqaa App",
    ig: "Aha ngwa",
    so: "Magaca App",
    ha: "Sunan App",
    mnk: "App name",
    ff: "Innde App",
    yo: "Orukọ App"
  },
  Version: {
    en: "Version",
    es: "Versión",
    fr: "Version",
    de: "Version",
    pt: "Versão",
    it: "Versione",
    sq: "Versioni",
    tr: "Sürüm",
    sw: "Toleo",
    am: "ተመን",
    om: "Version",
    ig: "Ụdị",
    so: "Nooc",
    ha: "Sigar",
    mnk: "Version",
    ff: "Siiɓe",
    yo: "Ẹdà"
  },
  "App version": {
    en: "App version",
    pt: "Versão do aplicativo",
    de: "App-Version",
    es: "Versión de la aplicación",
    fr: "Version de l'application",
    it: "Versione dell'app",
    sq: "Versioni i aplikacionit",
    tr: "Uygulama sürümü",
    sw: "Toleo la App",
    am: "ተመን ስለ አፕሊኬሽን",
    om: "Version App",
    ig: "Ụdị ngwa",
    so: "Nooca App",
    ha: "Sigar App",
    mnk: "App version",
    ff: "Siiɓe App",
    yo: "Ẹdà App"
  },
  "API key": {
    en: "API key",
    pt: "Chave da API",
    de: "API-Schlüssel",
    es: "Clave de API",
    fr: "Clé API",
    it: "Chiave API",
    sq: "Çelësi API",
    tr: "API anahtarı",
    sw: "Funguo la API",
    am: "ቁልፍ ኤፒአይ",
    om: "Kiloo API",
    ig: "Igodo API",
    so: "Furaha API",
    ha: "Mabuɗin API",
    mnk: "API key",
    ff: "API koode",
    yo: "Bọtìn API"
  },
  "Access code": {
    en: "Access code",
    pt: "Código de acesso",
    de: "Zugangscode",
    es: "Código de acceso",
    fr: "Code d'accès",
    it: "Codice di accesso",
    sq: "Kodi i hyrjes",
    tr: "Erişim kodu",
    sw: "Nambari ya Ufikiaji",
    am: "አቅራቢ ኮድ",
    om: "Koodii seensa",
    ig: "Koodu ohere",
    so: "Koodhka galitaanka",
    ha: "Lambar shiga",
    mnk: "Code le jɛkɔrɔ",
    ff: "Koodii cɗi",
    yo: "Koodu wiwọle"
  },
  "Auto renew": {
    en: "Auto renew",
    pt: "Renovação automática",
    de: "Automatische Verlängerung",
    es: "Renovación automática",
    fr: "Renouvellement automatique",
    it: "Rinnovo automatico",
    sq: "Rinovim automatik",
    tr: "Otomatik yenileme",
    sw: "Renewi kiotomatiki",
    am: "አዶ እንደገና እንዲጀምር",
    om: "Otomaatika itti fufsiisu",
    ig: "Nkwalite akpaghị aka",
    so: "Cusboonaysii si otomaatig ah",
    ha: "Sabunta ta atomatik",
    mnk: "Fɛli ki otomatik",
    ff: "Hakkunde eɓe so ɓe hotooɗo",
    yo: "Tunṣe laifọwọyi"
  },
  "Add Credits": {
    en: "Add Credits",
    pt: "Adicionar créditos",
    de: "Guthaben hinzufügen",
    es: "Agregar créditos",
    fr: "Ajouter des crédits",
    it: "Aggiungi crediti",
    sq: "Shtoni kredite",
    tr: "Kredi ekle",
    sw: "Ongeza Mikopo",
    am: "ክሬዲት አክል",
    om: "Kireeditii dabaluu",
    ig: "Tinye Krediti",
    so: "Ku darso Kredi",
    ha: "Ƙara Kuɗi",
    mnk: "Suɓaa Krediti",
    ff: "Njaɓdi Krediti",
    yo: "Fi Kirediti kun"
  },
  "Auto approve": {
    en: "Auto approve",
    pt: "Aprovação automática",
    de: "Automatische Genehmigung",
    es: "Aprobación automática",
    fr: "Approbation automatique",
    it: "Approvazione automatica",
    sq: "Mirato automatikisht",
    tr: "Otomatik onayla",
    sw: "Idhinisha kiotomatiki",
    am: "አዶ መፅሀፍ እንዲሆን",
    om: "Eeyyama otomaatika",
    ig: "Kwado akpaghị aka",
    so: "Ogola si otomaatig ah",
    ha: "Amince ta atomatik",
    mnk: "Fɛli kafu",
    ff: "Gollu ɓe hotooɗo",
    yo: "Fọwọsi laifọwọyi"
  },
  "Activated from parent": {
    en: "Activated from parent",
    pt: "Ativado pelo pai",
    de: "Aktiviert vom Elternteil",
    es: "Activado desde el padre",
    fr: "Activé depuis le parent",
    it: "Attivato dal genitore",
    sq: "Aktivare nga prindërit",
    tr: "Ebeveyn tarafından etkinleştirildi",
    sw: "Imezimwa kutoka kwa mzazi",
    am: "ከአባት ተከታተለ",
    om: "Irra abbaa irraa jalqabame",
    ig: "Emepepụtara site na nne",
    so: "Hawlgelin ka soo baxay waalid",
    ha: "An kunna daga uwa",
    mnk: "Taaja kafu kɔ parent le",
    ff: "Hakkunde eɓe waɗɗi la pay",
    yo: "Mu ṣiṣẹ lati ọdọ obi"
  },
  "activation info": {
    en: "If you will not choose any types of activation the device will be automatically activated and the credit will be charged.",
    pt: "Se você não escolher nenhum tipo de ativação, o dispositivo será ativado automaticamente e o crédito será cobrado.",
    de: "Wenn Sie keine Aktivierungsart auswählen, wird das Gerät automatisch aktiviert und Ihr Guthaben wird belastet.",
    es: "Si no elige ningún tipo de activación, el dispositivo se activará automáticamente y se le cobrará el crédito.",
    fr: "Si vous ne choisissez pas de type d'activation, le dispositif sera activé automatiquement et le crédit sera débité.",
    it: "Se non scegli alcun tipo di attivazione, il dispositivo verrà attivato automaticamente e il credito verrà addebitato.",
    sq: "Nëse nuk zgjedhni asnjë lloj aktivizimi, pajisja do të aktivizohet automatikisht dhe do të ngarkohet krediti.",
    tr: "Herhangi bir etkinleştirme türü seçmezseniz, cihaz otomatik olarak etkinleştirilecek ve kredi hesabınızdan düşülecektir.",
    sw: "Ikiwa hutachagua aina yoyote ya uanzishaji, kifaa kitawashwa moja kwa moja na krediti itatozwa.",
    am: "እንደሆነም ምንም የአካባቢ አይነት እንደማትምረጡ፣ መሳሪያው በራሱ እንደአካባቢ ተከታተለ እና ተመን እንደአካባቢ ይከፈላል።",
    om: "Yoo gosa tajaajilaa tokko illee hin filanne, meeshaan siʼachaa sirna jalqabsiifama, kreditiin immoo gatii ni kaffalama.",
    ig: "Ọ bụrụ na ịchọrọ nhọrọ nke ụdị mmepe, ngwaọrụ ga-emepegharị na-akpaghị aka, a ga-akwụ ụgwọ kredit.",
    so: "Haddii aadan dooran nooc kasta oo hawlgelinta ah, qalabku si otomaatig ah ayaa loo hawlgelin doonaa, lacagta ayaa lagu dari doonaa.",
    ha: "Idan bakada zaɓar kowanne irin kunna ba, na'urar za ta kunna ta atomatik, kuma za a caje kuɗin.",
    mnk: "Sumaŋo bɛrɛwo, niɛ bɛrɛwo jɔɔrɔ kafu fɔ, device taaja, krediti yɛrɛŋɛnna kɔna.",
    ff: "So aɓe naatinta ɗum, na'i ɗe hokkunde ɗi naatunde laɓe, krediti waɗi la.",
    yo: "Ti o ba ko ba yan iru iṣiṣẹ kankan, ẹrọ naa yoo mu ṣiṣẹ laifọwọyi ati pe a yoo gba owo naa."
  },
  "Activated from device": {
    en: "Activated from device",
    pt: "Ativado a partir do dispositivo",
    de: "Aktiviert vom Gerät",
    es: "Activado desde el dispositivo",
    fr: "Activé depuis l'appareil",
    it: "Attivato dal dispositivo",
    sq: "Aktivizuar nga pajisja",
    tr: "Cihazdan etkinleştirildi",
    sw: "Imezimwa kutoka kwa kifaa",
    am: "ከመሳሪያ ተከታተለ",
    om: "Irra meeshaa irraa jalqabame",
    ig: "Emepepụtara site na ngwaọrụ",
    so: "Hawlgelin ka soo baxay qalabka",
    ha: "An kunna daga na'ura",
    mnk: "Taaja kafu kɔ device le",
    ff: "Hakkunde eɓe waɗɗi la na'i",
    yo: "Mu ṣiṣẹ lati inu ẹrọ"
  },
  "Activated from panel": {
    en: "Activated from panel",
    pt: "Ativado a partir do painel",
    de: "Aktiviert vom Panel",
    es: "Activado desde el panel",
    fr: "Activé depuis le panneau",
    it: "Attivato dal pannello",
    sq: "Aktivizuar nga paneli",
    tr: "Panelden etkinleştirildi",
    sw: "Imezimwa kutoka kwa paneli",
    am: "ከፓኔል ተከታተለ",
    om: "Irra piraana irraa jalqabame",
    ig: "Emepepụtara site na panel",
    so: "Hawlgelin ka soo baxay panel-ka",
    ha: "An kunna daga panel",
    mnk: "Taaja kafu kɔ panel le",
    ff: "Hakkunde eɓe waɗɗi la panel",
    yo: "Mu ṣiṣẹ lati inu panẹli"
  },
  "Activate device": {
    en: "Activate device",
    pt: "Ativar dispositivo",
    de: "Gerät aktivieren",
    es: "Activar dispositivo",
    fr: "Activer l'appareil",
    it: "Attiva dispositivo",
    sq: "Aktivizo pajisjen",
    tr: "Cihazı etkinleştir",
    sw: "Washa kifaa",
    am: "መሳሪያ አክትተል",
    om: "Meeshaa jalqabsiisi",
    ig: "Mepụta ngwaọrụ",
    so: "Hawlgelin qalab",
    ha: "Kunna na'ura",
    mnk: "Taaja device",
    ff: "Hakkunde na'i",
    yo: "Mu ẹrọ ṣiṣẹ"
  },

  "Account Status": {
    en: "Account Status",
    pt: "Status da conta",
    de: "Kontostatus",
    es: "Estado de la cuenta",
    fr: "État du compte",
    it: "Stato dell'account",
    sq: "Statusi i Llogarisë",
    tr: "Hesap Durumu",
    sw: "Hali ya Akaunti",
    am: "ሁኔታ አካውንት",
    om: "Haala akawuntii",
    ig: "Ọnọdụ akaụntụ",
    so: "Xaaladda Akountiga",
    ha: "Matsayin Asusun",
    mnk: "Kafu le Account",
    ff: "Aade fowru",
    yo: "Ipo Iṣiro"
  },
  "Add new device": {
    en: "Add New Device",
    pt: "Adicionar Novo Dispositivo",
    de: "Neues Gerät Hinzufügen",
    es: "Agregar Nuevo Dispositivo",
    fr: "Ajouter Un Nouveau Dispositif",
    it: "Aggiungi Nuovo Dispositivo",
    sq: "Shto Pajisje Të Reja",
    tr: "Yeni Cihaz Ekle",
    sw: "Ongeza kifaa kipya",
    am: "አዲስ መሳሪያ ያክሉ",
    om: "Meeshaa haaraa dabaluu",
    ig: "Tinye ngwaọrụ ọhụrụ",
    so: "Ku darso qalab cusub",
    ha: "Ƙara sabuwar na'ura",
    mnk: "Suɓaa device leñol",
    ff: "Njaɓdi device ɗe hotooɗo",
    yo: "Fi ẹrọ tuntun kun"
  },
  "Add New DNS": {
    en: "Add New DNS",
    pt: "Adicionar Novo DNS",
    de: "Neue DNS Hinzufügen",
    es: "Agregar Nuevo DNS",
    fr: "Ajouter Un Nouveau DNS",
    it: "Aggiungi Nuovo DNS",
    sq: "Shto DNS Të Reja",
    tr: "Yeni DNS Ekle",
    sw: "Ongeza DNS Mpya",
    am: "አዲስ የDNS አክል",
    om: "DNS haaraa dabaluu",
    ig: "Tinye DNS ọhụrụ",
    so: "Ku darso DNS cusub",
    ha: "Ƙara sabbin DNS",
    mnk: "Suɓaa DNS leñol",
    ff: "Njaɓdi DNS ɗe hotooɗo",
    yo: "Fi DNS tuntun kun"
  },
  "All devices": {
    en: "All devices",
    pt: "Todos os dispositivos",
    de: "Alle Geräte",
    es: "Todos los dispositivos",
    fr: "Tous les appareils",
    it: "Tutti i dispositivi",
    sq: "Të gjitha pajisjet",
    tr: "Tüm cihazlar",
    sw: "Vifaa vyote",
    am: "መሳሪያዎች ሁሉ",
    om: "Meeshaalee hunda",
    ig: "Ngwaọrụ niile",
    so: "Dhammaan qalabka",
    ha: "Dukkan na'urori",
    mnk: "Device kafu",
    ff: "Na'i eɗi",
    yo: "Gbogbo awọn ẹrọ"
  },
  Approved: {
    en: "Approved",
    pt: "Aprovado",
    de: "Genehmigt",
    es: "Aprobado",
    fr: "Approuvé",
    it: "Approvato",
    sq: "Miratuar",
    tr: "Onaylandı",
    sw: "Imekubaliwa",
    am: "ተፈቅዷል",
    om: "Eeyyamame",
    ig: "Akwadoro",
    so: "La oggolaaday",
    ha: "An amince",
    mnk: "Jokoolu",
    ff: "Gollu",
    yo: "Fọwọsi"
  },
  "Activated devices": {
    en: "Activated devices",
    pt: "Dispositivos ativados",
    de: "Aktivierte Geräte",
    es: "Dispositivos activados",
    fr: "Appareils activés",
    it: "Dispositivi attivati",
    sq: "Pajisje të aktivizuara",
    tr: "Etkinleştirilen cihazlar",
    sw: "Vifaa vilivyowashwa",
    am: "መተግበሪያ ተሞክረው የሆኑ መሳሪያዎች",
    om: "Meeshaalee tajaajilaa jalqabame",
    ig: "Ngwaọrụ e kere site na mmepe",
    so: "Qalabka la hawlgeliyay",
    ha: "Na'urorin da aka kunna",
    mnk: "Device bɛrɛwo ke taaja",
    ff: "Na'i jeeɗi ɗe jaɓɓude",
    yo: "Ẹrọ ti a ti mu ṣiṣẹ"
  },
  "Allow app branding": {
    en: "Allow app branding",
    pt: "Permitir marca de aplicativo",
    de: "App-Branding zulassen",
    es: "Permitir marca de la aplicación",
    fr: "Autoriser la marque de l'application",
    it: "Consenti il branding dell'app",
    sq: "Lejo brandingun e aplikacionit",
    tr: "Uygulama markalamasına izin ver",
    sw: "Ruhusu ufanisi wa app",
    am: "እባኮት አፕ ብራንዲንግ እንዲከታተል",
    om: "Hayyama gochaalee app ilaalchisee",
    ig: "Kwe ka aha akwụkwọ ngosi ngwa",
    so: "U oggola astaanada barnaamijka",
    ha: "Bari app ya kasance tare da alama",
    mnk: "Jɔɔri keŋ ke branding la app",
    ff: "Lollude eɓe la brand e app",
    yo: "Gba ami iyasọtọ app"
  },
  "Activation type": {
    en: "Activation type",
    pt: "Tipo de ativação",
    de: "Aktivierungstyp",
    es: "Tipo de activación",
    fr: "Type d'activation",
    it: "Tipo di attivazione",
    sq: "Lloji i aktivizimit",
    tr: "Aktivasyon türü",
    sw: "Aina ya uanzishaji",
    am: "አካባቢ ማስተካከል",
    om: "Gosa sirna fayyadamuu",
    ig: "Ụdị nchịkọta",
    so: "Nooca hawlgelinta",
    ha: "Nau'in kunna",
    mnk: "Sumaŋo bɛrɛwo",
    ff: "Njaɓdi activation",
    yo: "Irú iṣiṣẹ"
  },
  "Add notification": {
    en: "Add notification",
    pt: "Adicionar notificação",
    de: "Benachrichtigung hinzufügen",
    es: "Agregar notificación",
    fr: "Ajouter une notification",
    it: "Aggiungi notifica",
    sq: "Shto njoftim",
    tr: "Bildirim ekle",
    sw: "Ongeza arifa",
    am: "አዲስ ማሳወቂያ ያክሉ",
    om: "Yaada haaraa dabaluu",
    ig: "Tinye mkpado ọhụụ",
    so: "Ku darso oge-siinta cusub",
    ha: "Ƙara sanarwa",
    mnk: "Suɓaa notification leñol",
    ff: "Njaɓdi notification ɗe hotooɗo",
    yo: "Fi ìkìlọ̀ tuntun kun"
  },
  "Add New Sub-Reseller": {
    en: "Add New Sub-Reseller",
    pt: "Adicionar Novo Sub-Revendedor",
    de: "Neuen Unterwiederverkäufer hinzufügen",
    es: "Agregar Nuevo Subrevendedor",
    fr: "Ajouter un Nouveau Sous-revendeur",
    it: "Aggiungi Nuovo Sub-Rivenditore",
    sq: "Shtoni Sub-Rishitës të Ri",
    tr: "Yeni Alt Bayi Ekle",
    sw: "Ongeza Sub-Reseller Mpya",
    am: "አዲስ ንግድ ተሽጣሪ አክል",
    om: "Sub-Reseller haaraa dabalu",
    ig: "Tinye onye ahịa ọzọ ọhụrụ",
    so: "Ku darso Sub-Reseller cusub",
    ha: "Ƙara Sabon Sub-Reseller",
    mnk: "Suɓaa Sub-Reseller leñol",
    ff: "Njaɓdi Sub-Reseller ɗe hotooɗo",
    yo: "Fi Sub-Reseller tuntun kun"
  },
  "Admin Panel Login Page": {
    en: "Admin Panel Login Page",
    pt: "Página de Login do Painel de Administração",
    de: "Anmeldeseite des Admin-Panels",
    es: "Página de Inicio de Sesión del Panel de Administración",
    fr: "Page de Connexion du Panneau d'Administration",
    it: "Pagina di Accesso al Pannello di Amministrazione",
    sq: "Faqe Hyrjeje në Panelin e Adminitratitës",
    tr: "Yönetici Paneli Giriş Sayfası",
    sw: "Ukuranja wa Ingia wa Paneli ya Admin",
    am: "ጠቅላላ ተጠቃሚ ማህበረ ሰብ ገፅ",
    om: "Fuula seensa panelii Bulchiinsaa",
    ig: "Okwu nbanye nke paneli njikwa",
    so: "Bogga Gelitaanka Gudiga Maamulka",
    ha: "Shafin Shiga Panel din Admin",
    mnk: "Fɔrɔ bɔlu, Admin panel gbanla",
    ff: "Panel ɗe tawruɗe admin banni",
    yo: "Ojú-ìwé Wọlé ti Ẹka Alákóso"
  },
  "Add new DNS configurations": {
    en: "Add new DNS configurations",
    pt: "Adicionar novas configurações de DNS",
    de: "Neue DNS-Konfigurationen hinzufügen",
    es: "Agregar nuevas configuraciones de DNS",
    fr: "Ajouter de nouvelles configurations DNS",
    it: "Aggiungi nuove configurazioni DNS",
    sq: "Shto konfigurime të reja të DNS",
    tr: "Yeni DNS yapılandırmaları ekle",
    sw: "Ongeza mipangilio mipya ya DNS",
    am: "አዲስ የDNS ኮንፊግራሽኖችን ያክሉ",
    om: "DNS haala haaraa dabalataa",
    ig: "Tinye mgbanwe DNS ọhụrụ",
    so: "Kudarso qaabeynta DNS cusub",
    ha: "Ƙara sabbin saitunan DNS",
    mnk: "Suɓaa DNS leñol ye",
    ff: "Njaɓdi DNS ɗe hotooɗo",
    yo: "Fi awọn eto DNS tuntun kun"
  },
  "Activate without free trial": {
    en: "Activate without free trial",
    pt: "Ativar sem teste gratuito",
    de: "Aktivieren ohne kostenlose Testversion",
    es: "Activar sin prueba gratuita",
    fr: "Activer sans essai gratuit",
    it: "Attiva senza prova gratuita",
    sq: "Aktivo pa provë falasë",
    tr: "Ücretsiz deneme olmadan etkinleştir",
    sw: "Washa bila majaribio bure",
    am: "በነፃ ሙከራ እንደማይሆን አስተካክል",
    om: "Tajaajilaa bilisa taʼee hin jalqabne",
    ig: "Mepee ngwa enweghị ule efu",
    so: "Hawsho adigoon tijaabo bilaash ah",
    ha: "A kunna ba tare da gwaji kyauta ba",
    mnk: "Sumaŋo njanɗo kɔrɔxɔmɔ ye free trial",
    ff: "Naɗi hoore kadi alaa e tumaade",
    yo: "Mu ṣiṣẹ lai idanwo ọfẹ"
  },
  "Auto renew when device expired": {
    en: "Auto renew when device expired",
    pt: "Renovação automática quando o dispositivo expirar",
    de: "Automatische Verlängerung, wenn das Gerät abgelaufen ist",
    es: "Renovación automática cuando el dispositivo haya caducado",
    fr: "Renouvellement automatique lorsque l'appareil a expiré",
    it: "Rinnova automaticamente quando il dispositivo è scaduto",
    sq: "Rinovohet automatikisht kur pajisja skadon",
    tr: "Cihazın süresi dolduğunda otomatik yenile",
    sw: "Renewi kiotomatiki wakati kifaa kimeisha",
    am: "በመነሻ ከመሳሪያው ጊዜ በኋላ እንዲከታተል",
    om: "Yeroo meeshaan guyyoota darbaniif fixate, deebisanii haaromsi",
    ig: "Họrọ ntinyegharị mgbe ngwaọrụ furu efu",
    so: "Kordhi marka qalabka uu dhacay",
    ha: "Sake sabunta lokacin da na'ura ta ƙare",
    mnk: "Taa jaama bɛrɛwo kɔsɔɔrɔ ye device di",
    ff: "E faale jango dumuɗɗi, kadi ɗeere mawɓe",
    yo: "Tunṣe aifọwọyi nigbati ẹrọ ba pari"
  },
  "Are you sure you want to": {
    en: "Are you sure you want to",
    pt: "Tem certeza de que deseja",
    de: "Sind Sie sicher, dass Sie möchten",
    es: "¿Estás seguro de que quieres",
    fr: "Êtes-vous sûr de vouloir",
    it: "Sei sicuro di voler",
    sq: "A jeni të sigurtë që doni të",
    tr: "Bunu yapmak istediğinize emin misiniz",
    sw: "Je, uko tayari kutaka",
    am: "እርግጠኛ ነህ ለማድረግ",
    om: "Dhugumaan akka gochuu barbaaddaa",
    ig: "Ị kwere ịga",
    so: "Ma waxaad hubtaa inaad rabto",
    ha: "Shin ka tabbantahay kana sonka",
    mnk: "Alaa te bɛlɛngoo",
    ff: "No njaltude ngal",
    yo: "Ṣe o daada lati"
  },
  "Are you sure you want to delete": {
    en: "Are you sure you want to delete?",
    pt: "Tem certeza de que deseja excluir?",
    de: "Sind Sie sicher, dass Sie löschen möchten?",
    es: "¿Estás seguro de que quieres borrar?",
    fr: "Êtes-vous sûr de vouloir supprimer ?",
    it: "Sei sicuro di voler eliminare?",
    sq: "A jeni të sigurtë që doni ta fshijeni?",
    tr: "Silmek istediğinize emin misiniz?",
    sw: "Je, uko tayari kufuta?",
    am: "እርግጠኛ ነህ ለማስረጃ ይፈልጋሉ?",
    om: "Dhugumaan akkaa haadha jechuun fedha kee?",
    ig: "Ị kwere iji hichapụ?",
    so: "Ma waxaad hubtaa inaad tirtirto?",
    ha: "Shin ka tabbantahay za a goge?",
    mnk: "Alaa te bɛlɛngoo ɲaaka ye?",
    ff: "No njaltude ngal, to bɛe ngel?",
    yo: "Ṣe o daada lati pa?"
  },

  "Allow users to login from application": {
    en: "Allow users to login from application",
    pt: "Permitir que os usuários façam login pelo aplicativo",
    de: "Erlauben Sie Benutzern, sich von der Anwendung aus anzumelden",
    es: "Permitir que los usuarios inicien sesión desde la aplicación",
    fr: "Autoriser les utilisateurs à se connecter depuis l'application",
    it: "Consenti agli utenti di effettuare l'accesso dall'applicazione",
    sq: "Lejo përdoruesve të hyjnë në llogari nga aplikacioni",
    tr: "Kullanıcıların uygulamadan giriş yapmasına izin ver",
    sw: "Waruhusu watumiaji kuingia kutoka kwa programu",
    am: "ተጠቃሚዎችን ከመተግበሪያ ለመግባት አስችሏቸው",
    om: "Fayyadamtoota gara tajaajila seenuuf hayyamuu",
    ig: "Kwe ka ndị ọrụ banye site na ngwa ahụ",
    so: "U oggola isticmaale inay gasho barnaamijka",
    ha: "Bari masu amfani su shiga daga aikace-aikacen",
    mnk: "Jɔɔri keŋ ke ɓennaaŋo na la programu yinna",
    ff: "Lollude eƴa tawruɗe kadi jooɗanɗe haŋki",
    yo: "Gba awọn olumulo laaye lati wọle lati inu eto naa"
  },

  "An unknown error occurred": {
    en: "An unknown error occurred",
    pt: "Ocorreu um erro desconhecido",
    de: "Ein unbekannter Fehler ist aufgetreten",
    es: "Ocurrió un error desconocido",
    fr: "Une erreur inconnue s'est produite",
    it: "Si è verificato un errore sconosciuto",
    sq: "Ndodhi një gabim i panjohur",
    tr: "Bilinmeyen bir hata oluştu",
    sw: "Hitilafu isiyojulikana ilitokea",
    am: "ማንበብ ስህተት ተከስቷል",
    om: "Dogoggora hin beekamne uumame",
    ig: "Mmemme a na-apụghị ịkọwa emeela",
    so: "Khalad aan la aqoon ayaa dhacay",
    ha: "An yi kuskure mara sani",
    mnk: "Sumaŋo kɔsɔɔrɔ wulu bɛ!",
    ff: "Hooɗo tawii ko ƴettude",
    yo: "Aṣiṣe aimọ ṣẹlẹ"
  },

  "Are you sure you want to remove this banner ad": {
    en: "Are you sure you want to remove this banner ad?",
    es: "¿Estás seguro de que deseas eliminar este banner?",
    fr: "Êtes-vous sûr de vouloir supprimer cette bannière publicitaire ?",
    de: "Sind Sie sicher, dass Sie dieses Banner-Werbung entfernen möchten?",
    pt: "Tem certeza de que deseja remover este banner?",
    it: "Sei sicuro di voler rimuovere questo banner?",
    sq: "Jeni të sigurt që dëshironi të hiqni këtë reklamë banner?",
    tr: "Bu banner reklamayı kaldırmak istediğinize emin misiniz?",
    sw: "Je, uko tayari kuondoa tangazo hili la bango?",
    am: "እርግጠኛ ነህ ስለምንታዊ ባነር ማስታወቂያ ማስተካከል?",
    om: "Dhugumaan banner ad kana haqsuuf barbaaddaa?",
    ig: "Ị kwere iwepụ mgbasa ozi a?",
    so: "Ma waxaad hubtaa inaad ka saarto xayaysiiskan banner-ka?",
    ha: "Shin ka tabbantahay za a cire wannan tallan banner?",
    mnk: "Alaa te bɛlɛngoo jɔɔri banner ad?",
    ff: "No njaltude ngal, to bɛe ngel banner ad?",
    yo: "Ṣe o daada lati yọ ifihan banner yii?"
  },

  "Are you sure you want to approve this sub-reseller": {
    en: "Are you sure you want to approve this sub-reseller?",
    pt: "Tem certeza de que deseja aprovar este sub-revendedor?",
    de: "Sind Sie sicher, dass Sie diesen Unterwiederverkäufer genehmigen möchten?",
    es: "¿Estás seguro de que quieres aprobar a este subrevendedor?",
    fr: "Êtes-vous sûr de vouloir approuver ce sous-revendeur ?",
    it: "Sei sicuro di voler approvare questo sub-rivenditore?",
    sq: "A jeni të sigurtë që doni ta miratoni këtë nën-rishitësen?",
    tr: "Bu alt bayi onaylamak istediğinize emin misiniz?",
    sw: "Je, uko tayari kuruhusu sub-reseller hii?",
    am: "እርግጠኛ ነህ ይህን ተጨማሪ ሽያጭ እትም ለማስተካከል?",
    om: "Dhugumaan sub-reseller kana eyyamuu barbaaddaa?",
    ig: "Ị kwere ịkwado sub-reseller a?",
    so: "Ma waxaad hubtaa inaad ansixiso sub-reseller-kan?",
    ha: "Shin ka tabbantahay ka amincewa wannan sub-reseller?",
    mnk: "Alaa te bɛlɛngoo approve sub-reseller?",
    ff: "No njaltude ngal, to bɛe ngel sub-reseller?",
    yo: "Ṣe o daada lati fọwọsi sub-reseller yii?"
  },

  "Are you sure you want to cancel this sub-reseller": {
    en: "Are you sure you want to cancel this sub-reseller?",
    pt: "Tem certeza de que deseja cancelar este sub-revendedor?",
    de: "Sind Sie sicher, dass Sie diesen Unterwiederverkäufer stornieren möchten?",
    es: "¿Estás seguro de que quieres cancelar a este subrevendedor?",
    fr: "Êtes-vous sûr de vouloir annuler ce sous-revendeur ?",
    it: "Sei sicuro di voler annullare questo sub-rivenditore?",
    sq: "A jeni të sigurtë që doni ta anuloni këtë nën-rishitësen?",
    tr: "Bu alt bayi iptal etmek istediğinize emin misiniz?",
    sw: "Je, uko tayari kufuta sub-reseller hii?",
    am: "እርግጠኛ ነህ ይህን ተጨማሪ ሽያጭ እትም ለማስተካከል?",
    om: "Dhugumaan sub-reseller kana haqsuuf barbaaddaa?",
    ig: "Ị kwere ịkpochapụ sub-reseller a?",
    so: "Ma waxaad hubtaa inaad joojiso sub-reseller-kan?",
    ha: "Shin ka tabbantahay kanaf daidaita wannan sub-reseller?",
    mnk: "Alaa te bɛlɛngoo ɲaaka ye sub-reseller?",
    ff: "No njaltude ngal, to bɛe ngel sub-reseller?",
    yo: "Ṣe o daada lati fagile sub-reseller yii?"
  },

  "Attach account": {
    en: "Attach account",
    pt: "Anexar conta",
    de: "Konto anhängen",
    es: "Adjuntar cuenta",
    fr: "Attacher un compte",
    it: "Allega account",
    sq: "Bashkëngjit llogarinë",
    tr: "Hesabı ekle",
    sw: "Ambatanisha akaunti",
    am: "መለኪያን አስገባ",
    om: "Akaawuntii itti dabaluu",
    ig: "Jikọọ akaụntụ",
    so: "Ku xidh xisaabta",
    ha: "Hada account",
    mnk: "Kañu akun",
    ff: "Faale jango",
    yo: "Sofo akọọlẹ"
  },

  "Are you sure to delete this account": {
    en: "Are you sure to delete this account?",
    pt: "Tem certeza de que deseja excluir esta conta?",
    de: "Sind Sie sicher, dieses Konto zu löschen?",
    es: "¿Está seguro de que desea eliminar esta cuenta?",
    fr: "Êtes-vous sûr de vouloir supprimer ce compte?",
    it: "Sei sicuro di voler eliminare questo account?",
    sq: "A jeni të sigurtë që doni ta fshijeni këtë llogari?",
    tr: "Bu hesabı silmek istediğinize emin misiniz?",
    sw: "Je, uko tayari kufuta akaunti hii?",
    am: "እርግጠኛ ነህ እንደምን እባክህ አካውንት ማስረጃ?",
    om: "Dhugumaan akkaa haadha jechuun fedha kee?",
    ig: "Ị kwere iji hichapụ akaụntụ a?",
    so: "Ma waxaad hubtaa inaad tirtirto xisaabtan?",
    ha: "Shin ka tabbantahay za a goge wannan asusun?",
    mnk: "Alaa te bɛlɛngoo ɲaaka ye?",
    ff: "No njaltude ngal, to bɛe ngel?",
    yo: "Ṣe o daada lati pa akọọlẹ yii?"
  },

  "App reset": {
    en: "App reset",
    pt: "Reiniciar aplicativo",
    de: "App zurücksetzen",
    es: "Restablecer aplicación",
    fr: "Réinitialiser l'application",
    it: "Ripristina app",
    sq: "Riniapplikoni",
    tr: "Uygulamayı sıfırla",
    sw: "Kurejesha Programu",
    am: "ስልኩን ማስጀመር",
    om: "Haaromsuu Tajaajila",
    ig: "Nchịgharị Ngwa",
    so: "Dib u habeyn Barnaamijka",
    ha: "Sake saitin Aikace-aikace",
    mnk: "Programu jɔɔri",
    ff: "Jooɗude haŋki",
    yo: "Tun Eto"
  },

  "Allow users to reset the application": {
    en: "Allow users to reset the application",
    pt: "Permitir que os usuários resetem o aplicativo",
    de: "Benutzern ermöglichen, die App zurückzusetzen",
    es: "Permitir a los usuarios restablecer la aplicación",
    fr: "Autoriser les utilisateurs à réinitialiser l'application",
    it: "Consentire agli utenti di ripristinare l'applicazione",
    sq: "Lejoju përdoruesit të rivendosin aplikacionin",
    tr: "Kullanıcıların uygulamayı sıfırlamasına izin ver",
    sw: "Waruhusu watumiaji kurekebisha programu",
    am: "ተጠቃሚዎችን ስልኩን እንደገና ለመመለስ አስችሏቸው",
    om: "Fayyadamtoota tajaajila haaromsuuf hayyamuu",
    ig: "Kwe ka ndị ọrụ weghachite ngwa ahụ",
    so: "U oggola isticmaale inay dib u habeyn karaan barnaamijka",
    ha: "Bari masu amfani su sake saitin aikace-aikacen",
    mnk: "Jɔɔri keŋ ke ɓennaaŋo na la programu yinna",
    ff: "Lollude eƴa tawruɗeɓe kadi jooɗanɗe haŋki",
    yo: "Gba awọn olumulo laaye lati tun eto naa ṣe"
  },
  "Available Credits": {
    en: "Available Credits",
    pt: "Créditos Disponíveis",
    de: "Verfügbare Credits",
    es: "Créditos Disponibles",
    fr: "Crédits Disponibles",
    it: "Crediti Disponibili",
    sq: "Kreditet e disponueshme",
    tr: "Mevcut Krediler",
    sw: "Mikopo Inayopatikana",
    am: "እንቁላል ክሬዲት",
    om: "Kireeditii Argamuu",
    ig: "Krediti Dị N'Ụwa",
    so: "Kireedhiga La Heli Karo",
    ha: "Kiredit ɗin da ake da su",
    mnk: "Sama Kɔndɔkɔrɔw",
    ff: "Koowi maɓɓe",
    yo: "Kirediti Ti o Wa"
  },
  Available: {
    en: "Available",
    pt: "Disponível",
    de: "Verfügbar",
    es: "Disponible",
    fr: "Disponible",
    it: "Disponibile",
    sq: "E disponueshme",
    tr: "Mevcut",
    sw: "Inayopatikana",
    am: "የሚገኙ",
    om: "Argamuu",
    ig: "Dịnụ",
    so: "La Heli Karo",
    ha: "Ake Da Su",
    mnk: "Kɛ Kɔndɔkɔrɔw",
    ff: "Maɓɓe",
    yo: "Ti o Wa"
  },
  "Available credits": {
    en: "Available credits",
    pt: "Créditos disponíveis",
    de: "Verfügbare Guthaben",
    es: "Créditos disponibles",
    fr: "Crédits disponibles",
    it: "Crediti disponibili",
    sq: "Kreditet e disponueshme",
    tr: "Mevcut krediler",
    sw: "Mikopo Inayopatikana",
    am: "የሚገኙ ክሬዲት",
    om: "Kireeditii Argamuu",
    ig: "Krediti Dịnụ",
    so: "Kireedhiga La Heli Karo",
    ha: "Kiredit ɗin da ake da su",
    mnk: "Sama Kɔndɔkɔrɔw",
    ff: "Koowi maɓɓe",
    yo: "Kirediti Ti o Wa"
  },
  "Are you sure to export data": {
    en: "Are you sure to export data?",
    pt: "Tem certeza de que deseja exportar os dados?",
    de: "Möchten Sie die Daten wirklich exportieren?",
    es: "¿Estás seguro de exportar los datos?",
    fr: "Êtes-vous sûr de vouloir exporter les données?",
    it: "Sei sicuro di voler esportare i dati?",
    sq: "Jeni të sigurt që doni të eksportoni të dhënat?",
    tr: "Verileri dışa aktarmak istediğinizden emin misiniz?",
    sw: "Je, uko tayari kusafirisha data?",
    am: "እርግጠኛ ነህ ስለምንታዊ መረጃ ማስረጃ?",
    om: "Dhugumaan data ergaa gochuuf qophii dha?",
    ig: "Ị kwere iji zipu data?",
    so: "Ma waxaad hubtaa inaad dhoofinayso xogta?",
    ha: "Shin ka tabbantahay doni dubaaboo bayanai?",
    mnk: "Alaa te bɛlɛngoo tɛ data?",
    ff: "No njaltude data, eƴa bɔh?",
    yo: "Ṣe o daada lati gbe data?"
  },
  "Add New Banner": {
    en: "Add New Banner",
    es: "Agregar nuevo banner",
    fr: "Ajouter une nouvelle bannière",
    de: "Neues Banner hinzufügen",
    pt: "Adicionar novo banner",
    it: "Aggiungi nuovo banner",
    sq: "Shto një banner të ri",
    tr: "Yeni Afiş Ekle",
    sw: "Ongeza Bango Jipya",
    am: "አዲስ ባነር ጨምር",
    om: "Banoo Haaraa Dabalata",
    ig: "Tinye Banna Ohuru",
    so: "Ku darso Bannaanka Cusub",
    ha: "Ƙara Sabon Banna",
    mnk: "Suma Bango Jii",
    ff: "Suyti Banoo Ɓe Njaat",
    yo: "Fi Banna Tuntun"
  },
  //b
  "Best regards, XCloud Support team": {
    en: "Best regards, XCloud Support team.",
    pt: "Atenciosamente, equipe de suporte XCloud.",
    de: "Mit freundlichen Grüßen, XCloud-Supportteam.",
    es: "Saludos cordiales, equipo de soporte de XCloud.",
    fr: "Cordialement, l'équipe de support XCloud.",
    it: "Cordiali saluti, il team di supporto XCloud.",
    sq: "Me respekt, Ekipi i mbështetjes së XCloud.",
    tr: "Saygılarımızla, XCloud Destek Ekibi.",
    sw: "Salamu za dhati, Timu ya Msaada ya XCloud.",
    am: "በምስጋና ግርማቸው፣ የXCloud ድጋፍ ቡድን።",
    om: "Galatooma, XCloud Tajaajila Garee.",
    ig: "Ekele kacha, Otu Nkwado XCloud.",
    so: "Salaan diiran, Kooxda Taageerada XCloud.",
    ha: "Gaisuwa mafi kyau, Ƙungiyar Taimako ta XCloud.",
    mnk: "Jaama, XCloud Baluwo Taaŋ.",
    ff: "Aɗa, XCloud Tabbitaare Kɔnngol.",
    yo: "Ẹ kí, Ẹgbẹ Atilẹyin XCloud."
  },
  "background image size range": {
    en: "The minimal and maximal background image sizes in pixels must be 1280x720 to 3840x2160",
    es: "Los tamaños mínimos y máximos de la imagen de fondo en píxeles deben ser de 1280x720 a 3840x2160",
    fr: "Les tailles minimales et maximales de l'image d'arrière-plan en pixels doivent être de 1280x720 à 3840x2160",
    de: "Die minimalen und maximalen Hintergrundbildgrößen in Pixeln müssen zwischen 1280x720 und 3840x2160 liegen",
    pt: "Os tamanhos mínimos e máximos da imagem de fundo em pixels devem ser de 1280x720 a 3840x2160",
    it: "Le dimensioni minime e massime dell'immagine di sfondo in pixel devono essere da 1280x720 a 3840x2160",
    sq: "Përmasat minimale dhe maksimale të imazhit të sfondit në piksel duhet të jenë nga 1280x720 deri në 3840x2160",
    tr: "Arka plan resim boyutlarının minimum ve maksimum boyutları piksel cinsinden 1280x720 ile 3840x2160 olmalıdır",
    sw: "Ukubwa wa picha ya nyuma wa chini na wa juu kwa pixels lazima uwe 1280x720 hadi 3840x2160",
    am: "በፒክሰል ምስሎች ትንሽና ትልቅ መጠን እንደ 1280x720 እስከ 3840x2160 መሆን አለባቸው",
    om: "Guddina fi giddu-gala suuraa irraa gara pixel 1280x720 hanga 3840x2160 ta'uu qaba",
    ig: "Nzọụkwụ nke elu na nke pere mpe nke onyonyo ndabere na piksel nwere ike ịbụ 1280x720 ruo 3840x2160",
    so: "Cabbirka sawirka dambeedka ugu yar iyo ugu weyn ee pixels waa inuu ahaadaa 1280x720 ilaa 3840x2160",
    ha: "Girman hoton bango mafi ƙanƙanta da mafi girma a cikin pixels dole ne su kasance 1280x720 zuwa 3840x2160",
    mnk: "Maliyo bolo jaama ni bolo folo pixel bɛ fɔ 1280x720 tɛ 3840x2160",
    ff: "Saarol ngalaa e jaaloo mawɗo e pixels ɓe njottude 1280x720 e 3840x2160",
    yo: "Iwọn aworan abẹlẹ ti o kere julọ ati ti o tobi julọ ni awọn piksẹli gbọdọ jẹ 1280x720 si 3840x2160"
  },
  "Banner AD": {
    en: "Banner AD",
    es: "Anuncio de banner",
    fr: "Annonce bannière",
    de: "Bannerwerbung",
    pt: "Anúncio de banner",
    it: "Annuncio banner",
    sq: "Reklamë banner",
    tr: "Afiş Reklamı",
    sw: "Tangazo la Bango",
    am: "ባነር ኤዲ",
    om: "Ijaarsa AD",
    ig: "Bana Akwụkwọ mgbasa ozi",
    so: "Xayeysiiska Bannaanka",
    ha: "Tallan Banner",
    mnk: "Bango AD",
    ff: "Aɗɗa Banner",
    yo: "Akọsílẹ Banner"
  },
  Block: {
    en: "Block",
    pt: "Bloquear",
    de: "Blockieren",
    es: "Bloquear",
    fr: "Bloquer",
    it: "Blocca",
    sq: "Blloko",
    tr: "Engelle",
    sw: "Zuia",
    am: "እስትንፋስ",
    om: "Cufuu",
    ig: "Mechie",
    so: "Xirid",
    ha: "Dakatar",
    mnk: "Fata",
    ff: "Taba",
    yo: "Dena"
  },
  Blocked: {
    en: "Blocked",
    pt: "Bloqueado",
    de: "Blockiert",
    es: "Bloqueado",
    fr: "Bloqué",
    it: "Bloccato",
    sq: "Blokuar",
    tr: "Engellendi",
    sw: "Iliyozuiwa",
    am: "ተከላከለ",
    om: "Cufamee",
    ig: "Emechiri",
    so: "La Xiray",
    ha: "Dakatar",
    mnk: "Fata",
    ff: "Taba",
    yo: "Ti a Dena"
  },
  "Blocked devices": {
    en: "Blocked devices",
    pt: "Dispositivos bloqueados",
    de: "Blockierte Geräte",
    es: "Dispositivos bloqueados",
    fr: "Appareils bloqués",
    it: "Dispositivi bloccati",
    sq: "Pajisje të bllokuara",
    tr: "Engellendi cihazlar",
    sw: "Vifaa Vilivyozuiwa",
    am: "መሳሪያ ተከላከለ",
    om: "Meeshaalee Cufamanne",
    ig: "Ngwaọrụ Emechiri",
    so: "Qalabka La Xiray",
    ha: "Na'urorin Da Aka Dakatar",
    mnk: "Fatawu Ke Vandi",
    ff: "Pularu Bimbudu",
    yo: "Ẹrọ Ti a Dena"
  },
  "Blocked date": {
    en: "Blocked date",
    pt: "Data bloqueada",
    de: "Blockiertes Datum",
    es: "Fecha bloqueada",
    fr: "Date bloquée",
    it: "Data bloccata",
    sq: "Data e bllokuar",
    tr: "Engellendi tarih",
    sw: "Tarehe Iliyozuiwa",
    am: "ቀን ተከላከለ",
    om: "Guyyaa Cufamee",
    ig: "Ụbọchị Zoro Ezu",
    so: "Taariikhda La Xiray",
    ha: "Ranar da Aka Dakatar",
    mnk: "Janngo Fata",
    ff: "Cernoo Taba",
    yo: "Ọjọ́ Ti a Dena"
  },
  Branding: {
    en: "Branding",
    pt: "Marca",
    de: "Branding",
    es: "Marca",
    fr: "Marque",
    it: "Marchio",
    sq: "Brendimi",
    tr: "Markalama",
    sw: "Ujumuaji wa Alama",
    am: "ብራንዲንግ",
    om: "Mallattoowwan",
    ig: "Ịkpụzi Brand",
    so: "Sumadeyn",
    ha: "Alamar Kasuwanci",
    mnk: "Folo Jəŋgɔ",
    ff: "Koowi ɗiɗo",
    yo: "Ami"
  },
  "Back Home": {
    en: "Back Home",
    pt: "Voltar para Casa",
    de: "Zurück zur Startseite",
    es: "Volver a Inicio",
    fr: "Retour à l'accueil",
    it: "Torna a Casa",
    sq: "Kthehu në Shtëpi",
    tr: "Ana Sayfaya Dön",
    sw: "Rudi Nyumbani",
    am: "መመለስ ወደ ቤት",
    om: "Deebi'aa Manaa",
    ig: "Lagha na Ụlọ",
    so: "Ku laabo Guriga",
    ha: "Komawa Gida",
    mnk: "Janngo taan Kunda",
    ff: "Njoɓe waari e Dam",
    yo: "Pada Sile"
  },
  "Become a seller": {
    en: "Become a seller",
    pt: "Tornar-se um vendedor",
    de: "Verkäufer werden",
    es: "Convertirse en vendedor",
    fr: "Devenir vendeur",
    it: "Diventa un venditore",
    sq: "Bëhu Shitës",
    tr: "Satıcı Ol",
    sw: "Kuwa Muuzaaji",
    am: "እንደ ሻግም ሆን",
    om: "Hojii Waliin Ta'uu",
    ig: "Bụrụ Onye Ahịa",
    so: "Noqo Iibiyaha",
    ha: "Zama Mai Sayarwa",
    mnk: "Seyaa Kɔnɔkɔrɔw",
    ff: "Waale ɓe Nyiɗa",
    yo: "Di Olùtajà"
  },
  Buy: {
    en: "Buy",
    pt: "Comprar",
    de: "Kaufen",
    es: "Comprar",
    fr: "Acheter",
    it: "Acquistare",
    sq: "Blej",
    tr: "Satın Al",
    sw: "Nunua",
    am: "ግዛ",
    om: "Bitachuu",
    ig: "Zụta",
    so: "Iibso",
    ha: "Sayi",
    mnk: "Samu",
    ff: "Suyi",
    yo: "Ra"
  },
  "Buy Credit": {
    en: "Buy Credit",
    pt: "Comprar créditos",
    de: "Kredit kaufen",
    es: "Comprar crédito",
    fr: "Acheter des crédits",
    it: "Acquista credito",
    sq: "Blej kredi",
    tr: "Kredi satın al",
    sw: "Nunua Mikopo",
    am: "መግዛት ቭሪት",
    om: "Kireeditii Bitachuu",
    ig: "Zụta Krediịtị",
    so: "Iibso Kiriditi",
    ha: "Sayi Kuɗi",
    mnk: "Samu Kafu",
    ff: "Suyti Kredi",
    yo: "Ra Kirediti"
  },
  "Brand name": {
    en: "Brand name",
    pt: "Nome da marca",
    de: "Markenname",
    es: "Nombre de la marca",
    fr: "Nom de la marque",
    it: "Nome del marchio",
    sq: "Emri i markës",
    tr: "Marka adı",
    sw: "Jina la Brand",
    am: "ስም ብራንድ",
    om: "Maqqaa Markeetii",
    ig: "Aha Brand",
    so: "Magaca Brand",
    ha: "Sunan Alamar Kasuwanci",
    mnk: "Kafu Folo",
    ff: "Njaat ɗeere",
    yo: "Oruko Ami"
  },
  "Back To Login": {
    en: "Back To Login",
    pt: "Voltar para o Login",
    de: "Zurück zur Anmeldung",
    es: "Volver a Iniciar Sesión",
    fr: "Retour à la Connexion",
    it: "Torna al Login",
    sq: "Kthehu në Hyrje",
    tr: "Geri Dön Giriş",
    sw: "Rudi Kwa Ingia",
    am: "መመለስ ወደ ግባ",
    om: "Deebi'aa Gara Galmee",
    ig: "Laghachi na nbanye",
    so: "Ku laabo Galitaanka",
    ha: "Komawa zuwa Shiga",
    mnk: "Janngo taan Login",
    ff: "Njoɓe waari e Login",
    yo: "Pada si Wọle"
  },
  "Billing history": {
    en: "Billing history",
    pt: "Histórico de faturamento",
    de: "Abrechnungshistorie",
    es: "Historial de facturación",
    fr: "Historique de facturation",
    it: "Cronologia delle fatture",
    sq: "Historia e faturimit",
    tr: "Fatura geçmişi",
    sw: "Historia ya Malipo",
    am: "ታሪክ ክፍያ",
    om: "Seenaa Kaffaltii",
    ig: "Akụkọ ụgwọ",
    so: "Taariikhda Bixinta",
    ha: "Tarihin Biyan Kuɗi",
    mnk: "Jodou Baluwo",
    ff: "Tariikhu ŋalɗo",
    yo: "Itan ìsanwó"
  },
  "Become A Partner": {
    en: "Become A Partner",
    pt: "Torne-se um Parceiro",
    de: "Werde Partner",
    es: "Conviértete en Socio",
    fr: "Devenir Partenaire",
    it: "Diventa un Partner",
    sq: "Bëhu Partner",
    tr: "Ortak Ol",
    sw: "Kuwa Mshirika",
    am: "እንደ ሚካኤል ሆን",
    om: "Hojii Waliin Ta'uu",
    ig: "Bụrụ Onye Ọrụ",
    so: "Noqo Lamaan",
    ha: "Zama Abokin Huldar",
    mnk: "Seyaa Kɔnɔkɔrɔw",
    ff: "Waale Wondiya",
    yo: "Di Alabaṣepọ"
  },

  become_seller_option: {
    en: "Become seller option is giving you opportunity to sell add credits to sub reseller from your activation count balance. You can decide what sub reseller can use your activations and what sub reseller need to buy from you to continue using your service. You will be also able to take back credits from sub reseller.",
    pt: "A opção de se tornar vendedor oferece a você a oportunidade de vender créditos adicionais para sub-revendedores a partir do saldo do seu contador de ativações. Você pode decidir o que o sub-revendedor pode usar de suas ativações e o que o sub-revendedor precisa comprar de você para continuar usando seu serviço. Você também poderá recuperar créditos do sub-revendedor.",
    de: "Die Option, Verkäufer zu werden, bietet Ihnen die Möglichkeit, Guthaben aus Ihrem Aktivierungszähler an Unterverkäufer zu verkaufen. Sie können entscheiden, welche Aktivierungen der Unterverkäufer nutzen kann und was der Unterverkäufer von Ihnen kaufen muss, um Ihren Service weiterhin nutzen zu können. Sie werden auch in der Lage sein, Guthaben vom Unterverkäufer zurückzufordern.",
    es: "La opción de convertirse en vendedor le brinda la oportunidad de vender créditos adicionales a sub revendedores desde el saldo de su contador de activaciones. Usted puede decidir qué puede utilizar el sub revendedor de sus activaciones y qué debe comprar el sub revendedor de usted para seguir utilizando su servicio. También podrá recuperar créditos del sub revendedor.",
    fr: "L'option de devenir vendeur vous offre la possibilité de vendre des crédits supplémentaires aux sous-revendeurs à partir de votre solde de compteur d'activation. Vous pouvez décider ce que le sous-revendeur peut utiliser de vos activations et ce que le sous-revendeur doit acheter auprès de vous pour continuer à utiliser votre service. Vous pourrez également récupérer des crédits auprès du sous-revendeur.",
    it: "L'opzione di diventare venditore ti offre l'opportunità di vendere crediti aggiuntivi a sub rivenditori dal saldo del tuo contatore di attivazioni. Puoi decidere cosa il sub rivenditore può utilizzare delle tue attivazioni e cosa il sub rivenditore deve acquistare da te per continuare a utilizzare il tuo servizio. Sarai inoltre in grado di recuperare i crediti dal sub rivenditore.",
    sq: "Opsioni për të bërë shitës ju jep mundësinë të shisni kredite shtesë në nën-shitës nga balanca e llogaritësit tuaj të aktivizimit. Ju mund të vendosni se çfarë mund të përdorë nën-shitësi nga aktivizimet tuaja dhe se çfarë duhet të blejë nën-shitësi nga ju për të vazhduar përdorimin e shërbimit tuaj. Ju do të mundeni gjithashtu të merrni prapa kreditet nga nën-shitësi.",
    tr: "Satıcı olma seçeneği, aktivasyon sayısı bakiyenizden alt satıcılara ek kredi satma fırsatı sunmaktadır. Hangi alt satıcının aktivasyonlarınızı kullanabileceğine ve hangi alt satıcının hizmetinizi kullanmaya devam etmek için sizden ne satın alması gerektiğine karar verebilirsiniz. Ayrıca alt satıcılardan kredileri geri alma imkanına da sahip olacaksınız.",
    sw: "Chaguo la kuwa muuzaji linakupa fursa ya kuuza na kuongeza mikopo kwa mtumiaji mdogo kutoka kwa salio lako la akaunti ya uhamasishaji. Unaweza kuamua ni nini mtumiaji mdogo anaweza kutumia kutoka kwa uhamasishaji wako na ni nini mtumiaji mdogo anahitaji kununua kutoka kwako ili kuendelea kutumia huduma yako. Pia utaweza kuchukua mikopo kutoka kwa mtumiaji mdogo.",
    am: "ሻግም ምርጫ ለእርስዎ እድል ይሰጣል እንዲሁም በመሣሪያ ቁልፍ ተመን በዚህ እንዲመላለስ ማስፈን ከፍትሔ ሳይ ምርጫችኋል. በታምነት እናንተጠቃሚ ትቻትበጓ",
    om: "Filannoo gurgurtaa ta'uu siif kennaa fursaa ittiin daldaluun fi hojii daldalaa dabalataa jechoota gatii yeroo galfata keessa jiran irraa galchuun salphaatti muuxannootti fayyadamu. Akkamittii ni filachuudhaaf barruu galfata kana kan ittiin waantota daldaloota galmee hawaasaa abbaa hin hawwata?",
    ig: "Oge nke ịga na ahịa na-enye gị ohere ịre na tinye krediti na onye na-ere ahịa nke ọzọ site na ikike gị. Ị nwere ike ịchịkọta ihe ndị onye na-ere ahịa nke ọzọ nwere ike iji nwee ikike gị na ihe onye na-ere ahịa ga-achọ ịzụta n'aka gị iji nọgide na-eji ọrụ gị. Ị ga-enwekwa ike ịlaghachi crediti site na onye na-ere ahịa nke ọzọ.",
    so: "Ikhtiyaarka iibka ayaa ku siinaya fursad aad ku iibiso oo aad ku darto krediyo ka soo iibiyaha hoos yimaada xisaabtaada firfircoonida. Waxaad go'aansan kartaa waxa iibiyahan hoose uu isticmaali karo firfircoonidaada iyo waxa uu iibiyahan hoos u baahan yahay inuu kaa soo iibsado si uu u sii wato isticmaalka adeegaaga. Sidoo kale waxaad awoodi doontaa inaad ka soo ceshato krediyo iibiyaha hoose.",
    ha: "Zaɓin zama mai sayarwa yana ba ku damar sayar da ƙara kuɗi ga mai sayar da ƙaramin daga lissafin ku na haɓaka. Zaka iya yanke shawara abin da ƙaramin mai sayarwa zai iya amfani da haɓakarku da abin da ƙaramin mai sayarwa zai saya daga gare ku don ci gaba da amfani da sabis ɗinku. Hakanan za ku iya dawo da kuɗi daga ƙaramin mai sayarwa.",
    mnk: "Seyi kafole ke wo na hunno kafu na setaare yiwuleba saama kuyɲi fɔɔnɔ. Komaata laŋo yiwuleba soɓɔ kafu la o gajali sing kɔ laja. Waddi layi na fita i nyaa seko kobale kɔ mɛnŋe da kaŋ.",
    ff: "Njaatɗe gollu makko umma wondi fuuniinoo kayii faa njide haɓɓe. Welaa ko windi howwaajii mun nan laama hooɓe ben yeddi ɗoo ballalako. Handi waali huunii gabba ngol feccere hee hendi garen.",
    yo: "Aṣayan di iṣaaju rẹ nfun ọ ni aye lati ta ati fi awọn kirẹditi kun si alatapọ ti o wa lati inu iwontunwonsi rẹ. O le pinnu kini alatapọ naa le lo lati inu awọn iṣeto rẹ ati kini alatapọ naa nilo lati ra lọwọ rẹ lati tẹsiwaju lilo iṣẹ rẹ. Iwọ yoo tun ni anfani lati gba awọn kirẹditi pada lati ọdọ alatapọ."
  },

  "By admin": {
    en: "By admin",
    pt: "Pelo administrador",
    de: "Vom Administrator",
    es: "Por el administrado",
    fr: "Par l'administrateur",
    it: "Da amministratore",
    sq: "Nga administratori",
    tr: "Yönetici tarafından",
    sw: "Na msimamizi",
    am: "በአስተዳደር",
    om: "Bulchaa irraa",
    ig: "Site onye nchịkwa",
    so: "Maamulka",
    ha: "Ta mai gudanarwa",
    mnk: "Kan aalimoo",
    ff: "Mabbitiir e jeyaa",
    yo: "Ní alákóso"
  },

  "By platforms": {
    en: "By platforms",
    pt: "Por plataformas",
    de: "Nach Plattformen",
    es: "Por plataformas",
    fr: "Par plateformes",
    it: "Per piattaforme",
    sq: "Nga platforma",
    tr: "Platformlara göre",
    sw: "Kwa majukwaa",
    am: "በፓላትፎርም",
    om: "Plaatfoormii irratti",
    ig: "Site nyiwe ọrụ",
    so: "Barxadaha",
    ha: "Ta dandali",
    mnk: "Kan lewru kafu",
    ff: "Njaatɗe",
    yo: "Ní pẹpẹ"
  },

  "By device key": {
    en: "By device key",
    pt: "Por chave do dispositivo",
    de: "Nach Geräteschlüssel",
    es: "Por clave del dispositivo",
    fr: "Par clé de l'appareil",
    it: "Per chiave del dispositivo",
    sq: "Nga çelësi i pajisjes",
    tr: "Cihaz anahtarı ile",
    sw: "Kwa ufunguo wa kifaa",
    am: "በመሣሪያ ቁልፍ",
    om: "Maqaa meeshaa irratti",
    ig: "Site mkpụrụokwu ngwá ọrụ",
    so: "Furaha qalabka",
    ha: "Ta mabuɗin na'ura",
    mnk: "Kan ñaama baŋ",
    ff: "Mabbitiir e jiidi",
    yo: "Ní bọtìnì ẹrọ"
  },

  "By registration date": {
    en: "By registration date",
    pt: "Por data de registro",
    de: "Nach Anmeldedatum",
    es: "Por fecha de registro",
    fr: "Par date d'inscription",
    it: "Per data di registrazione",
    sq: "Nga data e regjistrimit",
    tr: "Kayıt tarihine göre",
    sw: "Kwa tarehe ya usajili",
    am: "በምርጫ ቀን",
    om: "Guyyaa galmee irratti",
    ig: "Site ndebanye aha",
    so: "Taariikhda diiwaangelinta",
    ha: "Ta tarihi na rajista",
    mnk: "Kan koñɔ baŋ",
    ff: "Cakkaɓe lëddi",
    yo: "Ní ọjọ́ ìforúkọsílẹ̀"
  },

  "Brand name must include only letters or numbers.": {
    en: "Brand name must include only letters or numbers.",
    pt: "O nome da marca deve conter apenas letras ou números.",
    de: "Der Markenname darf nur Buchstaben oder Zahlen enthalten.",
    es: "El nombre de la marca debe contener solo letras o números.",
    fr: "Le nom de la marque ne doit contenir que des lettres ou des chiffres.",
    it: "Il nome del marchio deve contenere solo lettere o numeri.",
    sq: "Emri i markës duhet të përfshijë vetëm shkronja ose numra.",
    tr: "Marka adı yalnızca harfler veya sayılar içermelidir.",
    sw: "Jina la chapa lazima liwe na herufi au nambari pekee.",
    am: "ስም ብራንድ በተለምዶ በአብራሪ ፊደላት ወይም ቁጥሮች ብቻ መሆን አለበት።",
    om: "Maamiltoonni maqaa brandii qofa barruulee ykn lakkoofsa qabaachuu qabu.",
    ig: "Aha aha ahịrị ahụ kwesịrị ịgụnye naanị mkpụrụedemede ma ọ bụ nọmba.",
    so: "Magaca brand-ka waa inuu ka koobnaado kaliya xarfaha ama tirooyinka.",
    ha: "Sunan alama dole ne kawai ya ƙunshi haruffa ko lambobi.",
    mnk: "Nka brand kafu be kaŋo baa rofini wo amano.",
    ff: "Inndé brand ndiyam waɗaayum ba anndu oruwal e njundu.",
    yo: "Orukọ ami gbọdọ ni lẹta tabi awọn nọmba nikan."
  },

  "By clicking on menu you can": {
    en: "By clicking on the 'Action' menu, you can 'Edit' some credentials of the chosen device or 'Deactivate/Activate' the device active/inactive period.",
    pt: "Ao clicar no menu 'Ação', você pode 'Editar' algumas credenciais do dispositivo escolhido ou 'Desativar/Ativar' o período ativo/inativo do dispositivo.",
    de: "Durch Klicken auf das 'Aktion'-Menü können Sie einige Anmeldeinformationen des ausgewählten Geräts 'Bearbeiten' oder das aktive/inaktive Zeitintervall des Geräts 'Deaktivieren/Aktivieren'.",
    es: "Al hacer clic en el menú 'Acción', puedes 'Editar' algunas credenciales del dispositivo seleccionado o 'Desactivar/Activar' el período activo/inactivo del dispositivo.",
    fr: "En cliquant sur le menu 'Action', vous pouvez 'Modifier' certaines informations d'identification du dispositif sélectionné ou 'Désactiver/Activer' la période active/inactive du dispositif.",
    it: "Cliccando sul menu 'Azione', puoi 'Modificare' alcune credenziali del dispositivo scelto o 'Disattivare/Attivare' il periodo attivo/inattivo del dispositivo.",
    sq: "Duke klikuar në menun 'Veprim', mund të 'Ndryshoni' disa kredenciale të pajisjes së zgjedhur ose 'Deaktivizoni/Aktivizoni' periudhën aktive/inaktive të pajisjes.",
    tr: "'Eylem' menüsüne tıklayarak, seçilen cihazın bazı kimlik bilgilerini 'Düzenleyebilir' veya cihazın aktif/pasif dönemini 'Devre Dışı Bırakabilir/Aktif Edilebilir' olursunuz.",
    sw: "Kwa kubonyeza kwenye menyu ya 'Action', unaweza 'Hariri' baadhi ya sifa za kifaa kilichochaguliwa au 'Zima/Washa' kipindi cha kifaa kuwa hai/kutoonekana.",
    am: "በ'እርምጃ' ሜኑ ላይ በመጫን ስለተመረጠው መሳሪያ ተመን አንዳንድ መለኪያዎችን 'አርትም' ወይም በመሳሪያው ቀን ወይም ማንበብ እንቁላል/አንቀሳቅስ።",
    om: "Gochuun menu 'Action' irratti, meeshaa filatamee jiruu fi 'Sirreeffama' fi 'Deaktiviti/Activate' yeroo meeshaa hojii hin jirre fi jiraachu.",
    ig: "Site na ịpịpị na 'Action' menu, ị nwere ike 'Dezie' ụfọdụ nkwado nke ngwaọrụ ahọpụtara ma ọ bụ 'Deaktiviti/Activate' oge nke ngwaọrụ dị ọkụ/nkịtị.",
    so: "Marka aad gujiso menu 'Action', waxaad 'Sax' qaar ka mid ah xogta qalabka aad dooratay ama 'Ka deactivi/activate' muddada qalabka firfircoon/nabdoon.",
    ha: "Taɓa a cikin menu na 'Action', zaku iya 'Gyara' wasu bayanan na na'urar da aka zaɓa ko 'Kashe/Akewa' lokacin aiki/ba aiki na na'urar.",
    mnk: "Kilikki fi menu 'Action', nye lo 'Taa' ka fow nafaŋa na dongɛn kafu lo 'Deaktiviti/Activate' dolɔ ɓay nofe lafi/nɔɔm.",
    ff: "Ñaɓɓa e menuy 'Action', a danna 'Soodii' ɗumɓe tiiɓe ɗiɗi ɗaŋngol ngal 'Deaktiviti/Activate' ɓeɗe dammii.",
    yo: "Nípa titẹ lori menu 'Action', o le 'Ṣatunkọ' diẹ ninu awọn asọye ẹrọ ti a ti yan tabi 'Deaktiviti/Activate' akoko ẹrọ ṣiṣẹ/pa."
  },

  "Browser version": {
    en: "Browser version",
    pt: "Versão do navegador",
    de: "Browser-Version",
    es: "Versión del navegador",
    fr: "Version du navigateur",
    it: "Versione del browser",
    sq: "Versioni i shfletuesit",
    tr: "Tarayıcı sürümü",
    sw: "Toleo la kivinjari",
    am: "ብሮዘር እትም",
    om: "Versioni browser",
    ig: "Ụdị nchọgharị",
    so: "Nooca biraawsarka",
    ha: "Nau'in burauza",
    mnk: "Versioni browser",
    ff: "Version browser",
    yo: "Ẹya aṣawakiri"
  },

  "Edit New Banner": {
    en: "Edit New Banner",
    es: "Editar nuevo banner",
    fr: "Modifier la nouvelle bannière",
    de: "Neues Banner bearbeiten",
    pt: "Editar novo banner",
    it: "Modifica nuovo banner",
    sq: "Redakto banerin e ri",
    tr: "Yeni Afişi Düzenle",
    sw: "Hariri bendera mpya",
    am: "አዲስ ባነር አርትም",
    om: "Banneer haaraa sirreeffama",
    ig: "Dezie akwụkwọ ngosi ọhụrụ",
    so: "Sax boodhka cusub",
    ha: "Gyara sabuwar baturi",
    mnk: "Taa banner baru",
    ff: "Soodii banner ndi",
    yo: "Ṣatunkọ akọle tuntun"
  },

  "End date": {
    en: "End date",
    es: "Fecha de finalización",
    fr: "Date de fin",
    de: "Enddatum",
    pt: "Data de término",
    it: "Data di fine",
    sq: "Data e përfundimit",
    tr: "Bitiş tarihi",
    sw: "Tarehe ya mwisho",
    am: "መጨረሻ ቀን",
    om: "Guyyaa dhumaa",
    ig: "Ụbọchị njedebe",
    so: "Taariikhda dhammaadka",
    ha: "Ranar ƙarshe",
    mnk: "Jóoroo faden",
    ff: "Ñalawal saare",
    yo: "Ọjọ ipari"
  },

  "Export CSV": {
    en: "Export CSV",
    pt: "Exportar CSV",
    de: "CSV exportieren",
    es: "Exportar CSV",
    fr: "Exporter CSV",
    it: "Esporta CSV",
    sq: "Eksporto CSV",
    tr: "CSV'yi Dışa Aktar",
    sw: "Peleka CSV",
    am: "ማስተናገድ CSV",
    om: "CSV baafachu",
    ig: "Mbupụ CSV",
    so: "Soo CSV",
    ha: "Fitar da CSV",
    mnk: "Taa CSV",
    ff: "Soodii CSV",
    yo: "Gba CSV"
  },

  "Earn money": {
    en: "Earn money",
    pt: "Ganhar dinheiro",
    de: "Geld verdienen",
    es: "Ganar dinero",
    fr: "Gagner de l'argent",
    it: "Guadagnare denaro",
    sq: "Fitoni para",
    tr: "Para kazan",
    sw: "Pata pesa",
    am: "ገንዘብ አግኝ",
    om: "Maallaqa argachuu",
    ig: "Nweta ego",
    so: "Kasbasho lacag",
    ha: "Samun kudi",
    mnk: "Faya kafu",
    ff: "Nja ngel",
    yo: "Gba owó"
  },
  Episode: {
    en: "Episode",
    pt: "Episódio",
    de: "Folge",
    es: "Episodio",
    fr: "Épisode",
    it: "Episodio",
    sq: "Episod",
    tr: "Bölüm",
    sw: "Sehemu",
    am: "ክፍል",
    om: "Boqonnaa",
    ig: "Nkebi",
    so: "Qeyb",
    ha: "Sashe",
    mnk: "Bɔɔla",
    ff: "Boojii",
    yo: "Igbese"
  },
  Edit: {
    en: "Edit",
    pt: "Editar",
    de: "Bearbeiten",
    es: "Editar",
    fr: "Modifier",
    it: "Modifica",
    sq: "Ndrysho",
    tr: "Düzenle",
    sw: "Hariri",
    am: "አርትም",
    om: "Sirreeffama",
    ig: "Dezie",
    so: "Sax",
    ha: "Gyara",
    mnk: "Taa",
    ff: "Soodii",
    yo: "Ṣatunkọ"
  },
  "Edit profile": {
    en: "Edit profile",
    pt: "Editar perfil",
    de: "Profil bearbeiten",
    es: "Editar perfil",
    fr: "Modifier le profil",
    it: "Modifica profilo",
    sq: "Ndrysho profilin",
    tr: "Profili düzenle",
    sw: "Hariri wasifu",
    am: "አካውንት አርትም",
    om: "Istaatusii sirreeffama",
    ig: "Dezie profaịlụ",
    so: "Sax profile-ka",
    ha: "Gyara bayanan",
    mnk: "Taa profile",
    ff: "Soodii profile",
    yo: "Ṣatunkọ profaili"
  },
  Email: {
    en: "Email",
    pt: "Email",
    de: "E-Mail",
    es: "Correo electrónico",
    fr: "E-mail",
    it: "Email",
    sq: "Email",
    tr: "E-posta",
    sw: "Barua pepe",
    am: "ኢሜል",
    om: "Imeeli",
    ig: "Email",
    so: "Email",
    ha: "Imel",
    mnk: "Email",
    ff: "Imel",
    yo: "Imeeli"
  },
  "Enter your email": {
    en: "Enter your email",
    pt: "Digite seu e-mail",
    de: "Geben Sie Ihre E-Mail ein",
    es: "Ingrese su correo electrónico",
    fr: "Saisissez votre adresse e-mail",
    it: "Inserisci la tua email",
    sq: "Shkruaj email-in tënd (të gjitha shkronjat e vogla)",
    tr: "E-posta adresinizi girin",
    sw: "Ingiza barua pepe yako",
    am: "ኢሜልዎን እንቀላቀል",
    om: "Imeeli kee galchaa",
    ig: "Tinye email gị",
    so: "Geli emailkaaga",
    ha: "Shigar da adireshin imel ɗinku",
    mnk: "Taa email la",
    ff: "Nja email maa",
    yo: "Tẹ imeeli rẹ"
  },
  "Enter 2FA verification code": {
    en: "Enter 2FA verification code",
    pt: "Digite o código de verificação 2FA",
    de: "Geben Sie den 2FA-Verifizierungscode ein",
    es: "Ingrese el código de verificación 2FA",
    fr: "Saisissez le code de vérification 2FA",
    it: "Inserisci il codice di verifica 2FA",
    sq: "Fut kodin e verifikimit 2FA",
    tr: "2FA doğrulama kodunu girin",
    sw: "Ingiza msimbo wa uthibitisho wa 2FA",
    am: "ኮድ ማረጋገጫ 2FA እንቀላቀል",
    om: "Koodii mirkaneessaa 2FA galchaa",
    ig: "Tinye koodu nnwale 2FA",
    so: "Geli koodhka xaqiijinta 2FA",
    ha: "Shigar da lambar tabbatarwa ta 2FA",
    mnk: "Taa koodii 2FA buɓɓe",
    ff: "Nja koodii 2FA",
    yo: "Tẹ koodu ìmúdájú 2FA"
  },
  "Enable 2FA": {
    en: "Enable 2FA",
    pt: "Ativar 2FA",
    de: "2FA aktivieren",
    es: "Activar 2FA",
    fr: "Activer 2FA",
    it: "Abilita 2FA",
    sq: "Aktivizo 2FA",
    tr: "2FA'yı etkinleştir",
    sw: "Wezesha 2FA",
    am: "እባኮት 2FAን አንቀሳቅስ",
    om: "2FA banamaa",
    ig: "Mezigharị 2FA",
    so: "Fur 2FA",
    ha: "Kunna 2FA",
    mnk: "Weexu 2FA",
    ff: "Soodii 2FA",
    yo: "Mu 2FA ṣiṣẹ"
  },
  "Enter code": {
    en: "Enter code",
    pt: "Digite o código",
    de: "Code eingeben",
    es: "Ingresar código",
    fr: "Entrer le code",
    it: "Inserisci il codice",
    sq: "Fut kodin",
    tr: "Kodu girin",
    sw: "Ingiza msimbo",
    am: "ኮድ እንቀላቀል",
    om: "Koodii galchaa",
    ig: "Tinye koodu",
    so: "Geli koodhka",
    ha: "Shigar da lambar sirri",
    mnk: "Taa koodii",
    ff: "Nja koodii",
    yo: "Tẹ koodu"
  },
  Error: {
    en: "Error",
    pt: "Erro",
    de: "Fehler",
    es: "Error",
    fr: "Erreur",
    it: "Errore",
    sq: "Gabim",
    tr: "Hata",
    sw: "Kosa",
    am: "ስህተት",
    om: "Dogoggora",
    ig: "Mmejọ",
    so: "Khalad",
    ha: "Kuskure",
    mnk: "Dogoggora",
    ff: "Kalaɗi",
    yo: "Aṣiṣe"
  },
  "Error logs": {
    en: "Error logs",
    pt: "Registros de erro",
    de: "Fehlerprotokolle",
    es: "Registros de errores",
    fr: "Journaux d'erreurs",
    it: "Log degli errori",
    sq: "Regjistrat e gabimit",
    tr: "Hata günlükleri",
    sw: "Makala za makosa",
    am: "ስህተቶች ሰንጠረዥ",
    om: "Gabaaba dogoggoraa",
    ig: "Nchekwa mmejọ",
    so: "Xogaha khaladaad",
    ha: "Rahoton kuskure",
    mnk: "Log dogoggora",
    ff: "Log kalaɗi",
    yo: "Iwe aṣiṣe"
  },
  "Error count": {
    en: "Error count",
    pt: "Contagem de erros",
    de: "Fehleranzahl",
    es: "Contador de errores",
    fr: "Nombre d'erreurs",
    it: "Conteggio errori",
    sq: "Numri i gabimit",
    tr: "Hata sayısı",
    sw: "Idadi ya makosa",
    am: "ቁጥር ስህተቶች",
    om: "Lakkoofsa dogoggora",
    ig: "Ọnụọgụ mmejọ",
    so: "Tirada khaladaad",
    ha: "Yawan kuskure",
    mnk: "Lakkoo dogoggora",
    ff: "Tawɗe kalaɗi",
    yo: "Iye aṣiṣe"
  },
  "Edit DNS": {
    en: "Edit DNS",
    pt: "Editar DNS",
    de: "DNS bearbeiten",
    es: "Editar DNS",
    fr: "Modifier le DNS",
    it: "Modifica DNS",
    sq: "Modifiko DNS",
    tr: "DNS'yi düzenle",
    sw: "Hariri DNS",
    am: "ኤዲት ዲኤንኤስ",
    om: "DNS sirreeffama",
    ig: "Dezie DNS",
    so: "Sax DNS",
    ha: "Gyara DNS",
    mnk: "Taa DNS",
    ff: "Nja DNS",
    yo: "Ṣatunkọ DNS"
  },
  "Edit playlist": {
    en: "Edit playlist",
    pt: "Editar lista de reprodução",
    de: "Playlist bearbeiten",
    es: "Editar lista de reproducción",
    fr: "Modifier la playlist",
    it: "Modifica playlist",
    sq: "Modifiko playlistën",
    tr: "Çalma listesini düzenle",
    sw: "Hariri orodha ya nyimbo",
    am: "ምርጫ ፕሌስት አስተካክል",
    om: "Taaksii playlist sirreeffama",
    ig: "Dezie listi egwu",
    so: "Sax liiska heesaha",
    ha: "Gyara jerin waƙoƙi",
    mnk: "Taa playlist",
    ff: "Nja playlist",
    yo: "Ṣatunkọ atokọ orin"
  },
  "Expired date": {
    en: "Expired date",
    pt: "Data de expiração",
    de: "Ablaufdatum",
    es: "Fecha de vencimiento",
    fr: "Date d'expiration",
    it: "Data di scadenza",
    sq: "Data e skaducimit",
    tr: "Sonuç tarihi",
    sw: "Tarehe Iliyokamilika",
    am: "ቀን ተጠናቀቀ",
    om: "Guyyaa Gochiifame",
    ig: "Ụbọchị gwụrụ",
    so: "Taariikh dhamaatay",
    ha: "Ranar da ta ƙare",
    mnk: "Faañoo Ɲaataa",
    ff: "Ñoore yeddu",
    yo: "Ọjọ́ tí o pari"
  },
  Expired: {
    en: "Expired",
    pt: "Expirado",
    de: "Abgelaufen",
    es: "Caducado",
    fr: "Expiré",
    it: "Scaduto",
    sq: "Skadet",
    tr: "Süresi dolmuş",
    sw: "Imeisha",
    am: "ተጠናቀቀ",
    om: "Gochiifame",
    ig: "Fụrụrụ",
    so: "Dhamaaday",
    ha: "Ya ƙare",
    mnk: "Faañoo",
    ff: "Ñoore",
    yo: "Parí"
  },
  "Email address": {
    en: "Email address",
    pt: "Endereço de e-mail",
    de: "E-Mail-Adresse",
    es: "Dirección de correo electrónico",
    fr: "Adresse e-mail",
    it: "Indirizzo email",
    sq: "Adresa e emailit",
    tr: "E-posta adresi",
    sw: "Anwani ya barua pepe",
    am: "ኢሜይል አድራሻ",
    om: "Iimeelii ādreesii",
    ig: "Adreesị email",
    so: "Cinwaanka email",
    ha: "Adireshin imel",
    mnk: "E-mail adreesi",
    ff: "Adiresi e-mail",
    yo: "Adirẹsi imeeli"
  },
  "Export excel": {
    en: "Export excel",
    pt: "Exportar para o Excel",
    de: "Excel exportieren",
    es: "Exportar a Excel",
    fr: "Exporter vers Excel",
    it: "Esporta in Excel",
    sq: "Eksporto Excel",
    tr: "Excel'e aktar",
    sw: "Eksporti Excel",
    am: "ኤክሴል አስተናጋጅ",
    om: "Excel ergaa",
    ig: "Wepụ Excel",
    so: "Soo Excel",
    ha: "Fitar da Excel",
    mnk: "Soo Excel",
    ff: "Lataa Excel",
    yo: "Gbejade Excel"
  },
  "Edit Sub-Reseller": {
    en: "Edit Sub-Reseller",
    pt: "Editar Sub-Revendedor",
    de: "Unterwiederverkäufer bearbeiten",
    es: "Editar subrevendedor",
    fr: "Modifier le sous-revendeur",
    it: "Modifica sub-rivenditore",
    sq: "Ndrysho Nën-Shitjen",
    tr: "Alt-Bayi düzenle",
    sw: "Hariri Sub-Reseller",
    am: "ማንበብ ማስተካከል ሰርተር",
    om: "Sub-reseller sirreeffama",
    ig: "Dezie Sub-Reseller",
    so: "Sax sub-reseller",
    ha: "Gyara Sub-Reseller",
    mnk: "Taa sub-reseller",
    ff: "Nja sub-reseller",
    yo: "Ṣatunkọ Sub-Reseller"
  },
  "Enter server host": {
    en: "Enter server host",
    pt: "Digite o host do servidor",
    de: "Server-Host eingeben",
    es: "Introduce el host del servidor",
    fr: "Entrez l'hôte du serveur",
    it: "Inserisci l'host del server",
    sq: "Shkruaj hostin e serverit",
    tr: "Sunucu ana bilgisayarını girin",
    sw: "Ingiza mwenyeji wa seva",
    am: "አካባቢ ስር በሰርቨር ተመልከቱ",
    om: "Maqaa tajaajila galchaa",
    ig: "Tinye onye ọbịa sava",
    so: "Geli martida server",
    ha: "Shigar da mai masaukin uwar gizo",
    mnk: "Bɛ faalɔ server host",
    ff: "Jokku server host",
    yo: "Tẹ alejo olupin"
  },
  "Enter playlist URL": {
    en: "Enter playlist URL",
    pt: "Digite a URL da lista de reprodução",
    de: "Playlist-URL eingeben",
    es: "Introduce la URL de la lista de reproducción",
    fr: "Entrez l'URL de la playlist",
    it: "Inserisci l'URL della playlist",
    sq: "Shkruaj URL-në e Playlistës",
    tr: "Çalma listesi URL'sini girin",
    sw: "Ingiza URL ya orodha ya nyimbo",
    am: "ተኣማኒ እንደ ምርጫ በአስፋላሊ ያስገቡ",
    om: "URL taaksii galchaa",
    ig: "Tinye URL nke listi egwu",
    so: "Geli URL liiska heesaha",
    ha: "Shigar da URL na jerin waƙoƙi",
    mnk: "Bɛ faalɔ URL playlist",
    ff: "Jokku URL playlist",
    yo: "Tẹ URL atokọ orin"
  },
  "Enter device key": {
    en: "Enter device key",
    pt: "Digite a chave do dispositivo",
    de: "Geräteschlüssel eingeben",
    es: "Introduce la clave del dispositivo",
    fr: "Entrez la clé de l'appareil",
    it: "Inserisci la chiave del dispositivo",
    sq: "Shkruaj çelësin e pajisjes",
    tr: "Cihaz anahtarını girin",
    sw: "Ingiza funguo la kifaa",
    am: "ቁልፍ እባኮት እንደ መሳሪያ ያስገቡ",
    om: "Maqaa meeshaa galchaa",
    ig: "Tinye igodo ngwaọrụ",
    so: "Geli furaha qalabka",
    ha: "Shigar maballin na'ura",
    mnk: "Bɛ faalɔ faalɔtɛɛ",
    ff: "Nyaamiroo leeroo nafa",
    yo: "Tẹ bọtini ẹrọ"
  },
  "Enabling 2FA": {
    en: "Enabling 2FA",
    pt: "Ativando a autenticação de dois fatores",
    de: "2-Faktor-Authentifizierung aktivieren",
    es: "Activando la autenticación de dos factores",
    fr: "Activation de l'authentification à deux facteurs",
    it: "Abilitazione della doppia autenticazione",
    sq: "Aktivizimi i 2FA",
    tr: "İki faktörlü kimlik doğrulama etkinleştiriliyor",
    sw: "Inaruhusu 2FA",
    am: "እንቁላል ለ2FA ተጠቅሟል",
    om: "2FA eeyyamuu",
    ig: "Ịrụpụta 2FA",
    so: "Hawlgalinta 2FA",
    ha: "Kunna 2FA",
    mnk: "Bɛ nyaaɛ 2FA",
    ff: "Jokku 2FA",
    yo: "Mu ilana 2FA"
  },
  "Enter 2FA email address": {
    en: "Enter 2FA email address",
    pt: "Digite o endereço de e-mail para a autenticação de dois fatores",
    de: "Geben Sie die E-Mail-Adresse für die Zwei-Faktor-Authentifizierung ein",
    es: "Ingrese la dirección de correo electrónico para la autenticación de dos factores",
    fr: "Entrez l'adresse e-mail pour l'authentification à deux facteurs",
    it: "Inserisci l'indirizzo email per l'autenticazione a due fattori",
    sq: "Shkruaj adresën email për 2FA",
    tr: "İki faktörlü kimlik doğrulama için e-posta adresini girin",
    sw: "Ingiza anwani ya barua pepe ya 2FA",
    am: "ኢሜይል አድራሻ ለ2FA ያስገቡ",
    om: "Iimeelii 2FA galchaa",
    ig: "Tinye adreesị email 2FA",
    so: "Geli ciwaanka emaylka 2FA",
    ha: "Shigar da adireshin imel na 2FA",
    mnk: "Bɛ nyaaɛ email 2FA",
    ff: "Nyaamiroo e-mail 2FA",
    yo: "Tẹ adirẹsi imeeli 2FA"
  },
  "E-mail isn't valid": {
    en: "E-mail isn't valid!",
    pt: "E-mail não é válido!",
    de: "E-Mail ist ungültig!",
    es: "¡El correo electrónico no es válido!",
    fr: "L'adresse e-mail n'est pas valide !",
    it: "L'indirizzo email non è valido!",
    sq: "Email-i nuk është valid!",
    tr: "E-posta geçerli değil!",
    sw: "Barua pepe si halali!",
    am: "ኢሜይል አይሳምንም!",
    om: "Iimeelii sirrii miti!",
    ig: "E-mail adịghị mma!",
    so: "E-mail ma aha mid sax ah!",
    ha: "Imel ɗin ba daidai ba ne!",
    mnk: "E-mail dɛ bɛngɛ!",
    ff: "E-mail ɗo ɓe ɗum!",
    yo: "E-mail ko tọ!"
  },
  "Expired remaining days": {
    en: "Expired remaining days",
    pt: "Dias restantes de expiração",
    de: "Verbleibende Tage bis zum Ablauf",
    es: "Días restantes de vencimiento",
    fr: "Jours restants avant expiration",
    it: "Giorni rimasti alla scadenza",
    sq: "Ditet e mbetura të skaduara",
    tr: "Son kullanıma kalan günler",
    sw: "Siku zilizobaki zimeisha",
    am: "ቀሪ ቀናት ተሰናበቱ",
    om: "Guyyaa hafe galma'eera",
    ig: "Ụbọchị fọdụrụ agwụla",
    so: "Maalmihii haray waa dhamaadeen",
    ha: "Kwanaar da suka saura sun ƙare",
    mnk: "Bii naata",
    ff: "Ñafoore jomba",
    yo: "Ọjọ to ku ti pari"
  },
  "Enable Two-Factor Authentication": {
    en: "Enable Two-Factor Authentication",
    pt: "Ativar Autenticação de Dois Fatores",
    de: "Zwei-Faktor-Authentifizierung aktivieren",
    es: "Habilitar Autenticación de Dos Factores",
    fr: "Activer l'authentification à deux facteurs",
    it: "Abilita l'Autenticazione a Due Fattori",
    sq: "Aktivizo Autentikimin Dy-Faktorial",
    tr: "İki Faktörlü Kimlik Doğrulamayı Etkinleştir",
    sw: "Wezesha uthibitishaji wa hatua mbili",
    am: "እባኮትን ሁለት ተሞክሮ ማረጋገጫን አስተካክሉ",
    om: "Eenyu sirna ragaa lamaa dhaabbataa",
    ig: "Kwekọrịta asambodo abụọ",
    so: "Kaqi xaqiijinta labaad",
    ha: "Kunna tsaro na'ura biyu",
    mnk: "Bɛ nyaaɛ ka taaɲa",
    ff: "Jokku ɗaɓɓitii Hakkilɗe ndiyam",
    yo: "Mu iṣeduro ilana mejeeji"
  },
  //c
  "Choose progress bar color": {
    en: "Choose progress bar color",
    pt: "Escolha a cor da barra de progresso",
    de: "Wählen Sie die Farbe der Fortschrittsanzeige",
    es: "Elige el color de la barra de progreso",
    fr: "Choisissez la couleur de la barre de progression",
    it: "Scegli il colore della barra di progresso",
    sq: "Zgjidhni ngjyrën e shiritit të përparimit",
    tr: "İlerleme çubuğu rengini seçin",
    sw: "Chagua rangi ya upendeleo wa upau",
    am: "ተሳታፊ ነባሪ ቀለም ይምረጡ",
    om: "Fakkii rangi baarii tarkaanfii filadhu",
    ig: "Họrọ agba barụ nke mmepe",
    so: "Dooro midabka bar ku socodka",
    ha: "Zaɓi launin sandar ci gaba",
    mnk: "Bɛ faalɔ rangi progreso bar",
    ff: "Jokku wonde progress barji",
    yo: "Yan awọ ọpa idagbasoke"
  },
  "Choose items active color": {
    en: "Choose items active color",
    pt: "Escolha a cor ativa dos itens",
    de: "Wählen Sie die aktive Farbe der Elemente",
    es: "Elige el color activo de los elementos",
    fr: "Choisissez la couleur active des éléments",
    it: "Scegli il colore attivo degli elementi",
    sq: "Zgjidhni ngjyrën aktive të artikujve",
    tr: "Öğelerin aktif rengini seçin",
    sw: "Chagua rangi ya shughuli ya vitu",
    am: "ተግባራዊ ቀለም ይምረጡ",
    om: "Fakkii rangi hojiiwwan filadhu",
    ig: "Họrọ agba ihe ọrụ",
    so: "Dooro midabka firfircoonida shayga",
    ha: "Zaɓi launin abubuwa masu aiki",
    mnk: "Bɛ faalɔ yɛrɛ rangi",
    ff: "Jokku wonde finndere neɗɗo",
    yo: "Yan awọ iṣẹ awọn ohun"
  },
  "Choose icons color": {
    en: "Choose icons color",
    pt: "Escolha a cor dos ícones",
    de: "Wählen Sie die Farbe der Symbole",
    es: "Elige el color de los iconos",
    fr: "Choisissez la couleur des icônes",
    it: "Scegli il colore delle icone",
    sq: "Zgjidhni ngjyrën e ikonave",
    tr: "Simge rengini seçin",
    sw: "Chagua rangi ya alama",
    am: "አማራጮች ቀለም ይምረጡ",
    om: "Fakkii icon rangi filadhu",
    ig: "Họrọ agba akara",
    so: "Dooro midabka astaamaha",
    ha: "Zaɓi launin alamomin",
    mnk: "Bɛ faalɔ konti",
    ff: "Jokku wonde finndere",
    yo: "Yan awọ awọn aami"
  },
  "Choose items box form": {
    en: "Choose items box form",
    pt: "Escolha a forma da caixa de itens",
    de: "Wählen Sie das Kästchenformular",
    es: "Elige la forma de la caja de artículos",
    fr: "Choisissez la forme de la boîte d'articles",
    it: "Scegli la forma della casella degli articoli",
    sq: "Zgjidhni formën e kutisë së artikujve",
    tr: "Öğe kutusu formunu seçin",
    sw: "Chagua fomu ya boksi la vitu",
    am: "ምንጮች ቦክስ ቅርጸ ቅርጽ ይምረጡ",
    om: "Fakkii qaama booxii filadhu",
    ig: "Họrọ fọọmụ igbe ihe",
    so: "Dooro foomka sanduuqa shayga",
    ha: "Zaɓi fom ɗin akwatin abubuwa",
    mnk: "Bɛ faalɔ kɛmma",
    ff: "Jokku foomii boxji",
    yo: "Yan fọọmu apoti awọn ohun"
  },
  "Choose menu": {
    en: "Choose menu",
    pt: "Escolher menu",
    de: "Menü auswählen",
    es: "Elegir menú",
    fr: "Choisir le menu",
    it: "Scegli il menu",
    sq: "Zgjidhni menunë",
    tr: "Menü seçin",
    sw: "Chagua menyu",
    am: "ምናልባት ምንጭ ይምረጡ",
    om: "Filannoo menu",
    ig: "Họrọ menu",
    so: "Dooro menu",
    ha: "Zaɓi menu",
    mnk: "Bɛ faalɔ menu",
    ff: "Jokku menu",
    yo: "Yan akojọ aṣayan"
  },
  "Choose player": {
    en: "Choose player",
    pt: "Escolha o player",
    de: "Wähle den Player",
    es: "Elige el reproductor",
    fr: "Choisissez le lecteur",
    it: "Scegli il player",
    sq: "Zgjidh lojtarin",
    tr: "Oynatıcıyı seç",
    sw: "Chagua mchezaji",
    am: "ተጫዋችን ይምረጡ",
    om: "Taphataa filadhu",
    ig: "Họrọ onye egwuregwu",
    so: "Dooro ciyaaryahanka",
    ha: "Zaɓi dan wasan",
    mnk: "Bɛ faalɔ player",
    ff: "Jokku player",
    yo: "Yan ẹrọ orin"
  },
  Collection: {
    en: "Collection",
    pt: "Coleção",
    de: "Sammlung",
    es: "Colección",
    fr: "Collection",
    it: "Collezione",
    sq: "Koleksioni",
    tr: "Koleksiyon",
    sw: "Makusanyo",
    am: "ስብስብ",
    om: "Waliin walitti qabama",
    ig: "Ntụnyere",
    so: "Ururinta",
    ha: "Taron kaya",
    mnk: "Sumaaloo",
    ff: "Kondora",
    yo: "Akopọ"
  },
  "Choose your icons": {
    en: "Choose your icons",
    pt: "Escolha seus ícones",
    de: "Wählen Sie Ihre Symbole",
    es: "Elige tus íconos",
    fr: "Choisissez vos icônes",
    it: "Scegli le tue icone",
    sq: "Zgjidhni ikonat tuaja",
    tr: "Simgelerinizi seçin",
    sw: "Chagua alama zako",
    am: "እባኮትን አማራጮች ይምረጡ",
    om: "Suuraa kee filadhu",
    ig: "Họrọ akara gị",
    so: "Dooro astaamahaaga",
    ha: "Zaɓi alamar ku",
    mnk: "Bɛ faalɔ yɛrɛ",
    ff: "Jokkuɓe iconji",
    yo: "Yan awọn aami rẹ"
  },
  "Choose items color": {
    en: "Choose items color",
    pt: "Escolher a cor dos itens",
    de: "Wählen Sie die Farbe der Elemente",
    es: "Elegir el color de los elementos",
    fr: "Choisir la couleur des éléments",
    it: "Scegli il colore degli elementi",
    sq: "Zgjidhni ngjyrën e artikujve",
    tr: "Öğelerin rengini seç",
    sw: "Chagua rangi ya vitu",
    am: "ቅንብሩ ማህበረሰብ ቀለም",
    om: "Fakkii filannoo kaa'uu",
    ig: "Họrọ agba ihe",
    so: "Dooro midabka shayga",
    ha: "Zaɓi launin abubuwa",
    mnk: "Bɛ faalɔ konti",
    ff: "Jokku wonde finndere",
    yo: "Yan awọ ohun kan"
  },
  "Choose background from list": {
    en: "Choose background from list",
    pt: "Escolher plano de fundo da lista",
    de: "Hintergrund aus der Liste auswählen",
    es: "Elegir fondo de la lista",
    fr: "Choisir un arrière-plan dans la liste",
    it: "Scegli sfondo dall'elenco",
    sq: "Zgjidh sfondin nga lista",
    tr: "Listeden arka planı seç",
    sw: "Chagua mandharinyuma kutoka kwa orodha",
    am: "የመነሻ አካባቢ ከተዘረዘሩት ዝርዝሮች ይምረጡ",
    om: "Bakka duubbee irraa filadhu",
    ig: "Họrọ ndabere site na ndepụta",
    so: "Dooro asalka liiska",
    ha: "Zaɓi bango daga jerin",
    mnk: "Bɛ faalɔ sɛbɛla",
    ff: "Jokku duɓi e haɗɗi",
    yo: "Yan aṣayan ẹhin lati inu atokọ"
  },
  "Customize Layout": {
    en: "Customize Layout",
    es: "Personalizar diseño",
    fr: "Personnaliser la mise en page",
    de: "Layout anpassen",
    pt: "Personalizar layout",
    it: "Personalizza layout",
    sq: "Përshtat paraqitjen",
    tr: "Düzeni Özelleştir",
    sw: "Buni Mpangilio",
    am: "ቅንብሩ እቅድ",
    om: "Qindaa Layout",
    ig: "Hazie nhazi",
    so: "Habeyn Qaab-dhismeedka",
    ha: "Gyara Tsarin",
    mnk: "Falu layout",
    ff: "Kadi layout",
    yo: "Ṣatunṣe Ilana"
  },
  company: {
    en: "company",
    pt: "empresa",
    de: "Unternehmen",
    es: "empresa",
    fr: "entreprise",
    it: "azienda",
    sq: "kompani",
    tr: "şirket",
    sw: "Kampuni",
    am: "ኩባንያ",
    om: "Kompanii",
    ig: "Ụlọ ọrụ",
    so: "Shirkad",
    ha: "Kamfani",
    mnk: "Kumpani",
    ff: "Kompaani",
    yo: "Ilé-iṣẹ"
  },
  Continue: {
    en: "Continue",
    pt: "Continuar",
    de: "Weiter",
    es: "Continuar",
    fr: "Continuer",
    it: "Continua",
    sq: "Vazhdo",
    tr: "Devam et",
    sw: "Endelea",
    am: "ቀጥል",
    om: "Itti fufa",
    ig: "Gbaa mbọ",
    so: "Sii wadada",
    ha: "Ci gaba",
    mnk: "Dii na",
    ff: "Jomɗin",
    yo: "Tesiwaju"
  },
  Card: {
    en: "Card",
    pt: "Cartão",
    de: "Karte",
    es: "Tarjeta",
    fr: "Carte",
    it: "Carta",
    sq: "Kartë",
    tr: "Kart",
    sw: "Kadi",
    am: "ካርድ",
    om: "Kaardii",
    ig: "Kaadị",
    so: "Kaarka",
    ha: "Kadi",
    mnk: "Kadi",
    ff: "Kaadi",
    yo: "Kaadi"
  },
  Confirm: {
    en: "Confirm",
    pt: "Confirmar",
    de: "Bestätigen",
    es: "Confirmar",
    fr: "Confirmer",
    it: "Confermare",
    sq: "Konfirmo",
    tr: "Onayla",
    sw: "Thibitisha",
    am: "እትም ማረጋገጫ",
    om: "Mirkanneessaa",
    ig: "Kwadoro",
    so: "Xaqiiji",
    ha: "Tabbatacce",
    mnk: "Falu",
    ff: "Njaɗɗo",
    yo: "Jẹrisi"
  },
  Contacts: {
    en: "Contacts",
    pt: "Contatos",
    de: "Kontakte",
    es: "Contactos",
    fr: "Contacts",
    it: "Contatti",
    sq: "Kontakte",
    tr: "İletişimler",
    sw: "Mawasiliano",
    am: "ኮንታክቶች",
    om: "Waliin hidhii",
    ig: "Kpọtụrụ ndị ọzọ",
    so: "Xiriiriyeya",
    ha: "Hulɗo",
    mnk: "Sanngo",
    ff: "Fowle",
    yo: "Awọn olubasọrọ"
  },
  "Contact us on": {
    en: "Contact us on",
    pt: "Contate-nos em",
    de: "Kontaktieren Sie uns unter",
    es: "Contáctenos en",
    fr: "Contactez-nous au",
    it: "Contattaci su",
    sq: "Na kontaktoni në",
    tr: "Bize şu adresten ulaşın",
    sw: "Wasiliana nasi kwa",
    am: "እባኮትን በማኅበረሰብ ላይ እንደምን እንደንበሩ",
    om: "Nuu keenya irratti nu quunnamaa",
    ig: "Kpọtụrụ anyị na",
    so: "Nala soo xiriirno",
    ha: "Tuntuɓe mu a kan",
    mnk: "Bɛ faalɔ ka",
    ff: "Soodi fowle ka",
    yo: "Kan si wa lori"
  },
  close: {
    en: "close",
    pt: "fechar",
    de: "schließen",
    es: "cerrar",
    fr: "fermer",
    it: "chiudi",
    sq: "Mbyll",
    tr: "kapat",
    sw: "Funga",
    am: "ዝጋ",
    om: "Cufi",
    ig: "Mechie",
    so: "Xir",
    ha: "Rufe",
    mnk: "Bɛɛrɛ",
    ff: "Hollu",
    yo: "Pa"
  },
  Count: {
    en: "Count",
    pt: "Contagem",
    de: "Zählen",
    es: "Contar",
    fr: "Compter",
    it: "Conteggio",
    sq: "Llogaritje",
    tr: "Say",
    sw: "Hesabu",
    am: "ቆጣር",
    om: "Lakkoofsa",
    ig: "Ọnụọgụ",
    so: "Tirada",
    ha: "Yawan",
    mnk: "Jɔrɔ",
    ff: "Ɓeɗɗi",
    yo: "Iye"
  },
  custom: {
    en: "custom",
    pt: "personalizado",
    de: "individuell",
    es: "personalizado",
    fr: "personnalisé",
    it: "personalizzato",
    sq: "Personalizuar",
    tr: "özel",
    sw: "Maalum",
    am: "ባለሞያ",
    om: "Addaa",
    ig: "Ahaziri",
    so: "Gaar ah",
    ha: "Na al'ada",
    mnk: "Njɔ kafu",
    ff: "Kadi",
    yo: "Aṣa"
  },
  Credit: {
    en: "Credit",
    pt: "Crédito",
    de: "Guthaben",
    es: "Crédito",
    fr: "Crédit",
    it: "Credito",
    sq: "Kredit",
    tr: "Kredi",
    sw: "Kikopo",
    am: "ክሬዲት",
    om: "Kireeditii",
    ig: "Krediti",
    so: "Kireedhiga",
    ha: "Kirediti",
    mnk: "Kredi",
    ff: "Krediti",
    yo: "Kirediti"
  },
  Credits: {
    en: "Credits",
    pt: "Créditos",
    de: "Guthaben",
    es: "Créditos",
    fr: "Crédits",
    it: "Crediti",
    sq: "Kreditet",
    tr: "Krediler",
    sw: "Mikopo",
    am: "ክሬዲት",
    om: "Kireeditii",
    ig: "Krediti",
    so: "Kireedhiga",
    ha: "Kirediti",
    mnk: "Kredi",
    ff: "Krediti",
    yo: "Kirediti"
  },
  cancel: {
    en: "cancel",
    pt: "cancelar",
    de: "abbrechen",
    es: "cancelar",
    fr: "annuler",
    it: "annulla",
    sq: "anuloj",
    tr: "iptal",
    sw: "Futa",
    am: "አስተውል",
    om: "Haquu",
    ig: "Kagbuo",
    so: "Jooji",
    ha: "Soke",
    mnk: "Fana",
    ff: "Hollu",
    yo: "Fagilee"
  },
  Cancel: {
    en: "Cancel",
    pt: "Cancelar",
    de: "Abbrechen",
    es: "Cancelar",
    fr: "Annuler",
    it: "Annulla",
    sq: "Anuloj",
    tr: "İptal",
    sw: "Futa",
    am: "አስተውል",
    om: "Haquu",
    ig: "Kagbuo",
    so: "Jooji",
    ha: "Soke",
    mnk: "Fana",
    ff: "Hollu",
    yo: "Fagilee"
  },
  Check: {
    en: "Check",
    pt: "Verificar",
    de: "Überprüfen",
    es: "Comprobar",
    fr: "Vérifier",
    it: "Controlla",
    sq: "Kontrollo",
    tr: "Kontrol Et",
    sw: "Angalia",
    am: "ማረጋገጫ",
    om: "Mirkeessi",
    ig: "Nyocha",
    so: "Hubi",
    ha: "Duba",
    mnk: "Falu",
    ff: "Hollu",
    yo: "Ṣayẹwo"
  },
  Country: {
    en: "Country",
    pt: "País",
    de: "Land",
    es: "País",
    fr: "Pays",
    it: "Paese",
    sq: "Shteti",
    tr: "Ülke",
    sw: "Nchi",
    am: "ሃገር",
    om: "Biyya",
    ig: "Mba",
    so: "Dalka",
    ha: "Kasa",
    mnk: "Jɔrɔ",
    ff: "Leɗɗi",
    yo: "Orílẹ̀-èdè"
  },
  "Coming soon": {
    en: "Coming soon",
    pt: "Em breve",
    de: "Demnächst",
    es: "Próximamente",
    fr: "Bientôt disponible",
    it: "Prossimamente",
    sq: "Së shpejti",
    tr: "Yakında",
    sw: "Inakuja hivi karibuni",
    am: "በቅርቡ እንደምትመጣ",
    om: "Bara keenya dhufaa jira",
    ig: "Na-abịa ngwa ngwa",
    so: "Waa imaanaya dhowaan",
    ha: "Zuwa nan da nan",
    mnk: "Nanaŋ taŋ",
    ff: "Ñaawo kadi",
    yo: "N'ibè yóò wá"
  },
  "custom DNS": {
    en: "custom DNS",
    pt: "DNS personalizado",
    de: "Individuelle DNS",
    es: "DNS personalizado",
    fr: "DNS personnalisé",
    it: "DNS personalizzato",
    sq: "DNS i personalizuar",
    tr: "özel DNS",
    sw: "DNS maalum",
    am: "ተለዋዋጭ DNS",
    om: "DNS addaa",
    ig: "DNS ahaziri",
    so: "DNS gaar ah",
    ha: "DNS na al'ada",
    mnk: "DNS njɔ kafu",
    ff: "DNS kadi",
    yo: "DNS aṣa"
  },
  "Custom Playlist": {
    en: "Custom Playlist",
    pt: "Lista de Reprodução Personalizada",
    de: "Benutzerdefinierte Wiedergabeliste",
    es: "Lista de Reproducción Personalizada",
    fr: "Playlist Personnalisée",
    it: "Playlist Personalizzata",
    sq: "Lista e Përmbajtjes së Personalizuar",
    tr: "Özel Oynatma Listesi",
    sw: "Orodha ya nyimbo maalum",
    am: "ካም በአስተዳደር የተለየ ማቅረብ",
    om: "Preeplaayistii addaa",
    ig: "Ndepụta egwu ahaziri",
    so: "Liiska heesaha gaar ah",
    ha: "Jerin waƙoƙin al'ada",
    mnk: "Play-lista njɔ kafu",
    ff: "Playlist kadi",
    yo: "Akojọ orin aṣa"
  },
  "Created date": {
    en: "Created date",
    pt: "Data de criação",
    de: "Erstellungsdatum",
    es: "Fecha de creación",
    fr: "Date de création",
    it: "Data di creazione",
    sq: "Data e krijimit",
    tr: "Oluşturulma tarihi",
    sw: "Tarehe ya kuundwa",
    am: "ቀን ማመንጨት",
    om: "Guyyaa uumamaa",
    ig: "Ụbọchị e kere",
    so: "Taariikhda la abuuray",
    ha: "Ranar kirkira",
    mnk: "Sanngo kafu",
    ff: "Ñalɗi muudu",
    yo: "Ọjọ ti a dá"
  },

  "Confirmation code": {
    en: "Confirmation code",
    pt: "Código de confirmação",
    de: "Bestätigungscode",
    es: "Código de confirmación",
    fr: "Code de confirmation",
    it: "Codice di conferma",
    sq: "Kodi i konfirmimit",
    tr: "Kod onayı",
    sw: "Msimbo wa uthibitisho",
    am: "ኮድ ማረጋገጫ",
    om: "Koodii mirkaneessaa",
    ig: "Koodu nkwenye",
    so: "Koodhka xaqiijinta",
    ha: "Lamban tabbatarwa",
    mnk: "Code kafu fufu",
    ff: "Koodii annduɗɗo",
    yo: "Koodu ijẹrisi"
  },
  "Change language": {
    en: "Change language",
    pt: "Alterar idioma",
    de: "Sprache ändern",
    es: "Cambiar idioma",
    fr: "Changer de langue",
    it: "Cambia lingua",
    sq: "Ndrysho gjuhën",
    tr: "Dili değiştir",
    sw: "Badilisha lugha",
    am: "ቋንቋን ለማስተካከል",
    om: "Afaan jijjiiri",
    ig: "Gbanwee asụsụ",
    so: "Beddel luqadda",
    ha: "Canja harshe",
    mnk: "Fɔɔli nunɔ kili",
    ff: "Fesre ɗeeɗi",
    yo: "Yipada ede"
  },
  "Card number": {
    en: "Card number",
    pt: "Número do cartão",
    de: "Kartennummer",
    es: "Número de tarjeta",
    fr: "Numéro de carte",
    it: "Numero di carta",
    sq: "Numri i kartës",
    tr: "Kart numarası",
    sw: "Nambari ya kadi",
    am: "ቁጥር ካርድ",
    om: "Lakkofsa kaardii",
    ig: "Nọmba kaadị",
    so: "Lambarka kaarka",
    ha: "Lambar kati",
    mnk: "Nɔmbɔ kadi",
    ff: "Numbur kaardi",
    yo: "Nọmba kaadi"
  },
  "Change Parental code": {
    en: "Change Parental code",
    pt: "Alterar código de controle parental",
    de: "Elterncode ändern",
    es: "Cambiar código de control parental",
    fr: "Changer le code parental",
    it: "Cambia codice genitore",
    sq: "Ndrysho kodin prindëror",
    tr: "Ebeveyn kodunu değiştir",
    sw: "Badilisha msimbo wa wazazi",
    am: "ኮድ እንደምትሆን የማንበብ ማለት",
    om: "Koodii maatii jijjiiri",
    ig: "Gbanwee koodu nne na nna",
    so: "Beddel koodhka waalidka",
    ha: "Canja lamban ɗin iyaye",
    mnk: "Fɔɔli parental code",
    ff: "Fesre koodii maama",
    yo: "Yipada koodu obi"
  },
  "Credits count must be greater than": {
    en: "Credits count must be greater than",
    pt: "A contagem de créditos deve ser maior que",
    de: "Die Anzahl der Guthaben muss größer sein als",
    es: "El recuento de créditos debe ser mayor que",
    fr: "Le nombre de crédits doit être supérieur à",
    it: "Il conteggio dei crediti deve essere superiore a",
    sq: "Numri i kredive duhet të jetë më i madh se",
    tr: "Kredi sayısı daha büyük olmalıdır",
    sw: "Idadi ya mikopo inapaswa kuwa kubwa kuliko",
    am: "ቁጥር እንደምትሆን ተቀላቀሉ",
    om: "Lakkoofni kreditiin ol ta'uu qaba",
    ig: "Ọnụọgụ nke credit ga-abụrịrị karịa",
    so: "Tirada credits waxay tahay inay ka weyn tahay",
    ha: "Yawan kiredit ya zama mafi girma fi",
    mnk: "Kredi kafu yɛrɛ ka tan njɔ",
    ff: "Cetti krediti jooɗii no tawa ba",
    yo: "Iye kirediti gbọdọ jẹ ti o tobi ju"
  },
  "Choose the server you want to transfer from": {
    en: "Choose the server you want to transfer from",
    pt: "Escolha o servidor de onde deseja transferir",
    de: "Wählen Sie den Server aus, von dem Sie übertragen möchten",
    es: "Elija el servidor del que desea transferir",
    fr: "Choisissez le serveur que vous souhaitez transférer depuis",
    it: "Scegli il server da cui desideri trasferire",
    sq: "Zgjidhni serverin nga i cili dëshironi të transferoni",
    tr: "Transfer etmek istediğiniz sunucuyu seçin",
    sw: "Chagua seva unayotaka kuhamisha kutoka",
    am: "እባኮት እንደምትሆን ስም ለእንደምንት ከሲርቨሩ ማስተላለፍ",
    om: "Server iddoo irraa kaayyoo qabdu filadhu",
    ig: "Họrọ sava ị chọrọ ịkekọrịta si",
    so: "Dooro server-ka aad ka rabto inaad u wareejiso",
    ha: "Zaɓi sabar da kake son canja daga",
    mnk: "Taa server kafu ni yɔrɔnbɛ fɔlɔ",
    ff: "Wokki server goo waaɓi ha",
    yo: "Yan server ti o fẹ lati gbe lati"
  },
  "Credit will be charged for each activation": {
    en: "1 Credit will be charged for each activation.",
    pt: "1 crédito será cobrado para cada ativação.",
    de: "Für jede Aktivierung wird 1 Guthaben berechnet.",
    es: "Se cobrará 1 crédito por cada activación.",
    fr: "1 crédit sera facturé pour chaque activation.",
    it: "1 credito sarà addebitato per ogni attivazione.",
    sq: "1 Kredi do të tarifohet për çdo aktivizim.",
    tr: "Her aktivasyon için 1 kredi tahsil edilecektir.",
    sw: "Kredi 1 itatozwa kwa kila uanzishaji",
    am: "እስከ ተክል አንደኛ ክሬዲት ይጠቀማል",
    om: "Kireeditii 1 ni kaffalamee hirmaannaa tokkoo irratti",
    ig: "A ga-akwụ ụgwọ 1 Credit maka mmeghe ọ bụla",
    so: "1 Credit ayaa laga qaadi doonaa firfircooneed kasta",
    ha: "Za a caji Kirediti 1 don kowanne kunna",
    mnk: "Kredi 1 ye fufunfii koŋoo",
    ff: "1 Credit feere e janna fow",
    yo: "1 Kirediti ni yoo gba fun ọkọọkan iṣiṣẹ"
  },
  "Click here to complete your payment": {
    en: "Click here to complete your payment",
    pt: "Clique aqui para concluir o pagamento",
    de: "Klicken Sie hier, um die Zahlung abzuschließen",
    es: "Haga clic aquí para completar su pago",
    fr: "Cliquez ici pour finaliser votre paiement",
    it: "Clicca qui per completare il pagamento",
    sq: "Kliko këtu për të përfunduar pagesën tuaj",
    tr: "Ödemenizi tamamlamak için buraya tıklayın.",
    sw: "Bofya hapa kumaliza malipo yako",
    am: "እባኮት እንደአሁን ማከል እንደምትሆን",
    om: "Asxifto si taho tawilax kela jalaan",
    ig: "Pịa ebe a iji mezue ụgwọ gị",
    so: "Guji halkan si aad u dhammaystirto lacag bixintaada",
    ha: "Danna nan don kammala biyan kuɗin ku",
    mnk: "Fɔɔli keso ka baŋkɔrɔlɔ",
    ff: "Jokkondir e nde feehooji mo",
    yo: "Tẹ nibi lati pari owo rẹ"
  },

  //j
  "Join WhatsApp group": {
    en: "Join WhatsApp group",
    pt: "Entrar no grupo do WhatsApp",
    de: "WhatsApp-Gruppe beitreten",
    es: "Unirse al grupo de WhatsApp",
    fr: "Rejoindre le groupe WhatsApp",
    it: "Unisciti al gruppo WhatsApp",
    sq: "Bashkohu në grupin WhatsApp",
    tr: "WhatsApp grubuna katıl",
    sw: "Jiunge na kundi la WhatsApp",
    am: "በዋትሳፕ ቡድን ተቀላቀሉ",
    om: "Grupii WhatsApp keessa hirmaadhu",
    ig: "Soro otu WhatsApp",
    so: "Ku soo bi koox WhatsApp",
    ha: "Shiga cikin rukunin WhatsApp",
    mnk: "N jattaŋ group WhatsApp kili",
    ff: "Jokkondir e nder WhatsApp group",
    yo: "Darapọ mọ ẹgbẹ WhatsApp"
  },
  "Join Telegram group": {
    en: "Join Telegram group",
    pt: "Participar do grupo no Telegram",
    de: "Dem Telegram-Gruppe beitreten",
    es: "Unirse al grupo de Telegram",
    fr: "Rejoindre le groupe Telegram",
    it: "Unisciti al gruppo Telegram",
    sq: "Bashkohu në grupin Telegram",
    tr: "Telegram grubuna katıl",
    sw: "Jiunge na kundi la Telegram",
    am: "በተሌግራም ቡድን ተቀላቀሉ",
    om: "Grupii Telegram keessa hirmaadhu",
    ig: "Soro otu Telegram",
    so: "Ku soo bi koox Telegram",
    ha: "Shiga cikin rukunin Telegram",
    mnk: "N jattaŋ group Telegram kili",
    ff: "Jokkondir e nder Telegram group",
    yo: "Darapọ mọ ẹgbẹ Telegram"
  },
  "Join community": {
    en: "Join community",
    pt: "Participe da comunidade",
    de: "Der Community beitreten",
    es: "Únete a la comunidad",
    fr: "Rejoindre la communauté",
    it: "Unisciti alla comunità",
    sq: "Bashkohu në komunitet",
    tr: "Topluluğa katıl",
    sw: "Jiunge na jamii",
    am: "በማህበረሰብ ተቀላቀሉ",
    om: "Hawaasa hirmaadhu",
    ig: "Soro obodo",
    so: "Ku soo bi bulshada",
    ha: "Shiga cikin al'umma",
    mnk: "N jattaŋ kili",
    ff: "Jokkondir e nder community",
    yo: "Darapọ mọ agbegbe"
  },

  "Join Our Telegram Community": {
    en: "Join Our Telegram Community",
    pt: "Junte-se à Nossa Comunidade no Telegram",
    de: "Treten Sie unserer Telegram-Community bei",
    es: "Únete a nuestra comunidad de Telegram",
    fr: "Rejoignez notre communauté Telegram",
    it: "Unisciti alla nostra comunità Telegram",
    sq: "Bashkohuni me Komunitetin Tonë në Telegram",
    tr: "Telegram Topluluğumuza Katıl",
    sw: "Jiunge na Jamii Yetu ya Telegram",
    am: "በተሌግራም ማህበረሰባችን ተቀላቀሉ",
    om: "Hawaasni keenya Telegram keessa hirmaadhu",
    ig: "Soro obodo anyị na Telegram",
    so: "Ku soo bi bulshadeena Telegram",
    ha: "Shiga cikin al'ummar Telegram ɗinmu",
    mnk: "N jattaŋ a Telegram kili",
    ff: "Jokkondir e nder Telegram community",
    yo: "Darapọ mọ agbegbe Telegram wa"
  },

  //i

  "If you have selected Collection 1, you cannot select any edit tools. If you wish to use the edit tools, please choose a different collection":
    {
      en: "If you have selected Collection 1, you cannot select any edit tools. If you wish to use the edit tools, please choose a different collection.",
      pt: "Se você selecionou a Coleção 1, não poderá selecionar nenhuma ferramenta de edição. Se deseja usar as ferramentas de edição, por favor, escolha uma coleção diferente.",
      de: "Wenn Sie Sammlung 1 ausgewählt haben, können Sie keine Bearbeitungstools auswählen. Wenn Sie die Bearbeitungstools verwenden möchten, wählen Sie bitte eine andere Sammlung.",
      es: "Si ha seleccionado la Colección 1, no puede seleccionar ninguna herramienta de edición. Si desea usar las herramientas de edición, elija una colección diferente.",
      fr: "Si vous avez sélectionné Collection 1, vous ne pouvez pas sélectionner d'outils d'édition. Si vous souhaitez utiliser les outils d'édition, veuillez choisir une autre collection.",
      it: "Se hai selezionato la Collezione 1, non puoi selezionare alcuno strumento di modifica. Se desideri utilizzare gli strumenti di modifica, scegli un'altra collezione.",
      sq: "Nëse keni zgjedhur Koleksionin 1, nuk mund të zgjidhni asnjë mjet redaktimi. Nëse dëshironi të përdorni mjetet e redaktimit, ju lutemi zgjidhni një koleksion tjetër.",
      tr: "Koleksiyon 1'i seçtiyseniz, herhangi bir düzenleme aracını seçemezsiniz. Düzenleme araçlarını kullanmak istiyorsanız lütfen farklı bir koleksiyon seçin.",
      sw: "Ikiwa umechagua Collection 1, huwezi kuchagua zana zozote za kuhariri. Ikiwa unataka kutumia zana za kuhariri, tafadhali chagua mkusanyiko mwingine.",
      am: "እባኮት ከኮሌክሽን 1 ተመርጧል፣ እንደ እተማሪ ስም አባላት በማንኛውም ምርጥ ማውረድ አልተሳካም፣ ምስጋና ትዕዛዝን በመለያየት ትምህርትን አባላት",
      om: "Yoo kolleection 1 filattee jirate, meeshaalee gulaaluu tokkollee hin filatamtuu. Yoo meeshaalee gulaaluu fayyadamuuf barbaadde, kolleection biraa filadhu.",
      ig: "Ọ bụrụ na ị họpụtara Nchekwa 1, ị gaghị ahọrọ ngwaọrụ ndezi ọ bụla. Ọ bụrụ na ịchọrọ iji ngwaọrụ ndezi, biko họrọ nchekwa ọzọ.",
      so: "Haddii aad dooratay Collection 1, ma dooran kartid qalab tafatirka. Haddii aad rabto inaad isticmaasho qalabka tafatirka, fadlan dooro uruurin kale.",
      ha: "Idan ka zaɓi Collection 1, ba za ku iya zaɓar kowanne kayan gyara ba. Idan kuna son amfani da kayan gyara, don Allah zaɓi wani tarin.",
      mnk: "Manding yidi kɔlɔkɔɔ 1, a fo ka taa kanyiɛ ɲe fɔnni kɛra. Kadi ka ba kɔlɔkɔɔ, a lo baŋkɔ fɔmɔ a fo.",
      ff: "Sode eɓe kammude kolection 1, ɗoo ɗooji bideen heftinde gosa hoore .  Heftinde kolection fufu.",
      yo: "Ti o ba ti yan Akopọ 1, o ko le yan eyikeyi awọn irinṣẹ ẹda. Ti o ba fẹ lo awọn irinṣẹ ẹda, jọwọ yan akopọ miiran."
    },
  "Incorrect email or password": {
    en: "Incorrect email or password",
    pt: "E-mail ou senha incorretos",
    de: "Falsche E-Mail oder falsches Passwort",
    es: "Correo electrónico o contraseña incorrectos",
    fr: "E-mail ou mot de passe incorrect",
    it: "E-mail o password errati",
    sq: "Email ose fjalëkalim i pasaktë",
    tr: "E-posta veya şifre yanlış",
    sw: "Barua pepe au nywila si sahihi",
    am: "ኢሜል ወይም ፓስዎርድ አልተሳሳተም",
    om: "Iimeelii ykn jecha darbee dogoggora qaba",
    ig: "E-mail ma ọ bụ paswọọdụ ezighi ezi",
    so: "Iimeylka ama erayga sir ma aha",
    ha: "Imel ko kalmar sirri ba daidai ba ne",
    mnk: "E-mail ni kɔrɔɔ ɲaɲɛ bɔgɔma",
    ff: "Email e nder, waɗe ɓe ngattu",
    yo: "Imeeli tabi ọrọigbaniwọle ko tọ"
  },
  "invalid image file": {
    en: "Invalid image file",
    es: "Archivo de imagen no válido",
    fr: "Fichier image invalide",
    de: "Ungültige Bilddatei",
    pt: "Arquivo de imagem inválido",
    it: "File immagine non valido",
    sq: "Skedar imazhi i pavlefshëm",
    tr: "Geçersiz resim dosyası",
    sw: "Faili ya picha si sahihi",
    am: "ፎቶ ፋይል አልተቻለም",
    om: "Faayila suuraa hin ta’u",
    ig: "Faịlụ oyiyi adịghị mma",
    so: "Faylka sawirka ma saxna",
    ha: "Fayil ɗin hoton ba daidai ba ne",
    mnk: "Kangoolo faayi siya lɔɔrɔ",
    ff: "Ñeene fayl ɓe loowa",
    yo: "Faili aworan ko tọ"
  },
  "Images must be less than": {
    en: "Images must be less than",
    es: "Las imágenes deben ser menos de",
    fr: "Les images doivent être inférieures à",
    de: "Bilder müssen weniger als",
    pt: "As imagens devem ser menos de",
    it: "Le immagini devono essere meno di",
    sq: "Imazhet duhet të jenë më pak se",
    tr: "Resimler daha az olmalıdır",
    sw: "Picha lazima ziwe chini ya",
    am: "ምስሎች ከእስከ ታች ሊሆኑ ይገባል",
    om: "Suuraaleen ni xiqqaa'uu qabu",
    ig: "Ihe oyiyi kwesịrị ịdị obere karịa",
    so: "Sawirada waa inay ka yar yihiin",
    ha: "Hoton dole su zama ƙasa da",
    mnk: "Kangoolo yaa furu kɔrɔ",
    ff: "Ñeene waɗi e hoore",
    yo: "Aworan gbọdọ wa ni kere ju"
  },
  "in size": {
    en: "in size",
    es: "en tamaño",
    fr: "en taille",
    de: "in der Größe",
    pt: "em tamanho",
    it: "in dimensione",
    sq: "në madhësi",
    tr: "boyutunda",
    sw: "kwa ukubwa",
    am: "በመጠን",
    om: "guddina",
    ig: "na nha",
    so: "cabirka",
    ha: "a cikin girman",
    mnk: "lebu kɔrɔ",
    ff: "e hoore",
    yo: "nínú iwọn"
  },
  image: {
    en: "Image",
    es: "Imagen",
    fr: "Image",
    de: "Bild",
    pt: "Imagem",
    it: "Immagine",
    sq: "Imazh",
    tr: "Görüntü",
    sw: "Picha",
    am: "ቅርጸ ተሞክሮ",
    om: "Suuraa",
    ig: "Ìhè",
    so: "Sawir",
    ha: "Hoton",
    mnk: "Kangoolo",
    ff: "Ñeene",
    yo: "Aworan"
  },
  "If this option is enabled, resellers will have the option to automatically register with you as sub resellers. If not, you have to manually accept their requests.":
    {
      en: "If this option is enabled, resellers will have the option to automatically register with you as sub resellers. If not, you have to manually accept their requests.",
      pt: "Se esta opção estiver ativada, os revendedores terão a opção de se registrar automaticamente com você como sub-revendedores. Se não, você terá que aceitar manualmente os pedidos deles.",
      de: "Wenn diese Option aktiviert ist, können Wiederverkäufer sich automatisch bei Ihnen als Unterverkäufer registrieren. Andernfalls müssen Sie ihre Anfragen manuell akzeptieren.",
      es: "Si esta opción está habilitada, los revendedores tendrán la opción de registrarse automáticamente contigo como subrevendedores. Si no, tendrás que aceptar manualmente sus solicitudes.",
      fr: "Si cette option est activée, les revendeurs auront la possibilité de s'inscrire automatiquement chez vous en tant que sous-revendeurs. Sinon, vous devrez accepter manuellement leurs demandes.",
      it: "Se questa opzione è abilitata, i rivenditori avranno la possibilità di registrarsi automaticamente con te come sub-rivenditori. In caso contrario, dovrai accettare manualmente le loro richieste.",
      sq: "Nëse kjo opsion është i aktivizuar, rivendorët do të kenë mundësinë të regjistrohen automatikisht me ju si nën-rivendorë. Nëse jo, duhet të pranoni manualisht kërkesat e tyre.",
      tr: "Bu seçenek etkinleştirildiğinde, bayiilerin sizinle otomatik olarak alt bayi olarak kaydolma seçeneği olacak. Aksi takdirde, taleplerini manuel olarak kabul etmeniz gerekecek.",
      sw: "Ikiwa chaguo hili kimewezeshwa, wauzaji watakuwa na chaguo la kujisajili moja kwa moja kwako kama wauzaji wa chini. Ikiwa sivyo, utalazimika kukubali maombi yao kwa mikono.",
      am: "እባኮት በዚህ አማራጭ እንዲታሰሩ ተማሪ ሆነው በእርስዎ ላይ እንዲማርኩ ይችላሉ። እንዴትም እባኮት እንደተማሪ እንደከፋፈል ሰላም ማረኪ ይሆናል።",
      om: "Yoo filannoon kun ni hojjate, gurgurtootni akka sub-gurgurtoota waliin si wajjin galma'uuf filannoo ni qabaatu. Yoo hin ta'e, gaaffii isaanii mana murtii irratti ni fudhattu.",
      ig: "Ọ bụrụ na a họpụtara nhọrọ a, ndị na-ere ahịa ga-enwe nhọrọ ịdenye aha n'ịntanetị na gị dịka ndị na-ere ahịa. Ọ bụrụ na ọ bụghị, ị ga-azụpụ na-ekwe ka ha dee ntuziaka.",
      so: "Haddii ikhtiyaarkan la dhaqaajiyo, kuwa iibisa ayaa heli doona ikhtiyaarka inay si toos ah ugu diiwaangashadaan adiga oo ah kuwa iibisa sub. Haddii kale, waa inaad si gacanta ah u aqbasho codsiyadooda.",
      ha: "Idan wannan zaɓin an kunna, masu sayarwa za su sami zaɓin yin rajista kai tsaye tare da ku a matsayin ƙaramin mai sayarwa. Idan ba haka ba, sai ku karɓi buƙatunsu da hannu.",
      mnk: "Manding taatu lɛrɛ tiyo, daariyaa kaaŋ ce naŋ ma nyaa denle caŋkɔɔ daaba fɛy. Taa bɔŋke, ajiŋ ka taŋ la.",
      ff: "Soɓo waɗe tawaare ngam woni toɓɓere, eɓe njama eɓe ɓe meɗee saɓo to jokkere wuntu. Taa kadi, a waɗi nafa.",
      yo: "Ti aṣayan yii ba ti wa ni mu ṣiṣẹ, awọn alagbata yoo ni aṣayan lati forukọsilẹ laifọwọyi pẹlu rẹ gẹgẹbi awọn alagbata kekere. Ti kii ba bẹ, o gbọdọ gba awọn ibeere wọn ni ọwọ."
    },
  Info: {
    en: "Info",
    pt: "Informação",
    de: "Info",
    es: "Información",
    fr: "Info",
    it: "Info",
    sq: "Informacion",
    tr: "Bilgi",
    sw: "Habari",
    am: "መረጃ",
    om: "Oduu",
    ig: "Ozi",
    so: "Macluumaad",
    ha: "Bayanan",
    mnk: "Jaabi",
    ff: "Njaama",
    yo: "Alaye"
  },

  //d
  "Dear Reseller": {
    en: "Dear Reseller",
    pt: "Caro Revendedor",
    de: "Sehr geehrter Wiederverkäufer",
    es: "Estimado revendedor",
    fr: "Cher revendeur",
    it: "Caro Rivenditore",
    sq: "I dashur Shitës",
    tr: "Sayın Bayi",
    sw: "Mpendwa Muuzaji",
    am: "እንዴት ማንኛውም ሰንበት",
    om: "Kabajaa Gurgurtaa",
    ig: "Ndewo Onye Ahịa",
    so: "Gacali Reseller",
    ha: "Mai Siyarwa Mai Daraja",
    mnk: "Manding Kafookoo",
    ff: "Faalmaa heewoo",
    yo: "Oludari Tita"
  },
  Date: {
    en: "Date",
    es: "Fecha",
    fr: "Date",
    de: "Datum",
    pt: "Data",
    it: "Data",
    sq: "Data",
    tr: "Tarih",
    sw: "Tarehe",
    am: "ቀን",
    om: "Guyyaa",
    ig: "Ụbọchị",
    so: "Taariikhda",
    ha: "Rana",
    mnk: "Doolu",
    ff: "Ñeene",
    yo: "Ọjọ́"
  },
  Device: {
    en: "Device",
    pt: "Dispositivo",
    de: "Gerät",
    es: "Dispositivo",
    fr: "Appareil",
    it: "Dispositivo",
    sq: "Pajisje",
    tr: "Cihaz",
    sw: "Kifaa",
    am: "መሳሪያ",
    om: "Meeshaa",
    ig: "Ngwaọrụ",
    so: "Qalabka",
    ha: "Na'ura",
    mnk: "Kafookoo",
    ff: "Njaama",
    yo: "Ẹrọ"
  },
  Devices: {
    en: "Devices",
    pt: "Dispositivos",
    de: "Geräte",
    es: "Dispositivos",
    fr: "Appareils",
    it: "Dispositivi",
    sq: "Pajisje",
    tr: "Cihazlar",
    sw: "Vifaa",
    am: "መሳሪያዎች",
    om: "Meeshaalee",
    ig: "Ngwaọrụ",
    so: "Qalabka",
    ha: "Na'urori",
    mnk: "Kafookoo",
    ff: "Njaamaaji",
    yo: "Ẹrọ"
  },
  Deactive: {
    en: "Deactive",
    pt: "Inativo",
    de: "Inaktiv",
    es: "Inactivo",
    fr: "Inactif",
    it: "Disattivo",
    sq: "Inaktiv",
    tr: "Pasif",
    sw: "Zima",
    am: "አቋም አውጥቷል",
    om: "Deemsifamaa",
    ig: "Kwụsị",
    so: "Jooji",
    ha: "Kashe",
    mnk: "Njama",
    ff: "Faalmaa",
    yo: "Ti Dawọ́"
  },

  "Do you want to reset": {
    en: "Do you want to reset?",
    pt: "Deseja redefinir?",
    de: "Möchten Sie zurücksetzen?",
    es: "¿Quieres restablecer?",
    fr: "Voulez-vous réinitialiser?",
    it: "Vuoi ripristinare?",
    sq: "Dëshironi të rivendosni?",
    tr: "Sıfırlamak istiyor musunuz?",
    sw: "Unataka kufuta mabadiliko?",
    am: "እባኮት እንዴት ትርከት አድርጉ?",
    om: "Ati keessoo sirreeffama barbaaddaa?",
    ig: "Ị chọrọ idọghachi?",
    so: "Ma doonaysaa inaad dib u dejiso?",
    ha: "Kana so ku sake?",
    mnk: "Ala kafo wuufoo?",
    ff: "Joomo laama tiife?",
    yo: "Ṣe o fẹ tunto?"
  },

  "Device not found": {
    en: "Device not found",
    pt: "Dispositivo não encontrado",
    de: "Gerät nicht gefunden",
    es: "Dispositivo no encontrado",
    fr: "Appareil non trouvé",
    it: "Dispositivo non trovato",
    sq: "Pajisja nuk u gjet",
    tr: "Cihaz bulunamadı",
    sw: "Kifaa hakikupatikana",
    am: "እባኮት መሳሪያ አልተሳሰበም",
    om: "Meeshaa hin argamne",
    ig: "Ngwaọrụ ahụ enwetaghị",
    so: "Qalabka lama helin",
    ha: "Na'ura ba a samu ba",
    mnk: "Meeshaa taa sooma",
    ff: "Njaama neɓɓi",
    yo: "Ẹrọ kò rí"
  },
  Disabled: {
    en: "Disabled",
    pt: "Desativado",
    de: "Deaktiviert",
    es: "Desactivado",
    fr: "Désactivé",
    it: "Disabilitato",
    sq: "E padisponueshëm",
    tr: "Devre dışı",
    sw: "Imezimwa",
    am: "ተነቃቃል",
    om: "Hindha’aa",
    ig: "Nkwụsịrị",
    so: "La joojiyay",
    ha: "Kashe",
    mnk: "Njama",
    ff: "Faalmaa",
    yo: "Ti Dawọ́"
  },
  "Deactivate device": {
    en: "Deactivate device",
    pt: "Desativar dispositivo",
    de: "Gerät deaktivieren",
    es: "Desactivar dispositivo",
    fr: "Désactiver l'appareil",
    it: "Disattivare dispositivo",
    sq: "Çaktivizo pajisjen",
    tr: "Cihazı devre dışı bırak",
    sw: "Zima kifaa",
    am: "እባኮት አቋም አውጥቷል",
    om: "Deemsifama meeshaa",
    ig: "Kwụsị ngwá ọrụ",
    so: "Jooji qalabka",
    ha: "Kashe na'ura",
    mnk: "Njama Kafookoo",
    ff: "Faalmaa Njaama",
    yo: "Ti Dawọ́ Ẹrọ"
  },
  Deactivate: {
    en: "Deactivate",
    pt: "Desativar",
    de: "Deaktivieren",
    es: "Desactivar",
    fr: "Désactiver",
    it: "Disattiva",
    sq: "Çaktivizo",
    tr: "Devre dışı bırak",
    sw: "Zima",
    am: "አቋም አውጥቷል",
    om: "Deemsifama",
    ig: "Kwụsị",
    so: "Jooji",
    ha: "Kashe",
    mnk: "Njama",
    ff: "Faalmaa",
    yo: "Ti Dawọ́"
  },
  Dashboard: {
    en: "Dashboard",
    pt: "Painel",
    de: "Dashboard",
    es: "Tablero",
    fr: "Tableau de bord",
    it: "Cruscotto",
    sq: "Paneli",
    tr: "Gösterge paneli",
    sw: "Dashibodi",
    am: "ዳሽቦርድ",
    om: "Daashibodi",
    ig: "Dáshbọọdụ",
    so: "Dashbood",
    ha: "Dashboard",
    mnk: "Daashibodi",
    ff: "Dachbood",
    yo: "Dasibodi"
  },
  "Device key": {
    en: "Device key",
    pt: "Chave do dispositivo",
    de: "Geräteschlüssel",
    es: "Clave del dispositivo",
    fr: "Clé de l'appareil",
    it: "Chiave del dispositivo",
    sq: "Çelësi i pajisjes",
    tr: "Cihaz anahtarı",
    sw: "Funguo ya Kifaa",
    am: "ቁልፍ ተስማማሪ",
    om: "Maqaa Meeshaa",
    ig: "Igodo Ngwaọrụ",
    so: "Furaha Qalabka",
    ha: "Mabuɗin Na'ura",
    mnk: "Fungoo Kafookoo",
    ff: "Teefu Njaama",
    yo: "Bọtìn Ẹrọ"
  },
  "Devices count": {
    en: "Devices count",
    pt: "Contagem de dispositivos",
    de: "Geräteanzahl",
    es: "Conteo de dispositivos",
    fr: "Nombre d'appareils",
    it: "Conteggio dispositivi",
    sq: "Numri i pajisjeve",
    tr: "Cihaz sayısı",
    sw: "Idadi ya Vifaa",
    am: "ብዛት ተግባሩ",
    om: "Lakkoofsa Meeshaa",
    ig: "Ọnụ ọgụgụ Ngwaọrụ",
    so: "Tirada Qalabka",
    ha: "Yawan Na'urori",
    mnk: "Tiimoo Kafookoo",
    ff: "Ngamdu Njaama",
    yo: "Iye Ẹrọ"
  },
  Deactivated: {
    en: "Deactivated",
    pt: "Desativado",
    de: "Deaktiviert",
    es: "Desactivado",
    fr: "Désactivé",
    it: "Disattivato",
    sq: "Deaktivizuar",
    tr: "Devre dışı bırakıldı",
    sw: "Vilivyotenganishwa",
    am: "ተስማማሪ",
    om: "Deemsifamanii",
    ig: "Kwụsịrị",
    so: "La joojiyay",
    ha: "Kashe",
    mnk: "Njama hakiloo",
    ff: "Faalmaa",
    yo: "Ti Dawọ́"
  },
  "Deactivated by": {
    en: "Deactivated by",
    pt: "Desativado por",
    de: "Deaktiviert von",
    es: "Desactivado por",
    fr: "Désactivé par",
    it: "Disattivato da",
    sq: "Deaktivizuar nga",
    tr: "Devre dışı bırakan",
    sw: "Ilivyotenganishwa na",
    am: "ተስማማሪ እባኮት",
    om: "Deemsifameen jira",
    ig: "Kwụsịrị site na",
    so: "La joojiyay by",
    ha: "Kashe ta",
    mnk: "Njama hakiloo",
    ff: "Tawii ngoo faalmaa",
    yo: "Ti Dawọ́ nipasẹ"
  },

  "Deactivated date": {
    en: "Deactivated date",
    pt: "Data de desativação",
    de: "Deaktivierungsdatum",
    es: "Fecha de desactivación",
    fr: "Date de désactivation",
    it: "Data di disattivazione",
    sq: "Data e deaktivizimit",
    tr: "Devre dışı bırakılma tarihi",
    sw: "Tarehe ya Vifaa Vilivyotenganishwa",
    am: "ቀን ተስማማሪ",
    om: "Guyyaa Meeshaa Deemsifamanii",
    ig: "Ụbọchị nke ngwá ọrụ kwụsịrị",
    so: "Taariikhda Qalabka La Joojiyay",
    ha: "Ranar Na'urorin Da Aka Kashe",
    mnk: "Doolu Meeshaa njama hakiloo ɗi",
    ff: "Ñeene hoore faalmaa",
    yo: "Ọjọ́ Ẹrọ Ti Dawọ́"
  },
  "Deactivated Devices History": {
    en: "Deactivated Devices History",
    pt: "Histórico de Dispositivos Desativados",
    de: "Deaktivierte Gerätehistorie",
    es: "Historial de Dispositivos Desactivados",
    fr: "Historique des Appareils Désactivés",
    it: "Cronologia Dispositivi Disattivati",
    sq: "Historia e Dispositivëve të Deaktivizuar",
    tr: "Devre Dışı Bırakılan Cihazların Geçmişi",
    sw: "Historia ya Vifaa Vilivyotenganishwa",
    am: "ታማኝ ታሪክ ተስማማሪ ማሳሰቢያ",
    om: "Seenaa Meeshaa Deemsifamanii",
    ig: "Akụkọ ihe mere na ngwá ọrụ kwụsịrị",
    so: "Taariikhda Qalabka La Joojiyay",
    ha: "Tarihin Na'urorin Da Aka Kashe",
    mnk: "Meeshaa njama hakiloo ɗi",
    ff: "Tawii ngoo hoore faalmaa",
    yo: "Itan Ẹrọ Ti Dawọ́"
  },
  "Deactivated history": {
    en: "Deactivated history",
    pt: "Histórico desativado",
    de: "Verlauf deaktiviert",
    es: "Historial desactivado",
    fr: "Historique désactivé",
    it: "Cronologia disattivata",
    sq: "Historia e deaktivizuar",
    tr: "Devre Dışı Bırakılan Geçmiş",
    sw: "Historia ya Vifaa Vilivyotenganishwa",
    am: "ታማኝ ታሪክ ተስማማሪ",
    om: "Seenaa Meeshaa Deemsifamanii",
    ig: "Akụkọ ihe mere na ngwá ọrụ kwụsịrị",
    so: "Taariikhda Qalabka La Joojiyay",
    ha: "Tarihin Na'urorin Da Aka Kashe",
    mnk: "Meeshaa njama hakiloo ɗi",
    ff: "Tawii ngoo hoore faalmaa",
    yo: "Itan Ẹrọ Ti Dawọ́"
  },
  Description: {
    en: "Description",
    pt: "Descrição",
    de: "Beschreibung",
    es: "Descripción",
    fr: "Description",
    it: "Descrizione",
    sq: "Përshkrim",
    tr: "Açıklama",
    sw: "Maelezo",
    am: "መግለጫ",
    om: "Ibsa",
    ig: "Nkọwa",
    so: "Sharaxa",
    ha: "Bayani",
    mnk: "Njaama",
    ff: "Dafay",
    yo: "Apejuwe"
  },
  "Device count": {
    en: "Device count",
    pt: "Contagem de dispositivos",
    de: "Geräteanzahl",
    es: "Cantidad de dispositivos",
    fr: "Nombre d'appareils",
    it: "Conteggio dei dispositivi",
    sq: "Numri i pajisjeve",
    tr: "Cihaz sayısı",
    sw: "Idadi ya Vifaa",
    am: "ብዛት ተግባሩ",
    om: "Lakkoofsa Meeshaa",
    ig: "Ọnụ ọgụgụ Ngwaọrụ",
    so: "Tirada Qalabka",
    ha: "Yawan Na'urori",
    mnk: "Tiimoo Kafookoo",
    ff: "Ngamdu Njaama",
    yo: "Iye Ẹrọ"
  },
  "Device UUID": {
    en: "Device UUID",
    pt: "UUID do dispositivo",
    de: "Geräte UUID",
    es: "UUID del dispositivo",
    fr: "UUID de l'appareil",
    it: "UUID del dispositivo",
    sq: "UUID e pajisjes",
    tr: "Cihaz UUID",
    sw: "UUID ya Kifaa",
    am: "UUID ማሳሰቢያ",
    om: "UUID Meeshaa",
    ig: "UUID Ngwaọrụ",
    so: "UUID Qalabka",
    ha: "UUID na'ura",
    mnk: "UUID Kafookoo",
    ff: "UUID Njaama",
    yo: "UUID Ẹrọ"
  },
  "Device Config": {
    en: "Device Config",
    pt: "Configuração do dispositivo",
    de: "Gerätekonfiguration",
    es: "Configuración del dispositivo",
    fr: "Configuration de l'appareil",
    it: "Configurazione del dispositivo",
    sq: "Konfigurimi i pajisjes",
    tr: "Cihaz Yapılandırması",
    sw: "Mipangilio ya Kifaa",
    am: "ቅንብሮች ማሳሰቢያ",
    om: "Qindaa'ina Meeshaa",
    ig: "Ntọala Ngwaọrụ",
    so: "Qaabaynta Qalabka",
    ha: "Saitin na'ura",
    mnk: "Jaɓaaki Kafookoo",
    ff: "Teefu Njaama",
    yo: "Iṣeto Ẹrọ"
  },
  "Don't have account": {
    en: "Don't have account?",
    pt: "Não tem uma conta?",
    de: "Haben Sie kein Konto?",
    es: "¿No tienes una cuenta?",
    fr: "Vous n'avez pas de compte ?",
    it: "Non hai un account?",
    sq: "Nuk keni llogari?",
    tr: "Hesabınız yok mu?",
    sw: "Huna akaunti?",
    am: "አካውንት አልተኖሩም?",
    om: "Akaawunti hin qabduu?",
    ig: "Enweghị akaụntụ?",
    so: "Ma ma ahayn akoon?",
    ha: "Ba ka da asusun?",
    mnk: "Ala kafo nyinaa?",
    ff: "Joomo laama?",
    yo: "Ṣe o ni iroyin?"
  },
  "Deactivated devices": {
    en: "Deactivated devices",
    pt: "Dispositivos desativados",
    de: "Deaktivierte Geräte",
    es: "Dispositivos desactivados",
    fr: "Appareils désactivés",
    it: "Dispositivi disattivati",
    sq: "Pajisjet e çaktivizuara",
    tr: "Devre dışı bırakılan cihazlar",
    sw: "Vifaa vilivyotenganishwa",
    am: "ከማሳሰቢያ ተስማማሪ ከመጀመሪያ",
    om: "Meeshaa deemsifamanii",
    ig: "Ngwaọrụ ndị kwụsịrị",
    so: "Qalabka la joojiyay",
    ha: "Na'urorin da aka kashe",
    mnk: "Meeshaa hakiloo ɗi",
    ff: "Njaama hakiloo",
    yo: "Ẹrọ ti dawọ́"
  },

  "DE:Sky Thriller HD+": {
    en: "DE:Sky Thriller HD+",
    pt: "DE: Sky Thriller HD+",
    de: "DE: Sky Thriller HD+",
    es: "DE: Sky Thriller HD+",
    fr: "DE: Sky Thriller HD+",
    it: "DE: Sky Thriller HD+",
    sq: "DE: Sky Thriller HD+",
    tr: "DE: Sky Thriller HD+",
    sw: "DE:Sky Thriller HD+",
    am: "DE:Sky Thriller HD+",
    om: "DE:Sky Thriller HD+",
    ig: "DE:Sky Thriller HD+",
    so: "DE:Sky Thriller HD+",
    ha: "DE:Sky Thriller HD+",
    mnk: "DE:Sky Thriller HD+",
    ff: "DE:Sky Thriller HD+",
    yo: "DE:Sky Thriller HD+"
  },
  "Date (MM/YY)": {
    en: "Date (MM/YY)",
    pt: "Data (MM/AA)",
    de: "Datum (MM/YY)",
    es: "Fecha (MM/AA)",
    fr: "Date (MM/AA)",
    it: "Data (MM/AA)",
    sq: "Data (MM/VV)",
    tr: "Tarih (AA/YY)",
    sw: "Tarehe (MM/YY)",
    am: "ቀን (MM/YY)",
    om: "Guyyaa (MM/YY)",
    ig: "Ụbọchị (MM/YY)",
    so: "Taariikhda (MM/YY)",
    ha: "Ranar (MM/YY)",
    mnk: "Doolu (MM/YY)",
    ff: "Ñeene (MM/YY)",
    yo: "Ọjọ́ (MM/YY)"
  },

  "Do you want to delete this": {
    en: "Do you want to delete this",
    pt: "Deseja excluir isso",
    de: "Möchten Sie dies löschen",
    es: "¿Quieres eliminar esto?",
    fr: "Voulez-vous supprimer ceci?",
    it: "Vuoi eliminare questo?",
    sq: "Dëshironi të fshini këtë?",
    tr: "Bunu silmek istiyor musunuz?",
    sw: "Je, unataka kufuta hii?",
    am: "እባኮትን በመጀመሪያ ይተመንበሩ?",
    om: "Kanaa delete kana deemsifamuu barbaaddaa?",
    ig: "Ị chọrọ ịkpochapụ nke a?",
    so: "Ma dooneysaa inaad tirtirto tan?",
    ha: "Shin kana so ka goge wannan?",
    mnk: "I kadi a fo njaama hakiloo ɗi?",
    ff: "A yiin wonaa faatode hoore ka tan?",
    yo: "Ṣe o fẹ́ pa ẹ̀yà yìí?"
  },
  "DNS deleted successfully": {
    en: "DNS deleted successfully",
    pt: "DNS excluído com sucesso",
    de: "DNS erfolgreich gelöscht",
    es: "DNS eliminado con éxito",
    fr: "DNS supprimé avec succès",
    it: "DNS eliminato con successo",
    sq: "DNS u fshi me sukses",
    tr: "DNS başarıyla silindi.",
    sw: "DNS imetolewa kwa mafanikio.",
    am: "DNS በማሳሰቢያ አስተዳደር ተማማሪ በሙሉ.",
    om: "DNS milkaa'inaan haquu qabaa.",
    ig: "DNS ewepụtara nke ọma.",
    so: "DNS si guuleysatay waa la tirtiray.",
    ha: "Anfani DNS an goge shi cikin nasara.",
    mnk: "DNS alokoo maajumoo ko jam.",
    ff: "DNS dafaalu ngoo hoore.",
    yo: "DNS ti paarọ pẹlu aṣeyọri."
  },
  "Do you want to logout": {
    en: "Do you want to logout",
    pt: "Deseja sair",
    de: "Möchten Sie sich abmelden",
    es: "¿Quieres cerrar sesión",
    fr: "Voulez-vous vous déconnecter",
    it: "Vuoi uscire",
    sq: "Dëshironi të dilni",
    tr: "Çıkış yapmak istiyor musunuz",
    sw: "Je, unataka kutoka?",
    am: "እባኮትን በምን በመጀመሪያ በተማማሪ ማሳሰብ በሁሉም?",
    om: "Meeshaa irra deebi'uu barbaaddaa?",
    ig: "Ị chọrọ ịpụ?",
    so: "Ma dooneysaa inaad ka baxdo?",
    ha: "Shin kana so ka fita?",
    mnk: "I kadi a fo jaama hakiloo?",
    ff: "A yiin wonaa faalmaa huunde?",
    yo: "Ṣe o fẹ́ ya lọ?"
  },

  "Do you want to reset?": {
    en: "Do you want to reset?",
    pt: "Deseja redefinir?",
    de: "Möchten Sie zurücksetzen?",
    es: "¿Quieres restablecer?",
    fr: "Voulez-vous réinitialiser?",
    it: "Vuoi ripristinare?",
    sq: "Dëshironi të rivendosni?",
    tr: "Sıfırlamak istiyor musunuz?",
    sw: "Je, unataka kurekebisha?",
    am: "እባኮትን በምን በመነሻ እባኮት ስልኩ በተማማሪ ምንም?",
    om: "Deemsiyaa jechuun barbaaddaa?",
    ig: "Ị chọrọ ịtọghata?",
    so: "Ma dooneysaa inaad dib u dejiso?",
    ha: "Shin kana so ka sake saita?",
    mnk: "I kadi a fo njama hakiloo?",
    ff: "A yiin wonaa faalmaa huunde?",
    yo: "Ṣe o fẹ́ tunṣe?"
  },
  "Device key must be 6 characters": {
    en: "Device key must be 6 characters",
    pt: "A chave do dispositivo deve ter 6 caracteres",
    de: "Geräteschlüssel muss 6 Zeichen lang sein",
    es: "La clave del dispositivo debe tener 6 caracteres",
    fr: "La clé de l'appareil doit contenir 6 caractères",
    it: "La chiave del dispositivo deve essere composta da 6 caratteri",
    sq: "Çelësi i pajisjes duhet të jetë 6 karaktere",
    tr: "Cihaz anahtarı 6 karakter olmalıdır.",
    sw: "Funguo la kifaa lazima liwe na herufi 6.",
    am: "ቁልፍ አሳሽ መሆን አሁን በአንደኛው እንደ ከተማ 6 ስም አሁን",
    om: "Kilalloo meeshaa 6 ta'uu qaba.",
    ig: "Igodo ngwaọrụ ga-adị ka mkpụrụedemede 6.",
    so: "Furaha qalabkan waa inay noqotaa 6 xaraf.",
    ha: "Mabuɗin na'ura dole ne ya kasance haruffa 6.",
    mnk: "Kiɗɗo wooto ɓaahi haayre 6.",
    ff: "Koɗe hoore ɓe taama 6 goɗɗi.",
    yo: "Bọtini ẹrọ gbọdọ jẹ awọn ohun kikọ 6."
  },

  "Do you want to deactivate this device": {
    en: "Do you want to deactivate this device?",
    pt: "Deseja desativar este dispositivo?",
    de: "Möchten Sie dieses Gerät deaktivieren?",
    es: "¿Desea desactivar este dispositivo?",
    fr: "Voulez-vous désactiver cet appareil ?",
    it: "Vuoi disattivare questo dispositivo?",
    sq: "A dëshironi të çaktivizoni këtë pajisje?",
    tr: "Bu cihazı devre dışı bırakmak istiyor musunuz?",
    sw: "Je, unataka kutenganisha kifaa hiki?",
    am: "እባኮትን በምን በመነሻ ተግባሩን ሁሉን ተመዝጋበት?",
    om: "Kitaa kana deemsifamuu dhiisuu barbaaddaa?",
    ig: "Ị chọrọ ịkwụsị ngwaọrụ a?",
    so: "Ma dooneysaa inaad joojiso qalabkan?",
    ha: "Shin kana so ka kashe wannan na'ura?",
    mnk: "I kadi a fo njaama hakiloo ɗi?",
    ff: "A yiin wonaa faatode hoore ka tan?",
    yo: "Ṣe o fẹ́ dawọ́ iṣẹ́ ẹrọ yìí?"
  },

  "Do you want to block this device": {
    en: "Do you want to block this device?",
    pt: "Você quer bloquear este dispositivo?",
    de: "Möchten Sie dieses Gerät blockieren?",
    es: "¿Quieres bloquear este dispositivo?",
    fr: "Voulez-vous bloquer ce dispositif?",
    it: "Vuoi bloccare questo dispositivo?",
    sq: "A dëshironi të bllokoni këtë pajisje?",
    tr: "Bu cihazı engellemek istiyor musunuz?",
    sw: "Je, unataka kuzuia kifaa hiki?",
    am: "እባኮትን በምን በመነሻ እባኮት ተግባሩን ይህም ተመዝጋበት?",
    om: "Kitaa kana haquu barbaaddaa?",
    ig: "Ị chọrọ igbochi ngwaọrụ a?",
    so: "Ma dooneysaa inaad xirto qalabkan?",
    ha: "Shin kana so ka toshe wannan na'ura?",
    mnk: "I kadi a fo njaama hakiloo ɗi?",
    ff: "A yiin wonaa faatode hoore ka tan?",
    yo: "Ṣe o fẹ́ dènà ẹ̀rọ yìí?"
  },
  "Do you want to unblock this device": {
    en: "Do you want to unblock this device?",
    pt: "Deseja desbloquear este dispositivo?",
    de: "Möchten Sie dieses Gerät freischalten?",
    es: "¿Desea desbloquear este dispositivo?",
    fr: "Voulez-vous débloquer ce périphérique?",
    it: "Vuoi sbloccare questo dispositivo?",
    sq: "A dëshironi të çaktivizoni bllokimin e kësaj pajisje?",
    tr: "Bu cihazın engelini kaldırmak istiyor musunuz?",
    sw: "Je, unataka kufungua kifaa hiki?",
    am: "እባኮትን በምን በመነሻ እባኮት ስልኩ ይታወቅ?",
    om: "Kitaa kana hinni haquu barbaaddaa?",
    ig: "Ị chọrọ iwepụ mgbochi ngwaọrụ a?",
    so: "Ma dooneysaa inaad furto qalabkan?",
    ha: "Shin kana so ka buɗe wannan na'ura?",
    mnk: "I kadi a fo njaama hakiloo ɗi?",
    ff: "A yiin wonaa faatode hoore ka tan?",
    yo: "Ṣe o fẹ́ ṣi ẹ̀rọ yìí?"
  },
  "Device configuration updates are successful": {
    en: "Device configuration updates are successful.",
    pt: "As atualizações de configuração do dispositivo foram bem-sucedidas.",
    de: "Gerätekonfigurationsaktualisierungen waren erfolgreich.",
    es: "Las actualizaciones de configuración del dispositivo se realizaron con éxito.",
    fr: "Les mises à jour de la configuration de l'appareil ont réussi.",
    it: "Gli aggiornamenti della configurazione del dispositivo sono avvenuti con successo.",
    sq: "Përditësimet e konfigurimit të pajisjes janë të suksesshme.",
    tr: "Cihaz yapılandırma güncellemeleri başarılıdır.",
    sw: "Sasisho la usanidi wa kifaa limethibitishwa kwa mafanikio.",
    am: "እባኮት በማህበረሰቡ በማሳሰቢያ የመንበረሀብን የማሳሰበት",
    om: "Tooftaa qindaa'ina meeshaa milkaa'inaan jijjiirame.",
    ig: "Nkwalite nke ntọala ngwaọrụ ahụ bụ nke ọma.",
    so: "Cusbooneysiinta qaabaynta qalabka ayaa guulaysatay.",
    ha: "Sabunta saitin na'ura sun yi nasara.",
    mnk: "Nyaamaɗi aabaakoo hakiloo miiɗo a jam.",
    ff: "Nda ko duuɓe njayre ndiyam dewɓo e lewde.",
    yo: "Imudojuiwọn eto ẹrọ naa ti ṣaṣeyọri."
  },
  "Double check your server credentials before pressing start": {
    en: "Double check your server credentials before pressing start.",
    pt: "Verifique suas credenciais do servidor antes de pressionar Iniciar.",
    de: "Überprüfen Sie Ihre Server-Anmeldeinformationen, bevor Sie starten.",
    es: "Verifique sus credenciales del servidor antes de presionar Iniciar.",
    fr: "Vérifiez vos identifiants de serveur avant d'appuyer sur Démarrer.",
    it: "Verifica le tue credenziali del server prima di premere Avvia.",
    sq: "Verifikoni kredencialet tuaja të serverit para se të shtypni fillimi.",
    tr: "Başlatmadan önce sunucu kimlik bilgilerinizi kontrol edin.",
    sw: "Angalia tena uthibitisho wa seva yako kabla ya kubonyeza Anza.",
    am: "እባኮት በመጀመሪያ እንደተገናኙ ከመነሻ በፊት ይተመንበሩ",
    om: "Fakkeenya galmee sirriitti eeggachuu dura dhiibbaa 'start' gochuu.",
    ig: "Leleecha ozi gị nke ọma tupu ịpịa malite.",
    so: "Hubi aqoonsiga server-kaaga ka hor inta aanad riixin bilawga.",
    ha: "Duba bayanan sabar ɗinku kafin latsa fara.",
    mnk: "Janta sannoo e ginndu miiɗo ɓurndi ndene hakiloo.",
    ff: "Donnol pular ko ɓennutooji haayre ka ndiyam a fawri.",
    yo: "Ṣayẹwo ìmúlòṣẹ́ àkọọlẹ rẹ kí o to tẹ bọtini ìbẹ̀rẹ̀."
  },
  "device activation info": {
    en: "If you would like to activate this device in the future, you need to spend a credit on it again. Do you want to deactivate this device?",
    pt: "Se você deseja ativar este dispositivo no futuro, precisará gastar créditos novamente. Deseja desativar este dispositivo?",
    de: "Wenn Sie dieses Gerät in Zukunft aktivieren möchten, müssen Sie erneut Guthaben dafür ausgeben. Möchten Sie dieses Gerät deaktivieren?",
    es: "Si desea activar este dispositivo en el futuro, deberá gastar créditos nuevamente. ¿Desea desactivar este dispositivo?",
    fr: "Si vous souhaitez activer ce dispositif à l'avenir, vous devrez dépenser à nouveau des crédits. Souhaitez-vous désactiver ce dispositif ?",
    it: "Se desideri attivare nuovamente questo dispositivo in futuro, dovrai spendere nuovamente un credito. Vuoi disattivare questo dispositivo?",
    sq: "Nëse dëshironi të aktivizoni këtë pajisje në të ardhmen, duhet të shpenzoni përsëri një kredit për të. Doni të çaktivizoni këtë pajisje?",
    tr: "Bu cihazı gelecekte etkinleştirmek istiyorsanız, tekrar kredi harcamanız gerekiyor. Bu cihazı devre dışı bırakmak istiyor musunuz?",
    sw: "Ikiwa ungependa kuamsha kifaa hiki katika siku zijazo, unahitaji kutumia tena salio lako. Je, unataka kutenganisha kifaa hiki?",
    am: "እባኮትን በማህበረሰቡ ስለ እምብተተቱን አዲስ እንኳን ይሁን በማሳሰብ ይፈልጋሉ?",
    om: "Yoo akka deemsifamuuf waltajjii fuulduraatti gaafachuu yoo barbaaddan, deebii guutuu galchuu barbaachisa. Kanaaftu deemsifamuu dhiisuu barbaaddaa?",
    ig: "Ọ bụrụ na ịchọrọ ịkwụsị ngwanrọ na nke a n'ọdịnihu, ị ga-achọ ịkwaga akụ a ọzọ. Ị chọrọ ịchọpụtakwa ngwaọrụ a?",
    so: "Haddii aad rabto inaad dib u firfircooni qalabkan mustaqbalka, waa inaad mar kale ku qarashgareysaa. Ma rabtaa inaad joojiso qalabkan?",
    ha: "Idan kana son kunna wannan na'ura a nan gaba, dole ne ka sake kashe kiredit a kanta. Kana so ka kashe wannan na'ura?",
    mnk: "Too sa bɔi ndiyam hakiloo mbine jola ɓara kiné, waazoono fo wéen dun waala. A lë mi dimba ko wëtna mbine?",
    ff: "Nda i darma la fayda hoore la kadi, ko feewi waye bimbi wuro. Goolu ba goofeeje faati si e golde jeego ka?",
    yo: "Ti o ba fẹ́ tun ṣe àgbékalẹ̀ ẹ̀rọ yìí ní ọjọ́ iwájú, o nilo láti san kirediti lẹ́ẹ̀kan síi. Ṣe o fẹ́ dawọ́ iṣẹ́ yii?"
  },
  "Do you want to reset this device": {
    en: "Do you want to reset this device?",
    pt: "Deseja reiniciar este dispositivo?",
    de: "Möchten Sie dieses Gerät zurücksetzen?",
    es: "¿Desea restablecer este dispositivo?",
    fr: "Voulez-vous réinitialiser cet appareil?",
    it: "Vuoi ripristinare questo dispositivo?",
    sq: "Doni të ristartoni këtë pajisje?",
    tr: "Bu cihazı sıfırlamak istiyor musunuz?",
    sw: "Je, unataka kurekebisha kifaa hiki?",
    am: "እባኮትን በእርግጥ እባኮት ይቀርበዋል?",
    om: "Kitaa kana deemsifamuu barbaaddaa?",
    ig: "Ị chọrọ ịtọghata ngwaọrụ a?",
    so: "Ma dooneysaa inaad dib-u-dejiso qalabkan?",
    ha: "Shin kana so wanda ku saita wannan na'ura?",
    mnk: "I kadi a fo njaama hakiloo tuma ɗi?",
    ff: "A yiin wonaa faalmaa huunde leydi tan?",
    yo: "Ṣe o fẹ́ tunṣe ẹ̀rọ yìí?"
  },
  "Do you want to cancel reset this device": {
    en: "Do you want to cancel reset this device?",
    pt: "Você deseja cancelar a reinicialização deste dispositivo?",
    de: "Möchten Sie den Reset dieses Geräts abbrechen?",
    es: "¿Desea cancelar el restablecimiento de este dispositivo?",
    fr: "Voulez-vous annuler la réinitialisation de cet appareil?",
    it: "Vuoi annullare il ripristino di questo dispositivo?",
    sq: "Doni të anuloni ristartin e kësaj pajisje?",
    tr: "Bu cihazın sıfırlanmasını iptal etmek istiyor musunuz?",
    sw: "Je, unataka kughairi kurekebisha kifaa hiki?",
    am: "እባኮትን እርስዎ እባኮት በምን እንደአሁን ማሳሰብ ይፈልጋሉ?",
    om: "Kitaa kana hinni deemsifamuu dhiisuu barbaaddaa?",
    ig: "Ị chọrọ ịkwụsị ịtọghata ngwaọrụ a?",
    so: "Ma dooneysaa inaad joojiso dib-u-dejinta qalabkan?",
    ha: "Shin kana so wanda ku soke saitin wannan na'ura?",
    mnk: "I kadi a fo njaama hakiloo tuma ɗi?",
    ff: "A yiin wonaa faalmaa huunde leydi tan?",
    yo: "Ṣe o fẹ́ dáwọ́ ìtúnṣe ẹ̀rọ yìí?"
  },

  Delete: {
    en: "Delete",
    pt: "Excluir",
    de: "Löschen",
    es: "Eliminar",
    fr: "Supprimer",
    it: "Elimina",
    sq: "Fshij",
    tr: "Sil",
    sw: "Futa",
    am: "ማጥፋት",
    om: "Haqu",
    ig: "Hichapụ",
    so: "Tirtir",
    ha: "Goge",
    mnk: "Bubbu",
    ff: "Dokkude",
    yo: "Pa"
  },
  Day: {
    en: "Day",
    es: "Día",
    fr: "Jour",
    de: "Tag",
    pt: "Dia",
    it: "Giorno",
    sq: "Ditë",
    tr: "Gün",
    sw: "Siku",
    am: "ቀን",
    om: "Guyyaa",
    ig: "Ụbọchị",
    so: "Maalin",
    ha: "Rana",
    mnk: "Ña",
    ff: "Ñalawma",
    yo: "Ọjọ́"
  },

  //m
  Media: {
    en: "Media",
    pt: "Mídia",
    de: "Medien",
    es: "Medios",
    fr: "Médias",
    it: "Media",
    sq: "Media",
    tr: "Medya",
    sw: "Vyombo vya Habari",
    am: "ሚዲያ",
    om: "Meedhiya",
    ig: "Mepụta",
    so: "Warbaahinta",
    ha: "Kafofin watsa labarai",
    mnk: "Meediyaa",
    ff: "Kafooji",
    yo: "Ẹ̀ka ìròyìn"
  },
  Month: {
    en: "Month",
    es: "Mes",
    fr: "Mois",
    de: "Monat",
    pt: "Mês",
    it: "Mese",
    sq: "Mënd",
    tr: "Ay",
    sw: "Mwezi",
    am: "ወር",
    om: "Ji'a",
    ig: "Ọnwa",
    so: "Bishii",
    ha: "Wata",
    mnk: "Fay",
    ff: "Boro",
    yo: "Osù"
  },
  Message: {
    en: "Message",
    pt: "Mensagem",
    de: "Nachricht",
    es: "Mensaje",
    fr: "Message",
    it: "Messaggio",
    sq: "Mesazh",
    tr: "Mesaj",
    sw: "Ujumbe",
    am: "ምልክት",
    om: "Ergaa",
    ig: "Ozi",
    so: "Farriin",
    ha: "Sako",
    mnk: "Baalɗo",
    ff: "Fayde",
    yo: "Ìfẹnukò"
  },
  "Movie ID": {
    en: "Movie ID",
    pt: "ID do Filme",
    de: "Film-ID",
    es: "ID de Película",
    fr: "ID du Film",
    it: "ID Film",
    sq: "ID e Filmit",
    tr: "Film ID",
    sw: "Kitambulisho cha Filamu",
    am: "መታወቂያ ፊልም",
    om: "ID Filmii",
    ig: "Nọmbụ Fịlịm",
    so: "Aqoonsiga Filim",
    ha: "Lambar Fina-finai",
    mnk: "ID Filim",
    ff: "ID film",
    yo: "I.D. Fílàmù"
  },
  Menu: {
    en: "Menu",
    pt: "Menu",
    de: "Menü",
    es: "Menú",
    fr: "Menu",
    it: "Menu",
    sq: "Meny",
    tr: "Menü",
    sw: "Menyu",
    am: "ማንበብ",
    om: "Menu",
    ig: "Menu",
    so: "Menu",
    ha: "Menu",
    mnk: "Menyu",
    ff: "Menu",
    yo: "Akópọ"
  },

  "Menu Page": {
    en: "Menu Page",
    es: "Página de menú",
    fr: "Page de menu",
    de: "Menüseite",
    pt: "Página de menu",
    it: "Pagina del menu",
    sq: "Faqja e menysë",
    tr: "Sayfa Menüsü",
    sw: "Ukurasa wa Menyu",
    am: "ገፅ ማንበብ",
    om: "Bokii Menu",
    ig: "Ọnụ ọkpụkpụ Menu",
    so: "Bogga Menu",
    ha: "Shafin Menu",
    mnk: "Peeji Menu",
    ff: "Paje menu",
    yo: "Ojúewé Akọ́tọ̀"
  },
  month: {
    en: "month",
    pt: "mês",
    de: "Monat",
    es: "mes",
    fr: "mois",
    it: "mese",
    sq: "muaj",
    tr: "ay",
    sw: "Mwezi",
    am: "ወር",
    om: "Ji'a",
    ig: "Ọnwa",
    so: "Bishii",
    ha: "Wata",
    mnk: "Fay",
    ff: "Boro",
    yo: "Osù"
  },
  Movies: {
    en: "Movies",
    pt: "Filmes",
    de: "Filme",
    es: "Películas",
    fr: "Films",
    it: "Film",
    sq: "Filma",
    tr: "Filmler",
    sw: "Filamu",
    am: "ፊልሞች",
    om: "Filmii",
    ig: "Fịlịm",
    so: "Filimada",
    ha: "Fina-finai",
    mnk: "Filim",
    ff: "Leɗɗi film",
    yo: "Fílàmù"
  },
  Migration: {
    en: "Migration",
    pt: "Migração",
    de: "Migration",
    es: "Migración",
    fr: "Migration",
    it: "Migrazione",
    sq: "Migracion",
    tr: "Göç",
    sw: "Uhamiaji",
    am: "ስርዓተ እንቅስቃሴ",
    om: "Deemsisa",
    ig: "Mgbasa njem",
    so: "Soco",
    ha: "Hijira",
    mnk: "Kaɓu",
    ff: "Jeertooji",
    yo: "Ìrìnàjò"
  },
  migration_text: {
    en: "Your migration process started!. You will be notified via email about the migration status. It can take some time based on count of your users.",
    pt: "Seu processo de migração começou!. Você será notificado por e-mail sobre o status da migração. Pode levar algum tempo com base na contagem de seus usuários.",
    de: "Ihr Migrationsprozess hat begonnen!. Sie werden per E-Mail über den Migrationsstatus benachrichtigt. Es kann einige Zeit dauern, abhängig von der Anzahl Ihrer Benutzer.",
    es: "¡Su proceso de migración ha comenzado!. Se le notificará por correo electrónico sobre el estado de la migración. Puede llevar algún tiempo en función de la cantidad de sus usuarios.",
    fr: "Votre processus de migration a commencé!. Vous serez informé par e-mail de l'état de la migration. Cela peut prendre un certain temps en fonction du nombre de vos utilisateurs.",
    it: "Il tuo processo di migrazione è iniziato!. Sarai informato via e-mail sullo stato della migrazione. Può richiedere del tempo in base al numero dei tuoi utenti.",
    sq: "Procesi juaj i migrimit ka filluar!. Do të njoftoheni me email për statusin e migrimit. Mund të zgjasë një kohë në bazë të numrit të përdoruesve tuaj.",
    tr: "Göç işleminiz başladı!. Göç durumu hakkında e-posta yoluyla bilgilendirileceksiniz. Kullanıcı sayınıza bağlı olarak bir süre alabilir.",
    sw: "Mchakato wako wa uhamiaji umeanza!. Utapokea arifa kupitia barua pepe kuhusu hali ya uhamiaji. Inaweza kuchukua muda kulingana na idadi ya watumiaji wako.",

    am: "የሚገባው ስርዓተ እንቅስቃሴው ተጀምሯል!. በኢሜል ላይ የሚገባው ስርዓተ እንቅስቃሴ ሁኔታ ተልከዋል። የሚገባው በተለያዩ ተጠቃሚዎች መሆን ይችላሉ።",
    om: "Deemsisaan kana deemsifamuu barbaaddaa!. Kanaafuu e-mail keessaa deemsifamuu barbaaddaa. Kanaafuu waan barbaaddan keessaa deemsifamuu barbaaddaa. Kanaafuu waan barbaaddan keessaa deemsifamuu barbaaddaa.",
    ig: "Ọrụ ịchọrọ ịkwụsị ngwanrọ n'ọdịnihu!. Ọ bụrụ na ịchọrọ ịkwụsị ngwanrọ n'ọdịnihu. Ọ bụrụ na ịchọrọ ịkwụsị ngwanrọ n'ọdịnihu.",
    so: "Habkaaga socda!. Waxaad heli doontaa warar ku saabsan xaaladda socdaalka. Waxaa laga yaabaa inuu qaado waqtiga ku tiirsan tirada isticmaaleyaashaada.",
    ha: "Shirin hijira ya faru!. Zaku ci gaba da hanyar aiki ta email game da halin hijira. Zai iya daukar nauyi wani lokaci a kan adadin abokan aikinka.",
    mnk: "Kaɓu aabaakoo hakiloo miiɗo!. Kaɓu aabaakoo hakiloo miiɗo. Kaɓu aabaakoo hakiloo miiɗo.",
    ff: "Jeertooji a yiin wonaa faat",
    yo: "Ìṣẹ̀ ẹrọ rẹ ti bẹ̀rẹ̀!. Yóò wà láti rí àwọn ìròyìn ní ìmèlè nígbà tí ìṣẹ̀ ẹrọ bẹ̀rẹ̀. Ó le wà láti pẹ̀lú àkọ́kọ́ ní ìpínlẹ̀ àwọn olùṣẹ̀ rẹ."
  },
  Migrate: {
    en: "Migrate",
    pt: "Migrar",
    de: "Migrieren",
    es: "Migrar",
    fr: "Migrer",
    it: "Migrare",
    sq: "Migrimi",
    tr: "Göç",
    sw: "Hamia",
    am: "እንቅስቃሴ",
    om: "Galgalaa",
    ig: "Gaa njem",
    so: "Gaar",
    ha: "Hijira",
    mnk: "Jat",
    ff: "Weeje",
    yo: "Ṣe àgbékalẹ̀"
  },
  Model: {
    en: "Model",
    pt: "Modelo",
    de: "Modell",
    es: "Modelo",
    fr: "Modèle",
    it: "Modello",
    sq: "Modeli",
    tr: "Model",
    sw: "Mfano",
    am: "ሞዴል",
    om: "Maqaalee",
    ig: "Ụdị",
    so: "Muuqaal",
    ha: "Samfuri",
    mnk: "Miiɗo",
    ff: "Tawti",
    yo: "Awoṣe"
  },
  "Movies Categories": {
    en: "Movies Categories",
    pt: "Categorias de filmes",
    de: "Filmkategorien",
    es: "Categorías de películas",
    fr: "Catégories de films",
    it: "Categorie di film",
    sq: "Kategoritë e filmit",
    tr: "Film Kategorileri",
    sw: "Makundi ya Filamu",
    am: "መዝገቦች ፊልሞች",
    om: "Kitaawwan Filmii",
    ig: "Ụdị Fịlịm",
    so: "Qaybaha Filimada",
    ha: "Rukunin fina-finai",
    mnk: "Kataawoo Filim",
    ff: "Kategorii leydi film",
    yo: "Ẹ̀ka Fílàmù"
  },

  "Monday/September/2023": {
    en: "Monday/September/2023",
    pt: "Segunda-feira/Setembro/2023",
    de: "Montag/September/2023",
    es: "Lunes/Septiembre/2023",
    fr: "Lundi/Septembre/2023",
    it: "Lunedì/Settembre/2023",
    sq: "E Hënë/Shtator/2023",
    tr: "Pazartesi/ Eylül/2023",
    sw: "Jumatatu/Septemba/2023",
    am: "ሰኞ/ሴፕቴምበር/2023",
    om: "Wiixata/Onkololee/2023",
    ig: "Mọnde/Septemba/2023",
    so: "Isniin/Seteembar/2023",
    ha: "Litinin/Setemba/2023",
    mnk: "Saman juma/Seteemba/2023",
    ff: "Alhadi/Settemba/2023",
    yo: "Mọ́ńdè/Ṣẹ́tẹ́ẹ́mbà/2023"
  },
  "Migration system": {
    en: "Migration system",
    pt: "Sistema de migração",
    de: "Migrationsystem",
    es: "Sistema de migración",
    fr: "Système de migration",
    it: "Sistema di migrazione",
    sq: "Sistemi i migracionit",
    tr: "Göç sistemi",
    sw: "Mfumo wa uhamiaji",
    am: "ተሻሽለ ስርዓት",
    om: "Sistama deemsisaa",
    ig: "Ụzọ mgbasa ozi",
    so: "Nidaamka Socodka",
    ha: "Tsarin hijira",
    mnk: "Sistama kaɓu",
    ff: "Nay jeertooji",
    yo: "Ẹ̀ka ìrìnàjò"
  },
  "Migrate your users from one server to another": {
    en: "Migrate your users from one server to another",
    pt: "Migre seus usuários de um servidor para outro",
    de: "Migrieren Sie Ihre Benutzer von einem Server auf einen anderen",
    es: "Migra a tus usuarios de un servidor a otro",
    fr: "Migrez vos utilisateurs d'un serveur à un autre",
    it: "Migra i tuoi utenti da un server all'altro",
    sq: "Migrojini përdoruesit tuaj nga një server në një tjetër",
    tr: "Kullanıcılarınızı bir sunucudan diğerine taşımak için",
    sw: "Hamisha watumiaji wako kutoka seva moja hadi nyingine",
    am: "ምትክ ተጠቃሚዎችዎን ከአንደኛ አገልግሎት ወደሌላ",
    om: "Fayyadamtoota kee irraa gara seervar tokkoo gara biraatti geeddaru",
    ig: "Gbaa ndị ọrụ gị site na otu sava gaa na nke ọzọ",
    so: "Guuri isticmaalayaashaada ka server-ka mid ah ilaa server-ka kale",
    ha: "Matsar da masu amfani daga uwar garke guda ɗaya zuwa wani",
    mnk: "Jaŋngol faydaalu kee heɓe server ko ɓurndu",
    ff: "Yiyta fayyadamtoota ka ɗe ka server ɗiɗi",
    yo: "Gbigbe awọn olumulo rẹ lati olupin kan si omiran"
  },
  "Migrate your users from": {
    en: "Migrate your users from",
    pt: "Migre seus usuários de",
    de: "Migrieren Sie Ihre Benutzer von",
    es: "Migra tus usuarios desde",
    fr: "Migrez vos utilisateurs depuis",
    it: "Migra i tuoi utenti da",
    sq: "Migrojini përdoruesit tuaj nga",
    tr: "Kullanıcılarınızı buradan taşıyın",
    sw: "Hamisha watumiaji wako kutoka",
    am: "ምትክ ተጠቃሚዎችዎን ከ",
    om: "Migrate fayyadamtoota kee irraa",
    ig: "Gbaa ndị ọrụ gị site na",
    so: "Guuri isticmaalayaashaada ka",
    ha: "Matsar da masu amfani daga",
    mnk: "Jaŋngol faydaalu kee heɓe",
    ff: "Yiyta fayyadamtoota ka",
    yo: "Gbigbe awọn olumulo rẹ lati"
  },
  "Migration is not possible": {
    en: "Migration is not possible. The new migration server matches the server you want to migrate from.",
    pt: "A migração não é possível. O novo servidor de migração corresponde ao servidor de origem que deseja migrar.",
    de: "Die Migration ist nicht möglich. Der neue Migrationsserver entspricht dem Server, von dem Sie migrieren möchten.",
    es: "La migración no es posible. El nuevo servidor de migración coincide con el servidor del que desea migrar.",
    fr: "La migration n'est pas possible. Le nouveau serveur de migration correspond au serveur que vous souhaitez migrer.",
    it: "La migrazione non è possibile. Il nuovo server di migrazione corrisponde al server da cui desideri migrare.",
    sq: "Migrimi nuk është i mundur. Serveri i ri i migrimit përputhet me serverin që dëshironi të migroni.",
    tr: "Göç mümkün değildir. Yeni göç sunucusu, taşımak istediğiniz sunucu ile eşleşiyor.",
    sw: "Uhamaji hauwezekani. Seva mpya ya uhamaji inalingana na seva unayotaka kuhamia kutoka.",
    am: "ምትክ አልተከናወነም። አዲሱ ምትክ ስርዓት ከሚከተሉት ስርዓት ጋር ይለመናል።",
    om: "Uhamaja hin danda'amu. Seviin haaraan kan uhamajaaf filatame kan sirrii ta'e seviin si barbaadde irraa gara isaatti ce'u.",
    ig: "A naghị ekwe omume ịkwaga. Sava ọhụrụ mbufụt kwekọrọ na sava ị na-achọ ịkwaga site na ya.",
    so: "Guurista ma suurtogal maahan. Server-ka cusub ee guurista wuxuu la mid yahay server-ka aad rabto inaad ka guurto.",
    ha: "Hijira ba ta yiwu ba. Sabon sabar hijira yana dace da sabar da kake son canjawa daga gareta.",
    mnk: "Jaŋngol laaɓe ko feewu. Server jaŋngol baawo to maɓɓe heɓi ko server ngoo tawii waawde faamde.",
    ff: "Hijira ba za ta yiwu ba. Sabon sabar hijira yana dace da sabar da kake son canjawa daga gareta.",
    yo: "Ìkànsí kò ṣèṣe. Sẹ́và tuntun ti ìkànsí naa bá a ti fẹ́ràn láti kọ́kọ́ guuru sí."
  },
  "Migration system allows you to move all": {
    en: "Migration system allows you to move all your users from one server to another server within a few minutes in case of a problem.",
    pt: "O sistema de migração permite mover todos os seus usuários de um servidor para outro em poucos minutos, em caso de problema.",
    de: "Das Migrationsystem ermöglicht es Ihnen, alle Ihre Benutzer innerhalb weniger Minuten von einem Server auf einen anderen zu verschieben, falls ein Problem auftritt.",
    es: "El sistema de migración le permite mover a todos sus usuarios de un servidor a otro en cuestión de minutos en caso de un problema.",
    fr: "Le système de migration vous permet de déplacer tous vos utilisateurs d'un serveur à un autre en quelques minutes en cas de problème.",
    it: "Il sistema di migrazione consente di spostare tutti i tuoi utenti da un server all'altro in pochi minuti in caso di problemi.",
    sq: "Sistemi i migrimit ju lejon të lëvizni të gjithë përdoruesit tuaj nga një server në një tjetër brenda disa minutave në rast se ka një problem.",
    tr: "Göç sistemi, bir sorun olması durumunda, tüm kullanıcılarınızı birkaç dakika içinde bir sunucudan diğerine taşımanıza olanak tanır.",
    sw: "Mfumo wa uhamaji unakuwezesha kuhamisha watumiaji wako wote kutoka kwa seva moja hadi nyingine ndani ya dakika chache endapo kutatokea tatizo.",
    am: "ስርዓተ ምትክ እንዲሁም ስለተሳሳት ከሆነ በጥቂት ደቂቃ ሁሉንም ተጠቃሚዎቻችሁን ከአንድ አገልግሎት ስር ወደሌላ አገልግሎት መለወጥ ይፈቅዳል.",
    om: "Sistemi taja'u namoonni kee hunda yeroo xiqqoo keessatti haala rakkoo keessa jiru irraa gara tajaajila biraatti geessuu danda'ama.",
    ig: "Sistemụ mbufụt nwere ike inye gị ohere ịkwaga ndị ọrụ gị niile site na sava otu gaa na sava ọzọ n'ime nkeji ole na ole ma ọ bụrụ na nsogbu na-apụta.",
    so: "Nidaamka guurista wuxuu kuu oggolaanayaa inaad u guuriso dhammaan isticmaalayaashaada hal server ilaa server kale dhowr daqiiqo gudahood haddii ay jirto dhibaato.",
    ha: "Tsarin hijira yana ba ka damar canja dukkan masu amfani naka daga uwar garke guda zuwa wata uwar garke cikin mintuna kaɗan idan wani matsala ta taso.",
    mnk: "Sistemi jaŋngol la o waɗi ko fow ɗe njaɓata yooku ka server ngoo ndaarndu ka server baawo, eɓɓe so tawii biɗɗo yiɗi.",
    ff: "Sisteme ɗin hijira na ba ku damar canja dukkan masu amfani daga sabar ɗaya zuwa wata sabar cikin mintuna kaɗan idan matsala ta taso.",
    yo: "Ẹ̀ka ìkànsí nìkan ń fún ọ ní àǹfààní láti yí gbogbo àwọn olùsọ̀wọ́ rẹ láti server kan sí server míì pẹ̀lú àkókò kékèké nígbà tí ìṣòro bá ṣẹlẹ."
  },

  //l
  Lock: {
    en: "Lock",
    pt: "Trancar",
    de: "Sperren",
    es: "Bloquear",
    fr: "Verrouiller",
    it: "Blocca",
    sq: "Blloko",
    tr: "Kilitle",
    sw: "Funga",
    am: "ቁርጸ",
    om: "Cufi",
    ig: "Mechie",
    so: "Xidh",
    ha: "Kulle",
    mnk: "Bindi",
    ff: "Catta",
    yo: "Tii"
  },
  Login: {
    en: "Login",
    pt: "Entrar",
    de: "Anmeldung",
    es: "Iniciar sesión",
    fr: "Connexion",
    it: "Accesso",
    sq: "Identifikohu",
    tr: "Giriş",
    sw: "Ingia",
    am: "ግባ",
    om: "Seeni",
    ig: "Banye",
    so: "Gali",
    ha: "Shiga",
    mnk: "Sooma",
    ff: "Ñaama",
    yo: "Wọle"
  },
  Logout: {
    en: "Logout",
    pt: "Sair",
    de: "Abmelden",
    es: "Cerrar sesión",
    fr: "Déconnexion",
    it: "Esci",
    sq: "Çkyçu",
    tr: "Çıkış",
    sw: "Toka",
    am: "ውጣ",
    om: "Ba'ii",
    ig: "Pụọ",
    so: "Ka baxo",
    ha: "Fita",
    mnk: "Lɔɔŋ",
    ff: "Fiyo",
    yo: "Bọsẹ"
  },
  "Logo size": {
    en: "Logo size",
    pt: "Tamanho do logotipo",
    de: "Logo-Größe",
    es: "Tamaño del logotipo",
    fr: "Taille du logo",
    it: "Dimensione del logo",
    sq: "Madhësia e logotipit",
    tr: "Logo boyutu",
    sw: "Ukubwa wa Nembo",
    am: "መጠን ሎጎ",
    om: "Gatii Laagaa",
    ig: "Nchịkọta Logo",
    so: "Cabbirka Astaanta",
    ha: "Girman Tambari",
    mnk: "Leeri logo",
    ff: "Cikoojo logo",
    yo: "Iwọn aami"
  },
  "Live TV": {
    en: "Live TV",
    pt: "TV ao Vivo",
    de: "Live-TV",
    es: "TV en vivo",
    fr: "Télévision en direct",
    it: "TV in diretta",
    sq: "TV Live",
    tr: "Canlı TV",
    sw: "Televisheni ya Moja kwa Moja",
    am: "ቀጥታ ቴሌቪዥን",
    om: "Televiziyo Ajaan",
    ig: "Television dị ndụ",
    so: "Telefishinka Toos ah",
    ha: "Talabijin Kai Tsaye",
    mnk: "TV le Jooɗi",
    ff: "Telebijin na haɓɓe",
    yo: "Television Pẹlu akoko gidi"
  },
  lifetime: {
    en: "Lifetime",
    pt: "Vitalício",
    de: "Lebensdauer",
    es: "Vitalicio",
    fr: "Durée de vie",
    it: "Vita",
    sq: "Jeta",
    tr: "Ömür boyu",
    sw: "Muda wa Maisha",
    am: "እስከ መንፈስ",
    om: "Jireenya",
    ig: "Ndụ niile",
    so: "Nolosha",
    ha: "Rayuwa gaba daya",
    mnk: "Ntoŋo le",
    ff: "Rayuwa",
    yo: "Pẹlu igbesi aye"
  },
  "Last online": {
    en: "Last online",
    pt: "Último online",
    de: "Zuletzt online",
    es: "Último en línea",
    fr: "Dernière connexion",
    it: "Ultimo accesso",
    sq: "Online i fundit",
    tr: "Son çevrimiçi",
    sw: "Mtandaoni wa Mwisho",
    am: "በመጨረሻ በመስመር ላይ",
    om: "Online dhumaa",
    ig: "Nke ikpeazụ n'ịntanetị",
    so: "Online ugu horeysay",
    ha: "Na layi na ƙarshe",
    mnk: "Mansaŋo online",
    ff: "Yimɓe ko na kadi",
    yo: "Tó kẹhin lori ayelujara"
  },
  "Live TV Categories": {
    en: "Live TV Categories",
    pt: "Categorias de TV ao Vivo",
    de: "Live-TV-Kategorien",
    es: "Categorías de TV en vivo",
    fr: "Catégories de télévision en direct",
    it: "Categorie TV in diretta",
    sq: "Kategoritë e TV Live",
    tr: "Canlı TV Kategorileri",
    sw: "Vikundi vya TV za Moja kwa Moja",
    am: "ሕይወታዊ ቲቪ ክፍሎች",
    om: "Kategorii TV kanneen jireenya ta'an",
    ig: "Ụdị TV Ntụrụndụ",
    so: "Qaybaha TV-ga Tooska",
    ha: "Rukunin TV na Kai tsaye",
    mnk: "TV kafu jɔɔrɔ",
    ff: "Kategorii TV live",
    yo: "Ẹka TV laaye"
  },
  //n
  "Not available platform": {
    en: "Not available platform",
    pt: "Plataforma não disponível",
    de: "Plattform nicht verfügbar",
    es: "Plataforma no disponible",
    fr: "Plateforme non disponible",
    it: "Piattaforma non disponibile",
    sq: "Platformë e padisponueshme",
    tr: "Mevcut olmayan platform",
    sw: "Jukwaa halipo",
    am: "በስም ስር የሚገኙት አይነት አልተገኙም",
    om: "Pirofeeshinii hin argamne",
    ig: "Ebe ọrụ dịghị",
    so: "Barxad maqan ma jiraan",
    ha: "Ba dandali mai samuwa",
    mnk: "Platformo la kanu ɓaaxu la",
    ff: "Platform ngal e nder",
    yo: "Sẹ́yìn jùmọ̀ẹ̀yà péyàkàn"
  },
  "No devices found on server": {
    en: "No devices found on server",
    pt: "Nenhum dispositivo encontrado no servidor",
    de: "Keine Geräte auf dem Server gefunden",
    es: "No se encontraron dispositivos en el servidor",
    fr: "Aucun appareil trouvé sur le serveur",
    it: "Nessun dispositivo trovato sul server",
    sq: "Nuk u gjetën pajisje në server",
    tr: "Sunucuda hiç cihaz bulunamadı",
    sw: "Hakuna vifaa vilivyopatikana kwenye seva",
    am: "እስከ አገልግሎት ላይ መሳሪያዎች አልተገኙም",
    om: "Osoo qulqulluu hin argamin",
    ig: "Enweghị ngwaọrụ chọpụtara na sava",
    so: "Ma jiro qalab lagu helay server-ka",
    ha: "Babu na'urori da aka samu akan uwar garke",
    mnk: "Server la kanu ɓaaxu la",
    ff: "Ko na'i njamdi ndiyam e server",
    yo: "Ko awọn ẹrọ ri lori olupin"
  },

  No: {
    en: "No",
    pt: "Não",
    de: "Nein",
    es: "No",
    fr: "Non",
    it: "No",
    sq: "Jo",
    tr: "Hayır",
    sw: "Hapana",
    am: "አይ",
    om: "Lakki",
    ig: "Mba",
    so: "Maya",
    ha: "A'a",
    mnk: "Ala",
    ff: "A'a",
    yo: "Rara"
  },
  "N/A": {
    en: "N/A",
    pt: "N/D",
    de: "N/A",
    es: "N/D",
    fr: "N/D",
    it: "N/D",
    sq: "N/A",
    tr: "Yok",
    sw: "Hapana/Kupatikana",
    am: "የለም",
    om: "Haa'aa",
    ig: "Adịghị Ezi",
    so: "Ma Jiraan",
    ha: "Ba a Akwai",
    mnk: "Hala",
    ff: "Ala",
    yo: "Ko si"
  },
  Name: {
    en: "Name",
    pt: "Nome",
    de: "Name",
    es: "Nombre",
    fr: "Nom",
    it: "Nome",
    sq: "Emër",
    tr: "İsim",
    sw: "Jina",
    am: "ስም",
    om: "Maqaa",
    ig: "Aha",
    so: "Magaca",
    ha: "Suna",
    mnk: "Kafu",
    ff: "Innde",
    yo: "Oruko"
  },
  Next: {
    en: "Next",
    pt: "Próximo",
    de: "Nächster",
    es: "Siguiente",
    fr: "Suivant",
    it: "Successivo",
    sq: "Tjetra",
    tr: "Sonraki",
    sw: "Inayofuata",
    am: "ቀጣይ",
    om: "Itti aanu",
    ig: "Sonye na-esote",
    so: "Xiga",
    ha: "Naji",
    mnk: "Nangaa",
    ff: "Hoto",
    yo: "Tẹle"
  },
  "New password": {
    en: "New password",
    pt: "Nova senha",
    de: "Neues Passwort",
    es: "Nueva contraseña",
    fr: "Nouveau mot de passe",
    it: "Nuova password",
    sq: "Fjalëkalim i ri",
    tr: "Yeni şifre",
    sw: "Neno la siri jipya",
    am: "የአዲስ ቁልፍ",
    om: "Jecha haaraa",
    ig: "Okwuntugharị ọhụrụ",
    so: "Furaha cusub",
    ha: "Sabon kalmar wucewa",
    mnk: "Fanyi kafu jɔɔrɔ",
    ff: "Hakkunde mum",
    yo: "Ọrọ aṣínà tuntun"
  },
  "Name on card": {
    en: "Name on card",
    pt: "Nome no cartão",
    de: "Name auf der Karte",
    es: "Nombre en la tarjeta",
    fr: "Nom sur la carte",
    it: "Nome sulla carta",
    sq: "Emri në kartë",
    tr: "Karttaki isim",
    sw: "Jina kwenye kadi",
    am: "ስም በካርድ",
    om: "Maqaa kaardii irratti",
    ig: "Aha na kaadị",
    so: "Magaca kaarka",
    ha: "Suna a kan kati",
    mnk: "Jatigi kadi",
    ff: "Innde e kaadi",
    yo: "Oruko lori kaadi"
  },
  Notes: {
    en: "Notes",
    pt: "Notas",
    de: "Notizen",
    es: "Notas",
    fr: "Notes",
    it: "Note",
    sq: "Shënime",
    tr: "Notlar",
    sw: "Vidokezo",
    am: "ትንታኔዎች",
    om: "Yaadaalee",
    ig: "Nkwupụta",
    so: "Xusuus",
    ha: "Lura",
    mnk: "Nyaanle",
    ff: "Gandal",
    yo: "Akọsilẹ"
  },
  Notifications: {
    en: "Notifications",
    pt: "Notificações",
    de: "Benachrichtigungen",
    es: "Notificaciones",
    fr: "Notifications",
    it: "Notifiche",
    sq: "Njoftimet",
    tr: "Bildirimler",
    sw: "Arifa",
    am: "ማስታወቂያዎች",
    om: "Beeksisotaalee",
    ig: "Mmemme",
    so: "Ogeysiisyada",
    ha: "Sanarwa",
    mnk: "Baroodo",
    ff: "Fayde",
    yo: "Awọn iwifunni"
  },
  "New device configurations": {
    en: "New device configurations",
    pt: "Novas configurações de dispositivo",
    de: "Neue Gerätekonfigurationen",
    es: "Nuevas configuraciones de dispositivo",
    fr: "Nouvelles configurations de l'appareil",
    it: "Nuove configurazioni del dispositivo",
    sq: "Konfigurimet e reja të pajisjes",
    tr: "Yeni cihaz yapılandırmaları",
    sw: "Mipangilio mipya ya kifaa",
    am: "አዲስ መሳሪያ ቅንብሮች",
    om: "Konfiguraashinoo Haaraa Meeshaa",
    ig: "Nhazi ngwaọrụ ọhụrụ",
    so: "Qaabeynta qalabka cusub",
    ha: "Saitin Sabon Na'ura",
    mnk: "Kafu Binndoloo Fiying Kaba",
    ff: "Jangirde Nguurndu Vido",
    yo: "Eto Ẹrọ Titun"
  },
  "New device configuration guide": {
    en: "New device configuration guide",
    pt: "Novo guia de configuração de dispositivo",
    de: "Neue Gerätekonfigurationsanleitung",
    es: "Nueva guía de configuración de dispositivo",
    fr: "Nouveau guide de configuration de l'appareil",
    it: "Nuova guida alla configurazione del dispositivo",
    sq: "Udhëzuesi i konfigurimit të pajisjes së re",
    tr: "Yeni cihaz yapılandırma kılavuzu",
    sw: "Mwongozo wa usanidi wa kifaa kipya",
    am: "መመዝገብ እተመን በአዲስ መሳሪያ",
    om: "Qajeelfama Haaraa Usanii Meeshaa",
    ig: "Nduzi maka nhazi ngwaọrụ ọhụrụ",
    so: "Hagaha Qaabeynta Qalabka Cusub",
    ha: "Jagorar Saitin Sabon Na'ura",
    mnk: "Kafu Binndoloo Fiying Kaba",
    ff: "Jangirde Nguurndu Vido",
    yo: "Itọsọna Eto Ẹrọ Titun"
  },
  //g
  "General Info": {
    en: "General Info",
    pt: "Informações Gerais",
    de: "Allgemeine Informationen",
    es: "Información General",
    fr: "Informations Générales",
    it: "Informazioni Generali",
    sq: "Informacione të përgjithshme",
    tr: "Genel Bilgi",
    sw: "Taarifa za Jumla",
    am: "መረጃ አጠቃላይ",
    om: "Oduu Waliigalaa",
    ig: "Ozi Ozi",
    so: "Macluumaad Guud",
    ha: "Bayanan Gabaɗaya",
    mnk: "Buntini Kafu",
    ff: "Dandeettii Cimaan",
    yo: "Alaye Gbogbogbo"
  },
  "Generic Config": {
    en: "Generic Config",
    pt: "Configuração Genérica",
    de: "Generische Konfiguration",
    es: "Configuración Genérica",
    fr: "Configuration Générique",
    it: "Configurazione Generica",
    sq: "Konfigurim Gjenrik",
    tr: "Genel Yapılandırma",
    sw: "Mikakati ya Kawaida",
    am: "መሠረታዊ ኮንፊግ",
    om: "Kofii Gosa",
    ig: "Nhazi Ncheta",
    so: "Qaabka Guud",
    ha: "Tsarin Gabaɗaya",
    mnk: "Sistimoo Kafu",
    ff: "Konfigireshii Bulle",
    yo: "Ẹ̀dà Ilana"
  },
  "Get code": {
    en: "Get code",
    pt: "Obter código",
    de: "Code erhalten",
    es: "Obtener código",
    fr: "Obtenir le code",
    it: "Ottieni codice",
    sq: "Merr kodin",
    tr: "Kodu Al",
    sw: "Pata msimbo",
    am: "ኮድ ያግኙ",
    om: "Kodi argadhu",
    ig: "Nweta koodu",
    so: "Hel koodhka",
    ha: "Samun lambar",
    mnk: "Luŋna koodo",
    ff: "Jokkude kodal",
    yo: "Gba koodu"
  },
  "Get credits": {
    en: "Get credits",
    pt: "Obter créditos",
    de: "Credits erhalten",
    es: "Obtener créditos",
    fr: "Obtenir des crédits",
    it: "Ottieni crediti",
    sq: "Merr kredite",
    tr: "Kredi Al",
    sw: "Pata mikopo",
    am: "ክሬዲቶች ያግኙ",
    om: "Kireedii argadhu",
    ig: "Nweta ego mgbazinye",
    so: "Hel deynta",
    ha: "Samun ƙididdiga",
    mnk: "Luŋna kɔntu",
    ff: "Jokkude coogal",
    yo: "Gba kirediti"
  },
  Genre: {
    en: "Genre",
    pt: "Gênero",
    de: "Genre",
    es: "Género",
    fr: "Genre",
    it: "Genere",
    sq: "Janër",
    tr: "Tür",
    sw: "Aina",
    am: "አይነት",
    om: "Jecha",
    ig: "Ụdị",
    so: "Nooc",
    ha: "Nau'i",
    mnk: "Genye",
    ff: "Cuugal",
    yo: "Ẹ̀ka"
  },
  "Generate playlist": {
    en: "Generate playlist",
    pt: "Gerar lista de reprodução",
    de: "Playlist generieren",
    es: "Generar lista de reproducción",
    fr: "Générer une playlist",
    it: "Genera playlist",
    sq: "Krijo listën e për të dëgjuar",
    tr: "Çalma listesi oluştur",
    sw: "Tengeneza orodha ya kucheza",
    am: "የፕሌይሊስት ዝርዝር ይፍጠሩ",
    om: "Odeeffannoo tarree uumi",
    ig: "Mepụta ndepụta egwu",
    so: "Soojiye liiska ciyaar",
    ha: "Ƙirƙiri jerin waƙoƙi",
    mnk: "Nafama playlisto",
    ff: "Jokkondir playlist",
    yo: "Ṣẹda atokọ orin"
  },

  //s
  "Server ID": {
    en: "Server ID",
    pt: "ID do Servidor",
    de: "Server-ID",
    es: "ID del Servidor",
    fr: "ID du Serveur",
    it: "ID del Server",
    sq: "ID e Serverit",
    tr: "Sunucu Kimliği",
    sw: "Kitambulisho cha seva",
    am: "የአገልግሎት መታወቂያ",
    om: "Gargaarsa ID",
    ig: "ID onye ọbá akwụkwọ",
    so: "Aqoonsiga Server",
    ha: "ID na uwar garken",
    mnk: "Server ID",
    ff: "ID wornde",
    yo: "ID Olugba"
  },
  "Start date": {
    en: "Start date",
    es: "Fecha de inicio",
    fr: "Date de début",
    de: "Startdatum",
    pt: "Data de início",
    it: "Data di inizio",
    sq: "Data e fillimit",
    tr: "Başlangıç tarihi",
    sw: "Tarehe ya kuanza",
    am: "መነሻ ቀን",
    om: "Guyyaa Jalqabaa",
    ig: "Ụbọchị mmalite",
    so: "Taariikhda bilaabashada",
    ha: "Ranar farawa",
    mnk: "Dikunda dabuko",
    ff: "Ñalngu wornde",
    yo: "Ọjọ ibẹrẹ"
  },
  success: {
    en: "Success",
    pt: "Sucesso",
    de: "Erfolg",
    es: "Éxito",
    fr: "Succès",
    it: "Successo",
    sq: "Sukses",
    tr: "Başarı",
    sw: "Mafanikio",
    am: "አሳካ",
    om: "Milkaa'inni",
    ig: "Ịga nke ọma",
    so: "Guul",
    ha: "Nasara",
    mnk: "Duntu",
    ff: "Waynude",
    yo: "Aṣeyọri"
  },
  "Search by device key": {
    en: "Search by device key",
    pt: "Pesquisar por chave do dispositivo",
    de: "Suche nach Geräteschlüssel",
    es: "Buscar por clave de dispositivo",
    fr: "Recherche par clé de dispositif",
    it: "Ricerca per chiave del dispositivo",
    sq: "Kërkoni sipas çelësit të pajisjes",
    tr: "Cihaz anahtarı ile ara",
    sw: "Tafuta kwa ufunguo wa kifaa",
    am: "በመሣሪያ ቁልፍ ፈልግ",
    om: "Barbaadi ka furtuu faayila",
    ig: "Chọọ site na igodo ngwaọrụ",
    so: "Raadiye adoo adeegsanaya furaha qalabka",
    ha: "Bincika ta maɓallin na'ura",
    mnk: "Siti ka fa jabi laaŋo le",
    ff: "Ndi ngiɗitii ka puccu",
    yo: "Wa nipa bọtini ẹrọ"
  },
  State: {
    en: "State",
    pt: "Estado",
    de: "Zustand",
    es: "Estado",
    fr: "État",
    it: "Stato",
    sq: "Gjendja",
    tr: "Durum",
    sw: "Hali",
    am: "ሁኔታ",
    om: "Haalota",
    ig: "Ọnọdụ",
    so: "Xaalad",
    ha: "Matsayi",
    mnk: "Haalotoo",
    ff: "Laɓɓi",
    yo: "Ipo"
  },
  "Stream ID": {
    en: "Stream ID",
    pt: "ID do Fluxo",
    de: "Stream-ID",
    es: "ID de Transmisión",
    fr: "Identifiant de Flux",
    it: "ID del Flusso",
    sq: "ID e Strujës",
    tr: "Akış Kimliği",
    sw: "Kitambulisho cha Mtiririko",
    am: "የመስመር መለኪያ",
    om: "ID tamsaasa",
    ig: "ID mmiri",
    so: "Aqoonsiga Durdurka",
    ha: "ID ɗiga",
    mnk: "ID siŋa",
    ff: "ID ñaawo",
    yo: "ID sisan"
  },
  "Series ID": {
    en: "Series ID",
    pt: "ID da Série",
    de: "Seriennummer",
    es: "ID de la Serie",
    fr: "Identifiant de Série",
    it: "ID Serie",
    sq: "ID e Serisë",
    tr: "Dizi Kimliği",
    sw: "Kitambulisho cha Mfululizo",
    am: "መለኪያ መስመር",
    om: "ID garee",
    ig: "ID usoro",
    so: "Aqoonsiga Taxanaha",
    ha: "ID jerin",
    mnk: "ID siŋa",
    ff: "ID goɗɗo",
    yo: "ID ìkànsí"
  },
  Season: {
    en: "Season",
    pt: "Temporada",
    de: "Saison",
    es: "Temporada",
    fr: "Saison",
    it: "Stagione",
    sq: "Stinë",
    tr: "Mevsim",
    sw: "msimu",
    am: "ክፍል",
    om: "bona",
    ig: "oge",
    so: "xilli",
    ha: "lokaci",
    mnk: "siŋa",
    ff: "yerewol",
    yo: " akoko"
  },
  "server to another": {
    en: "server to another",
    pt: "servidor para outro",
    de: "Server zu einem anderen",
    es: "servidor a otro",
    fr: "serveur vers un autre",
    it: "server verso un altro",
    sq: "server për në tjetër",
    tr: "sunucu bir diğerine",
    sw: "serveri hadi nyingine",
    am: "ከሰርቨር እስከ ሌላ",
    om: "Seerverii gara biraatti",
    ig: "seva gaa ọzọ",
    so: "server-ka midigta kale",
    ha: "sabobin zuwa wani",
    mnk: "server la fiya",
    ff: "server maaɓe ɗiɗi",
    yo: "server si omiran"
  },
  Send: {
    en: "Send",
    pt: "Enviar",
    de: "Senden",
    es: "Enviar",
    fr: "Envoyer",
    it: "Invia",
    sq: "Dërgo",
    tr: "Gönder",
    sw: "Tuma",
    am: "ላክ",
    om: "Ergaa erguu",
    ig: "Ziga",
    so: "Dir",
    ha: "Aika",
    mnk: "Jara",
    ff: "Ñaama",
    yo: "Firan"
  },
  Seen: {
    en: "Seen",
    pt: "Visto",
    de: "Gesehen",
    es: "Visto",
    fr: "Vu",
    it: "Visto",
    sq: "Shikuar",
    tr: "Görüldü",
    sw: "Imeonekana",
    am: "ተመለከተ",
    om: "Mul'ate",
    ig: "Hụrụ",
    so: "La socday",
    ha: "Gani",
    mnk: "Ña",
    ff: "Janngo",
    yo: "Ri"
  },
  Sync: {
    en: "Sync",
    pt: "Sincronizar",
    de: "Synchronisieren",
    es: "Sincronizar",
    fr: "Synchroniser",
    it: "Sincronizza",
    sq: "Sinkronizo",
    tr: "Senkranize Et",
    sw: "Sanidi",
    am: "ማስተካከል",
    om: "Walitti fufuu",
    ig: "Kekọrịta",
    so: "Iskudhis",
    ha: "Haɗa",
    mnk: "Sɛnɛtɛ",
    ff: "Yeeso",
    yo: "Ṣeto"
  },
  "Sync playlist": {
    en: "Sync playlist",
    pt: "Sincronizar lista de reprodução",
    de: "Wiedergabeliste synchronisieren",
    es: "Sincronizar lista de reproducción",
    fr: "Synchroniser la playlist",
    it: "Sincronizza playlist",
    sq: "Sinkronizo listën e përhapjes",
    tr: "Senkranize Et liste",
    sw: "Sanidi orodha ya nyimbo",
    am: "እትም ፕሌይሌስት እንቅስቃሴ",
    om: "Playlist walitti fidu",
    ig: "Mepụta ndepụta egwu",
    so: "Isku-xidh playlist",
    ha: "Daida playlist",
    mnk: "Ɲaaba playlist",
    ff: "Soɓɓi playlist",
    yo: "Ṣeto atokọ orin"
  },
  Save: {
    en: "Save",
    pt: "Salvar",
    de: "Speichern",
    es: "Guardar",
    fr: "Enregistrer",
    it: "Salva",
    sq: "Ruaj",
    tr: "Kaydet",
    sw: "Hifadhi",
    am: "ቀምጥ",
    om: "Eegdu",
    ig: "Chekwa",
    so: "Kaydi",
    ha: "Ajiye",
    mnk: "Sɛbɛ",
    ff: "Jowlu",
    yo: "Fipamọ"
  },
  Start: {
    en: "Start",
    pt: "Iniciar",
    de: "Starten",
    es: "Iniciar",
    fr: "Démarrer",
    it: "Avvia",
    sq: "Fillo",
    tr: "Başla",
    sw: "Anza",
    am: "ጀምር",
    om: "Ebbisi",
    ig: "Malite",
    so: "Bilow",
    ha: "Fara",
    mnk: "Bantang",
    ff: "Yowri",
    yo: "Bẹrẹ"
  },
  Search: {
    en: "Search",
    pt: "Pesquisar",
    de: "Suchen",
    es: "Buscar",
    fr: "Rechercher",
    it: "Cerca",
    sq: "Kërko",
    tr: "Ara",
    sw: "Tafuta",
    am: "ፈልግ",
    om: "Barbaadi",
    ig: "Chọwa",
    so: "Raadi",
    ha: "Nema",
    mnk: "Fii",
    ff: "Jango",
    yo: "Wá"
  },
  Status: {
    en: "Status",
    pt: "Status",
    de: "Status",
    es: "Estado",
    fr: "Statut",
    it: "Stato",
    sq: "Statusi",
    tr: "Durum",
    sw: "Hali",
    am: "ሁኔታ",
    om: "Haala",
    ig: "Ọnọdụ",
    so: "Xaalad",
    ha: "Hali",
    mnk: "Nyaata",
    ff: "Seeda",
    yo: "Ipo"
  },
  Schedule: {
    en: "Schedule",
    es: "Horario",
    fr: "Horaire",
    de: "Zeitplan",
    pt: "Agenda",
    it: "Programma",
    sq: "Orari",
    tr: "Program",
    sw: "Ratiba",
    am: "ምክንያት",
    om: "Yeroo",
    ig: "Nchepụta",
    so: "Jadwal",
    ha: "Jadawali",
    mnk: "Tigi",
    ff: "Heɗɗi",
    yo: "Akopọ"
  },
  Select: {
    en: "Select",
    pt: "Selecionar",
    de: "Auswählen",
    es: "Seleccionar",
    fr: "Sélectionner",
    it: "Seleziona",
    sq: "Zgjidh",
    tr: "Seç",
    sw: "Chagua",
    am: "ምረጥ",
    om: "Filadhu",
    ig: "Họrọ",
    so: "Xulo",
    ha: "Zaɓi",
    mnk: "Nte",
    ff: "Ñaɓɓe",
    yo: "Yan"
  },
  Series: {
    en: "Series",
    pt: "Séries",
    de: "Serien",
    es: "Series",
    fr: "Séries",
    it: "Serie",
    sq: "Seria",
    tr: "Seriler",
    sw: "Mfululizo",
    am: "ስሪስ",
    om: "Sirii",
    ig: "Ụdị",
    so: "Taxane",
    ha: "Jerin",
    mnk: "Kunkunjɛ",
    ff: "Seree",
    yo: "Sẹẹri"
  },
  Surname: {
    en: "Surname",
    pt: "Sobrenome",
    de: "Nachname",
    es: "Apellido",
    fr: "Nom de famille",
    it: "Cognome",
    sq: "Mbiemri",
    tr: "Soyadı",
    sw: "Jina la Familia",
    am: "ስም ማንኛውም",
    om: "Maqaa Abbaa",
    ig: "Aha",
    so: "Magaca Qoyska",
    ha: "Sunan",
    mnk: "Baalo",
    ff: "Sungoo",
    yo: "Oruko Abikan"
  },
  Settings: {
    en: "Settings",
    pt: "Configurações",
    de: "Einstellungen",
    es: "Ajustes",
    fr: "Paramètres",
    it: "Impostazioni",
    sq: "Parametrat",
    tr: "Ayarlar",
    sw: "Mipangilio",
    am: "ቅንብሮች",
    om: "Settingii",
    ig: "Nhazi",
    so: "Dejin",
    ha: "Saitin",
    mnk: "Sajee",
    ff: "Wurtinde",
    yo: "Eto"
  },
  Seller: {
    en: "Seller",
    pt: "Vendedor",
    de: "Verkäufer",
    es: "Vendedor",
    fr: "Vendeur",
    it: "Venditore",
    sq: "Shitësi",
    tr: "Satıcı",
    sw: "Muuza",
    am: "ሻጭ",
    om: "Gurgurtaa",
    ig: "Onye na-ere",
    so: "Iib",
    ha: "Mai sayarwa",
    mnk: "Jambaaro",
    ff: "Ndaɓɓitaare",
    yo: "Taja"
  },
  Success: {
    en: "Success",
    pt: "Sucesso",
    de: "Erfolg",
    es: "Éxito",
    fr: "Succès",
    it: "Successo",
    sq: "Sukses",
    tr: "Başarı",
    sw: "Mafanikio",
    am: "ስኬት",
    om: "Milkaa'ina",
    ig: "Ọganiihu",
    so: "Guul",
    ha: "Nasara",
    mnk: "Foya",
    ff: "Jamii",
    yo: "Aseyori"
  },
  Signup: {
    en: "Signup",
    pt: "Cadastro",
    de: "Anmeldung",
    es: "Registro",
    fr: "Inscription",
    it: "Registrazione",
    sq: "Regjistrohu",
    tr: "Kayıt Ol",
    sw: "Jisajili",
    am: "ተመዝገብ",
    om: "Galmeessi",
    ig: "Debanye aha",
    so: "Isdiiwaangelin",
    ha: "Rajista",
    mnk: "Soo fi su",
    ff: "Sanngo e dawrugol",
    yo: "Forukọsilẹ"
  },
  "Send date": {
    en: "Send date",
    pt: "Data de envio",
    de: "Sendedatum",
    es: "Fecha de envío",
    fr: "Date d'envoi",
    it: "Data di invio",
    sq: "Data e dërgimit",
    tr: "Gönderim tarihi",
    sw: "Tarehe ya kutuma",
    am: "ቀን ለማስተናገድ",
    om: "Guyyaa erguu",
    ig: "Ụbọchị izipu",
    so: "Taariikhda diridda",
    ha: "Ranar aika",
    mnk: "Julaa fow",
    ff: "Ñalawooɗo goɗɗo",
    yo: "Ọjọ́ ìfiránṣẹ́"
  },
  Statistics: {
    en: "Statistics",
    pt: "Estatísticas",
    de: "Statistiken",
    es: "Estadísticas",
    fr: "Statistiques",
    it: "Statistiche",
    sq: "Statistikat",
    tr: "İstatistikler",
    sw: "Takwimu",
    am: "ቁጥር ተመን",
    om: "Seera",
    ig: "Ụzọ ụka",
    so: "Tirako",
    ha: "Lissafi",
    mnk: "Sise",
    ff: "Tawii",
    yo: "Iroyin"
  },
  "Select DNS": {
    en: "Select DNS",
    pt: "Selecionar DNS",
    de: "DNS auswählen",
    es: "Seleccionar DNS",
    fr: "Sélectionner DNS",
    it: "Seleziona DNS",
    sq: "Zgjidh DNS",
    tr: "DNS Seç",
    sw: "Chagua DNS",
    am: "ምርጥ DNS",
    om: "DNS filannoo",
    ig: "Họrọ DNS",
    so: "Xulo DNS",
    ha: "Zaɓi DNS",
    mnk: "Jàmmoo DNS",
    ff: "Huutu DNS",
    yo: "Yan DNS"
  },
  Support: {
    en: "Support",
    pt: "Suporte",
    de: "Unterstützung",
    es: "Soporte",
    fr: "Support",
    it: "Supporto",
    sq: "Mbështetje",
    tr: "Destek",
    sw: "Msaada",
    am: "ድጋፍ",
    om: "Deeggarsa",
    ig: "Nkwado",
    so: "Taageero",
    ha: "Taimako",
    mnk: "Sera",
    ff: "Jokkondiral",
    yo: "Atilẹyin"
  },
  Subresellers: {
    en: "Subresellers",
    pt: "Subrevendedores",
    de: "Unterhändler",
    es: "Subrevendedores",
    fr: "Sous-revendeurs",
    it: "Subrivenditori",
    sq: "Nën-rihumbësuesit",
    tr: "Altbayiler",
    sw: "Wauzaji wa chini",
    am: "ከላይ ወጣቶች",
    om: "Maalimii gurguddaa",
    ig: "Ndị na-ere ngwaahịa nke ọzọ",
    so: "Iibraarayaasha hoos",
    ha: "Sub resellers",
    mnk: "Sub-resellers",
    ff: "Sub-resellers",
    yo: "Awọn alátẹgbẹ"
  },
  "Select platform": {
    en: "Select platform",
    pt: "Selecionar plataforma",
    de: "Plattform auswählen",
    es: "Seleccionar plataforma",
    fr: "Sélectionner la plateforme",
    it: "Seleziona piattaforma",
    sq: "Zgjidh platformën",
    tr: "Platform Seç",
    sw: "Chagua jukwaa",
    am: "ማህበረሰብን በፍለጋ ማምረጥ:",
    om: "Meeshaa tooftaa irratti filachuu",
    ig: "Họrọ ikpo okwu",
    so: "Doora madalinka",
    ha: "Zabi dandamali",
    mnk: "Jajale kafu le:",
    ff: "Hakkilol kadi.",
    yo: "Yan pẹpẹ"
  },
  "Select device by": {
    en: "Select device by:",
    pt: "Selecionar dispositivo por:",
    de: "Gerät auswählen nach:",
    es: "Seleccionar dispositivo por:",
    fr: "Sélectionner l'appareil par :",
    it: "Seleziona dispositivo per:",
    sq: "Zgjidh pajisjen me:",
    tr: "Cihazı seç:",
    sw: "Chagua kifaa kwa:",
    am: "ማህበረሰብን በፍለጋ ማምረጥ:",
    om: "Meeshaa tooftaa irratti filachuu:",
    ig: "Họrọ ngwaọrụ site na:",
    so: "Doora aaladda iyadoo la adeegsanayo:",
    ha: "Zabi na'ura ta hanyar:",
    mnk: "Jajale kafu le:",
    ff: "Hakkilol kadi.",
    yo: "Yan ọpa ẹrọ nipa:"
  },
  "Secondary color": {
    en: "Secondary color",
    pt: "Cor secundária",
    de: "Sekundärfarbe",
    es: "Color secundario",
    fr: "Couleur secondaire",
    it: "Colore secondario",
    sq: "Ngjyra e dytësore",
    tr: "İkincil renk",
    sw: "Rangi ya Pili.",
    am: "በኋላ አለበለዚያ ማህበረሰብ ይችላሉ.",
    om: "Bariisaa Lammaffaa.",
    ig: "Agba Ọcha nke Abụọ.",
    so: "Midabka Labaad.",
    ha: "Launin Na Biyu.",
    mnk: "Mbayri lafiŋ.",
    ff: "Laawol mum.",
    yo: "Awo awọ keji."
  },
  "Sub-Reseller Info": {
    en: "Sub-Reseller Info",
    pt: "Informações de Sub-Revendedor",
    de: "Sub-Händler Informationen",
    es: "Información de Sub-Revendedor",
    fr: "Informations du sous-revendeur",
    it: "Informazioni del Sub-Rivenditore",
    sq: "Informacione për Nën-rihumbësuesin",
    tr: "Alt-Bayi Bilgisi",
    sw: "Taarifa za Sub-Reseller.",
    am: "መረጃ ስለ ማንኛውም ስም ሲነቀል.",
    om: "Odeeffannoo Sub-Reseller.",
    ig: "Ozi Sub-Reseller.",
    so: "Macluumaadka Sub-Reseller.",
    ha: "Bayanin Sub-Reseller.",
    mnk: "Mbeleewal Sub-Reseller.",
    ff: "Mbayim sub-reseller.",
    yo: "Alaye Sub-Reseller."
  },
  "Send notifications": {
    en: "Send notifications",
    pt: "Enviar notificações",
    de: "Benachrichtigungen senden",
    es: "Enviar notificaciones",
    fr: "Envoyer des notifications",
    it: "Invia notifiche",
    sq: "Dërgo njoftime",
    tr: "Bildirim gönder",
    sw: "Tuma arifa.",
    am: "ማሳሰቢያዎች አስተላልፉ.",
    om: "Ergaa ibsa erguu.",
    ig: "Ziga mkpesa.",
    so: "Dir fariimo.",
    ha: "Aika sanarwa.",
    mnk: "Kafu raada.",
    ff: "Dinaajugol ndiyam.",
    yo: "Firan awọn ìkìlọ̀."
  },
  "Select credit count": {
    en: "Select credit count",
    pt: "Selecionar contagem de crédito",
    de: "Kreditanzahl auswählen",
    es: "Seleccionar cantidad de crédito",
    fr: "Sélectionner le nombre de crédits",
    it: "Seleziona il conteggio dei crediti",
    sq: "Zgjidh numrin e kredive",
    tr: "Kredi sayısını seç",
    sw: "Chagua idadi ya mikopo.",
    am: "የታተመት ቁጥር ምርጥ.",
    om: "Lakkoofsa krediitii filadhu.",
    ig: "Họrọ ọnụ ọgụgụ nke ego.",
    so: "Xulo tirada lacagta.",
    ha: "Zaɓi adadin kuɗi.",
    mnk: "Woxo kɔy nyaa.",
    ff: "Ndoɗo iwadi mbugo.",
    yo: "Yan iye kirẹditi."
  },
  "Something went wrong": {
    en: "Something went wrong.",
    pt: "Algo deu errado.",
    de: "Etwas ist schiefgelaufen.",
    es: "Algo salió mal.",
    fr: "Quelque chose s'est mal passé.",
    it: "Qualcosa è andato storto.",
    sq: "Diçka shkoi gabim.",
    tr: "Bir şeyler yanlış gitti.",
    sw: "Kitu kilikosea.",
    am: "ነገር ተሳስተ።",
    om: "Waa'ee tokko hin taane.",
    ig: "Ihe gara nke ọma.",
    so: "Wax walxogaa ayaa dhacay.",
    ha: "Wani abu ya tafi a hanya mara kyau.",
    mnk: "Nbaaxu naxaraxan.",
    ff: "Noo wadi kaɗi.",
    yo: "Ohun kan lọ baje."
  },
  "Start migration process": {
    en: "Start migration process",
    pt: "Iniciar processo de migração",
    de: "Starte den Migrationsprozess",
    es: "Iniciar proceso de migración",
    fr: "Démarrer le processus de migration",
    it: "Avvia il processo di migrazione",
    sq: "Fillo procesin e migrimit",
    tr: "Geçiş sürecini başlat",
    sw: "Anza mchakato wa uhamaji",
    am: "መለወጥ ሂደትን ጀምር",
    om: "Hojii jijjiirraa jalqabu",
    ig: "Malite usoro mmụta",
    so: "Bilow habka guuritaanka",
    ha: "Fara shirin hijira",
    mnk: "Sunkuto kaɗa process",
    ff: "Wulɗu kaɓe process",
    yo: "Bẹrẹ ilana gbigbe"
  },
  "Select activation type": {
    en: "Select activation type",
    pt: "Selecionar tipo de ativação",
    de: "Aktivierungstyp auswählen",
    es: "Seleccionar tipo de activación",
    fr: "Sélectionner le type d'activation",
    it: "Seleziona il tipo di attivazione",
    sq: "Zgjidh llojin e aktivizimit",
    tr: "Aktivasyon türünü seç",
    sw: "Chagua aina ya uhamasishaji:",
    am: "አንድ የአንተን እንቅስቃሴ አይነት ምረጥ:",
    om: "Faayidaa sirreeffama filadhaa:",
    ig: "Họrọ ụdị ntinye ndụmọdụ:",
    so: "Dooro nooca firfircoonida:",
    ha: "Zaɓi nau'in kunna:",
    mnk: "Kafu kiya activation wulɛwulɛ:",
    ff: "Seltin fii activation wuldu:",
    yo: "Yan aṣayan iru imuse rẹ:"
  },
  "Select the type of your server": {
    en: "Select the type of your server:",
    pt: "Selecione o tipo do seu servidor:",
    de: "Wählen Sie den Servertyp aus:",
    es: "Seleccione el tipo de su servidor:",
    fr: "Sélectionnez le type de votre serveur :",
    it: "Seleziona il tipo del tuo server:",
    sq: "Zgjidhni llojin e serverit tuaj:",
    tr: "Sunucunuzun türünü seçin:",
    sw: "Chagua aina ya seva yako:",
    am: "አንድ የአንተን ሴርቨር አይነት ምረጥ:",
    om: "Faayidaa sirreeffama keessanii filadhaa:",
    ig: "Họrọ ụdị sava gị:",
    so: "Dooro nooca server-kaaga:",
    ha: "Zaɓi irin uwar gizonku:",
    mnk: "Kafu kiya server wulɛwulɛ:",
    ff: "Seltin fii server njoldu:",
    yo: "Yan aṣayan iru olupin rẹ:"
  },
  "Sorry, the page you visited does not exist": {
    en: "Sorry, the page you visited does not exist.",
    pt: "Desculpe, a página que você visitou não existe.",
    de: "Entschuldigung, die von Ihnen besuchte Seite existiert nicht.",
    es: "Lo siento, la página que visitó no existe.",
    fr: "Désolé, la page que vous avez visitée n'existe pas.",
    it: "Spiacente, la pagina che hai visitato non esiste.",
    sq: "Ndjesi, faqja që vizituat nuk ekziston.",
    tr: "Üzgünüm, ziyaret ettiğiniz sayfa mevcut değil.",
    sw: "Pole, ukurasa ulio tembelea haupo.",
    am: "ይቅርታ፣ እንደምንም ስለተጎበኙት ገጽ አልተመለከተም።",
    om: "Dhiifama, fuula ati daawwatte hinjiru.",
    ig: "Ndo, ibe nke ị gara adịghị.",
    so: "Waan ka xumadey, bogga aad booqatay ma jiro.",
    ha: "Yi haƙuri, shafin da ka ziyarci ba ya wanzu.",
    mnk: "Alaata, page kafu bejula wɔtɔ.",
    ff: "Wonaa, page ɓi yowti e fɔtɔ nyaa.",
    yo: "Ẹ ma binu, oju-iwe ti o ṣàbẹwò ko si."
  },
  "Select and attach one from your server": {
    en: "Select and attach one from your DNS server hosts then write the username and password of the chosen host. Or select one of your Xtream Servers and generate a playlist which will be attached to your device. You can see the generated URL in Playlist URL input.",
    pt: "Selecione e anexe um dos hosts do seu servidor DNS e, em seguida, escreva o nome de usuário e a senha do host escolhido. Ou selecione um dos seus servidores Xtream e gere uma lista de reprodução que será anexada ao seu dispositivo. Você pode ver a URL gerada na entrada de URL da lista de reprodução.",
    de: "Wählen Sie einen Ihrer DNS-Serverhosts aus und schreiben Sie dann den Benutzernamen und das Passwort des ausgewählten Hosts. Oder wählen Sie einen Ihrer Xtream Server aus und generieren Sie eine Playlist, die an Ihr Gerät angehängt wird. Sie können die generierte URL im Eingabefeld für die Playlist-URL sehen.",
    es: "Seleccione y adjunte uno de los hosts de su servidor DNS, luego escriba el nombre de usuario y la contraseña del host elegido. O seleccione uno de sus servidores Xtream y genere una lista de reproducción que se adjuntará a su dispositivo. Puede ver la URL generada en el campo de entrada de la URL de la lista de reproducción.",
    fr: "Sélectionnez et attachez l'un de vos hôtes de serveur DNS, puis saisissez le nom d'utilisateur et le mot de passe de l'hôte choisi. Ou sélectionnez l'un de vos serveurs Xtream et générez une liste de lecture qui sera attachée à votre appareil. Vous pouvez voir l'URL générée dans le champ d'entrée de l'URL de la liste de lecture.",
    it: "Seleziona e allega uno dei server DNS del tuo server, quindi scrivi il nome utente e la password dell'host scelto. Oppure seleziona uno dei tuoi server Xtream e genera una playlist che verrà allegata al tuo dispositivo. Puoi vedere l'URL generato nell'input dell'URL della playlist.",
    sq: "Zgjidhni dhe bashkangjitni një nga hostet e serverit tuaj DNS pastaj shkruani emrin e përdoruesit dhe fjalëkalimin e hostit të zgjedhur. Ose zgjidhni një nga Serverët tuaj Xtream dhe gjeneroni një listë riprodhimi që do të bashkangjitet me pajisjen tuaj. Mund të shihni URL-në e gjeneruar në hyrjen e URL-së së listës së riprodhimit.",
    tr: "DNS sunucunuzun hostlarından birini seçin ve ekleyin, ardından seçilen hostun kullanıcı adını ve şifresini yazın. Ya da Xtream sunucularınızdan birini seçin ve cihazınıza eklenecek bir çalma listesi oluşturun. Oluşturulan URL'yi Çalma Listesi URL girişi kısmında görebilirsiniz.",
    sw: "Chagua na ungamanishe moja kutoka kwa seva zako za DNS kisha andika jina la mtumiaji na nenosiri la seva uliyuchagua. Au chagua moja ya seva zako za Xtream na tengeneza orodha ya nyimbo ambayo itajiunganishwa na kifaa chako. Unaweza kuona URL iliyotengenezwa katika uwanja wa Playlist URL.",
    am: "ከሴርቨር ሆስቶችዎ አንዱን ምረጡና ተወላጅ ስምንና የሴርቨሩን ማህተም ጻፉ። ወይም ከ Xtream ሴርቨሮችዎ አንዱን ምረጡና ለእባብዎ አንድ ፕሌይስቶች ስራ አርክት ስም እንደምንም እንደምንም። ምሳሌ በ ፕሌይስቶች URL አማካይ።",
    om: "Filadhu fi kuwwachu ykn filee መሳሪያን እና መሳሪያ ስምንና አሁን ሴርቨር በ አምተኛ ማንበብ፣ ተመን ተስፋ፡ ልምም እቅዳማም",
    ig: "Họrọ ma kpọpụta otu n'ime sava DNS gị, mgbe ahụ dee aha njirimara na paswọọdụ nke sava a họpụtara. Ma ọ bụ họrọ otu n'ime sava Xtream gị ma mepụta playlist nke ga-edepụta na ngwaọrụ gị. Ị nwere ike ịhụ URL mepụtara na ntinye Playlist URL.",
    so: "Dooro oo ku xir oo ka socoto mid ka mid ah adeegyada DNS-kaaga, ka dibna qor magaca isticmaalaha iyo erayga sirta ah ee adeegga aad dooratay. Ama dooro mid ka mid ah adeegyadda Xtream-kaaga, kuna samee liiska heesaha ee lagu xiran doono qalabkaaga. Waxaad arki kartaa URL-ga la abuuray ee ku jira garoonka URL Playlist.",
    ha: "Zaɓi kuma haɗa ɗaya daga cikin sabobin DNS ɗinku, sannan ku rubuta sunan mai amfani da kalmar sirri na sabar da aka zaɓa. Ko kuma zaɓi ɗaya daga cikin sabobin Xtream ɗinku kuma ku ƙirƙiri jerin waƙoƙi wanda za a haɗa shi da na'urar ku. Kuna iya ganin URL ɗin da aka ƙirƙira a cikin shigarwar Playlist URL.",
    mnk: "Fɔtɔ, bɛtɛ fow dɔkɔ kafoy ɲi DNS server wo, nɛ bɛtɛ kafu hodi ɲi hoto la soro ɲi server bɛtɛ su. Baŋaa, bɛtɛ mɛwɔ ɲi Xtream servers kafu, nɛ kafu play-listo ma bɛtɛ njɔɔrɔ kafu naŋaa bɛtɛ device honto bɛrɛ. Fɔtɔ, bɛtɛ ɲa URL kafu njɔɔrɔ honto wɛrɛ nɔ Play-list URL.",
    ff: "Jooɗii e kuɗi sabar dns ɗe e njiyam heɓa, sengo ɓii kaƴiɗe ɓe heyba goɗɗo wusa ko miɗo taɓɓi. Wallikoo, jooɗii e Xtream ɓe hoto neɓi didi playlistri ngoodi e nafoore e kaama yufata seedi. Ko woni kanko no sakko URL ɗe ngam seŋa yowdyum hiti Playlist URL.",
    yo: "Yan ati so, yan ki o yan asopọ ọkan lati ọdọ awọn olupin DNS rẹ, lẹhinna kọ orukọ olumulo ati ọrọigbaniwọle ti olupin ti o yan. Tabi yan ọkan ninu awọn olupin Xtream rẹ ki o si ṣe agbekalẹ atokọ orin ti yoo so pọ pẹlu ẹrọ rẹ. O le rii URL ti a ṣẹda ninu fọọmu URL Playlist."
  },

  "server migration info": {
    en: "In first you need to choose the server from where you want to transfer your users, then choose the server to where you want to migrate your users.",
    pt: "Primeiro, você precisa escolher o servidor de onde deseja transferir seus usuários e, em seguida, escolher o servidor para onde deseja migrar seus usuários.",
    de: "Zuerst müssen Sie den Server auswählen, von dem aus Sie Ihre Benutzer übertragen möchten, und dann den Server auswählen, auf den Sie Ihre Benutzer migrieren möchten.",
    es: "En primer lugar, debe elegir el servidor desde el que desea transferir a sus usuarios y luego elegir el servidor al que desea migrar a sus usuarios.",
    fr: "Tout d'abord, vous devez choisir le serveur à partir duquel vous souhaitez transférer vos utilisateurs, puis choisir le serveur vers lequel vous souhaitez migrer vos utilisateurs.",
    it: "Innanzitutto, devi scegliere il server da cui desideri trasferire i tuoi utenti e poi scegliere il server in cui desideri migrare i tuoi utenti.",
    sq: "Fillimisht duhet të zgjidhni serverin nga ku dëshironi të transferoni përdoruesit tuaj, pastaj zgjidhni serverin në të cilin dëshironi të migratoni përdoruesit tuaj.",
    tr: "Öncelikle, kullanıcılarınızı transfer etmek istediğiniz sunucuyu seçmeniz ve ardından kullanıcılarınızı taşımak istediğiniz sunucuyu seçmeniz gerekir.",
    sw: "Kwanza unahitaji kuchagua seva kutoka ambapo unataka kuhamisha watumiaji wako, kisha chagua seva ambapo unataka kuhama kwa watumiaji wako.",
    am: "በመጀመሪያ እባኮትን ከሚፈልጉት ተጠቃሚ ወደሚገኙበት ሴርቨር ይምረጡ፣ ከዚህም በኋላ ተጠቃሚዎችዎን ወደ ተመረጠው ሴርቨር እንዲሄዱ ይምረጡ።",
    om: "Jalqaba, si’aawwan keeyyata server irraa filachuu qabda, booda garasii si’aawwan kee gara server keessa deemuuf filadhu.",
    ig: "Nke mbụ, ị ga-ahọrọ sava nke ịchọrọ ibufe ndị ọrụ gị, mgbe ahụ họrọ sava ebe ị chọrọ iweta ndị ọrụ gị.",
    so: "Marka hore, waxaad u baahan tahay inaad doorato server-ka aad rabto inaad u wareejiso isticmaalehaaga, ka dibna dooro server-ka aad rabto inaad isticmaalehaaga u beddesho.",
    ha: "Da farko, dole ne ka zabi sabar daga inda kake son canja wurin masu amfani, sannan ka zabi sabar daga inda kake son canja wurin masu amfani.",
    mnk: "Fɔtɔ, bɛtɛ wɔ ɲo kafu server le bɛtɛ fow yɛrɛfa la, nɛ bɛtɛ wɔ ɲo server le baŋaa fow ɲi kafu.",
    ff: "Jooɗii, kaɓɓi sabar e ngam waɗtude fow yowɗe maa, njetti sabar kaɓɓi ngam woɗɗude fow ɓeyngu maa.",
    yo: "Ni akọkọ, o nilo lati yan olupin lati ibi ti o fẹ lati gbe awọn olumulo rẹ, lẹhinna yan olupin ti o fẹ lati gbe awọn olumulo rẹ si."
  },
  "Something went wrong, try again": {
    en: "Something went wrong, try again",
    pt: "Algo deu errado, tente novamente",
    de: "Etwas ist schiefgegangen, versuche es erneut",
    es: "Algo salió mal, inténtalo de nuevo",
    fr: "Quelque chose s'est mal passé, réessayez",
    it: "Qualcosa è andato storto, riprova",
    sq: "Diçka shkoi keq, provoni përsëri",
    tr: "Bir şeyler ters gitti, lütfen tekrar deneyin.",
    sw: "Kuna kitu kilichokwama, jaribu tena",
    am: "ነገር አልተሳካም እባኮትን እንደገና ሞክሩ",
    om: "Waan ta'uu danda'ee jira, deebi'ee yaali",
    ig: "Ihe mere, nwalee ọzọ",
    so: "Wax wal qabay khalad, isku day mar kale",
    ha: "Wani abu ya faru, sake gwadawa",
    mnk: "Kuma bɛtɛ fɔ, nɛ kanyi ɲɛ",
    ff: "Eyi si fow, gëyi te",
    yo: "Ohun kan ṣẹlẹ, gbiyanju lẹẹkansi"
  },
  "Select file to upload": {
    en: "Select file to upload",
    es: "Seleccionar archivo para subir",
    fr: "Sélectionnez le fichier à télécharger",
    de: "Datei zum Hochladen auswählen",
    pt: "Selecione o arquivo para carregar",
    it: "Seleziona il file da caricare",
    sq: "Zgjidhni skedarin për ta ngarkuar",
    tr: "Yüklemek için dosyayı seçin",
    sw: "Chagua faili la kupakia",
    am: "ማንበብ ፋይል ለመምረጥ",
    om: "Faayilii filannoo olkaa'uu",
    ig: "Họrọ faịlụ ka ị bulite",
    so: "Dooro faylka si aad u soo rarayso",
    ha: "Zaɓi fayil ɗin don loda",
    mnk: "Jɔyɛ faayiloo laŋo",
    ff: "Suɓo fayiloo ngam ƴamɗo",
    yo: "Yan faili lati gbe"
  },

  //k
  kids: {
    en: "kids",
    pt: "crianças",
    de: "Kinder",
    es: "niños",
    fr: "enfants",
    it: "bambini",
    sq: "fëmijët",
    tr: "çocuklar",
    sw: "Watoto",
    am: "ልጆች",
    om: "Ijoollee",
    ig: "Ụmụaka",
    so: "Carruur",
    ha: "Yara",
    mnk: "Denmuso",
    ff: "Pettiɗi",
    yo: "Ọmọ"
  },
  //h
  "How to activate": {
    en: "How to activate",
    pt: "Como ativar",
    de: "Wie aktivieren",
    es: "Cómo activar",
    fr: "Comment activer",
    it: "Come attivare",
    sq: "Si të aktivizoj",
    tr: "Nasıl aktive edilir",
    sw: "Jinsi ya kuamsha",
    am: "እንዴት ማንቃት እንደሚቻል",
    om: "Akka banuuf",
    ig: "Otu esi emegharia",
    so: "Sida loo kiciyo",
    ha: "Yadda za a kunna",
    mnk: "Kɛ jɔɔrɔ",
    ff: "No mo waɗɗi nder ngina",
    yo: "Báwo la ṣe le ṣíṣẹ́"
  },
  History: {
    en: "History",
    pt: "História",
    de: "Geschichte",
    es: "Historia",
    fr: "Histoire",
    it: "Storia",
    sq: "Historiku",
    tr: "Tarih",
    sw: "Historia",
    am: "ታሪክ",
    om: "Seenaa",
    ig: "Akụkọ ihe mere eme",
    so: "Taariikhda",
    ha: "Tarihi",
    mnk: "Kunuwo",
    ff: "Kaɓirgol",
    yo: "Ìtàn"
  },
  hide: {
    en: "hide",
    pt: "ocultar",
    de: "verbergen",
    es: "ocultar",
    fr: "cacher",
    it: "nascondi",
    sq: "fsheh",
    tr: "gizle",
    sw: "Ficha",
    am: "መደበቅ",
    om: "Dhoksuu",
    ig: "Zochie",
    so: "Qari",
    ha: "Ɓoye",
    mnk: "Fennaa",
    ff: "Sifno",
    yo: "Pa mọ́"
  },
  Host: {
    en: "Host",
    pt: "Host",
    de: "Host",
    es: "Anfitrión",
    fr: "Hôte",
    it: "Host",
    sq: "Pritësi",
    tr: "Sunucu",
    sw: "Mwenyeji",
    am: "አስተናጋጅ",
    om: "Misiri",
    ig: "Onye na-ebu",
    so: "Martida",
    ha: "Mai masauki",
    mnk: "Lama",
    ff: "Leenol",
    yo: "Olugbalejo"
  },
  "Hidden content": {
    en: "Hidden content",
    pt: "Conteúdo oculto",
    de: "Versteckter Inhalt",
    es: "Contenido oculto",
    fr: "Contenu caché",
    it: "Contenuto nascosto",
    sq: "Përmbajtje e fshehur",
    tr: "Gizli içerik",
    sw: "Maudhui yaliyofichwa",
    am: "የተደበቀ ይዘት",
    om: "Icciitii qabachuu",
    ig: "Eziokwu ezoro ezo",
    so: "Waxyaabaha qarsoon",
    ha: "Abun da aka ɓoye",
    mnk: "Fɛŋ doli",
    ff: "Dani naange",
    yo: "Akoonu ti o farapamọ"
  },
  "Available now": {
    en: "Available now",
    pt: "Disponível agora",
    de: "Jetzt verfügbar",
    es: "Disponible ahora",
    fr: "Disponible maintenant",
    it: "Disponibile ora",
    sq: "E disponueshme tani",
    tr: "Şu anda mevcut",
    sw: "Inapatikana sasa",
    am: "አሁን ይገኛል",
    om: "Ammaa jira",
    ig: "Dị ugbu a",
    so: "Hadda waa la heli karaa",
    ha: "Akwai yanzu",
    mnk: "Doonii woora",
    ff: "Jaaɗi heɗii",
    yo: "Wa bayi"
  },

  //p
  Player: {
    en: "Player",
    pt: "Jogador",
    de: "Spieler",
    es: "Reproductor",
    fr: "Lecteur",
    it: "Giocatore",
    sq: "Lojtar",
    tr: "Oynatıcı",
    sw: "Mchezaji",
    am: "ተጫዋች",
    om: "Tapha",
    ig: "Onye egwu",
    so: "Ciyaaryahan",
    ha: "Mai wasa",
    mnk: "Tuntoo",
    ff: "Jaaɓirɗo",
    yo: "Ẹlẹrọ orin"
  },
  "Please note that only previously registered accounts within our system can be linked or attached":
    {
      en: "Please note that only previously registered accounts within our system can be linked or attached.",
      pt: "Por favor, note que apenas contas previamente registradas em nosso sistema podem ser vinculadas ou anexadas.",
      de: "Bitte beachten Sie, dass nur zuvor registrierte Konten in unserem System verknüpft oder angehängt werden können.",
      es: "Tenga en cuenta que solo las cuentas previamente registradas en nuestro sistema pueden ser vinculadas o adjuntadas.",
      fr: "Veuillez noter que seules les comptes préalablement enregistrées dans notre système peuvent être liées ou attachées.",
      it: "Si prega di notare che solo gli account precedentemente registrati nel nostro sistema possono essere collegati o associati.",
      sq: "Ju lutemi vini re se vetëm llogaritë e regjistruara më parë në sistemin tonë mund të lidhen ose bashkëngjiten.",
      tr: "Lütfen yalnızca sistemimizde daha önce kayıtlı olan hesapların bağlanabileceğini veya iliştirilebileceğini unutmayın.",
      sw: "Tafadhali kumbuka kwamba akaunti zilizorekodiwa awali tu ndani ya mfumo wetu ndizo zinazoweza kuunganishwa au kuambatanishwa.",
      am: "እባክህ ታስበው በስርዓታችን በቀድሞ ተመዝግበው ያሉ አካውንቶች ብቻ ሊያገኙ ወይም ሊገናኙ ይችላሉ።",
      om: "Maaliyyi, kontiroolii duraanii dhaabbata keenya keessatti galmaa'anii qofa walitti hidhamuu ykn maxxanfamuu danda'u.",
      ig: "Biko hụ na naanị akaụntụ ndị edere na usoro anyị nwere ike ịjikọta ma ọ bụ kwụpụta.",
      so: "Fadlan ogow in kaliya koontooyinka horey uga diiwaangashan nidaamkayaga ayaa lagu xiriiri karaa ama lagu dhejin karaa.",
      ha: "Don Allah ka lura cewa kawai asusun da aka riga aka rajista a cikin tsarinmu ne za a iya haɗawa ko haɗawa.",
      mnk: "A faama, njamaaji jaŋngol ɗoo leeɓtundu ɓe fotta anndinɗe e jemmaji maa",
      ff: "A jooni, ndiyam ɓe anndondirde ndee ɓe waɗa ngol maa no arii seernude e ngolngol ndiyam.",
      yo: "Jọwọ ṣe akiyesi pe awọn akọọlẹ ti a ti forukọsilẹ tẹlẹ nikan ninu eto wa ni a le so pọ tabi fi ẹda wọn si."
    },
  pixels: {
    en: "pixels",
    es: "píxeles",
    fr: "pixels",
    de: "Pixel",
    pt: "pixels",
    it: "pixel",
    sq: "piksela",
    tr: "piksel",
    sw: "pikseli",
    am: "ፒክሴል",
    om: "piksalii",
    ig: "pixels",
    so: "pixels",
    ha: "pixels",
    mnk: "pixels",
    ff: "pixels",
    yo: "piksẹli"
  },

  "In process": {
    en: "In process",
    pt: "Em processo",
    de: "In Bearbeitung",
    es: "En proceso",
    fr: "En cours",
    it: "In corso",
    sq: "Në proces",
    tr: "İşlemde",
    sw: "Katika mchakato",
    am: "በሂደት",
    om: "Dhaggeeffadhu",
    ig: "Na-eme",
    so: "Dhameystiran",
    ha: "A cikin aiki",
    mnk: "Fɔtɔ",
    ff: "Njëndi",
    yo: "Ni iṣẹ"
  },

  "Please enter date": {
    en: "Please enter date",
    es: "Por favor, ingrese la fecha",
    fr: "Veuillez entrer la date",
    de: "Bitte Datum eingeben",
    pt: "Por favor, insira a data",
    it: "Per favore, inserisci la data",
    sq: "Ju lutem, futni datën",
    tr: "Lütfen tarihi girin",
    sw: "Tafadhali ingiza tarehe",
    am: "እባክህ ቀን አስገባ",
    om: "Meeqa galchii guyyaa",
    ig: "Biko tinye ụbọchị",
    so: "Fadlan geli taariikhda",
    ha: "Don Allah shigar da ranar",
    mnk: "Nye toŋo taataa",
    ff: "A jooɗi date",
    yo: "Jọwọ tẹ ọjọ"
  },
  "Payment status": {
    en: "Payment status",
    es: "Estado del pago",
    fr: "Statut de paiement",
    de: "Zahlungsstatus",
    pt: "Status de pagamento",
    it: "Stato del pagamento",
    sq: "Statusi i pagesës",
    tr: "Ödeme durumu",
    sw: "Hali ya malipo",
    am: "ሂሳብ ሁኔታ",
    om: "Haalataa kaffaltii",
    ig: "Ọnọdụ akwụmụgwọ",
    so: "Xaaladda Bixinta",
    ha: "Matsayin biyan kuɗi",
    mnk: "Nnaatude lewlewal",
    ff: "Haalat biyi",
    yo: "Ipo Owo"
  },
  panel: {
    en: "Panel",
    pt: "Painel",
    de: "Panel",
    es: "Panel",
    fr: "Panneau",
    it: "Pannello",
    sq: "Paneli",
    tr: "Panel",
    sw: "Paneli",
    am: "ፓነል",
    om: "Paaneelii",
    ig: "Paneli",
    so: "Gosolka",
    ha: "Pannel",
    mnk: "Panela",
    ff: "Pannel",
    yo: "Pannẹli"
  },
  "Pay with": {
    en: "Pay with",
    pt: "Pagar com",
    de: "Bezahlen mit",
    es: "Pagar con",
    fr: "Payer avec",
    it: "Paga con",
    sq: "Paguaj me",
    tr: "İle Öde",
    sw: "Lipa kwa",
    am: "በእንቁላል እንዴት እትችላለህ",
    om: "Kaardii Waliin Kaffalu",
    ig: "Zụta site na",
    so: "Bixi kaarka",
    ha: "Biya da Katin",
    mnk: "Lama kaadi",
    ff: "Fayda kaardi",
    yo: "Sanwo pẹlu"
  },
  "Pay with Card": {
    en: "Pay with Card",
    pt: "Pagar com Cartão",
    de: "Mit Karte bezahlen",
    es: "Pagar con Tarjeta",
    fr: "Payer avec Carte",
    it: "Pagare con Carta",
    sq: "Pagesë me Kartë",
    tr: "Kartla Öde",
    sw: "Lipa kwa Kadi",
    am: "ከካርድ ጋር ተከፋፈል",
    om: "Kaardii Waliin Kaffalu",
    ig: "Zụta site na Kadi",
    so: "Bixi kaarka",
    ha: "Biya da Katin",
    mnk: "Lama kaadi",
    ff: "Fayda kaardi",
    yo: "Sanwo pẹlu Kaadi"
  },
  "PIX card": {
    en: "PIX card",
    pt: "Cartão PIX",
    de: "PIX-Karte",
    es: "Tarjeta PIX",
    fr: "Carte PIX",
    it: "Carta PIX",
    sq: "Kartë PIX",
    tr: "PIX kartı",
    sw: "Kadi ya PIX",
    am: "ካርድ በፒኤክስ",
    om: "Kaardii PIX",
    ig: "Kadi PIX",
    so: "Kaarka PIX",
    ha: "Katin PIX",
    mnk: "Kadi PIX",
    ff: "Kaardi PIX",
    yo: "Kaadi PIX"
  },

  Paid: {
    en: "Paid",
    pt: "Pago",
    de: "Bezahlt",
    es: "Pagado",
    fr: "Payé",
    it: "Pagato",
    sq: "Paguar",
    tr: "Ödenmiş",
    sw: "Lilipwa",
    am: "ተከፋፈለ",
    om: "Gatii kaffalamee",
    ig: "Akwụkwọ akwụ ụgwọ",
    so: "Lacag bixinta",
    ha: "An bayar",
    mnk: "Faaŋo",
    ff: "Ñaawo",
    yo: "Sanwo"
  },
  Price: {
    en: "Price",
    pt: "Preço",
    de: "Preis",
    es: "Precio",
    fr: "Prix",
    it: "Prezzo",
    sq: "Çmimi",
    tr: "Fiyat",
    sw: "Bei",
    am: "ውድቀት",
    om: "Gatii",
    ig: "Ọnụahịa",
    so: "Qiimaha",
    ha: "Farashin",
    mnk: "Fulo",
    ff: "Ndaɓo",
    yo: "Iye"
  },
  Provider: {
    en: "Provider",
    pt: "Fornecedor",
    de: "Anbieter",
    es: "Proveedor",
    fr: "Fournisseur",
    it: "Provider",
    sq: "Furnizues",
    tr: "Sağlayıcı",
    sw: "Mtoa huduma",
    am: "አቅራቢ",
    om: "Tajaamaa",
    ig: "Onye na-enye",
    so: "Bixiye",
    ha: "Mai bayarwa",
    mnk: "Nyaŋa",
    ff: "Jaɓɓirɗo",
    yo: "Oludamọran"
  },
  "Provider not found": {
    en: "Provider not found",
    pt: "Fornecedor não encontrado",
    de: "Anbieter nicht gefunden",
    es: "Proveedor no encontrado",
    fr: "Fournisseur non trouvé",
    it: "Provider non trovato",
    sq: "Furnizuesi nuk u gjet",
    tr: "Sağlayıcı bulunamadı",
    sw: "Mtoa huduma hajapatikana",
    am: "አቅራቢ አልተገኘም",
    om: "Tajaamaa hin argamne",
    ig: "Enyemaka achọtabeghị",
    so: "Bixiye ma heli",
    ha: "Mai ba da sabis ba a samu ba",
    mnk: "Nyaŋa kaŋtoŋo kaŋko",
    ff: "Jaɓɓirɗo anndude",
    yo: "Oludamọran ko ri"
  },
  Partner: {
    en: "Partner",
    pt: "Parceiro",
    de: "Partner",
    es: "Socio",
    fr: "Partenaire",
    it: "Partner",
    sq: "Partner",
    tr: "Ortak",
    sw: "Mshirika",
    am: "እንቅስቃሴ",
    om: "Hojii",
    ig: "Ọkpara",
    so: "Lama",
    ha: "Abokin hulɗa",
    mnk: "Kankoo",
    ff: "Njaatigi",
    yo: "Ẹgbẹ"
  },
  Platform: {
    en: "Platform",
    pt: "Plataforma",
    de: "Plattform",
    es: "Plataforma",
    fr: "Plateforme",
    it: "Piattaforma",
    sq: "Platformë",
    tr: "Platform",
    sw: "Jukwaa",
    am: "መድበኛ",
    om: "Plaatfoorma",
    ig: "Ụgbọ ala",
    so: "Madalo",
    ha: "Dandalin",
    mnk: "Jeloo",
    ff: "Plaatfɔɔm",
    yo: "Pẹpẹ"
  },
  Platforms: {
    en: "Platforms",
    pt: "Plataformas",
    de: "Plattformen",
    es: "Plataformas",
    fr: "Plateformes",
    it: "Piattaforme",
    sq: "Platforma",
    tr: "Platformlar",
    sw: "Majukwaa",
    am: "መድበኞች",
    om: "Plaatforomii",
    ig: "Ụgbọ ala",
    so: "Madaladaha",
    ha: "Dandalin",
    mnk: "Jeloosoo",
    ff: "Plaatfɔɔm",
    yo: "Pẹpẹ"
  },
  Playlist: {
    en: "Playlist",
    pt: "Lista de reprodução",
    de: "Wiedergabeliste",
    es: "Lista de reproducción",
    fr: "Liste de lecture",
    it: "Playlist",
    sq: "Lista e këngëve",
    tr: "Çalma Listesi",
    sw: "Orodha ya nyimbo",
    am: "ፕሌይሊስት",
    om: "Pireelistii",
    ig: "Ndepụta egwu",
    so: "Liiska heesaha",
    ha: "Jerin waƙoƙi",
    mnk: "Jamaado",
    ff: "Lefteewo",
    yo: "Atokọ orin"
  },
  Profile: {
    en: "Profile",
    pt: "Perfil",
    de: "Profil",
    es: "Perfil",
    fr: "Profil",
    it: "Profilo",
    sq: "Profili",
    tr: "Profil",
    sw: "Wasifu",
    am: "መገለጫ",
    om: "Dhaabbataa",
    ig: "Profaili",
    so: "Hesho",
    ha: "Bayanai",
    mnk: "Taalif",
    ff: "Koñɗo",
    yo: "Profaili"
  },
  "Previous step": {
    en: "Previous step",
    pt: "Passo anterior",
    de: "Vorheriger Schritt",
    es: "Paso anterior",
    fr: "Étape précédente",
    it: "Passaggio precedente",
    sq: "Hapi i mëparshëm",
    tr: "Önceki adım",
    sw: "Hatua ya awali",
    am: "ከቀድሞ እምነት ሂደት",
    om: "Itti aanu",
    ig: "Nzọụkwụ gara aga",
    so: "Tallaabo hore",
    ha: "Mataki na baya",
    mnk: "Jaloo kera",
    ff: "Mɓeedu mawɗo",
    yo: "Igbese ti tẹlẹ"
  },
  Password: {
    en: "Password",
    pt: "Senha",
    de: "Passwort",
    es: "Contraseña",
    fr: "Mot de passe",
    it: "Password",
    sq: "Fjalëkalim",
    tr: "Şifre",
    sw: "Neno la siri",
    am: "የማስታወቂያ ቁልፍ",
    om: "Jecha icciiti",
    ig: "Okwuntụ",
    so: "Erayga sirta",
    ha: "Kalmar sirri",
    mnk: "Sɔlɔtɔ",
    ff: "Cifor",
    yo: "Ọrọ aṣínà"
  },
  Phone: {
    en: "Phone",
    pt: "Telefone",
    de: "Telefon",
    es: "Teléfono",
    fr: "Téléphone",
    it: "Telefono",
    sq: "Telefoni",
    tr: "Telefon",
    sw: "Simu",
    am: "ስልክ",
    om: "Bilbila",
    ig: "Ekwenti",
    so: "Telefoon",
    ha: "Wayar hannu",
    mnk: "Tèlèfóon",
    ff: "Telefoon",
    yo: "Foonu"
  },
  "Playlist URL": {
    en: "Playlist URL",
    pt: "URL da lista de reprodução",
    de: "Playlist-URL",
    es: "URL de la lista de reproducción",
    fr: "URL de la liste de lecture",
    it: "URL della playlist",
    sq: "URL e listës së përgjimeve",
    tr: "Oynatma Listesi URL'si",
    sw: "URL ya orodha ya nyimbo",
    am: "ፕሌይሌስት አድራሻ",
    om: "URL giddu-galeessaa",
    ig: "URL nke playlist",
    so: "URL liiska ciyaaraha",
    ha: "URL na'urar jerin waƙoƙi",
    mnk: "URL playlist",
    ff: "URL playlist",
    yo: "URL akojọ orin"
  },
  "Partner key": {
    en: "Partner key",
    pt: "Chave do parceiro",
    de: "Partner-Schlüssel",
    es: "Clave del socio",
    fr: "Clé du partenaire",
    it: "Chiave del partner",
    sq: "Çelësi i partnerit",
    tr: "Ortak anahtarı",
    sw: "Funguo ya mshirika",
    am: "ባለስልጣን ቁልፍ",
    om: "Maqaa michuu",
    ig: "Igodo nke onye mmekọ",
    so: "Fureya lamaan",
    ha: "Mabuɗin abokin hulɗa",
    mnk: "Sama kafu",
    ff: "Kilse ƴettude",
    yo: "Bọtini alabaṣepọ"
  },
  "Primary color": {
    en: "Primary color",
    pt: "Cor primária",
    de: "Primärfarbe",
    es: "Color primario",
    fr: "Couleur principale",
    it: "Colore primario",
    sq: "Ngjyra kryesore",
    tr: "Ana renk",
    sw: "Rangi kuu",
    am: "ታላቅ ቀለም",
    om: "Color isa duraa",
    ig: "Agba agba mbụ",
    so: "Midabka ugu muhiimsan",
    ha: "Launin farko",
    mnk: "Colo kafu",
    ff: "Deyngal goɗɗo",
    yo: "Awọ pataki"
  },
  "Payment method": {
    en: "Payment method",
    pt: "Método de pagamento",
    de: "Zahlungsmethode",
    es: "Método de pago",
    fr: "Moyen de paiement",
    it: "Metodo di pagamento",
    sq: "Metoda e pagesës",
    tr: "Ödeme yöntemi",
    sw: "Mbinu ya malipo",
    am: "ክፍያ ስርዓት",
    om: "Malli kaffaltii",
    ig: "Usoro ịkwụ ụgwọ",
    so: "Habka lacag bixinta",
    ha: "Hanyar biyan kuɗi",
    mnk: "Kafu fiyɛlɛ",
    ff: "Hakkilo ngam njippu",
    yo: "Ọna isanwo"
  },
  "Password changed": {
    en: "Password changed!",
    pt: "Senha alterada!",
    de: "Passwort geändert!",
    es: "¡Contraseña cambiada!",
    fr: "Mot de passe changé !",
    it: "Password cambiata!",
    sq: "Fjalëkalimi u ndryshua!",
    tr: "Şifre değiştirildi!",
    sw: "Nenosiri imebadilika!",
    am: "የፓስወርድ ለቀቀ!",
    om: "Jecha oggaa jijjiirame!",
    ig: "Paswọọdụ agbanwere!",
    so: "Ereyga sirta waa la bedelay!",
    ha: "Anfanin kalmar sirri an canza!",
    mnk: "ɲaŋtɛ ɲa bɛ kɔrɔyɛŋ!",
    ff: "Passcode ɗon tawii!",
    yo: "Ọrọ aṣínà ti yipada!"
  },
  "Please enter the password of the account you want to attach.": {
    en: "Please enter the password of the account you want to attach.",
    pt: "Por favor, insira a senha da conta que deseja anexar.",
    de: "Bitte geben Sie das Passwort des Kontos ein, das Sie anhängen möchten.",
    es: "Por favor, ingrese la contraseña de la cuenta que desea adjuntar.",
    fr: "Veuillez entrer le mot de passe du compte que vous souhaitez attacher.",
    it: "Si prega di inserire la password dell'account che si desidera allegare.",
    sq: "Ju lutemi vendosni fjalëkalimin e llogarisë që dëshironi të bashkangjitni.",
    tr: "Lütfen eklemek istediğiniz hesabın şifresini girin.",
    sw: "Tafadhali ingiza nenosiri ya akaunti unayotaka kuunganishwa.",
    am: "እባክህ ይጠቀሙ ስለሚያገኙ አካውንት መለያ ማስመሰያ።",
    om: "Fadlan galmee jecha oggaa  akkamitti kankabala dhabamuuti.",
    ig: "Biko tinye paswọọdụ nke akaụntụ ịchọrọ itinye.",
    so: "Fadlan geli erayga sirta ee xisaabta aad rabto in aad ku xidhidho.",
    ha: "Da fatan shigar da kalmar sirri ta asusun da kake son haɗawa.",
    mnk: "Balaa ɲaŋtɛ sũwɔ ɓɛ bɛ kɔrɔyɛŋ  akuntee mɔ ko he meyɛ.",
    ff: "Fayda galɗa passcode ɗon komɓe geesandi.",
    yo: "Jọwọ tẹ ọrọ aṣínà ti àkọọlẹ ti o fẹ lati so."
  },
  "Please check the playlist as it is incorrectly filled in.": {
    en: "Please check the playlist as it is incorrectly filled in.",
    pt: "Por favor, verifique a lista de reprodução, pois está preenchida incorretamente.",
    de: "Bitte überprüfen Sie die Playlist, da sie falsch ausgefüllt ist.",
    es: "Por favor, compruebe la lista de reproducción, ya que está incorrectamente rellenada.",
    fr: "Veuillez vérifier la playlist car elle est incorrectement remplie.",
    it: "Si prega di controllare la playlist in quanto è compilata in modo errato.",
    sq: "Ju lutemi kontrolloni listën e këngëve, pasi është mbushur gabimisht.",
    tr: "Lütfen listeyi kontrol edin, çünkü yanlış doldurulmuş.",
    sw: "Tafadhali angalia orodha hii ya nyimbo kwani imejazwa vibaya.",
    am: "እባክህ ይህን ፕሌይሊስት ያሳስቡ ምክንያቱም በተሳሳተ መንገድ ተ γεተወሰነ።",
    om: "Fadlan sakatta playlist kana sirriitti hin guutamne.",
    ig: "Biko nyochaa playlist a ka o jụrụ na-ezighị ezi.",
    so: "Fadlan hubi playlist-kan maadaama si khalad ah ayaa lagu buuxiyey.",
    ha: "Da fatan duba wannan playlist domin an cika ta da kuskure.",
    mnk: "Balaa playlist ni mansa bibiɓe kafu la bɛtɛni.",
    ff: "Fayda kalaama playlist ɗon ngesaaji, kadi ngoorya.",
    yo: "Jọwọ ṣayẹwo playlist yi nitori pe a ti kún rẹ ni aṣiṣe."
  },
  "Please check this playlist as its expiration date has expired.": {
    en: "Please check this playlist as its expiration date has expired.",
    pt: "Por favor, verifique esta lista de reprodução, pois a data de validade expirou.",
    de: "Bitte überprüfen Sie diese Playlist, da das Ablaufdatum abgelaufen ist.",
    es: "Por favor, compruebe esta lista de reproducción, ya que su fecha de vencimiento ha caducado.",
    fr: "Veuillez vérifier cette playlist car sa date d'expiration est dépassée.",
    it: "Si prega di controllare questa playlist poiché la data di scadenza è scaduta.",
    sq: "Ju lutemi kontrolloni këtë listë këngësh, pasi data e skadimit ka përfunduar.",
    tr: "Lütfen bu listeyi kontrol edin, çünkü son kullanma tarihi dolmuştur.",
    sw: "Tafadhali angalia orodha hii ya nyimbo kwani tarehe yake ya kumalizika imeisha.",
    am: "እባክህ ይህን ፕሌይሊስት ያሳስቡ ምክንያቱም ተወላጅ ቀን አልተወሰነም።",
    om: "Fadlan sakatta playlist kana yeroo dhumaa isaa darbeera.",
    ig: "Biko nyochaa playlist a ka ụbọchị njedebe ya agwụla.",
    so: "Fadlan hubi playlist-kan maadaama taariikhda dhicitaanka ay dhamaatay.",
    ha: "Da fatan duba wannan playlist domin ranar karewa ta wuce.",
    mnk: "Balaa playlist ni mansa bibiɓe kafu la.",
    ff: "Fayda kalaama playlist ɗon ngesaaji mbon jungo.",
    yo: "Jọwọ ṣayẹwo playlist yi nitori pe ọjọ ipari rẹ ti kọja."
  },

  "Please input playlist URL": {
    en: "Please input playlist URL",
    pt: "Por favor, insira o URL da lista de reprodução",
    de: "Bitte geben Sie die URL der Wiedergabeliste ein",
    es: "Por favor, introduzca la URL de la lista de reproducción",
    fr: "Veuillez entrer l'URL de la playlist",
    it: "Si prega di inserire l'URL della playlist",
    sq: "Ju lutemi vendosni URL-në e listës së përgjimeve",
    tr: "Lütfen oynatma listesi URL'sini girin",
    sw: "Tafadhali ingiza URL ya orodha ya nyimbo!",
    am: "እባክህ ቁልፍ እትተም የፕሌይሊስት ዩአርኤል!",
    om: "Fadlan galmeewwan URL playlist!",
    ig: "Biko tinye URL nke playlist!",
    so: "Fadlan geli URL playlist-ka!",
    ha: "Da fatan shigar da URL na playlist!",
    mnk: "Balaa yiɓɓe playlist URL!",
    ff: "Fayda kalaama mbon playlist URL!",
    yo: "Jọwọ tẹ URL playlist!"
  },
  "Please enter device key": {
    en: "Please enter device key",
    pt: "Por favor, insira a chave do dispositivo",
    de: "Bitte geben Sie den Geräteschlüssel ein",
    es: "Por favor, introduzca la clave del dispositivo",
    fr: "Veuillez entrer la clé de l'appareil",
    it: "Per favore, inserisci la chiave del dispositivo",
    sq: "Ju lutemi vendosni çelësin e pajisjes",
    tr: "Lütfen cihaz anahtarını girin",
    sw: "Tafadhali ingiza funguo la kifaa!",
    am: "እባክህ ቁልፍ ተመን እትተም!",
    om: "Fadlan galmeewwan bitaawwan devaayisii!",
    ig: "Biko tinye igodo ngwaọrụ!",
    so: "Fadlan geli furaha qalabka!",
    ha: "Da fatan shigar da mabuɗin na'ura!",
    mnk: "Balaa yiɓɓe device key!",
    ff: "Fayda kalaama mbon biyi!",
    yo: "Jọwọ tẹ bọtini ẹrọ!"
  },
  "Please check the entered data": {
    en: "Please check the entered data",
    pt: "Por favor, verifique os dados inseridos",
    de: "Bitte überprüfen Sie die eingegebenen Daten",
    es: "Por favor, verifique los datos ingresados",
    fr: "Veuillez vérifier les données saisies",
    it: "Si prega di verificare i dati inseriti",
    sq: "Ju lutemi kontrolloni të dhënat e vendosura",
    tr: "Lütfen girilen verileri kontrol edin",
    sw: "Tafadhali angalia data ulizoingiza!",
    am: "እባክህ ታተመውን መረጃ እትተም በማስተካከል!",
    om: "Maaf, galmeewwan galchitee ilaalu!",
    ig: "Biko lelee data ị tinyere!",
    so: "Fadlan hubi xogta la geliyey!",
    ha: "Da fatan duba bayanan da aka shigar!",
    mnk: "Balaa yiɓɓe kafu saama!",
    ff: "Fayda kalaama ina addi!",
    yo: "Jọwọ ṣayẹwo data ti a tẹ!"
  },

  "Please input name": {
    en: "Please input name",
    pt: "Por favor, insira o nome",
    de: "Bitte geben Sie den Namen ein",
    es: "Por favor, ingrese el nombre",
    fr: "Veuillez entrer le nom",
    it: "Per favore, inserisci il nome",
    sq: "Ju lutemi vendosni emrin",
    tr: "Lütfen ismi girin",
    sw: "Tafadhali ingiza jina!",
    am: "እባክህ ስምን አስገባ!",
    om: "Maaf, maqaa galchuu!",
    ig: "Biko tinye aha!",
    so: "Fadlan geli magaca!",
    ha: "Da fatan shigar da suna!",
    mnk: "Balaa nyaa inyaa!",
    ff: "Fayda kalaama inna!",
    yo: "Jọwọ tẹ orukọ rẹ!"
  },
  "Please input Host": {
    en: "Please input Host",
    pt: "Por favor, insira o Host",
    de: "Bitte geben Sie den Host ein",
    es: "Por favor, ingrese el Host",
    fr: "Veuillez entrer l'hôte",
    it: "Per favore, inserisci l'Host",
    sq: "Ju lutemi vendosni Hostin",
    tr: "Lütfen Host'u girin",
    sw: "Tafadhali ingiza mwenyeji!",
    am: "እባክህ ሆስትን አስገባ!",
    om: "Maaf, host galchuu!",
    ig: "Biko tinye onye nkwado!",
    so: "Fadlan geli marti!",
    ha: "Da fatan shigar da mai masauki!",
    mnk: "Balaa nyaa host!",
    ff: "Fayda kalaama host!",
    yo: "Jọwọ tẹ Host rẹ!"
  },

  "Please input api key": {
    en: "Please input api key",
    pt: "Por favor, insira a chave da API",
    de: "Bitte geben Sie den API-Schlüssel ein",
    es: "Por favor, ingrese la clave de la API",
    fr: "Veuillez entrer la clé API",
    it: "Per favore, inserisci la chiave API",
    sq: "Ju lutemi vendosni çelësin API",
    tr: "Lütfen API anahtarını girin",
    sw: "Tafadhali ingiza funguo za API!",
    am: "እባክህ ኤፒአይ ቁልፍን አስገባ!",
    om: "Maaf, furtuu API galchuu!",
    ig: "Biko tinye igodo API!",
    so: "Fadlan geli furaha API!",
    ha: "Da fatan shigar da mabuɗin API!",
    mnk: "Balaa nyaa API key!",
    ff: "Fayda kalaama API key!",
    yo: "Jọwọ tẹ bọtini API rẹ!"
  },
  "Please input your name": {
    en: "Please input your name!",
    pt: "Por favor, insira o seu nome!",
    de: "Bitte geben Sie Ihren Namen ein!",
    es: "Por favor, ingrese su nombre!",
    fr: "Veuillez entrer votre nom !",
    it: "Per favore, inserisci il tuo nome!",
    sq: "Ju lutemi vendosni emrin tuaj!",
    tr: "Lütfen adınızı girin!",
    sw: "Tafadhali ingiza jina lako!",
    am: "እባክህ ስምህን አስገባ!",
    om: "Maaf, maqaa kee galchuu!",
    ig: "Biko tinye aha gị!",
    so: "Fadlan geli magacaga!",
    ha: "Da fatan shigar da sunanka!",
    mnk: "Balaa nyaa ɲooree!",
    ff: "Fayda kalaama joom!",
    yo: "Jọwọ tẹ oruko rẹ!"
  },
  "Please input your surname": {
    en: "Please input your surname",
    pt: "Por favor, insira o seu sobrenome",
    de: "Bitte geben Sie Ihren Nachnamen ein",
    es: "Por favor, ingrese su apellido",
    fr: "Veuillez entrer votre nom de famille",
    it: "Per favore, inserisci il tuo cognome",
    sq: "Ju lutemi vendosni mbiemrin tuaj",
    tr: "Lütfen soyadınızı girin",
    sw: "Tafadhali ingiza jina lako la mwisho",
    am: "እባክህ ስምህን አስገባ",
    om: "Maaf, maqaa kee galchuu",
    ig: "Biko tinye aha gị",
    so: "Fadlan geli magacaga",
    ha: "Da fatan shigar da sunanka",
    mnk: "Balaa nyaa ɲooree",
    ff: "Fayda kalaama joom",
    yo: "Jọwọ tẹ oruko rẹ"
  },

  "Please input cvv": {
    en: "Please input cvv",
    pt: "Por favor, insira o CVV",
    de: "Bitte geben Sie den CVV ein",
    es: "Por favor, ingrese el CVV",
    fr: "Veuillez saisir le cryptogramme visuel",
    it: "Inserisci il CVV per favore",
    sq: "Ju lutemi vendosni CVV-në",
    tr: "Lütfen CVV'yi girin",
    sw: "Tafadhali ingiza CVV!",
    am: "እባክህ ቁጥር በCVV አስገባ!",
    om: "Maaf, CVV galchuu!",
    ig: "Biko tinye CVV!",
    so: "Fadlan geli CVV!",
    ha: "Da fatan shigar da CVV!",
    mnk: "Balaa nyaa xewniyaa CVV!",
    ff: "Fayda kalaama CVV!",
    yo: "Jọwọ tẹ CVV!"
  },
  "Please input number": {
    en: "Please input number",
    pt: "Por favor, insira um número",
    de: "Bitte geben Sie eine Zahl ein",
    es: "Por favor, ingrese un número",
    fr: "Veuillez saisir un numéro",
    it: "Per favore, inserire un numero",
    sq: "Ju lutemi vendosni numrin",
    tr: "Lütfen bir numara girin",
    sw: "Tafadhali ingiza namba!",
    am: "እባክህ ቁጥር አስገባ!",
    om: "Maaf, lakkoofsa galchuu!",
    ig: "Biko tinye nọmba!",
    so: "Fadlan geli lambarka!",
    ha: "Da fatan shigar da lamba!",
    mnk: "Balaa nyaa xewniyaa number!",
    ff: "Fayda kalaama number!",
    yo: "Jọwọ tẹ nọmba!"
  },
  "Please input expiration month": {
    en: "Please input expiration month",
    pt: "Por favor, insira o mês de validade",
    de: "Bitte geben Sie den Ablaufmonat ein",
    es: "Por favor, ingrese el mes de vencimiento",
    fr: "Veuillez entrer le mois d'expiration",
    it: "Si prega di inserire il mese di scadenza",
    sq: "Ju lutemi vendosni muajin e skadimit",
    tr: "Lütfen son kullanma ayını girin",
    sw: "Tafadhali ingiza mwezi wa kuisha!",
    am: "እባክህ የመጨረሻ ወር አስገባ!",
    om: "Maaf, bara haala dhiisuu ji'a galchuu!",
    ig: "Biko tinye ọnwa nke mmechi!",
    so: "Fadlan geli bisha dhicitaanka!",
    ha: "Da fatan shigar da wata na ƙarewa!",
    mnk: "Balaa nyaa xewniyaa month!",
    ff: "Fayda kalaama expiration month!",
    yo: "Jọwọ tẹ oṣù ipari!"
  },
  "Please input expiration year": {
    en: "Please input expiration year",
    pt: "Por favor, insira o ano de expiração",
    de: "Bitte geben Sie das Ablaufjahr ein",
    es: "Por favor, introduzca el año de vencimiento",
    fr: "Veuillez entrer l'année d'expiration",
    it: "Si prega di inserire l'anno di scadenza",
    sq: "Ju lutemi vendosni vitin e skadimit",
    tr: "Lütfen son kullanma yılını girin",
    sw: "Tafadhali ingiza mwaka wa kuisha!",
    am: "እባክህ የመጨረሻ አመት አስገባ!",
    om: "Maaf, bara haala dhiisuu galchuu!",
    ig: "Biko tinye afọ nke mmechi!",
    so: "Fadlan geli sanadka dhicitaanka!",
    ha: "Da fatan shigar da shekara ta ƙarewa!",
    mnk: "Balaa nyaa xewniyaa year!",
    ff: "Fayda kalaama expiration year!",
    yo: "Jọwọ tẹ ọdun ipari!"
  },

  "Please check auto renew": {
    en: "Please check auto renew",
    pt: "Por favor, verifique a renovação automática",
    de: "Bitte überprüfen Sie die automatische Verlängerung",
    es: "Por favor, compruebe la renovación automática",
    fr: "Veuillez vérifier le renouvellement automatique",
    it: "Per favore, controlla il rinnovo automatico",
    sq: "Ju lutemi kontrolloni rinovimin automatik",
    tr: "Lütfen otomatik yenilemeyi kontrol edin",
    sw: "Tafadhali angalia ufanisi wa upya kiotomatiki!",
    am: "እባክህ አዶ ሪኑ ማስተካከል ማረጋገጥ!",
    om: "Maaf, mirkane dandeettii haaraa ilaali!",
    ig: "Biko lelee mmegharị auto!",
    so: "Fadlan hubi cusboonaysiinta otomaatiga ah!",
    ha: "Da fatan duba sabunta kai tsaye!",
    mnk: "Balaa nyaa auto-renewal!",
    ff: "Fayda kalaama renewde ngal!",
    yo: "Jọwọ ṣayẹwo imudojuiwọn aifọwọyi!"
  },

  "Please input your password": {
    en: "Please input your password!",
    pt: "Por favor, insira sua senha!",
    de: "Bitte geben Sie Ihr Passwort ein!",
    es: "Por favor, ingrese su contraseña!",
    fr: "Veuillez entrer votre mot de passe !",
    it: "Per favore, inserisci la tua password!",
    sq: "Ju lutemi vendosni fjalëkalimin tuaj!",
    tr: "Lütfen şifrenizi girin!",
    sw: "Tafadhali ingiza nenosiri lako!",
    am: "እባክህ ፓስወርድዎን አስገባ!",
    om: "Maaf, paasword kee galchi!",
    ig: "Biko tinye paswọọdụ gị!",
    so: "Fadlan geli eraygaaga sirta ah!",
    ha: "Da fatan shigar da kalmar sirrinka!",
    mnk: "Balaa nyaa password la!",
    ff: "Fayda kalaama seerɗe!",
    yo: "Jọwọ tẹ ọrọ igbaniwọle rẹ!"
  },

  "Please confirm your password": {
    en: "Please confirm your password!",
    pt: "Por favor, confirme sua senha!",
    de: "Bitte bestätigen Sie Ihr Passwort!",
    es: "Por favor, confirme su contraseña!",
    fr: "Veuillez confirmer votre mot de passe !",
    it: "Per favore, conferma la tua password!",
    sq: "Ju lutemi konfirmoni fjalëkalimin tuaj!",
    tr: "Lütfen şifrenizi onaylayın!",
    sw: "Tafadhali thibitisha nenosiri lako!",
    am: "እባክህ ፓስወርድዎን ማረጋገጫ አድርጉ!",
    om: "Maaf, paasword kee mirkaneessi!",
    ig: "Biko kwadoro paswọọdụ gị!",
    so: "Fadlan xaqiiji eraygaaga sirta ah!",
    ha: "Da fatan tabbatar da kalmar sirrinka!",
    mnk: "Balaa nyaa password la fannde!",
    ff: "Fayda nden kalaama seerɗe!",
    yo: "Jọwọ jẹrisi ọrọ igbaniwọle rẹ!"
  },
  "Please input your username": {
    en: "Please input your username!",
    pt: "Por favor, insira seu nome de usuário!",
    de: "Bitte geben Sie Ihren Benutzernamen ein!",
    es: "Por favor, ingrese su nombre de usuario!",
    fr: "Veuillez entrer votre nom d'utilisateur !",
    it: "Per favore, inserisci il tuo nome utente!",
    sq: "Ju lutemi vendosni emrin tuaj të përdoruesit!",
    tr: "Lütfen kullanıcı adınızı girin!",
    sw: "Tafadhali ingiza jina lako la mtumiaji!",
    am: "እባክህ ስም ያስገቡ!",
    om: "Maaf, lakko maqaa fayyadamtootaa!",
    ig: "Biko tinye aha gị!",
    so: "Fadlan geli magacaaga isticmaalaha!",
    ha: "Da fatan shigar da sunan mai amfani!",
    mnk: "Balaa nyaa innaama la fana!",
    ff: "Fayda nden ɗemngal ngalɗo!",
    yo: "Jọwọ tẹ orukọ olumulo rẹ!"
  },
  "Please input access code": {
    en: "Please input access code",
    pt: "Por favor, insira o código de acesso",
    de: "Bitte geben Sie den Zugangscode ein",
    es: "Por favor, ingrese el código de acceso",
    fr: "Veuillez entrer le code d'accès",
    it: "Per favore, inserisci il codice di accesso",
    sq: "Ju lutemi vendosni kodin e qasjes",
    tr: "Lütfen erişim kodunu girin",
    sw: "Tafadhali ingiza nambari ya ufikiaji!",
    am: "እባክህ የመድረክ ኮድ እንዲገባ!",
    om: "Maaf, lakko koodii seera dhiibbaa!",
    ig: "Biko tinye koodu nnweta!",
    so: "Fadlan geli koodhka gelitaanka!",
    ha: "Da fatan shigar da lambar shiga!",
    mnk: "Balaa nyaa koodo la wejii!",
    ff: "Fayda nden code la ɗemndu!",
    yo: "Jọwọ tẹ koodu wiwọle rẹ!"
  },
  "Please input your E-mail": {
    en: "Please input your E-mail!",
    pt: "Por favor, insira o seu e-mail!",
    de: "Bitte geben Sie Ihre E-Mail-Adresse ein!",
    es: "Por favor, ingrese su correo electrónico!",
    fr: "Veuillez entrer votre adresse e-mail !",
    it: "Per favore, inserisci la tua email!",
    sq: "Ju lutemi vendosni emailin tuaj!",
    tr: "Lütfen e-posta adresinizi girin!",
    sw: "Tafadhali ingiza barua pepe yako!",
    am: "እባክህ ኢሜል ስህተትህን እንዲገባ!",
    om: "Maaf, email kee galchi!",
    ig: "Biko tinye email gị!",
    so: "Fadlan geli email-kaaga!",
    ha: "Da fatan shigar da imel ɗinku!",
    mnk: "Balaa nyaa email la!",
    ff: "Fayda nden email maa!",
    yo: "Jọwọ tẹ imeeli rẹ!"
  },
  "Please input title": {
    en: "Please input title!",
    pt: "Por favor, insira o título!",
    de: "Bitte geben Sie den Titel ein!",
    es: "Por favor, ingrese el título!",
    fr: "Veuillez entrer le titre !",
    it: "Per favore, inserisci il titolo!",
    sq: "Ju lutemi vendosni titullin!",
    tr: "Lütfen başlığı girin!",
    sw: "Tafadhali ingiza kichwa!",
    am: "እባክህ ርዕስ እንዲገባ!",
    om: "Maaf, mataduree galchi!",
    ig: "Biko tinye aha!",
    so: "Fadlan geli cinwaanka!",
    ha: "Da fatan shigar da take!",
    mnk: "Balaa nyaa title la!",
    ff: "Fayda nden title maa!",
    yo: "Jọwọ tẹ akọle!"
  },
  "Please input your 2FA email address": {
    en: "Please input your 2FA email address",
    pt: "Por favor, insira o seu endereço de e-mail de 2FA",
    de: "Bitte geben Sie Ihre 2FA-E-Mail-Adresse ein",
    es: "Por favor, ingrese su dirección de correo electrónico de 2FA",
    fr: "Veuillez entrer votre adresse e-mail 2FA",
    it: "Inserisci il tuo indirizzo email 2FA, per favore",
    sq: "Ju lutemi vendosni adresën tuaj të emailit 2FA",
    tr: "Lütfen 2FA e-posta adresinizi girin",
    sw: "Tafadhali ingiza anwani ya barua pepe ya 2FA yako!",
    am: "እባክህ እትም እባብስ ስም በ 2FA እና ኢሜል አድራሻ!",
    om: "Maaf, email address 2FA kee galchi!",
    ig: "Biko tinye adreesị email 2FA gị!",
    so: "Fadlan geli cinwaanka iimaylkaaga ee 2FA!",
    ha: "Da fatan shigar da adireshin imel na 2FA!",
    mnk: "Balaa nyaa 2FA email address la!",
    ff: "Fayda nden 2FA email address maa!",
    yo: "Jọwọ tẹ adirẹsi imeeli 2FA rẹ!"
  },
  "Please input your brand name": {
    en: "Please input your brand name!",
    pt: "Por favor, insira o nome da sua marca!",
    de: "Bitte geben Sie den Markennamen ein!",
    es: "Por favor, ingrese el nombre de su marca!",
    fr: "Veuillez entrer le nom de votre marque !",
    it: "Per favore, inserisci il nome del tuo brand!",
    sq: "Ju lutemi vendosni emrin e markës suaj!",
    tr: "Lütfen marka adınızı girin!",
    sw: "Tafadhali ingiza jina la chapa yako!",
    am: "እባክህ ስለምንም ስም አስገባ!",
    om: "Maaf, maqaa brand kee galchi!",
    ig: "Biko tinye aha akara gị!",
    so: "Fadlan geli magaca sumaddaada!",
    ha: "Da fatan za a shigar da sunan alamar!",
    mnk: "Balaa nyaa brand name la!",
    ff: "Fayda nden brand name maa!",
    yo: "Jọwọ tẹ orukọ ami iyasọtọ rẹ!"
  },
  "Please input description": {
    en: "Please input description!",
    pt: "Por favor, insira a descrição!",
    de: "Bitte geben Sie eine Beschreibung ein!",
    es: "Por favor, ingrese una descripción!",
    fr: "Veuillez entrer la description !",
    it: "Per favore, inserisci la descrizione!",
    sq: "Ju lutemi vendosni përshkrimin!",
    tr: "Lütfen açıklamayı girin!",
    sw: "Tafadhali ingiza maelezo!",
    am: "እባክህ ስለምንም መግለጫ አስገባ!",
    om: "Maaf, ibsa galchi!",
    ig: "Biko tinye nkọwa!",
    so: "Fadlan geli sharraxaadda!",
    ha: "Da fatan za a shigar da bayanin!",
    mnk: "Balaa nyaa description la!",
    ff: "Fayda nden description maa!",
    yo: "Jọwọ tẹ apejuwe!"
  },
  "Please input your credit count": {
    en: "Please input your credit count!",
    pt: "Por favor, insira a contagem de crédito!",
    de: "Bitte geben Sie die Kreditanzahl ein!",
    es: "Por favor, ingrese la cantidad de créditos!",
    fr: "Veuillez entrer le nombre de crédits !",
    it: "Per favore, inserisci il conteggio dei crediti!",
    sq: "Ju lutemi vendosni numrin tuaj të kreditit!",
    tr: "Lütfen kredi sayınızı girin!",
    sw: "Tafadhali ingiza idadi ya mikopo yako!",
    am: "እባክህ የእርስዎን ክሬዲት ቁጥር አስገባ!",
    om: "Maaf, lakkoofsa krediti kee galchi!",
    ig: "Biko tinye ọnụ ọgụgụ kredit gị!",
    so: "Fadlan geli tirada kredit-kaaga!",
    ha: "Da fatan za a shigar da adadin kuɗin ku!",
    mnk: "Balaa nyaa credit count la!",
    ff: "Fayda nden credit count maa!",
    yo: "Jọwọ tẹ nọmba krediti rẹ!"
  },
  "Pick new server to migrate your users": {
    en: "Pick new server to migrate your users",
    pt: "Escolha um novo servidor para migrar seus usuários",
    de: "Wählen Sie einen neuen Server, um Ihre Benutzer zu migrieren",
    es: "Seleccione un nuevo servidor para migrar a sus usuarios",
    fr: "Choisissez un nouveau serveur pour migrer vos utilisateurs",
    it: "Scegli un nuovo server per migrare i tuoi utenti",
    sq: "Zgjidhni serverin e ri për të migrur përdoruesit tuaj",
    tr: "Kullanıcılarınızı taşımak için yeni bir sunucu seçin",
    sw: "Chagua seva mpya ili kuhamisha watumiaji wako!",
    am: "አዲስ ሰርቨር ይምረጡ ለተጠቃሚዎችዎን ለመላክ!",
    om: "Sirna haaraa filadhu akka fayyadamaa kee geeddaruu!",
    ig: "Họrọ ọrụ ọhụrụ iji nyefee ndị ọrụ gị!",
    so: "Dooro server cusub si aad u wareejiso isticmaalayaashaada!",
    ha: "Zaɓi sabuwar uwar garken don canja wurin masu amfani da ku!",
    mnk: "Chɛ new server la taa nyaa users la!",
    ff: "Suɓo server fow ngam gollal ɗe njahi!",
    yo: "Yanju olupin tuntun lati gbe awọn olumulo rẹ!"
  },
  "Please input your new password": {
    en: "Please input your new password!",
    pt: "Por favor, insira sua nova senha!",
    de: "Bitte geben Sie Ihr neues Passwort ein!",
    es: "Por favor, ingrese su nueva contraseña!",
    fr: "Veuillez entrer votre nouveau mot de passe !",
    it: "Per favore, inserisci la tua nuova password!",
    sq: "Ju lutemi vendosni fjalëkalimin tuaj të ri!",
    tr: "Lütfen yeni şifrenizi girin!",
    sw: "Tafadhali ingiza neno lako la siri jipya!",
    am: "እባክህ ፈጣን አዲስ ፕስዎርድን አስገባ!",
    om: "Meeqa sirrii haaraa kee galchaa!",
    ig: "Biko tinye paswọọdụ ọhụrụ gị!",
    so: "Fadlan geli eraygaaga sirri cusub!",
    ha: "Da fatan, shigar da sabon kalmar wucewa!",
    mnk: "Taa kafu nyaa password la kafu!",
    ff: "Jara kafu password fow!",
    yo: "Jọwọ tẹ ọrọ aṣina tuntun rẹ!"
  },
  "Please input your verify code": {
    en: "Please input your verify code",
    pt: "Por favor, insira seu código de verificação",
    de: "Bitte geben Sie Ihren Bestätigungscode ein",
    es: "Por favor, ingrese su código de verificación",
    fr: "Veuillez saisir votre code de vérification",
    it: "Inserisci il tuo codice di verifica",
    sq: "Ju lutemi vendosni kodin tuaj të verifikimit",
    tr: "Lütfen doğrulama kodunuzu girin",
    sw: "Tafadhali ingiza msimbo wako wa uthibitisho.",
    am: "እባክህ ኮድ ማረጋገጫህን አስገባ።",
    om: "Meeqa sirrii kee galchaa.",
    ig: "Biko tinye koodu nyocha gị.",
    so: "Fadlan geli koodhkaaga xaqiijinta.",
    ha: "Da fatan, shigar da lambar tabbatarwa.",
    mnk: "Taa kafu bɔ kafu code.",
    ff: "Jara kafu code fow.",
    yo: "Jọwọ tẹ koodu ìmúlò rẹ."
  },
  "Password must be minimum 8 characters": {
    en: "Password must be minimum 8 characters.",
    pt: "A senha deve ter no mínimo 8 caracteres.",
    de: "Das Passwort muss mindestens 8 Zeichen lang sein.",
    es: "La contraseña debe tener como mínimo 8 caracteres.",
    fr: "Le mot de passe doit comporter au moins 8 caractères.",
    it: "La password deve essere lunga almeno 8 caratteri.",
    sq: "Fjalëkalimi duhet të jetë minimum 8 karaktere",
    tr: "Şifre en az 8 karakter olmalıdır.",
    sw: "Neno la siri lazima liwe na angalau herufi 8.",
    am: "ፓስዎርድ ቢያንስ 8 ቁምፊ መሆን አለበት።",
    om: "Jecha icciitii gara fageenya herregaa 8 ol ta'uu qaba.",
    ig: "Okwuntughari kwesịrị ịbụ ihe dịkarịa ala mkpụrụedemede 8.",
    so: "Furaha waa inuu ugu yaraan yahay 8 xaraf.",
    ha: "Kalmar sirriya dole ta zama aƙalla haruffa 8.",
    mnk: "Password be kafu jɔɔrɔ 8 kafu.",
    ff: "Ladaa haŋgo, password bɛ 8.",
    yo: "Ọrọ aṣínà gbọdọ jẹ o kere ju awọn ohun kikọ 8."
  },
  "Provider hasn't approved": {
    en: "Provider hasn't approved",
    pt: "O provedor não aprovou",
    de: "Der Anbieter hat nicht zugestimmt",
    es: "El proveedor no ha aprobado",
    fr: "Le fournisseur n'a pas approuvé",
    it: "Il fornitore non ha approvato",
    sq: "Furnizuesi nuk ka miratuar",
    tr: "Sağlayıcı onaylamadı",
    sw: "Mtoa huduma hajathibitisha.",
    am: "አቅራቢው አልተመረጠም.",
    om: "Tajaalee hin mirkaneeffamne.",
    ig: "Onye na-enye ọrụ ekwenyebeghị.",
    so: "Bixiyaha ma aysan ansixin.",
    ha: "Mai bayarwa bai amince ba.",
    mnk: "Fayaata ka kafu ka di nda.",
    ff: "Pulaaku mbee wa ka suɓa.",
    yo: "Oluranlowo ko ti fọwọsi."
  },
  "Pick expiration date of the playlist": {
    en: "Pick expiration date of the playlist",
    pt: "Escolha a data de expiração da lista de reprodução",
    de: "Wählen Sie das Ablaufdatum der Wiedergabeliste",
    es: "Seleccione la fecha de vencimiento de la lista de reproducción",
    fr: "Choisissez la date d'expiration de la liste de lecture",
    it: "Scegli la data di scadenza della playlist",
    sq: "Zgjidh datën e skadimit të listës së riprodhimit",
    tr: "Oynatma listesinin sonlandırma tarihini seçin",
    sw: "Chagua tarehe ya kumalizika ya orodha ya nyimbo.",
    am: "እባኮትን በስምምነት ቀን ይምረጡ፡፡",
    om: "Guyyaa dhuma qaraxii faayilota kee fili.",
    ig: "Biko, họrọ ụbọchị ngwụcha nke ndekọ egwu.",
    so: "Fadlan, dooro taariikhda dhicitaanka liiska ciyaaraha.",
    ha: "Da fatan, zaɓi ranar karewar jerin waƙoƙinku.",
    mnk: "Kege feeɓee, ko la finse fiile ha lɔɔ.",
    ff: "Mi yiye, mi yaa finse fooroo na tan naata.",
    yo: "Jọwọ, yan ọjọ ipari ti atokọ orin."
  },
  "Please select the type of your server": {
    en: "Please select the type of your server",
    pt: "Por favor, selecione o tipo do seu servidor",
    de: "Bitte wählen Sie den Servertyp aus",
    es: "Por favor, seleccione el tipo de su servidor",
    fr: "Veuillez sélectionner le type de votre serveur",
    it: "Per favore, seleziona il tipo del tuo server",
    sq: "Ju lutemi zgjidhni llojin e serverit tuaj",
    tr: "Lütfen sunucunuzun türünü seçin",
    sw: "Tafadhali chagua aina ya seva yako.",
    am: "እባኮትን የሰርቨርዎን አይነት ምረጡ፡፡",
    om: "Baga suni, gargarraa seerfara kee filadhu.",
    ig: "Biko, họrọ ụdị sava gị.",
    so: "Fadlan, dooro nooca serverkaaga.",
    ha: "Da fatan, zaɓi nau'in uwar garkenku.",
    mnk: "Kege feeɓee, ko be la finse gi seerfara yaa.",
    ff: "Mi yiye, mi yaa finse fooroo na tan seerferi.",
    yo: "Jọwọ, yan iru olupin rẹ."
  },
  "Please, contact us in case of any changes": {
    en: "Please, contact us in case of any changes.",
    pt: "Por favor, entre em contato conosco em caso de qualquer alteração.",
    de: "Bitte kontaktieren Sie uns im Falle von Änderungen.",
    es: "Por favor, contáctenos en caso de cualquier cambio.",
    fr: "Veuillez nous contacter en cas de modifications.",
    it: "Per favore, contattaci in caso di modifiche.",
    sq: "Ju lutemi, na kontaktoni në rast ndonjë ndryshimi.",
    tr: "Lütfen herhangi bir değişiklik durumunda bizimle iletişime geçin.",
    sw: "Tafadhali, wasiliana nasi endapo kutakuwa na mabadiliko yoyote.",
    am: "እባኮትን ማንኛውንም ለውጦች እባኮትን እንኩዋን እንደተለመደ፡፡",
    om: "Baga suni, nutti hidhii keessi jijjiirraa hunda.",
    ig: "Biko, kpọtụrụ anyị ma ọ bụrụ na e nwere mgbanwe ọ bụla.",
    so: "Fadlan, nala soo xiriir haddii ay jiraan wax isbeddel ah.",
    ha: "Da fatan, tuntuɓi mu idan akwai canje-canje ko wanne.",
    mnk: "Jeng wooroo, karey kege lefeeroriya boronka lan.",
    ff: "Fii faami, nji binta yaa lefi sameya fewrayo janna.",
    yo: "Jọwọ, kan si wa ti iyipada eyikeyi ba o ba wa."
  },
  "Please select the server you want to migrate": {
    en: "Please select the server you want to migrate from and the new server you want to migrate to!",
    pt: "Por favor, selecione o servidor de origem e o novo servidor para migrar!",
    de: "Bitte wählen Sie den Server aus, von dem Sie migrieren möchten, und den neuen Server, zu dem Sie migrieren möchten!",
    es: "Por favor, seleccione el servidor del que desea migrar y el nuevo servidor al que desea migrar.",
    fr: "Veuillez sélectionner le serveur à partir duquel vous souhaitez migrer et le nouveau serveur vers lequel vous souhaitez migrer !",
    it: "Per favore, seleziona il server da cui vuoi migrare e il nuovo server in cui vuoi migrare!",
    sq: "Ju lutemi zgjidhni serverin nga të cilit dëshironi të migratoni dhe serverin e ri ku dëshironi të migroni!",
    tr: "Lütfen taşımak istediğiniz sunucuyu seçin ve taşımak istediğiniz yeni sunucuyu seçin!",
    sw: "Tafadhali chagua seva unayotaka kuhamia kutoka na seva mpya unayotaka kuhamia kwayo!",
    am: "እባኮትን ከእርስዎ ማምለሻ ላይ የሚጠቀሙትን ሰርቨር እና ወደ አዲሱ ሰርቨር ማምለሻ እንዲደርስ እባኮትን ይምረጡ!",
    om: "Maaf, akka fe'umsaa seera aadaa kana saagaa fi bakka waliin beeku hojjachuu barbaaddan keessatti mul'atu!",
    ig: "Biko họrọ sava na-achọsi ike ịgbanwee na sava ọhụrụ ị ga-achọ ịgbanwee!",
    so: "Fadlan dooro server-ka aad rabto inaad ka guurto iyo server cusub ee aad rabto inaad guurto!",
    ha: "Da fatan zabi sabar daga wanda kake son canza daga gare shi da sabon sabar da kake son canza zuwa!",
    mnk: "Taba ja kafu, bayidi suge biya furora lefiɓo ɓam furora",
    ff: "Fii faamii yi ɓuri a faa, kadi e beyhe kadi e feere nyaari laamu gooyte!",
    yo: "Jọwọ yan olupin ti o fẹ lati yipada lati ọdọ rẹ ati olupin tuntun ti o fẹ lati yipada si!"
  },
  "Please enter the Device key": {
    en: "Please enter the Device key. It is shown in the bottom left corner of the device.",
    pt: "Por favor, insira a chave do dispositivo. Ela é mostrada no canto inferior esquerdo do dispositivo.",
    de: "Bitte geben Sie den Geräteschlüssel ein. Er wird unten links auf dem Gerät angezeigt.",
    es: "Por favor, ingrese la clave del dispositivo. Se muestra en la esquina inferior izquierda del dispositivo.",
    fr: "Veuillez entrer la clé de l'appareil. Elle est affichée dans le coin inférieur gauche de l'appareil.",
    it: "Per favore, inserisci la chiave del dispositivo. È mostrata nell'angolo in basso a sinistra del dispositivo.",
    sq: "Ju lutemi vendosni çelësin e pajisjes. Ai shfaqet në cepin e majtë të fundit të pajisjes.",
    tr: "Lütfen Aygıt anahtarını girin. Aygıtın sol alt köşesinde gösterilmektedir.",
    sw: "Tafadhali ingiza funguo ya kifaa. Inavyoonyeshwa kwenye kona ya chini kushoto ya kifaa.",
    am: "እባኮትን የመሳሪያ ቁልፍ አስገባ። በመሳሪያ በታች ግራ ማንበብ ቦታ ታይቷል።",
    om: "Maaf, gatii moofataa galchii. Iddo isa jalqabaa gadi fagoo gubbaa miidiyaa keessatti argama.",
    ig: "Biko tinye igodo ngwaọrụ ahụ. Ọ na-egosi na nkwojiaka aka ekpe nke ngwaọrụ.",
    so: "Fadlan geli furaha qalabka. Waxaa lagu muujiyey geeska hoose ee bidix ee qalabka.",
    ha: "Da fatan shigar maɓallin na'ura. Ana nuna shi a kusurwar haguun ƙasa na na'urar.",
    mnk: "Nye ko tabala ko. A be fe suge kunu kafu.",
    ff: "Nji ma na koomaali na. Kadi e feere nene a ñaari laamu.",
    yo: "Jọwọ tẹ bọtini ẹrọ. O han ni igun isalẹ osi ẹrọ."
  },

  "Please input": {
    en: "Please input",
    pt: "Por favor, insira",
    de: "Bitte eingeben",
    es: "Por favor, ingrese",
    fr: "Veuillez entrer",
    it: "Per favore, inserire",
    sq: "Ju lutemi vendosni",
    tr: "Lütfen girin",
    sw: "Tafadhali ingiza",
    am: "እባኮትን እንዲሁ አስገባ",
    om: "Maaf, galchii",
    ig: "Biko tinye",
    so: "Fadlan geli",
    ha: "Da fatan shigar",
    mnk: "Nye ko",
    ff: "Nji ma",
    yo: "Jọwọ tẹ"
  },
  Payments: {
    en: "Payments",
    pt: "Pagamentos",
    de: "Zahlungen",
    es: "Pagos",
    fr: "Paiements",
    it: "Pagamenti",
    sq: "Pagesat",
    tr: "Ödemeler",
    sw: "Malipo",
    am: "ክፍያዎች",
    om: "Kaffaltii",
    ig: "Ụgwọ",
    so: "Bixinta",
    ha: "Biya",
    mnk: "Kafu",
    ff: "Ñaari",
    yo: "Isanwo"
  },
  "Pay with BTC": {
    en: "Pay with BTC",
    pt: "Pagar com BTC",
    de: "Mit BTC bezahlen",
    es: "Pagar con BTC",
    fr: "Payer avec BTC",
    it: "Paga con BTC",
    sq: "Paguaj me BTC",
    tr: "BTC ile öde",
    sw: "Lipa kwa BTC",
    am: "ከBTC ጋር ክፍያ",
    om: "Kaffaltii waliin BTC",
    ig: "Kwụrụ ụgwọ site na BTC",
    so: "Bixinta adigoo isticmaalaya BTC",
    ha: "Biya ta hanyar amfani da BTC",
    mnk: "Baa BTC kafu",
    ff: "Ñaari e BTC",
    yo: "Sanwo pẹlu BTC"
  },
  "Pay with Coinbase": {
    en: "Pay with Coinbase",
    pt: "Pagar com Coinbase",
    de: "Mit Coinbase bezahlen",
    es: "Pagar con Coinbase",
    fr: "Payer avec Coinbase",
    it: "Paga con Coinbase",
    sq: "Paguaj me Coinbase",
    tr: "Coinbase ile öde",
    sw: "Lipa kwa Coinbase",
    am: "ከCoinbase ጋር ክፍያ",
    om: "Kaffaltii waliin Coinbase",
    ig: "Kwụrụ ụgwọ site na Coinbase",
    so: "Bixinta adigoo isticmaalaya Coinbase",
    ha: "Biya ta hanyar amfani da Coinbase",
    mnk: "Baa Coinbase kafu",
    ff: "Ñaari e Coinbase",
    yo: "Sanwo pẹlu Coinbase"
  },
  "Pay with PIX": {
    en: "Pay with PIX",
    pt: "Pagar com PIX",
    de: "Zahlen Sie mit PIX",
    es: "Pagar con PIX",
    fr: "Payer avec PIX",
    it: "Paga con PIX",
    sq: "Paguaj me PIX",
    tr: "PIX ile öde",
    sw: "Lipa kwa PIX",
    am: "ከPIX ጋር ክፍያ",
    om: "Kaffaltii waliin PIX",
    ig: "Kwụrụ ụgwọ site na PIX",
    so: "Bixinta adigoo isticmaalaya PIX",
    ha: "Biya ta hanyar amfani da PIX",
    mnk: "Baa PIX kafu",
    ff: "Ñaari e PIX",
    yo: "Sanwo pẹlu PIX"
  },
  "Pay with PIX card": {
    en: "Pay with PIX card",
    pt: "Pagar com cartão PIX",
    de: "Mit PIX-Karte bezahlen",
    es: "Pagar con tarjeta PIX",
    fr: "Payer avec la carte PIX",
    it: "Paga con la carta PIX",
    sq: "Paguaj me kartën PIX",
    tr: "PIX kart ile öde",
    sw: "Lipa kwa kadi ya PIX",
    am: "ከPIX ካርድ ጋር ክፍያ",
    om: "Kaffaltii waliin kaardii PIX",
    ig: "Kwụrụ ụgwọ site na kaadị PIX",
    so: "Bixinta adigoo isticmaalaya kaarka PIX",
    ha: "Biya ta hanyar amfani da kati na PIX",
    mnk: "Baa PIX kaardi kafu",
    ff: "Ñaari e PIX kaard",
    yo: "Sanwo pẹlu kaadi PIX"
  },
  "Paymant method not avalible now": {
    en: "Paymant method not avalible now",
    pt: "Método de pagamento não disponível agora",
    de: "Zahlungsmethode jetzt nicht verfügbar",
    es: "Método de pago no disponible ahora",
    fr: "Méthode de paiement non disponible maintenant",
    it: "Metodo di pagamento non disponibile ora",
    sq: "Metoda e pagesës nuk është e disponueshme tani",
    tr: "Ödeme yöntemi şu anda mevcut değil",
    sw: "Njia ya malipo haitopatikana sasa",
    am: "እባኮት አሁን አልተገኘም እንደ መንገድ",
    om: "Kaffaltii hin jiruu ammaa",
    ig: "Ụzọ ịkwụ ụgwọ adịghị ugbu a",
    so: "Habka lacag bixinta hadda ma heli kartid",
    ha: "Hanyar biya ba a samuwa yanzu",
    mnk: "Kafu fɔɔyi ka bulo le kafu",
    ff: "Jaaɓaandi ndaari ko hoore",
    yo: "Ọna isanwo ko wa niwọn igba bayi"
  },
  Pay: {
    en: "Pay",
    pt: "Pagar",
    de: "Bezahlen",
    es: "Pagar",
    fr: "Payer",
    it: "Pagare",
    sq: "Paguaj",
    tr: "Öde",
    sw: "Lipa",
    am: "ክፍያ",
    om: "Baasu",
    ig: "Kwụọ ụgwọ",
    so: "Bixinta",
    ha: "Biya",
    mnk: "Baa",
    ff: "Ñaari",
    yo: "Sanwo"
  },
  Poster: {
    en: "Poster",
    pt: "Pôster",
    de: "Plakat",
    es: "Póster",
    fr: "Affiche",
    it: "Poster",
    sq: "Poster",
    tr: "Afiş",
    sw: "Posta",
    am: "ፖስተር",
    om: "Phooositerii",
    ig: "Pọsịtạ",
    so: "Boodhka",
    ha: "Tafiya",
    mnk: "Booro",
    ff: "Posteeri",
    yo: "Fọsita"
  },
  "Privacy policy": {
    en: "Privacy policy",
    pt: "Política de privacidade",
    de: "Datenschutzbestimmungen",
    es: "Política de privacidad",
    fr: "Politique de confidentialité",
    it: "Informativa sulla privacy",
    sq: "Politika e privatësisë",
    tr: "Gizlilik politikası",
    sw: "Sera ya faragha",
    am: "ፕራይቬሲ ፖሊሲ",
    om: "Siyaasa eegumsa dhuunfaa",
    ig: "Atụmatụ nzuzo",
    so: "Siyasadda qarsoodiga",
    ha: "Dokar sirri",
    mnk: "Bulo kafu",
    ff: "Politik privacy",
    yo: "Ilana asiri"
  },

  //t
  "Thank you for trying our service! If you enjoyed your experience and want to continue accessing all premium features, please contact us for more information on our subscription plans. We're here to help you make the most of your IPTV experience":
    {
      en: "Thank you for trying our service! If you enjoyed your experience and want to continue accessing all premium features, please contact us for more information on our subscription plans. We're here to help you make the most of your IPTV experience.",
      pt: "Obrigado por experimentar o nosso serviço! Se você gostou da sua experiência e deseja continuar acessando todos os recursos premium, entre em contato conosco para mais informações sobre nossos planos de assinatura. Estamos aqui para ajudá-lo a aproveitar ao máximo sua experiência com IPTV.",
      de: "Vielen Dank, dass Sie unseren Service ausprobiert haben! Wenn Sie Ihre Erfahrung genossen haben und weiterhin auf alle Premium-Funktionen zugreifen möchten, kontaktieren Sie uns bitte für weitere Informationen zu unseren Abonnementplänen. Wir sind hier, um Ihnen zu helfen, das Beste aus Ihrer IPTV-Erfahrung herauszuholen.",
      es: "¡Gracias por probar nuestro servicio! Si disfrutó su experiencia y desea continuar accediendo a todas las funciones premium, contáctenos para obtener más información sobre nuestros planes de suscripción. Estamos aquí para ayudarle a aprovechar al máximo su experiencia de IPTV.",
      fr: "Merci d'avoir essayé notre service ! Si vous avez apprécié votre expérience et souhaitez continuer à accéder à toutes les fonctionnalités premium, veuillez nous contacter pour plus d'informations sur nos plans d'abonnement. Nous sommes là pour vous aider à tirer le meilleur parti de votre expérience IPTV.",
      it: "Grazie per aver provato il nostro servizio! Se ti è piaciuta la tua esperienza e vuoi continuare ad accedere a tutte le funzionalità premium, contattaci per ulteriori informazioni sui nostri piani di abbonamento. Siamo qui per aiutarti a sfruttare al massimo la tua esperienza IPTV.",
      sq: "Faleminderit që provuat shërbimin tonë! Nëse keni shijuar përvojën tuaj dhe dëshironi të vazhdoni të keni qasje në të gjitha veçoritë premium, ju lutemi na kontaktoni për më shumë informacion mbi planet tona të abonimit. Ne jemi këtu për t'ju ndihmuar të përfitoni sa më shumë nga përvoja juaj IPTV.",
      tr: "Hizmetimizi denediğiniz için teşekkür ederiz! Deneyiminizi beğendiyseniz ve tüm premium özelliklere erişmeye devam etmek istiyorsanız, lütfen abonelik planlarımız hakkında daha fazla bilgi için bizimle iletişime geçin. IPTV deneyiminizden en iyi şekilde yararlanmanıza yardımcı olmak için buradayız.",
      sw: "Asante kwa kujaribu huduma yetu! Ikiwa ulifurahiya uzoefu wako na unataka kuendelea kupata vipengele vyote vya malipo, tafadhali wasiliana nasi kwa habari zaidi kuhusu mipango yetu ya usajili. Tuko hapa kusaidia ili upate manufaa zaidi ya uzoefu wako wa IPTV.",
      am: "እናመሰግናለን ለእርስዎ አገልግሎታችንን ማምለኪያ! እባኮትን በእንቅስቃሴዎ ላይ ስለሆነ ተስፋ እንዲቀጥሉ የፕሪምየም ባለጋራ ባለኝን ሁሉንም እባኮት በእንቅስቃሴዎ ላይ እንዲቀጥሉ ይከፍሉ በግል ምስጋና ስለማሳየት ስለማሳየት!",
      om: "Galatoomi tajaajila keenya eeguuf! Yeroo yaalii keessan gammachuun waan jiru, dhugaan jaalala fi gargaarsagahannu ni argachuu fi qaama fiixa ittiafi irratti uumaa.",
      ig: "Daalụ maka ịnwale ọrụ anyị! Ọ bụrụ na ị nwere afọ ojuju na ahụmịhe gị ma chọọ ịga n'ihu iji nweta atụmatụ niile dị elu, biko kpọtụrụ anyị maka ozi ndị ọzọ gbasara atụmatụ nchịkọta anyị. Anyị nọ ebe a iji nyere gị aka mee ka ahụmịhe IPTV gị bụrụ nke kachasị.",
      so: "Waad ku mahadsan tahay inaad tijaabiso adeegayaga! Haddii aad ku raaxaysatay waayo-aragnimadaada oo aad rabto inaad sii wadato inaad gasho dhammaan astaamaha lacagta, fadlan nala soo xiriir si aad u hesho macluumaad dheeri ah oo ku saabsan qorshayaasha rukhsadda. Waxaan halkaan u joognaa inaan kaa caawino inaad ka faa'iidaysato waayo-aragnimada IPTV-gaaga.",
      ha: "Mun gode don gwada sabis ɗinmu! Idan kuna jin daɗin ƙwarewar ku kuma kuna son ci gaba da samun damar duk siffofin ƙirar kuɗi, don Allah ku tuntuɓe mu don ƙarin bayani game da shirin biyan kuɗi. Muna nan don taimaka muku yin amfani da ƙwarewar IPTV ɗinku.",
      mnk: "Ndaapoo taŋo kafu ni bonge goore leya! Mbinnaa be tegga leteefu mbindi e kafuŋɔ betee kɔ kafu fi mini mansa kafu.",
      ff: "Mi yettude ko waawtuɗo haɗaaji! Ko ndin heen tanaale jaɓe ngel!",
      yo: "E dupe fun igbiyanju iṣẹ wa! Ti o ba ti ni itẹlọrun pẹlu iriri rẹ ati pe o fẹ lati tẹsiwaju lati ni iraye si gbogbo awọn ẹya ọjọgbọn, jọwọ kan si wa fun alaye diẹ sii lori awọn ero ìforúkọsílẹ wa. A wa nibi lati ran ọ lọwọ lati ni iriri IPTV rẹ daradara."
    },
  "Thank you for your prompt attention": {
    en: "Thank you for your prompt attention",
    pt: "Obrigado pela sua pronta atenção",
    de: "Danke für Ihre umgehende Aufmerksamkeit",
    es: "Gracias por su pronta atención",
    fr: "Merci pour votre attention rapide",
    it: "Grazie per la tua pronta attenzione",
    sq: "Faleminderit për vëmendjen tuaj të shpejtë",
    tr: "İlginiz için teşekkür ederim",
    sw: "Asante kwa umakini wako wa haraka",
    am: "እናመሰግናለን ለፍጥነት ትኩረትዎ",
    om: "Galatooma xiyyeeffannaa yeroo dheeraaf",
    ig: "Daalụ maka ịmara nke ọma",
    so: "Mahadsanid xiisaddaada degdegga ah",
    ha: "Mun gode don kulawar ku cikin sauri",
    mnk: "Ndaapoo taŋo kafu yooloo",
    ff: "Ajarama kadi hitiyaa",
    yo: "E dupe fun itọju rẹ ni kiakia"
  },
  "to remove seller status": {
    en: "to remove seller status",
    pt: "remover o status de vendedor",
    de: "den Verkäuferstatus entfernen",
    es: "eliminar el estado de vendedor",
    fr: "retirer le statut de vendeur",
    it: "rimuovere lo status di venditore",
    sq: "për të hequr statusin e shitësit",
    tr: "satıcı statüsünü kaldırmak",
    sw: "Kuondoa hadhi ya muuzaji",
    am: "ለሻጭ ሁኔታ ማስወገድ",
    om: "Haala gurgurtaa kaasuuf",
    ig: "Iwepụ ọnọdụ onye ahịa",
    so: "Ka saar xaaladda iibiyaha",
    ha: "Cire xaaladda iibiyaha",
    mnk: "Jomoo seller status",
    ff: "Yeeso seller status",
    yo: "Lati yọ ipo olutaja"
  },
  "The dimensions of the file are": {
    en: "The dimensions of the file are",
    es: "Las dimensiones del archivo son",
    fr: "Les dimensions du fichier sont",
    de: "Die Abmessungen der Datei sind",
    pt: "As dimensões do arquivo são",
    it: "Le dimensioni del file sono",
    sq: "Përmasat e skedarit janë",
    tr: "Dosyanın boyutları şunlardır",
    sw: "Vipimo vya faili ni",
    am: "መጠቀም ስርዓት",
    om: "Itti ga'umsa faayilii",
    ig: "Nchekwa faịlụ bụ",
    so: "Cabbirka faylka waa",
    ha: "Girman fayil ɗin shi ne",
    mnk: "Faayiluu baaxaa la",
    ff: "Mbay le file ndee",
    yo: "Iwọn faili ni"
  },

  "Terms of usage": {
    en: "Terms of usage",
    pt: "Termos de uso",
    de: "Nutzungsbedingungen",
    es: "Términos de uso",
    fr: "Conditions d'utilisation",
    it: "Termini di utilizzo",
    sq: "Kushtet e përdorimit",
    tr: "Kullanım şartları",
    sw: "Masharti ya matumizi",
    am: "መጠቀም ስርዓት",
    om: "Seera hojii",
    ig: "Okwu nke ojiji",
    so: "Shuruudaha isticmaalka",
    ha: "Sharuɗɗan amfani",
    mnk: "Bàkɔfɔndɔ le julu",
    ff: "Dokkitaaji ɓe miijji",
    yo: "Ẹ̀sùn ìlò"
  },

  "Total free trial and active": {
    en: "Total free trial and active",
    pt: "Total de teste grátis e ativo",
    de: "Gesamte kostenlose Testversion und aktiv",
    es: "Total de prueba gratuita y activo",
    fr: "Essai gratuit total et actif",
    it: "Totale prova gratuita e attiva",
    sq: "Totali i provave të lira dhe aktiv",
    tr: "Toplam ücretsiz deneme ve aktif",
    sw: "Jumla ya jaribio la bure na la kazi",
    am: "ጠቅላላ ነፃ ሙከራ እና አንቀሳቃሽ",
    om: "Waliigala waltajjii fi hojii",
    ig: "Ọnụọgụ niile nke nnwale efu na nke dị ndụ",
    so: "Wadarada tijaabooyinka bilaashka ah iyo kuwa firfircoon",
    ha: "Jimillar gwaji kyauta da aiki",
    mnk: "Jamaa free trial kafu le active",
    ff: "Sahaa jiɓɓe le free trial e work",
    yo: "Apapọ ìdánwò ọfẹ ati iṣẹ"
  },

  "This platform is currently unavailable to you because your partner has not subscribed yet. If you want to access this platform, please reach out to your partner":
    {
      en: "This platform is currently unavailable to you because your partner has not subscribed yet. If you want to access this platform, please reach out to your partner.",
      pt: "Esta plataforma está atualmente indisponível para você porque seu parceiro ainda não se inscreveu. Se você deseja acessar esta plataforma, por favor, entre em contato com seu parceiro.",
      de: "Diese Plattform steht Ihnen derzeit nicht zur Verfügung, da Ihr Partner sich noch nicht angemeldet hat. Wenn Sie auf diese Plattform zugreifen möchten, setzen Sie sich bitte mit Ihrem Partner in Verbindung.",
      es: "Esta plataforma no está disponible actualmente para ti porque tu compañero aún no se ha suscrito. Si deseas acceder a esta plataforma, por favor, ponte en contacto con tu compañero.",
      fr: "Cette plateforme n'est actuellement pas disponible pour vous car votre partenaire ne s'est pas encore abonné. Si vous souhaitez accéder à cette plateforme, veuillez contacter votre partenaire.",
      it: "Questa piattaforma non è attualmente disponibile per te perché il tuo partner non si è ancora iscritto. Se desideri accedere a questa piattaforma, per favore, contatta il tuo partner.",
      sq: "Ky platformë është momentalisht e padisponueshme për ju sepse partneri juaj ende nuk është abonuar. Nëse dëshironi të hyni në këtë platformë, ju lutemi kontaktoni partnerin tuaj.",
      tr: "Bu platform şu anda size uygun değil çünkü ortağınız henüz abone olmadı. Bu platforma erişmek istiyorsanız, lütfen ortağınıza ulaşın.",
      sw: "Jukwaa hili kwa sasa halipatikani kwako kwa sababu mshirika wako bado haja jiandikisha. Ikiwa unataka kufikia jukwaa hili, tafadhali wasiliana na mshirika wako.",
      am: "ይህ መድረክ በአሁኑ ጊዜ ለእርስዎ አይገኝም ምክንያቱም አጋሩ ገና አልተመዘገበም። ይህንን መድረክ ለመድረስ ከፈለጉ እባክዎን ከአጋሩ ጋር ይደውሉለት።",
      om: "Sagantaan kun amma siif hin jiraatu sababni isaas hirmaattonni kee hanga ammaatti hin galmoofne. Sagantaa kana fayyadamuu yoo barbaadde, hirmaataa keetti dhiyaadhu.",
      ig: "Ebe a adịghị ugbu a n’aka gị n’ihi na onye òtù gị akwụghị ụgwọ ugbu a. Ọ bụrụ na ịchọrọ ịnweta ebe a, biko kpọtụrụ onye òtù gị.",
      so: "Madaladan kani waa lagaa heli karin sababtoo ah lamaanahaagu wali isdiiwaangilin. Haddii aad rabto inaad hesho madaladan, fadlan la xiriir lamaanahaaga.",
      ha: "Dandalin nan baya samuwa a gareka a halin yanzu saboda abokin kasuwancinka bai yi rijista ba tukuna. Idan kana son samun damar shiga dandalin nan, don Allah ka tuntuɓi abokinka.",
      mnk: "La platformo laa bee nee taaman le fɔ, fo partnero le ta bota si fo koo ta bɛ sabati la. Mu n naŋ la, n naŋ kan partner la kunu.",
      ff: "Platform nji don mi waale no waɗaako e jooni yo anndoyum jogii makko. To a yiɗi waɗɗude platform nden, ndaarndi to anndoyma makko.",
      yo: "Àyè yìí kò sí fún ọ lọ́wọ́ nítorí ọ̀rẹ́ rẹ kò tíì forúkọ sílẹ̀. Bí o bá fẹ́ wọlé sí àyè yìí, jọ̀wọ́ bá ọ̀rẹ́ rẹ sọ̀rọ̀."
    },

  Type: {
    en: "Type",
    pt: "Tipo",
    de: "Typ",
    es: "Tipo",
    fr: "Type",
    it: "Tipo",
    sq: "Lloji",
    tr: "Tür",
    sw: "Aina",
    am: "አይነት",
    om: "Gosa",
    ig: "Ụdị",
    so: "Nooca",
    ha: "Nau'i",
    mnk: "Kafu",
    ff: "Ligi",
    yo: "Iru"
  },

  Title: {
    en: "Title",
    pt: "Título",
    de: "Titel",
    es: "Título",
    fr: "Titre",
    it: "Titolo",
    sq: "Titulli",
    tr: "Başlık",
    sw: "Kichwa",
    am: "ርዕስ",
    om: "Mata-duree",
    ig: "Aha",
    so: "Cinwaan",
    ha: "Suna",
    mnk: "Kandiroo",
    ff: "Tiitoo",
    yo: "Àkọlé"
  },

  Total: {
    en: "Total",
    pt: "Total",
    de: "Gesamt",
    es: "Total",
    fr: "Total",
    it: "Totale",
    sq: "Totali",
    tr: "Toplam",
    sw: "Jumla",
    am: "ጠቅላላ",
    om: "Walumaagalatti",
    ig: "Ngụkọ",
    so: "Wadarta",
    ha: "Jimla",
    mnk: "Wuru",
    ff: "Juunii",
    yo: "Lapapọ̀"
  },

  Trial: {
    en: "Trial",
    pt: "Teste",
    de: "Test",
    es: "Prueba",
    fr: "Essai",
    it: "Prova",
    sq: "Provë",
    tr: "Deneme",
    sw: "Jaribio",
    am: "ሙከራ",
    om: "Yaalii",
    ig: "Nnwale",
    so: "Tijaabo",
    ha: "Jarrabawa",
    mnk: "Kununnoo",
    ff: "Ɗeƴno",
    yo: "Àdánwò"
  },
  "Top Movies": {
    en: "Top Movies",
    pt: "Melhores Filmes",
    de: "Top-Filme",
    es: "Películas Populares",
    fr: "Meilleurs Films",
    it: "I Migliori Film",
    sq: "Filmat më të Mirë",
    tr: "En İyi Filmler",
    sw: "Filamu Bora",
    am: "ከፍተኛ ፊልሞች",
    om: "Fiilmota Oliya",
    ig: "Egwuregwu Top",
    so: "Filimada ugu sareeya",
    ha: "Manyan Finafinai",
    mnk: "Top Ke le",
    ff: "Dowre Filimaaji leɗɗe",
    yo: "Àwọn Fíìmù Tó Ga Júlọ"
  },

  "Top Lives": {
    en: "Top Lives",
    pt: "Melhores Canais",
    de: "Top-Lives",
    es: "Vidas Destacadas",
    fr: "Meilleures Lives",
    it: "Live Top",
    sq: "Lives më të Mirë",
    tr: "En İyi Yayınlar",
    sw: "Matukio Bora ya Moja kwa Moja",
    am: "ከፍተኛ ቀጥታ ፕሮግራሞች",
    om: "Sirna Gulaalaa Oliya",
    ig: "Ndụ Ndụ Top",
    so: "Nolosha ugu sareysa",
    ha: "Manyan Shirye-shiryen Kai Tsaye",
    mnk: "Top Kunna la",
    ff: "Dowre Gulliɓe leɗɗe",
    yo: "Àwọn Ẹ̀mí Tó Ga Júlọ"
  },

  "Top TV Shows": {
    en: "Top TV Shows",
    pt: "Melhores Séries",
    de: "Top-Fernsehsendungen",
    es: "Mejores Programas de TV",
    fr: "Meilleures Émissions de Télévision",
    it: "Migliori Programmi TV",
    sq: "Shfaqje televizive më të Mirë",
    tr: "En İyi TV Şovları",
    sw: "Vipindi Bora vya TV",
    am: "ከፍተኛ የቴሌቪዥን ትርእሶች",
    om: "Sagantaalee TV kan duraa",
    ig: "Ntọala Ụdọbọ TV",
    so: "Barnaamijyada TV ee ugu sarreeya",
    ha: "Manyan Shirye-Shiryen TV",
    mnk: "Top TV Show la",
    ff: "Dowre TV ɗe leɗɗe",
    yo: "Àwọn Ètò Tẹlifísàn Tó Ga Júlọ"
  },

  "Take Credits": {
    en: "Take Credits",
    pt: "Adquirir Créditos",
    de: "Credits Erwerben",
    es: "Obtener Créditos",
    fr: "Obtenir des Crédits",
    it: "Acquista Crediti",
    sq: "Merr Kredite",
    tr: "Kredi Al",
    sw: "Chukua Krediti",
    am: "ክሬዲቶች ይውሰዱ",
    om: "Kireeditii fudhadhu",
    ig: "Were Kredi",
    so: "Qaado Dhibcaha",
    ha: "Dauki Kiredit",
    mnk: "Kireediŋo la fa",
    ff: "Ngoodi ɗeeɓi",
    yo: "Gba kírẹ́dítì"
  },

  "Transaction ID": {
    en: "Transaction ID",
    pt: "ID da Transação",
    de: "Transaktions-ID",
    es: "ID de Transacción",
    fr: "ID de Transaction",
    it: "ID Transazione",
    sq: "ID e Transaksionit",
    tr: "İşlem Kimliği",
    sw: "Kitambulisho cha Muamala",
    am: "የግብይት መታወቂያ",
    om: "Mallattoo Deebii",
    ig: "ID azụmahịa",
    so: "Aqoonsiga Macaamilka",
    ha: "Lambar Tabbatarwa ta Mu’amala",
    mnk: "Transaction ID",
    ff: "Nattaande ngurndugol",
    yo: "ID ìṣòwò"
  },

  "Total activations": {
    en: "Total activations",
    pt: "Ativações Totais",
    de: "Gesamtaktivierungen",
    es: "Activaciones Totales",
    fr: "Activations Totales",
    it: "Attivazioni Totali",
    sq: "Aktivizime totale",
    tr: "Toplam Aktivasyon",
    sw: "Jumla ya miamsho",
    am: "ጠቅላላ እንቅስቃሴዎች",
    om: "Walii gala hoji keessaa ka’umsa",
    ig: "Nkata mmalite niile",
    so: "Wadarta aktifad",
    ha: "Jimillar tashoshin kunnawa",
    mnk: "Ka suma dulaa la kalatiyalu",
    ff: "Danndu ɗe njaltuge",
    yo: "Àpapọ̀ ìrànwọ́ ṣíṣe"
  },
  "Trial expired": {
    en: "Trial expired",
    pt: "Período de teste expirado",
    de: "Testphase abgelaufen",
    es: "Prueba expirada",
    fr: "Essai expiré",
    it: "Prova scaduta",
    sq: "Prova ka skaduar",
    tr: "Deneme süresi dolmuş",
    sw: "Muda wa majaribio umeisha",
    am: "የሙከራ ጊዜ አልቆል",
    om: "Yeroo qormaata xumurame",
    ig: "Oge ule agwụla",
    so: "Waqtiga tijaabo waa dhacay",
    ha: "Lokacin gwaji ya ƙare",
    mnk: "Looni fuɓɓe kafu bɛbɛ ka sɛbɛ",
    ff: "Ñeewnde hakki waawinaama",
    yo: "Àkókò ìdánwò ti parí"
  },

  "Trial remaining days": {
    en: "Trial remaining days",
    pt: "Dias de teste restantes",
    de: "Verbleibende Testtage",
    es: "Días de Prueba Restantes",
    fr: "Jours d'Essai Restants",
    it: "Giorni di Prova Rimasti",
    sq: "Ditët e mbetura të provës",
    tr: "Kalan deneme günleri",
    sw: "Siku zilizosalia za majaribio",
    am: "ቀሪ ቀናት ሙከራ",
    om: "Guyyaa hojii qabsoo hafe",
    ig: "Ụbọchị fọdụrụ na ule",
    so: "Maalmihii tijaabada ee haray",
    ha: "Ranar gwaji da aka saura",
    mnk: "Looni fuɓɓe kafu la tɛrɛ",
    ff: "Ñeewnde hakki ƴeewi",
    yo: "Ọjọ́ ìdánwò to ku"
  },

  "That's not a valid URL": {
    en: "That's not a valid URL",
    pt: "Isso não é uma URL válida",
    de: "Das ist keine gültige URL",
    es: "Esa no es una URL válida",
    fr: "Ce n'est pas une URL valide",
    it: "Questa non è un'URL valida",
    sq: "Ky nuk është një URL e vlefshme",
    tr: "Bu geçerli bir URL değil",
    sw: "Hiyo si URL halali.",
    am: "እሱ ታማኝ ዩኤርኤል አይደለም.",
    om: "Kun hin ta’u URL sirrii miti.",
    ig: "Ọ bụghị URL ziri ezi.",
    so: "Kani ma aha URL sax ahayn.",
    ha: "Wannan ba URL na gaskiya ba ne.",
    mnk: "Kafu la URL ka fɛ bɛne ma aɲa.",
    ff: "Omon doo woroto keewngol URL fa.",
    yo: "Ẹyi kii ṣe URL to wulo."
  },
  "The URL is not reachable": {
    en: "The URL is not reachable.",
    pt: "A URL não está acessível.",
    de: "Die URL ist nicht erreichbar.",
    es: "La URL no es accesible.",
    fr: "L'URL n'est pas accessible.",
    it: "L'URL non è raggiungibile.",
    sq: "URL nuk është i arritshëm.",
    tr: "URL ulaşılamıyor.",
    sw: "URL haiwezi kupatikana.",
    am: "ዩኤርኤል አይሳሰበውም.",
    om: "URL hin argamuu danda’u.",
    ig: "Ụlọ URL adịghị enweta.",
    so: "URL-ga lama gaari karo.",
    ha: "URL ba a iya samu ba.",
    mnk: "URL nyaa ta ɲɛ ɛ bɛ rɔɲo.",
    ff: "URL leɓɓude kadi darsoowal",
    yo: "URL naa kọja."
  },

  "That's not a valid host": {
    en: "That's not a valid host",
    pt: "Isso não é um host válido",
    de: "Das ist kein gültiger Host",
    es: "Eso no es un host válido",
    fr: "Ce n'est pas un hôte valide",
    it: "Questo non è un host valido",
    sq: "Ky nuk është një host i vlefshëm",
    tr: "Bu geçerli bir host değil.",
    sw: "Hiyo si mwenyeji halali",
    am: "እሱ ታማኝ ሆስት አይደለም",
    om: "Kun hin ta’u hosti sirrii miti",
    ig: "Ọ bụghị onye nwere ikike",
    so: "Kani ma aha marti waa sax ma ahayn",
    ha: "Wannan ba mai masauki na gaskiya ba ne",
    mnk: "Kafu la hosti ka fɛ bɛne ma aɲa",
    ff: "Omon doo woroto keewngol fa",
    yo: "Ẹyi kii ṣe olùforúkọ to wulo"
  },

  "TV Shows Categories": {
    en: "TV Shows Categories",
    pt: "Categorias de Séries",
    de: "TV-Sendungskategorien",
    es: "Categorías de Programas de TV",
    fr: "Catégories d'Émissions de Télévision",
    it: "Categorie di Programmi TV",
    sq: "Kategoritë e emisioneve televizive",
    tr: "TV Şovları Kategorileri",
    sw: "Vikundi vya Tamthilia za TV",
    am: "ምድቦች በቲቪ ሾውዎች",
    om: "Kategoriiwwan TV agarsiisa",
    ig: "Ụdị Ihe ngosi TV",
    so: "Qaybaha bandhigyada TV-ga",
    ha: "Rukunan Shahararrun talabijin",
    mnk: "Kiliya TV la kafu",
    ff: "Tombii ngondirɗe TV",
    yo: "Ẹka Awọn Eto TV"
  },
  "There are 2 types of activation": {
    en: "There are 2 types of activation:",
    pt: "Existem 2 tipos de ativação:",
    de: "Es gibt 2 Arten der Aktivierung:",
    es: "Hay 2 tipos de activación:",
    fr: "Il y a 2 types d'activation :",
    it: "Ci sono 2 tipi di attivazione:",
    sq: "Ka 2 lloje të aktivizimit:",
    tr: "2 tür aktivasyon vardır:",
    sw: "Kuna aina 2 za uanzishaji:",
    am: "አንደኛ ባለ፣ ሁለት አይነት አስተካክል፡",
    om: "Jechoowwan lamaa qabu:",
    ig: "Enwere ụdị 2 nke imepụta:",
    so: "Waxaa jira 2 nooc oo firfircoonida ah:",
    ha: "Akwai nau'i 2 na kunnawa:",
    mnk: "Bɛnna fɛnni baa bɔ ye ngomin.",
    ff: "Ngamin moto kɛlë tɛni baati shila",
    yo: "Àwọn irú ìmúlò 2 wa:"
  },

  "The two passwords that you entered do not match": {
    en: "The two passwords that you entered do not match!",
    pt: "As duas senhas que você digitou não coincidem!",
    de: "Die beiden Passwörter, die Sie eingegeben haben, stimmen nicht überein!",
    es: "Las dos contraseñas que ingresó no coinciden!",
    fr: "Les deux mots de passe que vous avez saisis ne correspondent pas !",
    it: "Le due password che hai inserito non corrispondono!",
    sq: "Dy fjalëkalimet që keni shkruar nuk përputhen!",
    tr: "Girdiğiniz iki şifre eşleşmiyor!",
    sw: "Manenosiri mawili uliyoyeka hayafanani!",
    am: "ሁለቱ ፕስዎድ እንደተለያዩ ተለያዩ ናቸው!",
    om: "Jechoowwan lamaa galchite hin walfakkaatan!",
    ig: "Okwu paswọọdụ abụọ ị tinyeghị anaghị adabara!",
    so: "Labada erayga sirta ah ee aad gelisay ma isku mid ahayn!",
    ha: "Kalmar sirri guda biyu da kuka shigar ba su dace ba!",
    mnk: "Faya password du fɔrɔ tuŋ mansa sa la kafu.",
    ff: "Gaalii wono nafasuɗe ka finndii",
    yo: "Àwọn pàsìwọ̀dù méjì tí o tẹ̀ sílẹ̀ kò péjọ!"
  },

  "Tell us your email and we will send your password": {
    en: "Tell us your email and we will send your password.",
    pt: "Informe-nos seu e-mail e enviaremos sua senha.",
    de: "Geben Sie uns Ihre E-Mail-Adresse an, und wir senden Ihnen Ihr Passwort.",
    es: "Díganos su correo electrónico y le enviaremos su contraseña.",
    fr: "Indiquez-nous votre adresse e-mail, et nous vous enverrons votre mot de passe.",
    it: "Dicci la tua email e ti invieremo la tua password.",
    sq: "Na tregoni emailin tuaj dhe ne do t'ju dërgojmë fjalëkalimin tuaj.",
    tr: "Bize e-posta adresinizi söyleyin, şifrenizi göndereceğiz.",
    sw: "Tuambie barua pepe yako na tutatuma nenosiri lako.",
    am: "ኢሜልዎን አሳውቁን እና ፕስዎዱን እንላክላችሁ",
    om: "Email kee nuuf himi, ergaa sirrii kee ni ergina.",
    ig: "Gwa anyị email gị ma anyị ga-eziga paswọọdụ gị.",
    so: "Noo email-kaaga oo waan kuu soo diraynaa eraygaaga sirta ah.",
    ha: "Taimaka mana imel ɗin ku za mu tura muku kalmar sirri.",
    mnk: "Faya email la kafu bɛ nyaa password la.",
    ff: "Ite email-nu mi gam kintee seer tokkinde",
    yo: "Sọ ìmèlì rẹ̀ fún wa, a ó sì ránṣẹ́ pàsíwọọ̀dù rẹ."
  },
  "Then you can separate and send notifications by device keys": {
    en: "Then you can separate and send notifications by device keys, by registration date, by platforms, all at once.",
    pt: "Em seguida, você pode separar e enviar notificações por chaves de dispositivo, por data de registro, por plataformas, tudo de uma vez.",
    de: "Dann können Sie Benachrichtigungen nach Geräteschlüsseln, nach Anmeldedatum, nach Plattformen oder alles auf einmal trennen und senden.",
    es: "Luego puede separar y enviar notificaciones por claves de dispositivo, por fecha de registro, por plataformas, todo a la vez.",
    fr: "Ensuite, vous pouvez séparer et envoyer des notifications par clés de périphérique, par date d'inscription, par plateformes, tout en une seule fois.",
    it: "Poi puoi separare e inviare notifiche per chiavi di dispositivo, per data di registrazione, per piattaforme, tutto in una volta.",
    sq: "Pastaj mund të ndani dhe të dërgoni njoftime sipas çelësave të pajisjes, sipas datës së regjistrimit, sipas platformave, të gjitha në njëherë.",
    tr: "Sonra, bildirimleri cihaz anahtarlarına, kayıt tarihine ve platformlara göre ayırabilir ve hepsini birden gönderebilirsiniz.",
    sw: "Kisha unaweza kutenganisha na kutuma arifa kwa funguo za kifaa, kwa tarehe ya usajili, kwa majukwaa, yote kwa mara moja.",
    am: "ከዚህ በኋላ በመለኪያ ማሳሰቢያ ሳንባ ተመድቷል ፣በመጠን፡ተስማም",
    om: "Inumaa booda, amalli ykn fuula jechuun moogima si'achildiyyaa sana hunda sa'u",
    ig: "Mgbe ahụ, ị nwere ike ịkekọrịta ma zigara ihe nchịkọta site na igodo ngwaọrụ, site na ụbọchị ndebanye aha, site na nyiwe, niile n'otu oge.",
    so: "Kadib, waxaad kala soocid email-kastaa oo aad u diraysaa furaha device-ga, taariikhda diiwaangelinta, qoysaska dhamaan.",
    ha: "Sannan, zaka iya raba kuma aika sanarwa ta amfani da maɓallin na'ura, ta ranar rajista, ta dandamali, duka a lokaci guda.",
    mnk: "Bɛnna, sugalaa ni sɛntar kafu konni tilo ko kafu fowlo yɔŋi taamu",
    ff: "Fula ni jula saay ni kadiyanding portaalduy kodiyoo goor halis",
    yo: "Lẹ́yìn náà, o le ya sọtọ ati ránṣẹ́ ìkìlọ̀ nipa bọtìn iṣẹ́, ọjọ́ ìforúkọsílẹ̀, pẹpẹ gbogbo lẹ́ẹ̀kan."
  },
  "Then, set the notification send date and the notification expiration date": {
    en: "Then, set the notification send date and the notification expiration date.",
    pt: "Em seguida, defina a data de envio da notificação e a data de expiração da notificação.",
    de: "Legen Sie dann das Datum für den Versand der Benachrichtigung und das Ablaufdatum der Benachrichtigung fest.",
    es: "Luego, establezca la fecha de envío de la notificación y la fecha de vencimiento de la notificación.",
    fr: "Ensuite, définissez la date d'envoi de la notification et la date d'expiration de la notification.",
    it: "Poi, imposta la data di invio della notifica e la data di scadenza della notifica.",
    sq: "Pastaj, vendosni datën e dërgimit të njoftimit dhe datën e skadimit të njoftimit.",
    tr: "Sonra, bildirim gönderim tarihini ve bildirim sona erme tarihini ayarlayın.",
    sw: "Kisha, weka tarehe ya kutuma arifa na tarehe ya kuisha kwa arifa.",
    am: "በኋላ በመላክ ቀን እና በመላክ ቀን የመጠን ተደርጓል",
    om: "Ergaa ergaa fi guyyaa ergaa irratti laali. Akkasumas, guyyaa turjumaadhaan",
    ig: "Ma ọ bụ mgbe, hazi ụbọchị izipu ngosi na ụbọchị ọnwụ nke ngosi.",
    so: "Kadib, dejiso taariikhda dirista oge iyo taariikhda dhammaan oge oge.",
    ha: "Sannan, saita ranar aika sanarwa da ranar ƙarewar sanarwa.",
    mnk: "Bɛnna, i fow tagee haala kaŋla le jɔɔrɔ kafu yontolo",
    ff: "Galee sabbin wayaaku pɔtamudo fi sabitwo kɛ ni zanki",
    yo: "Lẹ́yìn náà, ṣètò ọjọ́ fífi ìkìlọ̀ ránṣẹ́ àti ọjọ́ tí ìkìlọ̀ yóò parí."
  },
  "The credit count will be updated on your profile during 5 minutes": {
    en: "The credit count will be updated on your profile during 5 minutes. If you will have any problems with the payment please contact",
    pt: "A contagem de créditos será atualizada em seu perfil durante 5 minutos. Se você tiver algum problema com o pagamento, entre em contato.",
    de: "Die Kreditanzahl wird in Ihrem Profil innerhalb von 5 Minuten aktualisiert. Wenn Sie Probleme mit der Zahlung haben, kontaktieren Sie bitte",
    es: "El recuento de créditos se actualizará en su perfil durante 5 minutos. Si tiene algún problema con el pago, póngase en contacto con",
    fr: "Le nombre de crédits sera mis à jour sur votre profil pendant 5 minutes. Si vous avez des problèmes de paiement, veuillez contacter",
    it: "Il conteggio dei crediti verrà aggiornato nel tuo profilo entro 5 minuti. Se avrai problemi con il pagamento, contatta",
    sq: "Numri i kredive do të përditësohet në profilin tuaj gjatë 5 minutave. Nëse keni ndonjë problem me pagesën, ju lutemi kontaktoni",
    tr: "Kredi sayısı profilinizde 5 dakika içinde güncellenecektir. Ödeme ile ilgili herhangi bir sorun yaşarsanız, lütfen iletişime geçin.",
    sw: "Idadi ya mikopo itasasishwa kwenye wasifu wako ndani ya dakika 5. Ikiwa utakuwa na shida yoyote na malipo tafadhali wasiliana",
    am: "ቁጥር እቃ በአካውንት የእቃ መጠን እንዲታወቅ በሰከንዶች 5 ውስጥ ተስፋ አሳሰበ፣ እንዲሁም አንዴ ከተለኮ አንባሳታት",
    om: "Koodi ykn miiddhaaf jecha, siʼaa barti horii sirra asiraan kan ilaalu ta'u keessa laalu sadiqeen seenaa oromo",
    ig: "A ga-emelite ọnụ ọgụgụ gị na profaịlụ gị n'ime nkeji 5. Ọ bụrụ na ị nwere nsogbu ọ bụla na nkwụnye ego, biko kpọtụrụ",
    so: "Tirada lacagta ayaa la cusboonaysiin doonaa koontadaada muddo 5 daqiiqo ah. Haddii aad wax dhibaato ah kala kulanto lacag bixinta fadlan la xiriir",
    ha: "Za a sabunta yawan kudaden a cikin bayanan ku cikin mintuna 5. Idan kuna da matsala tare da biyan kuɗi, don Allah a tuntuɓe mu",
    mnk: "Kredit bɛ wulɔ kafu na fuwo yontoli fowlo bi 5 na si bɛ nyiŋkɔmɔ jula na dɔɔwɔrɔ",
    ff: "Hawwaaji ɗe moƴƴi ɓii, za'a sabunta darajar kuɗin daga 5 na zankan",
    yo: "Iye kirediti yoo ṣe imudojuiwọn lori profaili rẹ ni iṣẹju 5. Ti o ba ni awọn iṣoro eyikeyi pẹlu isanwo, jọwọ kan si"
  },
  "This device is attached to you, you can find it in your device list": {
    en: "This device is attached to you, you can find it in your device list",
    pt: "Este dispositivo está ligado a si, pode encontrá-lo na sua lista de dispositivos",
    de: "Dieses Gerät ist mit Ihnen verbunden, Sie finden es in Ihrer Geräteliste",
    es: "Este dispositivo está vinculado a usted, puede encontrarlo en su lista de dispositivos",
    fr: "Cet appareil vous est attribué, vous pouvez le trouver dans votre liste d'appareils",
    it: "Questo dispositivo è collegato a te, puoi trovarlo nella tua lista di dispositivi",
    sq: "Kjo pajisje është e lidhur me ju, mund ta gjeni në listën tuaj të pajisjeve",
    tr: "Bu cihaz size bağlı, cihazlar listenizde bulabilirsiniz.",
    sw: "Kifaa hiki kimeunganishwa kwako, unaweza kukipata kwenye orodha ya vifaa vyako",
    am: "መሳሪያው እንደምታያዝ እንደምትመክቶ እንደመሳሪያ በእርስዎ ቅርጸት ትመክቱ",
    om: "Meeshaan kunu si waliin si walii galte, ni keessaa muraasa keessa",
    ig: "Ngwáọrụ a jụrụ gị, ị nwere ike ịchọta ya na ndepụta ngwáọrụ gị",
    so: "Aaladdan waxaa kuu xiran, waad ka heli kartaa liiska aaladahaaga",
    ha: "Na'urar tana haɗe da kai, zaka iya samunta a jerin na'urorinka",
    mnk: "Meeyinaŋo bɛ wulɔ kafu, i bɛ fow kɔn na lista meyinaŋo",
    ff: "Meeshaaɗi ɗe inaɗi da kai, za ka iya samunta a jerin na'urorinka",
    yo: "Ẹ̀rọ yìí ti darapọ mọ́ rẹ, o lè rí i nínú àkójọ àwọn ẹ̀rọ rẹ"
  },
  "The devices are busy with another provider": {
    en: "The devices are busy with another provider",
    pt: "Os dispositivos estão ocupados com outro fornecedor",
    de: "Die Geräte sind bei einem anderen Anbieter im Einsatz",
    es: "Los dispositivos están ocupados con otro proveedor",
    fr: "Les appareils sont occupés avec un autre fournisseur",
    it: "I dispositivi sono occupati con un altro fornitore",
    sq: "Pajisjet janë të zëna me një ofrues tjetër",
    tr: "Cihazlar başka bir sağlayıcı ile meşguldür.",
    sw: "Vifaa vimejaa kwa mtoa huduma mwingine",
    am: "መሳሪያዎቹ ከሌላ ምላሽ ጋር በተመለከተ እየተጠቀሙ ናቸው",
    om: "Meeshaalee biroo waliin hojii irra jiru",
    ig: "Ngwáọrụ nọ na-eji onye na-enye ọrụ ọzọ",
    so: "Aaladaha waxaa ku mashquulsan bixiye kale",
    ha: "Na'urorin suna cikin aiki tare da wani mai samar da sabis",
    mnk: "Meeyinaŋo bɛ fow bɛn na provider kɛra",
    ff: "Meeshaaɗi ɗe inaɗi da provider mako",
    yo: "Àwọn ẹ̀rọ náà ń ṣiṣẹ́ pẹ̀lú olùpèsè iṣẹ́ mìíràn"
  },
  "Total: All devices count Free trial": {
    en: "Total: All devices count Free trial: Devices that are in Free Trial period Active: Active devices count Trial expired: Devices whose free trial period has been expired and they need to be activated to continue using the TV app.",
    pt: "Total: Contagem de todos os dispositivos Teste gratuito: Dispositivos que estão no período de teste gratuito Ativos: Contagem de dispositivos ativos Teste expirado: Dispositivos cujo período de teste gratuito expirou e precisam ser ativados para continuar usando o aplicativo de TV.",
    de: "Gesamt: Anzahl aller Geräte Testversion: Geräte, die sich im Testzeitraum befinden Aktiv: Anzahl der aktiven Geräte Test abgelaufen: Geräte, deren Testzeitraum abgelaufen ist und die aktiviert werden müssen, um die TV-App weiterhin zu nutzen.",
    es: "Total: Cantidad total de dispositivos Prueba gratuita: Dispositivos que están en período de prueba gratuita Activo: Cantidad de dispositivos activos Prueba vencida: Dispositivos cuyo período de prueba gratuita ha vencido y necesitan activarse para seguir utilizando la aplicación de TV.",
    fr: "Total : Nombre total de dispositifs Essai gratuit : Dispositifs en période d'essai gratuit Actif : Nombre de dispositifs actifs Essai expiré : Dispositifs dont la période d'essai gratuit a expiré et qui doivent être activés pour continuer à utiliser l'application TV.",
    it: "Totale: Conteggio totale dei dispositivi Prova gratuita: Dispositivi che si trovano in periodo di prova gratuita Attivi: Conteggio dei dispositivi attivi Prova scaduta: Dispositivi il cui periodo di prova gratuita è scaduto e devono essere attivati per continuare a utilizzare l'app TV.",
    sq: "Totali: Gjithë numërimi i pajisjeve Provë e lirë: Pajisjet që janë në periudhën e provës së lirë Aktiv: Numërimi i pajisjeve aktive Provë e skaduar: Pajisjet të cilat janë skaduar periudha e tyre e provës së lirë dhe duhet të aktivizohen për të vazhduar të përdorin aplikacionin e TV-së.",
    tr: "Toplam: Tüm cihaz sayısı Ücretsiz deneme: Ücretsiz deneme süresinde olan cihazlar Aktif: Aktif cihaz sayısı Deneme süresi dolmuş: Ücretsiz deneme süresi dolmuş cihazlar, TV uygulamasını kullanmaya devam etmek için etkinleştirilmesi gerekmektedir.",
    sw: "Jumla: Idadi ya vifaa vyote\nJaribio la bure: Vifaa vilivyo katika kipindi cha Jaribio la Bure\nHai: Idadi ya vifaa vilivyowezeshwa\nJaribio limekwisha: Vifaa ambavyo kipindi cha jaribio la bure kimekwisha na vinahitaji kuwezeshwa ili kuendelea kutumia programu ya TV.",
    am: "ጠቅላላ: የመሳሪያዎች ብዛት አጠቃላይ\nነጻ ፈተና: በነጻ ፈተና ጊዜ ውስጥ ያሉ መሳሪያዎች\nንቁ: ንቁ የሆኑ መሳሪያዎች ብዛት\nፈተና አልተሳካም: ነጻ የፈተና ጊዜ የጨረሱና የቴሌቪዥን መተግበሪያን ለመቀጠል ማስነሻ ያስፈልጋቸው መሳሪያዎች።",
    om: "Guutuu: Lakkoofsa meeshaalee hunda\nYeroo qormaataa bilisaa: Meeshaalee yeroo qormaataa bilisaa keessa jiran\nHojiirra Oolan: Lakkoofsi meeshaalee hojiirra jiran\nYeroon Qormaataa Xumurame: Meeshaalee yeroo qormaataa isaanii fixatanii fayyadamuu itti fufsiisuuf hojiirra ooluuf gaafatan.",
    ig: "Nkeji: Ọgụgụ ngwáọrụ niile\nNnwale n’efu: Ngwáọrụ nọ na oge Nnwale n’efu\nNdi dị ndụ: Ọgụgụ ngwáọrụ dị ndụ\nNnwale agwụla: Ngwáọrụ nke oge ha dị n’efu agwụla ma ha chọrọ ịgbanye ha iji gaa n’ihu na-eji ngwa TV.",
    so: "Wadar: Tirada dhammaan aaladaha\nTijaabo bilaash ah: Aaladaha ku jira muddada Tijaabada Bilaashka ah\nFirfircoon: Tirada aaladaha firfircoon\nTijaabo dhammaatay: Aaladaha oo muddada tijaabada bilaashka ah dhammaatay waxayna u baahan yihiin in la hawlgeliyo si ay u sii wadaan isticmaalka app-ka TV.",
    ha: "Jimilla: Jimillar adadin na'urori duka\nGwaji Kyauta: Na'urorin da suke cikin lokacin Gwaji Kyauta\nMai aiki: Jimillar na'urorin da suke aiki\nLokacin Gwaji ya ƙare: Na'urorin da lokacin gwaji kyauta ya ƙare kuma suna bukatar a kunna su don ci gaba da amfani da manhajar TV.",
    mnk: "Totalo: Meeyinaŋo dun kaŋo jelu\nFree trialo: Meeyinaŋo ye Free trial laataŋ la\nActive: Meeyinaŋo kɛnɛ laataŋ la\nTrial ti xuranyi: Meeyinaŋo ye free trial xuranyi na taraŋa laataŋ kɔ dɛnma bɛ ka TV app bɛ tara lafaŋo.",
    ff: "Ndat: Cuɓii kulle mawɗe ɗe\nFaalaa koddaaji: Mawɗe ɗe wonde faalaa koddaaji\nAadinde: Cuɓii kulle mawɗe ɗe njarnaade\nFaalaa woodde: Mawɗe ɗe waɗii faalaa woodde ɗe teelii njarnude nanndi TV njaŋngindi.",
    yo: "Apapọ: Gbogbo awọn ẹrọ gbogbo\nÌdánwò ọfẹ: Awọn ẹrọ tí wọ́n wà nípàdé ìdánwò ọfẹ\nÌtọ́jú: Àwọn ẹ̀rọ tí wọ́n ṣiṣẹ́\nÌdánwò ti pari: Àwọn ẹ̀rọ tí ìpàdé ìdánwò ọfẹ wọn ti pari tí wọn nílò kí wọ́n tún ṣiṣẹ́ láti tẹsiwaju lilo app tẹlifísàn."
  },
  "to become a seller": {
    en: "to become a seller",
    pt: "tornar-se um vendedor",
    de: "ein Verkäufer werden",
    es: "convertirse en vendedor",
    fr: "devenir vendeur",
    it: "diventare un venditore",
    sq: "të bëheni një shitës",
    tr: "bir satıcı olmak",
    sw: "kuwa muuzaji",
    am: "ሻጭ ለመሆን",
    om: "gara daldalaan ta’uuf",
    ig: "ị bụrụ onye na-ere ahịa",
    so: "in noqoto iibiyaha",
    ha: "zama mai sayarwa",
    mnk: "bo kɛnɛlaŋo bɛ",
    ff: "ndon e sellere",
    yo: "láti di ẹni tó ń tàá"
  },
  "Two-Factor Authentication (2FA) Code Reminder": {
    en: "Please ensure that a verification code is dispatched to the email address registered for your two-factor authentication (2FA). Kindly review your mailbox for the code within the next 10 minutes.",
    pt: "Certifique-se de que um código de verificação seja enviado para o endereço de e-mail registrado para sua autenticação de dois fatores (2FA). Por favor, verifique sua caixa de entrada para o código nos próximos 10 minutos.",
    de: "Bitte stellen Sie sicher, dass ein Bestätigungscode an die für Ihre Zwei-Faktor-Authentifizierung (2FA) registrierte E-Mail-Adresse gesendet wird. Überprüfen Sie bitte Ihr Postfach auf den Code innerhalb der nächsten 10 Minuten.",
    es: "Asegúrese de que se envíe un código de verificación a la dirección de correo electrónico registrada para su autenticación de dos factores (2FA). Revise su bandeja de entrada para encontrar el código en los próximos 10 minutos.",
    fr: "Veuillez vous assurer qu'un code de vérification est envoyé à l'adresse e-mail enregistrée pour votre authentification à deux facteurs (2FA). Veuillez vérifier votre boîte de réception pour le code dans les 10 prochaines minutes.",
    it: "Assicurati che un codice di verifica venga inviato all'indirizzo email registrato per la tua autenticazione a due fattori (2FA). Controlla gentilmente la tua casella di posta per il codice entro i prossimi 10 minuti.",
    sq: "Ju lutemi sigurohuni që një kod verifikimi të dërgohet në adresën e emailit të regjistruar për autentifikimin tuaj dyfaktor (2FA). Ju lutemi, shqyrtoni postën tuaj elektronike për kodin brenda 10 minutave të ardhshme.",
    tr: "Lütfen iki faktörlü kimlik doğrulama (2FA) için kayıtlı e-posta adresinize bir doğrulama kodunun gönderildiğinden emin olun. Lütfen kodu kontrol etmek için gelen kutunuzu sonraki 10 dakika içinde gözden geçirin.",
    sw: "Tafadhali hakikisha kuwa msimbo wa uthibitisho umetumwa kwa barua pepe iliyosajiliwa kwa uthibitisho wako wa hatua mbili (2FA). Tafadhali angalia kikasha chako kwa msimbo ndani ya dakika 10 zijazo.",
    am: "እባኮትን ሁለት እሴት ማረጋገጫ (2FA) ለማጣሪያዎ የተመዘገበው የኢሜል አድራሻ ላይ የማረጋገጫ ኮድ መላክ እንደተከናወነ እንድታረጋግጡ። እባክዎ በሚቀጥለው 10 ደቂቃዎች በኢሜልዎ እንዲመጣው አንድ ጊዜ ይለዋሉ።",
    om: "Mee ariiti keewwata mirkaneessituu gara imeelii kee kan galmee keetiif 2FAtti ergame tahuu mirkaneessi. Akkasumas, keewwata sana daqiiqaa 10 keessatti sanduuqa kee keessa ilaali.",
    ig: "Biko jide n'aka na e zigara koodu nnọchitega na adreesị email ahịrị kpọpụtara maka ntụkwasị obi ọkwa abụọ gị (2FA). Biko lelee igbe ozi gị maka koodu ahụ n'ime nkeji iri sochirinụ.",
    so: "Fadlan hubi in koodka xaqiijinta loo diray cinwaanka iimaylka ee ka diiwaan gashan nidaamkaaga xaqiijinta laba-tallaabo (2FA). Fadlan fiiri sanduuqaaga iimaylka ee koodka 10-ka daqiiqo ee soo socota.",
    ha: "Da fatan ka tabbatar cewa an aika lambar tabbaci zuwa adireshin imel da aka yi rijista da shi don tabbatar da matakai biyu (2FA) naka. Da fatan za a duba akwatin saƙon ku don lambar a cikin mintuna 10 masu zuwa.",
    mnk: "Jamana la saayoo ke verification code la emailo kɛnɛ sa wa 2FA ɗoo ni taamala. Suusoo jamoo la ɲi dɔɔli minyantaŋ ka na kode ɗoo la ko minen minen kɔnɔ 10 miniti kelenoo bɛ jelo.",
    ff: "A laata ɓuri iwde koddu woodude emaali ɗee ɓe njarnge ɓamfaadu ɓen nokkon 2FA. A naara kalaare emaali ɓen ndema koddu nanngi nder 10 daqqiika.",
    yo: "Jọ̀wọ́ dájú pé àkọ́tọ̀ ìdánimọ̀ ti fi ránṣẹ́ sí àdíréẹsì ìmélì tó wà fún ìtẹ́wẹ́lé-gun méjì rẹ (2FA). Ẹ̀ jọ̀wọ́ tún wo àpótí rẹ fún àkọ́tọ̀ náà nínú iṣẹju mẹ́wàá tó ń bọ̀."
  },
  "Text copied to clipboard": {
    en: "Text copied to clipboard",
    pt: "Texto copiado para a área de transferência",
    de: "Text in die Zwischenablage kopiert",
    es: "Texto copiado al portapapeles",
    fr: "Texte copié dans le presse-papiers",
    it: "Testo copiato negli appunti",
    sq: "Teksti u kopjua në shiritin e prerjes",
    tr: "Metin panoya kopyalandı",
    sw: "Maandishi yamenakiliwa kwenye ubao wa kunakili",
    am: "ጽሑፉ ወደ ቅንጫፍ ተቀድቷል",
    om: "Barruun gara gabatee qabiyyee ergame",
    ig: "Ederede e copy na clipboard",
    so: "Qoraalka waxaa lagu koobiyeeyay loox aan la arki karin",
    ha: "An kwafi rubutu zuwa allo na kwafi",
    mnk: "Alkaloɔ ka suruntaŋo ka doon sumaya",
    ff: "Binndol ina hoɗi tonngol clip",
    yo: "Ìkọ̀wé ti dáàpò mọ́ sísà"
  },
  "Trial expired date": {
    en: "Trial expired date",
    pt: "Data de expiração do período de avaliação",
    de: "Ablaufdatum der Testphase",
    es: "Fecha de vencimiento de la prueba",
    fr: "Date d'expiration de l'essai",
    it: "Data di scadenza della prova",
    sq: "Data e skadimit të provës së lirë",
    tr: "Deneme süresi bitiş tarihi",
    sw: "Tarehe ya kumalizika kwa jaribio",
    am: "የፈተናው ጊዜ አልፎበታል ቀን",
    om: "Guyyaa xumura qorannichaa",
    ig: "Ụbọchị ngwụcha ule",
    so: "Taariikhda dhammaadka tijaabada",
    ha: "Ranar karewar gwaji",
    mnk: "Jaroolu ka minen ka suwoo",
    ff: "Ñalngu jamirde cepaade",
    yo: "Ọjọ ìparí ìdánwò"
  },

  //r
  "Refund policy": {
    en: "Refund policy",
    pt: "Política de reembolso",
    de: "Rückerstattungsrichtlinie",
    es: "Política de reembolso",
    fr: "Politique de remboursement",
    it: "Politica di rimborso",
    sq: "Politika e rimbursimit",
    tr: "İade politikası",
    sw: "Sera ya kurejesha pesa",
    am: "የተመላሽ ፖሊሲ",
    om: "Siyaasa deebiisuu",
    ig: "Ụkpụrụ agbata ego",
    so: "Shuruudaha soo celinta",
    ha: "Dokar maidowa kuɗi",
    mnk: "Hɔrɔnya ladin so kɛra la fankilingo",
    ff: "Njoɓdi yobde",
    yo: "Ètò ìsanwó padà"
  },
  Reset: {
    en: "Reset",
    pt: "Redefinir",
    de: "Zurücksetzen",
    es: "Restablecer",
    fr: "Réinitialiser",
    it: "Ripristina",
    sq: "Rivendos",
    tr: "Sıfırla",
    sw: "Weka upya",
    am: "ዳግም አዘጋጅ",
    om: "Haaromsuu",
    ig: "Tọgharịa",
    so: "Dib u dejiso",
    ha: "Sake saita",
    mnk: "Kunu bata",
    ff: "Jottugol",
    yo: "Tún ṣètò"
  },
  "Reseller name": {
    en: "Reseller name",
    pt: "Nome do Revendedor",
    de: "Wiederverkäufername",
    es: "Nombre del Revendedor",
    fr: "Nom du Revendeur",
    it: "Nome del Rivenditore",
    sq: "Emri i ri-shitësit",
    tr: "Bayii adı",
    sw: "Jina la muuzaji",
    am: "የሻጭ ስም",
    om: "Maqaa tajaajilaa",
    ig: "Aha onye na-ere ahịa",
    so: "Magaca iibiyaha",
    ha: "Sunan dillali",
    mnk: "Farata la tɔɔrɔ",
    ff: "Innde huutorde",
    yo: "Orúkọ olùtajà"
  },
  "Reseller email": {
    en: "Reseller email",
    pt: "E-mail do Revendedor",
    de: "Wiederverkäufer-E-Mail",
    es: "Correo electrónico del Revendedor",
    fr: "E-mail du Revendeur",
    it: "Email del Rivenditore",
    sq: "Emaili i ri-shitësit",
    tr: "Bayii e-postası",
    sw: "Barua pepe ya muuzaji",
    am: "የሻጭ ኢሜል",
    om: "Imeelii tajaajilaa",
    ig: "Email onye na-ere ahịa",
    so: "Email-ka iibiyaha",
    ha: "Imel ɗin dillali",
    mnk: "Farata la emailo",
    ff: "Imeel huutorde",
    yo: "Ẹ̀máilì olùtajà"
  },
  "Release date": {
    en: "Release date",
    pt: "Data de Lançamento",
    de: "Veröffentlichungsdatum",
    es: "Fecha de Lanzamiento",
    fr: "Date de Sortie",
    it: "Data di Uscita",
    sq: "Data e publikimit",
    tr: "Çıkış tarihi",
    sw: "Tarehe ya kutolewa",
    am: "እታች ቀን",
    om: "Guyyaa gadhiinsaa",
    ig: "Ụbọchị ntọhapụ",
    so: "Taariikhda sii deynta",
    ha: "Ranar sakin",
    mnk: "Yɛnna ka ŋa minen",
    ff: "Ñalngu ngu ñaawngude",
    yo: "Ọjọ ìdásílẹ̀"
  },
  "Reset password": {
    en: "Reset password",
    pt: "Redefinir senha",
    de: "Passwort zurücksetzen",
    es: "Restablecer contraseña",
    fr: "Réinitialiser le mot de passe",
    it: "Ripristina password",
    sq: "Rivendos fjalëkalimin",
    tr: "Şifreyi sıfırla",
    sw: "Weka upya nenosiri",
    am: "የማለፊያ ቃል ዳግም አዘጋጅ",
    om: "Jecha sirrii haaromsuu",
    ig: "Tọgharịa okwuntughe",
    so: "Dib u dejiso erayga sirta",
    ha: "Sake saita kalmar sirri",
    mnk: "Kunu jege bata",
    ff: "Nafoore jottugol",
    yo: "Tun orukọ ààbò ṣe"
  },
  "Reseller Config": {
    en: "Reseller Config",
    pt: "Configuração do Revendedor",
    de: "Wiederverkäuferkonfiguration",
    es: "Configuración del Revendedor",
    fr: "Configuration du Revendeur",
    it: "Configurazione del Rivenditore",
    sq: "Konfigurimi i Rishitësit",
    tr: "Bayii Konfigürasyonu",
    sw: "Usanidi wa Muuzaji",
    am: "የሻጭ አቀማመጥ",
    om: "Gulaanta Tajaajilaa",
    ig: "Nhazi onye na-ere ahịa",
    so: "Qaabeynta iibiyaha",
    ha: "Saitin Dillali",
    mnk: "Fankiloo la ke farata",
    ff: "Njumte Huutorde",
    yo: "Àtúnṣe Olùtajà"
  },
  "Receiving devices": {
    en: "Receiving devices",
    pt: "Recebendo dispositivos",
    de: "Geräte empfangen",
    es: "Recepción de dispositivos",
    fr: "Réception des dispositifs",
    it: "Ricezione dei dispositivi",
    sq: "Marrja e pajisjeve",
    tr: "Alma cihazları",
    sw: "Vifaa vya kupokea",
    am: "የመቀበያ መሳሪያዎች",
    om: "Meeshaalee simannaa",
    ig: "Ngwaọrụ nnabata",
    so: "Qalabka soo dhaweynta",
    ha: "Na'urorin karɓa",
    mnk: "Fulatula ke ladila",
    ff: "Leemooji waɗtagol",
    yo: "Àwọn ẹ̀rọ gbigba"
  },
  "Retype password": {
    en: "Retype password",
    pt: "Digite a senha novamente",
    de: "Passwort erneut eingeben",
    es: "Vuelva a escribir la contraseña",
    fr: "Saisissez à nouveau le mot de passe",
    it: "Ridigita la password",
    sq: "Rishkruaj fjalëkalimin",
    tr: "Şifreyi yeniden girin",
    sw: "Andika tena nenosiri",
    am: "የማለፊያ ቃል ድገም",
    om: "Jecha sirrii deebisanii galchuu",
    ig: "Tinye okwuntughe ọzọ",
    so: "Ku qor password-ka mar kale",
    ha: "Sake shigar da kalmar sirri",
    mnk: "Kunu jege fɔrɔ",
    ff: "Yettinir nafoore",
    yo: "Tún tẹ orúkọ ààbò"
  },
  "Reseller devices": {
    en: "Reseller devices",
    pt: "Dispositivos do Revendedor",
    de: "Wiederverkäufergeräte",
    es: "Dispositivos del Revendedor",
    fr: "Dispositifs du Revendeur",
    it: "Dispositivi del Rivenditore",
    sq: "Pajisjet e Rishitësit",
    tr: "Bayii cihazları",
    sw: "Weka upya nenosiri langu",
    am: "የማለፊያ ቃሌን እንደገና አዘጋጅ",
    om: "Jecha sirrii koo haaromsuu",
    ig: "Tọgharịa okwuntughe m",
    so: "Dib u dejiso erayga sirta",
    ha: "Sake saita kalmar sirri na",
    mnk: "N bata kunu jege",
    ff: "Nafoore am-jottugol",
    yo: "Tun oruko aabo mi se"
  },
  "Reset my password": {
    en: "Reset my password",
    pt: "Redefinir minha senha",
    de: "Mein Passwort zurücksetzen",
    es: "Restablecer mi contraseña",
    fr: "Réinitialiser mon mot de passe",
    it: "Ripristina la mia password",
    sq: "Rivendos fjalëkalimin tim",
    tr: "Şifremi sıfırla",
    sw: "Weka upya nenosiri langu",
    am: "የማለፊያ ቃሌን እንደገና አዘጋጅ",
    om: "Jecha sirrii koo haaromsuu",
    ig: "Tọgharịa okwuntughe m",
    so: "Dib u dejiso erayga sirta",
    ha: "Sake saita kalmar sirri na",
    mnk: "N bata kunu jege",
    ff: "Nafoore am-jottugol",
    yo: "Tun oruko aabo mi se"
  },
  "Reseller configuration updates are successful": {
    en: "Reseller configuration updates are successful.",
    pt: "As atualizações de configuração do revendedor foram bem-sucedidas.",
    de: "Die Aktualisierungen der Wiederverkäuferkonfiguration waren erfolgreich.",
    es: "Las actualizaciones de configuración del revendedor se han realizado con éxito.",
    fr: "Les mises à jour de la configuration du revendeur ont réussi.",
    it: "Le modifiche alla configurazione del rivenditore sono state effettuate con successo.",
    sq: "Përditësimet e konfigurimit të shitësit janë kryer me sukses.",
    tr: "Bayii yapılandırma güncellemeleri başarıyla gerçekleştirildi.",
    sw: "Sasisho za usanidi wa muuzaji zimefanikiwa",
    am: "የተሸጠ እቃ አሰራር ዝመናዎች በተሳካ ሁኔታ ተከናውኗል።",
    om: "Gulaantii qindoomina tajaajilaa milkaa'inaan xumurameera",
    ig: "Mmelite nhazi nke onye na-ere ahịa gara nke ọma",
    so: "Cusbooneysiinta qaabeynta iibiyaha ayaa guulaysatay",
    ha: "Sabunta saitin dillali ya yi nasara",
    mnk: "Fankiloo safoŋo ka bɛ jɛrɛjɛrɛ",
    ff: "Jokkitere ɗe gasii njilloraa",
    yo: "Àtúnṣe ìṣètò olùtajà ti ṣàṣeyọrí"
  },
  "Reseller will need to buy credits": {
    en: "Reseller will need to buy credits",
    pt: "O revendedor precisará comprar créditos",
    de: "Der Wiederverkäufer muss Guthaben kaufen",
    es: "El revendedor deberá comprar créditos",
    fr: "Le revendeur devra acheter des crédits",
    it: "Il rivenditore dovrà acquistare crediti",
    sq: "Shitësi do të ketë nevojë të blejë kredite",
    tr: "Bayinin kredi satın alması gerekecek",
    sw: "Muuzaji atahitaji kununua salio",
    am: "እንደኛ አብራት እንዲገኙ ይገባል",
    om: "Resellera maallaqa bitee argachuu qaba",
    ig: "Ọrụ ahịa chọrọ ịzụta ego",
    so: "Iibiyaha wuxuu u baahan yahay inuu iibsado deyn",
    ha: "Mai sayarwa zai bukaci sayan kuɗi",
    mnk: "Reseller bɛ kɛ ra taamu ka ɲɔɔ siima",
    ff: "Jaɓinteeɗo maa mbaari ngoosi mbundi",
    yo: "Olutaja nilo lati ra awọn kirediti"
  },
  "Registered Devices": {
    en: "Registered Devices",
    pt: "Dispositivos Registrados",
    de: "Registrierte Geräte",
    es: "Dispositivos Registrados",
    fr: "Appareils Enregistrés",
    it: "Dispositivi Registrati",
    sq: "Pajisjet e Regjistruara",
    tr: "Kayıtlı Cihazlar",
    sw: "Vifaa vilivyosajiliwa",
    am: "መመዝገብ ተሳካ መሳሪያዎች",
    om: "Meeshaalee galmaa'aa",
    ig: "Ngwaọrụ Edebanyere",
    so: "Qalabka la diiwaangeliyay",
    ha: "Na'urorin da aka yi rajista",
    mnk: "Dɔɔti ti o wa",
    ff: "Njuɗum gammbal",
    yo: "Ẹrọ ti a forukọsilẹ"
  },
  "Reseller can use my credits": {
    en: "Reseller can use my credits",
    pt: "O revendedor pode usar meus créditos",
    de: "Der Wiederverkäufer kann meine Guthaben nutzen",
    es: "El revendedor puede utilizar mis créditos",
    fr: "Le revendeur peut utiliser mes crédits",
    it: "Il rivenditore può usare i miei crediti",
    sq: "Shitësi mund të përdorë kreditë e mia",
    tr: "Bayii kredilerimi kullanabilir",
    sw: "Muuzaji anaweza kutumia salio langu",
    am: "እንደኛው ክሬዲቶቼን መጠቀም ይችላል",
    om: "Resellerri maallaqa koo fayyadamuu danda’a",
    ig: "Onye na-ere ahịa nwere ike iji ego m",
    so: "Iibiyaha wuxuu isticmaali karaa deynta aan haysto",
    ha: "Mai sayarwa zai iya amfani da kuɗina",
    mnk: "Reseller bɛ n siima dila la",
    ff: "Jaɓinteeɗo maa ɗa mbundi am",
    yo: "Olutaja le lo awọn kirediti mi"
  },
  "Reset requests": {
    en: "Reset requests",
    pt: "Solicitações de reset",
    de: "Reset-Anfragen",
    es: "Solicitudes de reinicio",
    fr: "Demandes de réinitialisation",
    it: "Richieste di reset",
    sq: "Kërkesat e rivendosjes",
    tr: "Sıfırlama talepleri",
    sw: "Maombi ya kurejesha",
    am: "ግዴታ ወላጅ ማስተካከያ",
    om: "Gaaffii deebii",
    ig: "Arịrịọ ịtọghata",
    so: "Codsiga dib-u-dejinta",
    ha: "Buƙatun sake saita",
    mnk: "Donfuden finde",
    ff: "Reete jibbe",
    yo: "Awọn ibeere atunṣe"
  },
  //f
  from: {
    en: "from",
    pt: "de",
    de: "von",
    es: "de",
    fr: "de",
    it: "da",
    sq: "nga",
    tr: "den",
    sw: "kutoka",
    am: "ከ",
    om: "irraa",
    ig: "site",
    so: "laga",
    ha: "daga",
    mnk: "bya",
    ff: "jumtude",
    yo: "lati"
  },
  Filter: {
    en: "Filter",
    pt: "Filtrar",
    de: "Filtern",
    es: "Filtrar",
    fr: "Filtrer",
    it: "Filtrare",
    sq: "Filtro",
    tr: "Filtrele",
    sw: "Chuja",
    am: "ተጠቃሚ",
    om: "Firiiraa",
    ig: "Sịfịlita",
    so: "Shaandhe",
    ha: "Tace",
    mnk: "Fintaaloo",
    ff: "Fitte",
    yo: "Tẹ̀rẹ̀"
  },
  Favorites: {
    en: "Favorites",
    pt: "Favoritos",
    de: "Favoriten",
    es: "Favoritos",
    fr: "Favoris",
    it: "Preferiti",
    sq: "Preferencat",
    tr: "Favoriler",
    sw: "Vipendwa",
    am: "ታማኝ ምርጫዎች",
    om: "Filannoo",
    ig: "Ihe a hụrụ n’anya",
    so: "Jecel",
    ha: "Abubuwan da aka fi so",
    mnk: "Fanatee",
    ff: "Faɓɓi ɗi",
    yo: "Ayanfẹ"
  },
  "Free trial": {
    en: "Free trial",
    pt: "Teste gratuito",
    de: "Kostenlose Testversion",
    es: "Prueba gratuita",
    fr: "Essai gratuit",
    it: "Prova gratuita",
    sq: "Prova falas",
    tr: "Ücretsiz deneme",
    sw: "Majaribio bure",
    am: "ሙከራ በነፃ",
    om: "Tajaajila bilisaa",
    ig: "Nnwale n'efu",
    so: "Tijaabada bilaashka ah",
    ha: "Gwaji kyauta",
    mnk: "Kafole jumo",
    ff: "Njoɓdi jompiɗi",
    yo: "Ìdánwò ọfẹ"
  },
  "Fill the name": {
    en: "Fill the name",
    pt: "Preencha o nome",
    de: "Name ausfüllen",
    es: "Rellene el nombre",
    fr: "Remplissez le nom",
    it: "Compila il nome",
    sq: "Mbusheni emrin",
    tr: "Adı doldurun",
    sw: "Jaza jina",
    am: "ስም አስገባ",
    om: "Maqaa guutuu",
    ig: "Tinye aha",
    so: "Buuxi magaca",
    ha: "Cadee sun name ",
    mnk: "Bintung xamle",
    ff: "Hokkoi yimbe",
    yo: "Fọwọsi orukọ"
  },
  "Forgot Your Password": {
    en: "Forgot Your Password",
    pt: "Esqueceu sua senha",
    de: "Passwort vergessen",
    es: "¿Olvidó su contraseña?",
    fr: "Mot de passe oublié",
    it: "Hai dimenticato la password",
    sq: "Keni harruar fjalëkalimin tuaj",
    tr: "Şifrenizi mi unuttunuz?",
    sw: "Umesahau nenosiri lako",
    am: "የአካውንትዎን ፕስዎርድ ማሳረፍ?",
    om: "Password kee? Gammachuun!",
    ig: "Chefuru paswọọdụ gị?",
    so: "Ma hilmaami furahaadaada",
    ha: "Ka manta da kalmar sirri",
    mnk: "Nka kunfi passowrd",
    ff: "Mungkol njoɓdi password",
    yo: "Ṣe o ti gbagbe ọrọ aṣina rẹ?"
  },
  "Free trial expired": {
    en: "Free trial expired",
    pt: "Teste gratuito expirado",
    de: "Kostenlose Testversion abgelaufen",
    es: "Prueba gratuita caducada",
    fr: "Essai gratuit expiré",
    it: "Prova gratuita scaduta",
    sq: "Prova falas ka skaduar",
    tr: "Ücretsiz deneme süresi doldu",
    sw: "Majaribio bure yameisha",
    am: "ሙከራ በነፃ አሁን አልሆነም",
    om: "Tajaajilaan bilisaa xumurame",
    ig: "Nnwale n'efu agwụla",
    so: "Tijaabada bilaashka ah way dhamaatay",
    ha: "Gwajin kyauta ya ƙare",
    mnk: "Kofole jumo kafole",
    ff: "Jompiɗi njoɓdi kafole",
    yo: "Ìdánwò ọfẹ ti pari"
  },
  "Failed to copy text to clipboard": {
    en: "Failed to copy text to clipboard",
    pt: "Falha ao copiar texto para a área de transferência",
    de: "Text konnte nicht in die Zwischenablage kopiert werden",
    es: "Error al copiar texto al portapapeles",
    fr: "Échec de la copie du texte dans le presse-papiers",
    it: "Impossibile copiare il testo negli appunti",
    sq: "Dështoi në kopjimin e tekstit në të dhënat e transferimit",
    tr: "Metin panoya kopyalanamadı",
    sw: "Imeshindikana nakopi maandishi kwenye kipakuzi",
    am: "እባኮት ተጽላል ማስተንተኛ ማድረግ",
    om: "Barruu gara clipboard geeddaruu dhufuu dhabe",
    ig: "Enweghị ike idepụta ederede n'ime clipboard",
    so: "Nuqulka qoraalka looma guurin clipboard",
    ha: "Ba a samu kwafa rubutu zuwa clipboard ba",
    mnk: "Jangka ɲaama daada na yaama",
    ff: "Sofo hokki text to clipboard na fanndu",
    yo: "Ko ṣẹṣẹ ẹda ọrọ si clipboard"
  },
  "Free trial days": {
    en: "Free trial days",
    pt: "Dias de teste gratuito",
    de: "Tage der kostenlosen Testversion",
    es: "Días de prueba gratuita",
    fr: "Jours d'essai gratuit",
    it: "Giorni di prova gratuita",
    sq: "Ditë prova falas",
    tr: "Ücretsiz deneme günleri",
    sw: "Siku za majaribio bure",
    am: "ቀናት ሙከራ በነፃ",
    om: "Guyyaa tajaajila bilisaa",
    ig: "Ụbọchị nnwale n'efu",
    so: "Maalmaha tijaabada bilaashka ah",
    ha: "Ranar gwaji kyauta",
    mnk: "Jumo kafole jumo",
    ff: "Ɓeennde njoɓdi jompiɗi",
    yo: "Ọjọ́ ìdánwò ọfẹ"
  },
  "Free trial expired date": {
    en: "Free trial expired date",
    pt: "Data de expiração do período de avaliação gratuita",
    de: "Datum des Ablaufs der kostenlosen Testversion",
    es: "Fecha de vencimiento de la prueba gratuita",
    fr: "Date d'expiration de l'essai gratuit",
    it: "Data di scadenza del periodo di prova gratuito",
    sq: "Data e skadimit të provës së falas",
    tr: "Ücretsiz deneme süresi sona erdi tarihi",
    sw: "Tarehe ya kumalizika kwa majaribio bure",
    am: "በነፃ ሙከራ የተሰጠ ቀን ይሁን",
    om: "Guyyaa tajaajila bilisaa xumurame",
    ig: "Ụbọchị ịwụpụ nke nnwale n'efu",
    so: "Taariikhda dhammaadka tijaabada bilaashka ah",
    ha: "Ranar karewar gwaji kyauta",
    mnk: "Taarixi kafole juma",
    ff: "Ɓeennde njoɓdi jompiɗi",
    yo: "Ọjọ́ ipari ìdánwò ọfẹ"
  },
  "Forget your password? No need to worry": {
    en: "Forget your password? No need to worry.",
    pt: "Esqueceu sua senha? Não precisa se preocupar.",
    de: "Passwort vergessen? Keine Sorge.",
    es: "¿Olvidaste tu contraseña? No te preocupes.",
    fr: "Oublié votre mot de passe ? Pas besoin de s'inquiéter.",
    it: "Hai dimenticato la password? Nessun problema.",
    sq: "Keni harruar fjalëkalimin tuaj? S'ka nevojë për shqetësim.",
    tr: "Şifrenizi mi unuttunuz? Endişelenmeyin.",
    sw: "Umesahau nenosiri lako? Hakuna haja ya kujiworopesha.",
    am: "አስታወቅህ? አይደለም፣ አልተስፋፋም።",
    om: "Password keeti? Rakkoo hin qabdu.",
    ig: "Chefuru paswọọdụ gị? Enweghị mkpa ịṅọdụ nsogbu.",
    so: "Ma hilmaami furahaada? Ma dhibciga ha lahayn.",
    ha: "Ka manta da kalmar sirri? Babu abin damuwa.",
    mnk: "Nka kunfi passowrd? Ko ɲɔgɔrɔ le kafu le.",
    ff: "Tawi kadi ce password ko? Waaldu ɓe laraa.",
    yo: "Ṣe o ti gbagbe ọrọ aṣina rẹ? Ko si iṣoro."
  },
  "For every 1 activation Expired: Devices whose": {
    en: "For every 1 activation will be charged 1 credit from your account. Expired: Devices whose active 1-year period has been expired and they need to be activated to continue using the TV app.",
    pt: "Para cada ativação, será cobrado 1 crédito da sua conta. Expirado: Dispositivos cujo período ativo de 1 ano expirou e precisam ser ativados para continuar usando o aplicativo de TV.",
    de: "Für jede Aktivierung wird 1 Kredit von Ihrem Konto abgebucht. Abgelaufen: Geräte, deren aktiver 1-Jahres-Zeitraum abgelaufen ist und die aktiviert werden müssen, um die Nutzung der TV-App fortzusetzen.",
    es: "Por cada activación se cobrará 1 crédito de su cuenta. Expirado: Dispositivos cuyo período activo de 1 año ha expirado y necesitan ser activados para seguir utilizando la aplicación de TV.",
    fr: "Pour chaque activation, 1 crédit sera débité de votre compte. Expiré : Dispositifs dont la période active d'un an a expiré et qui doivent être activés pour continuer à utiliser l'application TV.",
    it: "Per ogni attivazione verrà addebitato 1 credito dal tuo account. Scaduti: Dispositivi il cui periodo attivo di 1 anno è scaduto e devono essere attivati per continuare a utilizzare l'app TV.",
    sq: "Për çdo aktivizim do të tarifizohet 1 kredit nga llogaria juaj. Skaduar: Dispositivat të cilët janë skaduar periudha aktive 1-vjeçare dhe ata duhet të aktivizohen për të vazhduar përdorimin e aplikacionit TV.",
    tr: "Her bir aktivasyon için hesabınızdan 1 kredi alınacaktır. Süresi dolmuş: Aktif 1 yıllık süresi dolmuş cihazlar, TV uygulamasını kullanmaya devam etmek için etkinleştirilmesi gerekmektedir.",
    sw: "Kila activation 1 itatozwa 1 kredi kutoka kwa akaunti yako. Imeisha: Vifaa vilivyomaliza kipindi cha mwaka 1 cha ufanisi na vinahitaji kuamilishwa ili kuendelea kutumia programu ya TV.",
    am: "ለእያንዳንዱ 1 እንቅስቃሴ ከመለኪያው 1 ተከፋፈለ ቁልፍ ነው። ተሰበሰበ: መሣሪያዎች እንደ ምርመራ ሲቀር ሲጠቀሙ፣ እንደ ኢኮኖሚተ ሳምንት ቁጥር፣ አላቸውም.",
    om: "Activateerama tokkoof, 1 krediitii akka deebi’uu akka gargar baafameefi. Deebii: Meeshalee tokkoo yeroo hojii isaanii waggaa tokkoo fixatee fi si’a itti aansee hojii gegeffamuuf ofeeggannaan!",
    ig: "Ọ bụla activation 1 ga-efu 1 kredit site na akaụntụ gị. Gwụrụ: Ngwaọrụ ndị kwụsịrị afọ 1 nke ọrụ na-achọ ka emegharịrị ha iji gaa n'ihu na-eji ngwa TV.",
    so: "Kasta oo 1 activation, 1 kredit ayaa laga qaadi doonaa akoonkaaga. Dhamaaday: Qalabka muddo 1 sano ah ee firfircoon ayaa dhammaaday waxayna u baahan yihiin in la hawlgaliyo si ay u sii wataan isticmaalka app-ka TV-ga.",
    ha: "Duk wani activation 1, za a caje 1 kirediti daga asusunka. Ya ƙare: Na’urorin da shekarar su ta 1 ta ƙare kuma suna buƙatar a kunna su don ci gaba da amfani da aikace-aikacen TV.",
    mnk: "Sofo jango 1 ti kafu jango, 1 juma  to ɓeyngel ndiyam. Kafole: Sofo wa 1 ti jango kanfɔ.",
    ff: "Sofo activation koɗɗi 1, ɓeenna to 1 ɓeyngel ndiyam. Koɗo: Meeshii ɓe ne nyi jompiɗi e ɓe ngoni wadi.",
    yo: "Fun gbogbo iṣeto 1, 1 kirediti yoo gba lati akọọlẹ rẹ. Ti pari: Awọn ẹrọ ti akoko ọdun 1 wọn ti pari ati pe wọn nilo lati jẹ imudojuiwọn lati tẹsiwaju lilo ohun elo TV."
  },
  "For every 1 activation will be charged 1 credit from your account": {
    en: "For every 1 activation will be charged 1 credit from your account.",
    pt: "Para cada ativação, será cobrado 1 crédito da sua conta.",
    de: "Für jede Aktivierung wird 1 Kredit von Ihrem Konto abgebucht.",
    es: "Por cada activación se cobrará 1 crédito de su cuenta.",
    fr: "Pour chaque activation, 1 crédit sera débité de votre compte.",
    it: "Per ogni attivazione verrà addebitato 1 credito dal tuo account.",
    sq: "Për çdo aktivizim do të tarifizohet 1 kredit nga llogaria juaj.",
    tr: "Her bir aktivasyon için hesabınızdan 1 kredi alınacaktır.",
    sw: "Kila activation 1 itatozwa 1 kredi kutoka kwa akaunti yako.",
    am: "ለእያንዳንዱ 1 እንቅስቃሴ ከመለኪያው 1 ተከፋፈለ ቁልፍ ነው።",
    om: "Activateerama tokkoof, 1 krediitii akka deebi’uu akka gargar baafameefi.",
    ig: "Ọ bụla activation 1 ga-efu 1 kredit site na akaụntụ gị.",
    so: "Kasta oo 1 activation, 1 kredit ayaa laga qaadi doonaa akoonkaaga.",
    ha: "Duk wani activation 1, za a caje 1 kirediti daga asusunka.",
    mnk: "Jango 1 ti kafu jango, 1 juma tobanko.",
    ff: "Sofo activation koɗɗi 1, ɓeenna to 1 ɓeyngel ndiyam.",
    yo: "Fun gbogbo iṣeto 1, 1 kirediti yoo gba lati akọọlẹ rẹ."
  },
  "If enabled, resellers will be able to log in to their devices using your DNS":
    {
      en: "If enabled, resellers will be able to log in to their devices using your DNS.",
      pt: "Se ativado, os revendedores poderão fazer login em seus dispositivos usando seu DNS.",
      de: "Wenn aktiviert, können Wiederverkäufer sich mit ihren Geräten über Ihr DNS anmelden.",
      es: "Si está habilitado, los revendedores podrán iniciar sesión en sus dispositivos utilizando su DNS.",
      fr: "Si activé, les revendeurs pourront se connecter à leurs appareils en utilisant votre DNS.",
      it: "Se abilitato, i rivenditori potranno accedere ai loro dispositivi utilizzando il tuo DNS.",
      sq: "Nëse aktivizohet, shitësit do të jenë në gjendje të kyçen në pajisjet e tyre duke përdorur DNS-in tuaj.",
      tr: "Etkinleştirildiğinde, bayiler cihazlarına sizin DNS'iniz ile giriş yapabilecekler.",
      sw: "Ikiwa imewezeshwa, wauzaji watakuwa na uwezo wa kuingia kwenye vifaa vyao kwa kutumia DNS yako.",
      am: "እንደምትፈቀድ፣ ተሸጣሪዎች በእባኮት ኤፒአይን በመጠቀም ለመገባት ይችላሉ።",
      om: "Yoo ta’eera, gabaa’oonni meeshalee isaanii gara DNS kee fayyadamuun seenuu ni danda’u.",
      ig: "Ọ bụrụ na emepechara, ndị na-ere ahịa ga-enwe ike ịbanye na ngwaọrụ ha site na iji DNS gị.",
      so: "Haddii la shaqeysiiyo, iibiyayaashu waxay awoodi doonaan inay ku galo qalabkooda adiga oo isticmaalaya DNS-kaaga.",
      ha: "Idan aka kunna, masu sayarwa za su iya shiga na’urorinsu ta amfani da DNS ɗinka.",
      mnk: "Sofo, resellers kafu kanbiri kafole ne?",
      ff: "Toongu, famɓe fotoraa waɗirii kuuma jango e DNS ɗum.",
      yo: "Ti o ba ti ni imudojuiwọn, awọn tita yoo ni anfani lati wọle si awọn ẹrọ wọn nipa lilo DNS rẹ."
    },
  "is equal to": {
    en: "is equal to",
    pt: "é igual a",
    de: "ist gleich",
    es: "es igual a",
    fr: "est égal à",
    it: "è uguale a",
    sq: "është e barabartë me",
    tr: "eşittir",
    sw: "ni sawa na",
    am: "እኩል ነው በ",
    om: "Waliin wal-qixxummaadha",
    ig: "Na-eko na",
    so: "Waa isku mid ah",
    ha: "Ya yi daidai da",
    mnk: "Kanbiri kanfɔ",
    ff: "Koɗɗi na",
    yo: "Dọgba pẹlu"
  },
  "IP address": {
    en: "IP address",
    pt: "Endereço IP",
    de: "IP-Adresse",
    es: "Dirección IP",
    fr: "Adresse IP",
    it: "Indirizzo IP",
    sq: "Adresa IP",
    tr: "IP adresi",
    sw: "Anwani ya IP",
    am: "አይፒ አድራሻ",
    om: "Teessoo IP",
    ig: "Adreesị IP",
    so: "Cinwaanka IP",
    ha: "Adireshin IP",
    mnk: "IP Jangoo",
    ff: "Koɗɗi IP",
    yo: "Adirẹsi IP"
  },
  ID: {
    en: "ID",
    pt: "ID",
    de: "ID",
    es: "ID",
    fr: "ID",
    it: "ID",
    sq: "ID",
    tr: "ID",
    sw: "ID",
    am: "መታወቂያ",
    om: "ID",
    ig: "ID",
    so: "ID",
    ha: "ID",
    mnk: "ID",
    ff: "ID",
    yo: "ID"
  },
  "Is trial": {
    en: "Is trial",
    pt: "É teste",
    de: "Ist Test",
    es: "Es prueba",
    fr: "Est un essai",
    it: "È prova",
    sq: "Është provë",
    tr: "Denemedir",
    sw: "Ni jaribio.",
    am: "እንደ ምርመራ እንደሆነ.",
    om: "Trial jira.",
    ig: "Ọ bụ ule.",
    so: "Waa tijaabo.",
    ha: "Shahararre ne.",
    mnk: "Kanbiri trial.",
    ff: "Habe ngaaji.",
    yo: "Ṣe idanwo."
  },
  "Is lifetime": {
    en: "Is lifetime",
    pt: "É vitalício",
    de: "Ist lebenslang",
    es: "Es de por vida",
    fr: "Est à vie",
    it: "È a vita",
    sq: "Është përjetësi",
    tr: "Sonsuzdur",
    sw: "Ni ya maisha.",
    am: "እንደምስክር እንደ ሕይወት ሁሉ.",
    om: "Is lifetime jira.",
    ig: "Ọ bụ ndụ niile.",
    so: "Waa cimri.",
    ha: "Rayuwa ne.",
    mnk: "Kanbiri lifetime.",
    ff: "Habe waaji.",
    yo: "Nipẹ igbesi aye."
  },
  "Is active": {
    en: "Is active",
    pt: "Está ativo",
    de: "Ist aktiv",
    es: "Está activo",
    fr: "Est actif",
    it: "È attivo",
    sq: "Është aktiv",
    tr: "Aktif mi",
    sw: "Ipo hai.",
    am: "እንደምስክር እንደ ሕይወት እንደሆነ.",
    om: "Active jira.",
    ig: "Ọ dị ndụ.",
    so: "Waxaa firfircoon.",
    ha: "Yana aiki.",
    mnk: "Kanbiri active.",
    ff: "Habe annde.",
    yo: "Ṣe nṣiṣe."
  },
  "Is online": {
    en: "Is online",
    pt: "Está online",
    de: "Ist online",
    es: "Está en línea",
    fr: "Est en ligne",
    it: "È online",
    sq: "Është në linjë",
    tr: "Çevrimi çevrimiçi",
    sw: "Ipo mtandaoni.",
    am: "እንደ አሁን እንደምስክር እንደ ድር አቀባ.",
    om: "Online jira.",
    ig: "Na intaneti.",
    so: "Online ayaa ah.",
    ha: "Yana kan layi.",
    mnk: "Kanbiri online.",
    ff: "Habe ndirda fota.",
    yo: "Wa lori ayelujara."
  },
  "Input name": {
    en: "Input name",
    pt: "Nome de entrada",
    de: "Eingabename",
    es: "Nombre de entrada",
    fr: "Nom d'entrée",
    it: "Nome di input",
    sq: "Emri i hyrjes",
    tr: "Girdi adı",
    sw: "Ingiza jina.",
    am: "እባኮት ስም ይግቡ.",
    om: "Maqaa galchii.",
    ig: "Tinye aha.",
    so: "Geli magaca.",
    ha: "Shigar da suna.",
    mnk: "Kafu wa nime.",
    ff: "Fii naŋa ndiyam.",
    yo: "Fi orukọ."
  },
  "Input Host": {
    en: "Input Host",
    pt: "Host de entrada",
    de: "Eingabe-Host",
    es: "Host de entrada",
    fr: "Hôte d'entrée",
    it: "Host di input",
    sq: "Hosti i hyrjes",
    tr: "Girdi Ana Bilgisi",
    sw: "Ingiza mwenyeji.",
    am: "እባኮት ባለሞያን እንደምስክር ስለ ሆን.",
    om: "Dirqamaawwan galchii.",
    ig: "Tinye onye njikwa.",
    so: "Geli martiinta.",
    ha: "Shigar da mai masaukin.",
    mnk: "Kafu wa jango.",
    ff: "Bii dantii bente.",
    yo: "Fi orukọ olutọju."
  },
  "Input API key": {
    en: "Input API key",
    pt: "Chave da API de entrada",
    de: "API-Schlüssel eingeben",
    es: "Clave de API de entrada",
    fr: "Clé API d'entrée",
    it: "Chiave API di input",
    sq: "Çelësi API i hyrjes",
    tr: "API anahtarını girin",
    sw: "Ingiza ufunguo wa API.",
    am: "እባኮት ቁልፍ ኤፒአይ እንዲገቡ.",
    om: "Furuma furma API.",
    ig: "Tinye igodo API.",
    so: "Geli furaha API.",
    ha: "Shigar da mabuɗin API.",
    mnk: "Nyaama jango API.",
    ff: "Fii kanɗa keye API.",
    yo: "Fi bọtini API."
  },
  "Input a number": {
    en: "Input a number",
    pt: "Insira um número",
    de: "Geben Sie eine Zahl ein",
    es: "Ingrese un número",
    fr: "Entrez un numéro",
    it: "Inserisci un numero",
    sq: "Vendos një numër",
    tr: "Bir sayı girin",
    sw: "Ingiza nambari.",
    am: "እባኮት ቁጥር ይግቡ.",
    om: "Lakkoofsa galchii.",
    ig: "Tinye nọmba.",
    so: "Geli lambar.",
    ha: "Shigar da lamba.",
    mnk: "Nyaama furuma.",
    ff: "Fii kanɗa naŋ.",
    yo: "Fi nọmba."
  },
  "Input access code": {
    en: "Input access code",
    pt: "Código de acesso de entrada",
    de: "Zugangscode eingeben",
    es: "Código de acceso de entrada",
    fr: "Code d'accès d'entrée",
    it: "Inserisci il codice di accesso",
    sq: "Vendosni kodin e hyrjes",
    tr: "Erişim kodunu girin",
    sw: "Ingiza nambari ya ufikiaji.",
    am: "እባኮት ኮድ ማስመሰያ እንዲገቡ.",
    om: "Koodii seenaa galchaa.",
    ig: "Tinye koodu nnweta.",
    so: "Geli koodhka helitaanka.",
    ha: "Shigar da lambar shiga.",
    mnk: "Nyaama koodo.",
    ff: "Fii kalngal koɗi.",
    yo: "Fi koodu iraye si."
  },
  "Insert the host of your Xtream Playlist": {
    en: "Insert the host of your Xtream Playlist",
    pt: "Insira o host da sua Playlist Xtream",
    de: "Geben Sie den Host Ihrer Xtream-Playlist ein",
    es: "Inserte el host de su lista de reproducción de Xtream",
    fr: "Insérez l'hôte de votre liste de lecture Xtream",
    it: "Inserisci l'host della tua Playlist Xtream",
    sq: "Vendosni hostin e listës së juaj Xtream",
    tr: "Xtream Oynatma Listenizin ana bilgisini girin",
    sw: "Weka mwenyeji wa Orodha ya Nyimbo yako ya Xtream.",
    am: "እባኮት ባለሞያን እንደ Xtream ምስክር ይስተላለፉ.",
    om: "Dirqamaawwan kee fayyadama Xtream kanbiroo galchii.",
    ig: "Tinye onye nwe playlist gị Xtream.",
    so: "Geli martiinta ee liiskaaga Xtream.",
    ha: "Shigar da mai masaukin Xtream Playlist ɗinku.",
    mnk: "Kafu wa jango Xtream.",
    ff: "Bii dantii bente Xtream.",
    yo: "Fi ifọwọyi orukọ olutọju Xtream Playlist rẹ."
  },
  "In first, create a title and a description for your text": {
    en: "In first, create a title and a description for your text.",
    pt: "Primeiro, crie um título e uma descrição para o seu texto.",
    de: "Erstellen Sie zuerst einen Titel und eine Beschreibung für Ihren Text.",
    es: "En primer lugar, cree un título y una descripción para su texto.",
    fr: "Tout d'abord, créez un titre et une description pour votre texte.",
    it: "Innanzitutto, crea un titolo e una descrizione per il tuo testo.",
    sq: "Fillimisht, krijoni një titull dhe një përshkrim për tekstin tuaj.",
    tr: "Öncelikle, metniniz için bir başlık ve açıklama oluşturun.",
    sw: "Kwanza, tengeneza kichwa na maelezo kwa maandiko yako.",
    am: "በመጀመሪያ ለጽሑፍዎ ርእስና ማተሚያ ይፍጠሩ.",
    om: "Jalqaba, mataa fi gubba wal makala kee.",
    ig: "Na mbụ, mee aha na nkọwa maka ederede gị.",
    so: "Marka hore, abuuro cinwaan iyo sharraxaad qoraalkaaga.",
    ha: "Da farko, ƙirƙiri take da bayani don rubutun ku.",
    mnk: "Banji, fooyii jooyoje.",
    ff: "Mbarika, juɓi toyo layii.",
    yo: "Ni akọkọ, ṣẹda akọle ati apejuwe fun ọrọ rẹ."
  },
  "It can take from few minutes to hours depending on the device count": {
    en: "It can take from a few minutes to hours depending on the device count.",
    pt: "Pode levar de alguns minutos a horas, dependendo da contagem de dispositivos.",
    de: "Es kann je nach Geräteanzahl einige Minuten bis Stunden dauern.",
    es: "Puede tomar desde unos minutos hasta horas, dependiendo de la cantidad de dispositivos.",
    fr: "Cela peut prendre quelques minutes à quelques heures en fonction du nombre de dispositifs.",
    it: "Ciò può richiedere da pochi minuti a ore, a seconda del numero di dispositivi.",
    sq: "Mund të zgjasë nga disa minuta deri në orë, në varësi të numrit të pajisjeve.",
    tr: "Cihaz sayısına bağlı olarak birkaç dakikadan saate kadar sürebilir.",
    sw: "Inaweza kuchukua kutoka kwa dakika chache hadi masaa kulingana na idadi ya vifaa.",
    am: "እንዲሁም ከጥቂት ደቂቃት እስከ ሰዓት እስከሰር ተረተ.",
    om: "Dhiibbaa yeroo irraa miinira dandeetti tooraa galatee.",
    ig: "O nwere ike iwepụta site na nkeji ole na ole ruo awa ndị dị iche iche dabere na ọnụ ọgụgụ ngwaọrụ.",
    so: "Waxay qaadan kartaa ilaa daqiiqado kooban ilaa saacado iyadoo ku xiran tirada qalabka.",
    ha: "Zai iya ɗaukar daga mintuna kaɗan zuwa sa'o'i gwargwadon adadin na'urorin.",
    mnk: "Sambew foo da caafoora beyubaa foogin.",
    ff: "Siye na muudu hane ɓen totali gwam.",
    yo: "O le gba lati iṣẹju diẹ si wakati diẹ da lori iye awọn ẹrọ."
  },
  "If you want to activate the device after deactivating it, you must spend 1 credit from your account":
    {
      en: "If you want to activate the device after deactivating it, you must spend 1 credit from your account.",
      pt: "Se desejar ativar o dispositivo após desativá-lo, você deve gastar 1 crédito da sua conta.",
      de: "Wenn Sie das Gerät nach der Deaktivierung aktivieren möchten, müssen Sie 1 Guthaben von Ihrem Konto verwenden.",
      es: "Si desea activar el dispositivo después de desactivarlo, debe gastar 1 crédito de su cuenta.",
      fr: "Si vous souhaitez activer l'appareil après l'avoir désactivé, vous devez dépenser 1 crédit de votre compte.",
      it: "Se desideri attivare il dispositivo dopo averlo disattivato, devi spendere 1 credito dal tuo account.",
      sq: "Nëse dëshironi të aktivizoni pajisjen pasi ta keni çaktivizuar, duhet të shpenzoni 1 kredit nga llogaria juaj.",
      tr: "Cihazı devre dışı bıraktıktan sonra etkinleştirmek istiyorsanız, hesabınızdan 1 kredi harcamanız gerekir.",
      sw: "Ikiwa unataka kuwasha kifaa baada ya kukizima, lazima utumie salio moja kutoka kwa akaunti yako.",
      am: "እንዲሁም ተተኪ የሆነ አንደ አሰባሰባ ይፍታል።",
      om: "Kuni xiqqoo keessaa eegalee si'aayi.",
      ig: "Ọ bụrụ na ịchọrọ ịmalite ngwaọrụ ahụ mgbe ị gachara, ị ga-eji 1 ndenye ego site na akaụntụ gị.",
      so: "Haddii aad rabto inaad awood siiso qalabka ka dib markaad damiso, waa inaad isticmaashaa 1 dhibic ka mid ah akoonkaaga.",
      ha: "Idan kana zaɓin nan yana kunne, kana buƙatar kashe kiredit ɗaya daga cikin asusunka.",
      mnk: "Pungwi na fanjii yeeyee ziti byie.",
      ff: "Xiɓe anduu baaku ɓen ne ku fanjaa.",
      yo: "Ti o ba fẹ lati mu ẹrọ ṣiṣẹ lẹẹkansi lẹhin ti o ti da a duro, o gbọdọ lo kirẹditi 1 lati ọdọ akọọlẹ rẹ."
    },

  "Incorrect password": {
    en: "Incorrect password",
    pt: "Senha incorreta",
    de: "Falsches Passwort",
    es: "Contraseña incorrecta",
    fr: "Mot de passe incorrect",
    it: "Password errata",
    sq: "Fjalëkalim i pasaktë",
    tr: "Yanlış şifre",
    sw: "Nenosiri si sahihi",
    am: "መልካም ስም አልተሳሳተም",
    om: "Jechoo dogoggora",
    ig: "Ozugbo ọnụ ọnụ erughị",
    so: "Fure wuxuu khalad yahay",
    ha: "Kalmar sirri ba ta dace ba",
    mnk: "Sirka fitowaa",
    ff: "Tooro won ga jee",
    yo: "Ọrọigbani n jẹ aṣiṣe"
  },
  "If this option is enabled, users will be able to log in to the app. If not, you will have to manually add the playlist to the device.":
    {
      en: "If this option is enabled, users will be able to log in to the app. If not, you will have to manually add the playlist to the device.",
      pt: "Se esta opção estiver ativada, os usuários poderão fazer login no aplicativo. Caso contrário, você terá que adicionar a lista de reprodução manualmente ao dispositivo.",
      de: "Wenn diese Option aktiviert ist, können sich Benutzer in der App anmelden. Andernfalls müssen Sie die Wiedergabeliste manuell zum Gerät hinzufügen.",
      es: "Si esta opción está habilitada, los usuarios podrán iniciar sesión en la aplicación. De lo contrario, deberá agregar la lista de reproducción al dispositivo manualmente.",
      fr: "Si cette option est activée, les utilisateurs pourront se connecter à l'application. Sinon, vous devrez ajouter la liste de lecture manuellement à l'appareil.",
      it: "Se questa opzione è abilitata, gli utenti potranno accedere all'app. In caso contrario, dovrai aggiungere manualmente la playlist al dispositivo.",
      sq: "Nëse ky opsion është i aktivizuar, përdoruesit do të jenë në gjendje të kyçen në aplikacion. Nëse jo, do të duhet ta shtoni manualisht listën e përgjërimeve në pajisjen.",
      tr: "Bu seçenek etkinleştirilirse, kullanıcılar uygulamaya giriş yapabileceklerdir. Aksi takdirde, oynatma listesini cihaza manuel olarak eklemeniz gerekecek.",
      sw: "Ikiwa chaguo hili limewezeshwa, watumiaji wataweza kuingia kwenye programu. Ikiwa sivyo, itabidi uongeze orodha ya nyimbo kwa kifaa kwa mikono.",
      am: "እስካሁን ቢቀጠል እንደምንኛ ሁሉም ትክክለኛ እታወቀዋል፤ ልትመልከቱን ምርጫ ይፈለጋሉ.",
      om: "Yoo bakka kana filatamaa tahe, fayyadamoonni app keessaa seenuu danda’u. Yoo ta’e immoo, playlist tajaajila kee irraanfachuu qabda.",
      ig: "Ọ bụrụ na nhọrọ a kwadoro, ndị ọrụ ga-enwe ike ịbanye na ngwa ahụ. Ọ bụrụ na ọ bụghị, ị ga-achọ itinye n'ụzọ aka playlist ahụ na ngwaọrụ.",
      so: "Haddii xulashadan la dhaqaajiyo, isticmaalayaashu waxay awoodi doonaan inay ku soo galaan app-ka. Haddii kale, waa inaad si gacanta ah ugu darto liiska heesaha qalabka.",
      ha: "Idan an zaɓin nan yana kunne, masu amfani za su iya shiga cikin aikace-aikacen. Idan ba haka ba, dole ne ka ƙara jerin waƙoƙi zuwa na'urar ta hannu.",
      mnk: "I ma jaati nyaa, jaa kafu noya kafu eyon wulo.",
      ff: "So wona yi njoo, foydaayi ɓe ngamoo fu njinɓe.",
      yo: "Ti aṣayan yii ba ti ṣiṣẹ, awọn olumulo yoo ni anfani lati wọle si ohun elo naa. Ti kii ba bẹ, iwọ yoo ni lati fi playlist kun si ẹrọ naa ni ọwọ."
    },
  "It is available for Smart TVs and a new mobile version": {
    en: "It is available for Smart TVs and a new mobile version. You can already set a default language for the app from here, making it easier to adapt the app for different language preferences. It is available for Smart TVs and a new mobile version.",
    pt: "Está disponível para Smart TVs e uma nova versão móvel. Você já pode definir um idioma padrão para o aplicativo daqui, facilitando a adaptação para diferentes preferências de idioma. Está disponível para Smart TVs e uma nova versão móvel.",
    de: "Es ist für Smart-TVs und eine neue mobile Version verfügbar. Sie können hier bereits eine Standardsprache für die App festlegen, um die App an verschiedene Sprachpräferenzen anzupassen. Es ist für Smart-TVs und eine neue mobile Version verfügbar.",
    es: "Está disponible para Smart TVs y una nueva versión móvil. Ya puede configurar un idioma predeterminado para la aplicación desde aquí, lo que facilita la adaptación de la aplicación a diferentes preferencias de idioma. Está disponible para Smart TVs y una nueva versión móvil.",
    fr: "Il est disponible pour les Smart TV et une nouvelle version mobile. Vous pouvez déjà définir une langue par défaut pour l'application ici, ce qui facilite l'adaptation de l'application aux différentes préférences linguistiques. Il est disponible pour les Smart TV et une nouvelle version mobile.",
    it: "È disponibile per Smart TV e una nuova versione mobile. Puoi già impostare una lingua predefinita per l'app da qui, rendendo più semplice adattare l'app alle diverse preferenze linguistiche. È disponibile per Smart TV e una nuova versione mobile.",
    sq: "Është e disponueshme për Smart TV dhe një version të ri celular. Ju tashmë mund të vendosni një gjuhë të paracaktuar për aplikacionin nga këtu, duke e bërë më të lehtë përshtatjen e aplikacionit për preferencat e ndryshme gjuhësore. Është e disponueshme për Smart TV dhe një version të ri celular.",
    tr: "Akıllı TV'ler ve yeni bir mobil sürüm için kullanılabilir. Buradan uygulama için varsayılan bir dil ayarlayarak uygulamanın farklı dil tercihleri için uyarlanmasını kolaylaştırabilirsiniz. Akıllı TV'ler ve yeni bir mobil sürüm için kullanılabilir.",
    sw: "Inapatikana kwa Smart TVs na toleo jipya la simu. Unaweza tayari kuweka lugha ya chaguo-msingi kwa programu kutoka hapa, kufanya iwe rahisi kubadilisha programu kwa mapendeleo ya lugha tofauti.",
    am: "እሱ ለስማርት ቲቪዎች እና አዲስ ሞባይል ስርዓት ተደርሷል። እርስዎ በዚህ ቦታ ለአፕ በአዲስ ቋንቋ እንዲወቀሱ እንደተቀመጠ ይችላሉ፣ በዚህ ቋንቋ የተለያዩን አጀንዳ በማቀላጠፍ እንዲቀረበው።",
    om: "Inni tajaajilamuu yoo Smart TVs fi haala gabatee haaraa ykn sochii moobiilawwan biroo kennama. Qabiyyee maqaaf deetaa dabalata kana godhuuf jecha, afaan tarreeffama gargaarsa fayyadama, dirree keessatti kaa’amuu.",
    ig: "O dị maka Smart TVs na ụdị ọhụrụ nke mobile. Ị nwere ike itinye asụsụ ndabara maka ngwa site ebe a, nke na-eme ka ọ dị mfe ịkwado ngwa maka mmasị asụsụ dị iche iche.",
    so: "Waa ku jira Smart TVs iyo nooca cusub ee moobiilka. Waxa aad horey u dejin kartaa luuqada default ee app-ka halkan, taasoo sahlaysa in la qabsado app-ka iyadoo la eegayo doorashada luuqadaha kala duwan.",
    ha: "Ana samuwa ga Smart TVs da sabuwar sigar wayar hannu. Zaka iya riga ka saita yaren da aka saba don app daga nan, wanda zai sa ya fi sauƙi don daidaita app don zaɓuɓɓukan yare daban-daban.",
    mnk: "Nteɛmɛ buo e kot na Smart TVs ee mobile kasa a kyɛba atto nnɔ",
    ff: "Ɓe wiye worriye Smart TVs wo feere ɗoo moobi ɗoo ayum.",
    yo: "O wa fun Smart TVs ati ẹya tuntun ti foonu alagbeka. O le ṣeto ede aiyipada fun ohun elo lati ibi, ṣiṣe irọrun lati ṣe deede ohun elo fun awọn ayanfẹ ede oriṣiriṣi."
  },
  //y
  "Your activations count": {
    en: "Your activations count",
    pt: "Sua contagem de ativações",
    de: "Ihre Aktivierungsanzahl",
    es: "Su cuenta de activaciones",
    fr: "Votre nombre d'activations",
    it: "Il conteggio delle tue attivazioni",
    sq: "Numri i aktivizimeve tuaja",
    tr: "Aktivasyon sayınız",
    sw: "Idadi ya mabadiliko yako",
    am: "እባኮት ማስተካከያዎች የምርት",
    om: "Lakkoofsa hojii kee",
    ig: "Ọnụọgụgụ ịmalite gị",
    so: "Tirada firfircooniyadaada",
    ha: "Yawan kunna naka",
    mnk: "Kafoi bɛfi gofa",
    ff: "Bille keema taaree",
    yo: "Iye awọn iṣẹ akanṣe rẹ"
  },
  "Your subresellers activations count": {
    en: "Your subresellers activations count",
    pt: "Contagem de ativações dos seus sub-revendedores",
    de: "Aktivierungsanzahl Ihrer Unterhändler",
    es: "Su cuenta de activaciones de subrevendedores",
    fr: "Le nombre d'activations de vos sous-revendeurs",
    it: "Conteggio delle attivazioni dei tuoi sub-rivenditori",
    sq: "Numri i aktivizimeve të nën-shitësve tuaj",
    tr: "Alt bayilerinizin aktivasyon sayısı",
    sw: "Idadi ya mabadiliko ya wauzaji wako wa chini",
    am: "እባኮት ማስተካከያዎች የምርት",
    om: "Lakkoofsa hojii hamtuu keenya",
    ig: "Ọnụọgụgụ ịmalite nke ndị na-ere gị",
    so: "Tirada firfircooniyada iibiyeyaashaada hoose",
    ha: "Yawan kunna na masu sayarwa na ƙasa",
    mnk: "Wusorii bɛnfi gofa.",
    ff: "Bille keema taaree",
    yo: "Iye awọn iṣẹ akanṣe awọn olupese rẹ"
  },
  "Your subresellers devices": {
    en: "Your subresellers devices",
    pt: "Dispositivos dos seus sub-revendedores",
    de: "Geräte Ihrer Unterhändler",
    es: "Dispositivos de sus subrevendedores",
    fr: "Les appareils de vos sous-revendeurs",
    it: "Dispositivi dei tuoi sub-rivenditori",
    sq: "Pajisjet e nën-shitësve tuaj",
    tr: "Alt bayilerinizin cihazları",
    sw: "Vifaa vya wauzaji wako wa chini",
    am: "መስተዋቢዎች እባኮት ተስፋ በምርት",
    om: "Qabiyyee horii tii",
    ig: "Ngwaọrụ nke ndị na-ere gị",
    so: "Qalabka iibiyeyaashaada hoose",
    ha: "Na'urorin masu sayarwa na ƙasa",
    mnk: "Mɛnna suyi bɛn",
    ff: "Nyaamaɗi jemma konngo",
    yo: "Awọn ẹrọ awọn olupese rẹ"
  },
  "Your Free Trial Has Ended": {
    en: "Your Free Trial Has Ended",
    pt: "Seu Período de Teste Gratuito Terminou",
    de: "Ihre kostenlose Testversion ist abgelaufen",
    es: "Su prueba gratuita ha finalizado",
    fr: "Votre essai gratuit est terminé",
    it: "Il tuo periodo di prova gratuito è terminato",
    sq: "Periudha juaj falas e provës ka përfunduar",
    tr: "Ücretsiz deneme süreniz sona erdi",
    sw: "Jaribio lako la bure limemalizika.",
    am: "ምንም አንዴ አሁን ለተሳካ ተጠቀሙ.",
    om: "Manni fudhatama keessa deemeera.",
    ig: "Ndị nnwale gị efuola.",
    so: "Tijaarkaaga bilaashka ayaa dhammaatay.",
    ha: "Gwajin kuɗi na kyauta ya ƙare.",
    mnk: "Mɛnna suyi bɛn",
    ff: "Tama fii waaltere",
    yo: "Igbimọ ọfẹ rẹ ti pari."
  },
  Yes: {
    en: "Yes",
    pt: "Sim",
    de: "Ja",
    es: "Sí",
    fr: "Oui",
    it: "Sì",
    sq: "Po",
    tr: "Evet",
    sw: "Ndiyo",
    am: "አዎን",
    om: "Eeyyee",
    ig: "Ee",
    so: "Haa",
    ha: "Eh",
    mnk: "Awa",
    ff: "Eey",
    yo: "Bẹẹni"
  },
  "Your guide": {
    en: "Your guide",
    pt: "Seu guia",
    de: "Ihr Leitfaden",
    es: "Tu guía",
    fr: "Votre guide",
    it: "La tua guida",
    sq: "Udhëzuesi juaj",
    tr: "Rehberiniz",
    sw: "Mwongozo wako",
    am: "መመሪያዎት",
    om: "Qajeelfama kee",
    ig: "Nduzi gị",
    so: "Hageeridaada",
    ha: "Jagoran ka",
    mnk: "Kuɲɔwɗo",
    ff: "Biiɗoo",
    yo: "Itọsọna rẹ"
  },
  "Your devices": {
    en: "Your devices",
    pt: "Seus dispositivos",
    de: "Ihre Geräte",
    es: "Tus dispositivos",
    fr: "Vos appareils",
    it: "I tuoi dispositivi",
    sq: "Pajisjet tuaja",
    tr: "Cihazlarınız",
    sw: "Vifaa vyako",
    am: "እባኮት ምንባብ",
    om: "Qabiyyee kee",
    ig: "Ngwaọrụ gị",
    so: "Qalabkaaga",
    ha: "Na'urorinka",
    mnk: "Fatoo wulo",
    ff: "Nyaamaɗi",
    yo: "Awọn ẹrọ rẹ"
  },
  "You cannot use branding": {
    en: "You cannot use branding!",
    pt: "Você não pode usar marca!",
    de: "Sie können keine Marken verwenden!",
    es: "¡No puedes usar la marca!",
    fr: "Vous ne pouvez pas utiliser de marque !",
    it: "Non puoi usare il marchio!",
    sq: "Nuk mund të përdorni markën!",
    tr: "Marka kullanamazsınız!",
    sw: "Huwezi kutumia nembo!",
    am: "አስተኛ ተጠቀምም!",
    om: "Maqaan ummataa wantota hin qabu!",
    ig: "Ị nweghị ike iji akara ngosi!",
    so: "Ma isticmaali kartid calaamadda!",
    ha: "Ba za ka iya amfani da alama ba!",
    mnk: "Tii kɛtɛ donwá",
    ff: "Bafaa ree hoɗaa",
    yo: "O ko le lo ami-ami!"
  },
  "You will get email to email": {
    en: "You will get email to email",
    pt: "Você receberá um e-mail para o e-mail",
    de: "Sie erhalten eine E-Mail an die E-Mail-Adresse",
    es: "Recibirás un correo electrónico a tu correo electrónico",
    fr: "Vous recevrez un e-mail à l'adresse e-mail",
    it: "Riceverai un'e-mail all'indirizzo e-mail",
    sq: "Do të merrni email në email",
    tr: "E-posta almak için e-posta alacaksınız",
    sw: "Utapokea barua pepe kwenye barua pepe.",
    am: "በተስፋፋ ይጠቀማል ሰበትላ እባኮት እስኪ",
    om: "Meessaji kee emailii wajjiraa.",
    ig: "Ị ga-enweta ozi email na email.",
    so: "Waxaad heli doontaa email aad emailkaaga.",
    ha: "Za ku karɓi imel zuwa imel.",
    mnk: "Yooru jaatenday",
    ff: "Sang huudijo huundi ne",
    yo: "I yoo gba imeeli si imeeli."
  },
  "Your account has been blocked": {
    en: "Your account has been blocked!",
    pt: "Sua conta foi bloqueada!",
    de: "Ihr Konto wurde gesperrt!",
    es: "Tu cuenta ha sido bloqueada!",
    fr: "Votre compte a été bloqué !",
    it: "Il tuo account è stato bloccato!",
    sq: "Llogaria juaj është bllokuar!",
    tr: "Hesabınız engellendi!",
    sw: "Akaunti yako imezuiwa!",
    am: "አካውንትዎ ተከላቸው!",
    om: "Akaawuntii kee cufame!",
    ig: "Akaụntị gị e mepụtara!",
    so: "Akaawnkhtadaada ayaa la xannibay!",
    ha: "An yi toƙarinka!",
    mnk: "Akan noorɔ",
    ff: "Jowtora kene",
    yo: "Akaunti rẹ ti dina!"
  },

  "Your old password and new password are same": {
    en: "Your old password and new password are the same",
    pt: "Sua senha antiga e nova são iguais",
    de: "Ihr altes Passwort und Ihr neues Passwort sind identisch",
    es: "Tu antigua contraseña y nueva contraseña son iguales",
    fr: "Votre ancien mot de passe et votre nouveau mot de passe sont identiques",
    it: "La tua vecchia password e la nuova password sono uguali",
    sq: "Fjalëkalimi i vjetër dhe i ri janë të njëjtë",
    tr: "Eski şifreniz ve yeni şifreniz aynıdır",
    sw: "Neno lako la siri la zamani na jipya ni sawa.",
    am: "ያህል ያለ ፈተሻ ስለሆነ",
    om: "Maqqannaa mootummaa duraanii fi haaraa dhugaa waliinii jedhuu",
    ig: "Okwu izizi gị na okwu ọhụrụ bụ otu.",
    so: "Furahaadaadii hore iyo furaha cusub waa isku mid.",
    ha: "Tsohon kalmomin ku da sabon kalmomi guda daya.",
    mnk: "Fóyé ɓi mɔlɔ kɔɔrɔ",
    ff: "Suɓe njarondi",
    yo: "Ọrọ igbaniwọle atijọ rẹ ati ọrọ igbaniwọle tuntun jẹ kanna."
  },
  "Your playlist will be autogenerated": {
    en: "Your playlist will be autogenerated",
    pt: "Sua lista de reprodução será gerada automaticamente",
    de: "Ihre Wiedergabeliste wird automatisch generiert",
    es: "Su lista de reproducción se generará automáticamente",
    fr: "Votre playlist sera générée automatiquement",
    it: "La tua playlist verrà generata automaticamente",
    sq: "Lista juaj e përgjegjësive do të gjenerohet automatikisht",
    tr: "Oynatma listeniz otomatik olarak oluşturulacaktır",
    sw: "Orodha yako ya nyimbo itajitengeneza yenyewe.",
    am: "የምርት ቅርጸ ምንጭ ለምስጋናው ይኸው።",
    om: "Mataabaqaa haarawa uumamaa gudoomsa hin bartu",
    ig: "A ga-emepụta playlist gị n'ikike nkeonwe.",
    so: "Liiskaaga heesaha waxaa si toos ah loo abuurayaa.",
    ha: "Za a kirkiri jerin waƙoƙinku ta atomatik.",
    mnk: "Kɛfɛ feᵽa wotuma",
    ff: "Liya sonde nyaama waɗta",
    yo: "A yoo ṣẹda atokọ orin rẹ laifọwọyi."
  },
  "Your administrator restricted your permissions": {
    en: "Your administrator restricted your permissions",
    pt: "Seu administrador restringiu suas permissões",
    de: "Ihr Administrator hat Ihre Berechtigungen eingeschränkt",
    es: "Su administrador restringió sus permisos",
    fr: "Votre administrateur a restreint vos permissions",
    it: "Il tuo amministratore ha limitato le tue autorizzazioni",
    sq: "Administruesi juaj ka kufizuar lejet tuaja",
    tr: "Yönetici, izinlerinizi kısıtladı",
    sw: "Msimamizi wako amezuia ruhusa zako.",
    am: "አስተዳደርዎ ፈቃዶችን አዘጋጅሁ።",
    om: "Gorsa dhugaaf weerya akka gooneen.",
    ig: "Nchekwa gị ejirila ikike gị.",
    so: "Maamulahaaga ayaa xaddiday oggolaanshahaaga.",
    ha: "Mai gudanarwa ya takaita izinin ku.",
    mnk: "Ñi munu ke ɓa fataa wate nuunu",
    ff: "Saayre laatuu waɗɗo suude wa",
    yo: "Alakoso rẹ ti dín awọn igbanilaaye rẹ."
  },
  "You can use this tool to send notifications to your users": {
    en: "You can use this tool to send notifications to your users.",
    pt: "Você pode usar esta ferramenta para enviar notificações aos seus usuários.",
    de: "Sie können dieses Tool verwenden, um Benachrichtigungen an Ihre Benutzer zu senden.",
    es: "Puede utilizar esta herramienta para enviar notificaciones a sus usuarios.",
    fr: "Vous pouvez utiliser cet outil pour envoyer des notifications à vos utilisateurs.",
    it: "Puoi utilizzare questo strumento per inviare notifiche ai tuoi utenti.",
    sq: "Mund të përdorni këtë mjet për të dërguar njoftime tek përdoruesit tuaj.",
    tr: "Bu aracı kullanıcılarınıza bildirim göndermek için kullanabilirsiniz.",
    sw: "Unaweza kutumia chombo hiki kutuma arifa kwa watumiaji wako.",
    am: "በእባኮት ይህን መሣሪያ ለእባኮት አንዴ መስማሪ",
    om: "Ummataa sirna hojii dorgommii hojii gooree hinde",
    ig: "Ị nwere ike iji ngwaọrụ a izipu mkpesa na ndị ọrụ gị.",
    so: "Waad isticmaali kartaa qalabkan si aad u dirto digniino isticmaalaashaada.",
    ha: "Zaka iya amfani da wannan kayan aikin don tura sanarwa ga masu amfani da kai.",
    mnk: "Nteme bɛ yitoo safoo",
    ff: "Ngun meeco ɗum anndi ndyare wa.",
    yo: "O le lo ọpa yii lati fi awọn iwifunni ranṣẹ si awọn olumulo rẹ."
  },
  "You have to wait until migration will be ended successfully": {
    en: "You have to wait until migration will be ended successfully.",
    pt: "Você precisa esperar até que a migração seja concluída com sucesso.",
    de: "Sie müssen warten, bis die Migration erfolgreich abgeschlossen ist.",
    es: "Debe esperar hasta que la migración se haya completado con éxito.",
    fr: "Vous devez attendre que la migration se termine avec succès.",
    it: "Devi aspettare che la migrazione sia completata con successo.",
    sq: "Duhet të prisni derisa migrimi të përfundojë me sukses.",
    tr: "Göç başarılı bir şekilde tamamlanana kadar beklemelisiniz.",
    sw: "Lazima usubiri hadi uhamishaji utakapoisha kwa mafanikio.",
    am: "እባኮት በተስፋፋ ተምረቱን ማዕረግ አላቸው.",
    om: "Gorsa taasifata jechoo goongumattii marii heyyuuti.",
    ig: "Ị ga-achọ ichere ruo mgbe njem ahụ ga-adịcha nke ọma.",
    so: "Waa inaad sugto ilaa guuritaanka uu si guul leh ku dhammaado.",
    ha: "Dole ne ka jira har sai canjin ya kammala cikin nasara.",
    mnk: "Tii nàyá kɛrɛ so kɛɲɔ",
    ff: "Tama keeta hite jooɗii goɓere to sowre",
    yo: "O ni lati duro titi de igba ti gbigbe yoo pari ni aṣeyọri."
  },
  "You can set up devices to be activated at once, after logging in": {
    en: "You can set up devices to be activated at once, after logging in.",
    pt: "Você pode configurar dispositivos para serem ativados imediatamente após o login.",
    de: "Sie können Geräte so einrichten, dass sie sofort nach dem Einloggen aktiviert werden.",
    es: "Puede configurar dispositivos para que se activen al instante después de iniciar sesión.",
    fr: "Vous pouvez configurer des appareils pour être activés immédiatement après la connexion.",
    it: "Puoi configurare i dispositivi per essere attivati immediatamente dopo il login.",
    sq: "Mund të konfiguroni pajisjet që të aktivizohen menjëherë, pas hyrjes në llogari.",
    tr: "Giriş yaptıktan sonra birden fazla cihazı etkinleştirmek için ayarlayabilirsiniz.",
    sw: "Unaweza kuweka vifaa viwe activated mara moja, baada ya kuingia.",
    am: "ተሞላ ተሞላን ያህል ይጠቀማል",
    om: "Hojii dhugaa doo fi dorgomnii sirri hinde",
    ig: "Ị nwere ike ịhazi ngwaọrụ ka ha mepee ozugbo, mgbe ịbanye.",
    so: "Waxaad dejin kartaa qalabka si ay si deg deg ah u noqdaan firfircoon, ka dib marka aad gasho.",
    ha: "Zaka iya saitin na'urori su kunna su nan take, bayan shiga.",
    mnk: "Faa nɛnna xaalami, kafu na kiyo",
    ff: "Tuna to diwo birin no towa ndyarri",
    yo: "O le ṣeto awọn ẹrọ lati mu ṣiṣẹ lẹsẹkẹsẹ, lẹhin ti o buwọlu."
  },
  "You must become a Partner in order to use the functionality on this page": {
    en: "You must become a Partner in order to use the functionality on this page.",
    pt: "Você deve se tornar um Parceiro para usar a funcionalidade nesta página.",
    de: "Sie müssen Partner werden, um die Funktion auf dieser Seite nutzen zu können.",
    es: "Debe convertirse en un Socio para poder utilizar la funcionalidad de esta página.",
    fr: "Vous devez devenir un Partenaire pour utiliser les fonctionnalités de cette page.",
    it: "Devi diventare un Partner per utilizzare le funzionalità di questa pagina.",
    sq: "Duhet të bëheni Partner për të përdorur funksionalitetin në këtë faqe.",
    tr: "Bu sayfadaki işlevselliği kullanmak için Partner olmalısınız.",
    sw: "Lazima uwe partner ili kutumia kipengele kilichopo kwenye ukurasa huu.",
    am: "ተከታታይ አባል ስለሆነ በእባኮት እንዴት ስማም ስንት",
    om: "Yoo jiraa dhugaaf hin qabuuf sirnicha caaluuf barsiisaa",
    ig: "Ị ga-enwerịrị ịbụ onye mmekọ iji jiri ọrụ dị na peeji a.",
    so: "Waa inaad noqoto Wadaag si aad u isticmaasho hawsha boggan.",
    ha: "Dole ne ka zama Abokin hulɗa don amfani da aikin a wannan shafin.",
    mnk: "Bali ame kɛfɛ jaa fali cɛrɛ",
    ff: "Eke tawaaki woni fɔw ko duno",
    yo: "O gbọdọ di alabaṣepọ lati lo iṣẹ lori oju-iwe yii."
  },
  "You can give Free trial to your users. Free trial can be between 1 and 7 days":
    {
      en: "You can give Free trial to your users. Free trial can be between 1 and 7 days.",
      pt: "Você pode oferecer um teste gratuito aos seus usuários. O teste gratuito pode ter duração de 1 a 7 dias.",
      de: "Sie können Ihren Benutzern eine kostenlose Testversion anbieten. Die kostenlose Testversion kann zwischen 1 und 7 Tagen liegen.",
      es: "Puede ofrecer una prueba gratuita a sus usuarios. La prueba gratuita puede ser de 1 a 7 días.",
      fr: "Vous pouvez offrir un essai gratuit à vos utilisateurs. L'essai gratuit peut durer de 1 à 7 jours.",
      it: "Puoi offrire una prova gratuita ai tuoi utenti. La prova gratuita può durare da 1 a 7 giorni.",
      sq: "Ju mund të jepni provë falas për përdoruesit tuaj. Prova falas mund të jetë nga 1 deri në 7 ditë.",
      tr: "Kullanıcılarınıza ücretsiz deneme verebilirsiniz. Ücretsiz deneme süresi 1 ila 7 gün arasında olabilir.",
      sw: "Unaweza kutoa majaribio ya bure kwa watumiaji wako. Majaribio ya bure yanaweza kuwa kati ya siku 1 na 7.",
      am: "ተመርጦ ያልዘዎት ስራ እናም ቀንቀን",
      om: "Tajaajilaa bilisaa ummataa kenni dhaqi si moraa fi beeyyuumaan gara soochii duraa bashaachisuutu",
      ig: "Ị nwere ike inye ndị ọrụ gị nlele n'efu. Nlele n'efu nwere ike ịbụ site na ụbọchị 1 ruo 7.",
      so: "Waxaad siin kartaa isticmaalayaashaada tijaabo bilaash ah. Tijaabada bilaashka waxay noqon kartaa inta u dhaxaysa 1 ilaa 7 maalmood.",
      ha: "Zaka iya bayar da gwaji kyauta ga masu amfani da kai. Gwajin kyauta na iya kasancewa daga kwanaki 1 zuwa 7.",
      mnk: "Konjo di woowɔtɛ muso banta kwiyume",
      ff: "Duumon jowha e jodigosi ndyarre",
      yo: "O le fun awọn olumulo rẹ idanwo ọfẹ. Idanwo ọfẹ le wa laarin ọjọ 1 ati 7."
    },
  "You will get email message about the results of the migration in the end of the process":
    {
      en: "You will get email message about the results of the migration in the end of the process.",
      pt: "Você receberá uma mensagem de e-mail sobre os resultados da migração no final do processo.",
      de: "Sie erhalten eine E-Mail-Nachricht über die Ergebnisse der Migration am Ende des Prozesses.",
      es: "Recibirá un mensaje de correo electrónico sobre los resultados de la migración al final del proceso.",
      fr: "Vous recevrez un message électronique concernant les résultats de la migration à la fin du processus.",
      it: "Riceverai un messaggio di posta elettronica sui risultati della migrazione alla fine del processo.",
      sq: "Ju do të merrni një mesazh emaili për rezultatet e migrimit në fund të procesit.",
      tr: "Göç sonuçları hakkında bir e-posta mesajı alacaksınız, işlemin sonunda.",
      sw: "Utapokea ujumbe wa barua pepe kuhusu matokeo ya uhamishaji mwishoni mwa mchakato.",
      am: "ስለ ሂደቱ መጨረሻ እባኮት አምሳል እስክ ትኩር!",
      om: "Ibsaa godhamii jechoo gabaasaan yaada hama hewlatti",
      ig: "Ị ga-enweta ozi email banyere nsonaazụ nke njem a na njedebe nke usoro ahụ.",
      so: "Waxaad heli doontaa fariin email ah oo ku saabsan natiijooyinka guuritaanka dhamaadka geedi socodka.",
      ha: "Za ku karɓi saƙon imel game da sakamakon canjin a ƙarshen aikin.",
      mnk: "Yɔrɔ ɓi fɔtɔ saran boorok",
      ff: "Safe tii dowtuwoy fumbé",
      yo: "I yoo gba ifiranṣẹ imeeli nipa abajade gbigbe ni ipari ilana."
    },
  "You can choose: Give the device free trial": {
    en: "You can choose: Give the device free trial or activate without a free trial. Free trial can be from 1 to 7 days.",
    pt: "Você pode escolher: Dar ao dispositivo um teste gratuito ou ativá-lo sem um teste gratuito. O teste gratuito pode ser de 1 a 7 dias.",
    de: "Sie können wählen: Geben Sie dem Gerät eine kostenlose Testversion oder aktivieren Sie es ohne kostenlose Testversion. Die kostenlose Testversion kann von 1 bis 7 Tagen dauern.",
    es: "Puede elegir: Dar al dispositivo una prueba gratuita o activarlo sin una prueba gratuita. La prueba gratuita puede ser de 1 a 7 días.",
    fr: "Vous pouvez choisir : Donner un essai gratuit à l'appareil ou l'activer sans essai gratuit. L'essai gratuit peut durer de 1 à 7 jours.",
    it: "Puoi scegliere: Dare al dispositivo una prova gratuita o attivarlo senza una prova gratuita. La prova gratuita può durare da 1 a 7 giorni.",
    sq: "Ju mund të zgjidhni: Dhënia e provës falas të pajisjes ose aktivizimi pa një provë falas. Prova falas mund të jetë nga 1 deri në 7 ditë.",
    tr: "Şunu seçebilirsiniz: Cihaza ücretsiz deneme verin veya ücretsiz deneme olmadan aktive edin. Ücretsiz deneme süresi 1 ila 7 gün arasında olabilir.",
    sw: "Unaweza kuchagua: Toa kifaa kwa majaribio ya bure au uamilishe bila majaribio ya bure. Majaribio ya bure yanaweza kuwa kutoka siku 1 hadi 7.",
    am: "ምርጫ ማስተላለፊያ: ተመን ማስተላለፊያ እንዲሆን ወይም በማስተላለፊያ ማቅረብ.",
    om: "Filannoo gochuu dandeessa: Meeshaa tajaajilaa bilisaa kenni yookiin meeshaa bilisaa malee hojii jalqabi. Tajaajilaan bilisaa guyyaa 1 hanga 7.",
    ig: "Ị nwere ike ịhọrọ: Nye ngwaọrụ ahụ nlele n'efu ma ọ bụ mepee ya na-enweghị nlele n'efu. Nlele n'efu nwere ike ịbụ site na ụbọchị 1 ruo 7.",
    so: "Waxaad dooran kartaa: Siinta qalabka tijaabo bilaash ah ama u firfircoonid la'aan tijaabo bilaash ah. Tijaabada bilaashka waxay noqon kartaa 1 ilaa 7 maalmood.",
    ha: "Zaka zaɓar: Bayar da na'ura gwaji kyauta ko kunna shi ba tare da gwaji kyauta ba. Gwajin kyauta na iya kasancewa daga kwanaki 1 zuwa 7.",
    mnk: "Fiin, ɓi jute ne ɓe dɔnke",
    ff: "Bamsi safey reelu wopfi loore",
    yo: "O le yan: Fun ẹrọ naa idanwo ọfẹ tabi muu ṣiṣẹ laisi idanwo ọfẹ. Idanwo ọfẹ le wa lati ọjọ 1 si 7."
  },
  "You can click on the checkbox and let our system auto renew your device activation after one year from activation date.":
    {
      en: "You can click on the checkbox and let our system auto renew your device activation after one year from activation date.",
      pt: "Você pode clicar na caixa de seleção e permitir que nosso sistema renove automaticamente a ativação do seu dispositivo após um ano a partir da data de ativação.",
      de: "Sie können auf das Kontrollkästchen klicken und unser System automatisch die Aktivierung Ihres Geräts nach einem Jahr ab dem Aktivierungsdatum erneuern lassen.",
      es: "Puede hacer clic en la casilla de verificación e permitir que nuestro sistema renueve automáticamente la activación de su dispositivo después de un año desde la fecha de activación.",
      fr: "Vous pouvez cliquer sur la case à cocher et laisser notre système renouveler automatiquement l'activation de votre appareil après un an à compter de la date d'activation.",
      it: "Puoi fare clic sulla casella di controllo e permettere al nostro sistema di rinnovare automaticamente l'attivazione del tuo dispositivo dopo un anno dalla data di attivazione.",
      sq: "Ju mund të klikoni në kutinë e zgjedhjes dhe lejoni sistemin tonë të riaftësojë automatikisht aktivizimin e pajisjes suaj pas një viti nga data e aktivizimit.",
      tr: "Onay kutusuna tıklayabilir ve sistemimizin cihazınızın aktivasyonunu, aktivasyon tarihinden bir yıl sonra otomatik olarak yenilemesine izin verebilirsiniz.",
      sw: "Unaweza kubofya kisanduku cha kuangalia na kuruhusu mfumo wetu uendelee kuamilisha kifaa chako baada ya mwaka mmoja tangu tarehe ya uanzishaji.",
      am: "በእባኮት በቁልፍ ስም እባኮትን አልሰርየም",
      om: "Tuqii irratti qixxeessa seenuu fi sirna keenyaaf hayyama kenni kan hojii isaanii fi adda bokkira jalqaba keessa biyya keessaa sirnaaf.",
      ig: "Ị nwere ike pịa igbe nhọrọ ahụ ma mee ka usoro anyị nyochaa na-akpaghị aka ịmaliteghachi mmezi nke ngwaọrụ gị mgbe otu afọ gasịrị site na ụbọchị mmeghe.",
      so: "Waad gujin kartaa sanduuqa doorashada oo u oggolow nidaamkayaga inuu si toos ah u cusbooneysiiyo firfircoonida qalabkaaga halka sanad kadib taariikhda firfircoonida.",
      ha: "Za ka iya danna akwatin zaɓi kuma bari tsarinmu ya sabunta ta atomatik don sabunta kunna na'ura ɗinka bayan shekara ɗaya daga ranar kunna.",
      mnk: "Tii yiterexe kafu fɛlɛ cɛn kɔ naŋ kɔrɔ ka bɔɔ kɛrɛ kɔfɛ.",
      ff: "O jooɗii hakke gooɗii ɓe ngooɗi",
      yo: "O le tẹ apoti aṣayan ki o si jẹ ki eto wa tunto laifọwọyi ṣiṣẹlẹ fun imudojuiwọn igbekale ẹrọ rẹ lẹhin ọdun kan lati ọjọ imudojuiwọn."
    },
  "You can click on checkbox and let our system": {
    en: "You can click on the checkbox and let our system",
    pt: "Você pode clicar na caixa de seleção e deixar nosso sistema",
    de: "Sie können das Kontrollkästchen aktivieren und unser System",
    es: "Puede hacer clic en la casilla de verificación y dejar que nuestro sistema",
    fr: "Vous pouvez cliquer sur la case à cocher et laisser notre système",
    it: "Puoi fare clic sulla casella di controllo e lasciare che il nostro sistema",
    sq: "Mund të klikoni në kutinë e zgjedhjes dhe lejoni sistemin tonë",
    tr: "Onay kutusuna tıklayabilir ve sistemimizin",
    sw: "Unaweza kubofya kisanduku cha kuangalia na kuruhusu mfumo wetu",
    am: "እባኮት በእባኮት እባኮት እባኮት እና",
    om: "Tuqii irratti qixxeessa seenuu fi sirna keenyaaf hayyama kenni",
    ig: "Ị nwere ike pịa igbe nhọrọ ahụ ma mee ka usoro anyị",
    so: "Waad gujin kartaa sanduuqa doorashada oo u oggolow nidaamkayaga",
    ha: "Za ka iya danna akwatin zaɓi kuma bari tsarinmu",
    mnk: "Tii yiterexe kafu fɛlɛ cɛn kɔ naŋ, sirina kɛrɛ sɔɔrɔ na",
    ff: "Dumeeddu o jooɗii ka hakke ngam",
    yo: "O le tẹ apoti aṣayan ki o si jẹ ki eto wa"
  },
  "You can make setup for the activation of new devices in this section": {
    en: "You can make setup for the activation of new devices in this section. If you activate the checkbox of 'Allow users to login from application,' your users can be auto-activated and login to TV by themselves.",
    pt: "Você pode configurar a ativação de novos dispositivos nesta seção. Se você ativar a caixa de seleção 'Permitir que os usuários façam login pelo aplicativo', seus usuários podem ser ativados automaticamente e fazer login na TV por conta própria.",
    de: "Sie können in diesem Abschnitt die Aktivierung neuer Geräte einrichten. Wenn Sie das Kontrollkästchen 'Benutzern das Anmelden von der Anwendung erlauben' aktivieren, können Ihre Benutzer automatisch aktiviert und sich selbst in TV einloggen.",
    es: "Puede configurar la activación de nuevos dispositivos en esta sección. Si activa la casilla 'Permitir que los usuarios inicien sesión desde la aplicación', sus usuarios pueden ser activados automáticamente e iniciar sesión en la TV por sí mismos.",
    fr: "Vous pouvez configurer l'activation de nouveaux appareils dans cette section. Si vous activez la case à cocher 'Autoriser les utilisateurs à se connecter depuis l'application', vos utilisateurs peuvent être activés automatiquement et se connecter à la TV par eux-mêmes.",
    it: "Puoi configurare l'attivazione di nuovi dispositivi in questa sezione. Se attivi la casella di controllo 'Consenti agli utenti di effettuare l'accesso dall'applicazione', i tuoi utenti possono essere attivati automaticamente e accedere alla TV da soli.",
    sq: "Ju mund të bëni konfigurimin për aktivizimin e pajisjeve të reja në këtë seksion. Nëse aktivizoni kutinë e zgjedhjes 'Lejo përdoruesit të kyçen nga aplikacioni,' përdoruesit tuaj mund të aktivizohen automatikisht dhe të kyçen në televizor vetë.",
    tr: "Bu bölümde yeni cihazların aktivasyonu için ayarlama yapabilirsiniz. 'Kullanıcıların uygulamadan giriş yapmasına izin ver' onay kutusunu etkinleştirirseniz, kullanıcılarınız otomatik olarak etkinleştirilebilir ve televizyona kendileri giriş yapabilirler.",
    sw: "Unaweza kufanya mipangilio kwa ajili ya uanzishaji wa vifaa vipya katika sehemu hii. Ikiwa utaamsha kisanduku cha 'Ruhusu watumiaji kuingia kutoka kwa programu,' watumiaji wako wanaweza kujijiandikisha kiotomatiki na kuingia kwenye TV kwao wenyewe.",
    am: "በእባኮት ስራ ላይ በቅርብ ተመለከተ ስም ሲሆን በስም ከታክለ ባከ አሠናዶ ስልኩ በየቤታይሁ ጊር ሕጊ",
    om: "Keessumma addan gahuuf saayitaa kee keewwatanii illee hin qaxxaamurree",
    ig: "Ị nwere ike ịhazigharị ntọala maka imeghe ngwaọrụ ọhụrụ na ngalaba a. Ọ bụrụ na ị na-eme ka igbe nhọrọ 'Kwe ka ndị ọrụ banye site na ngwa,' ndị ọrụ gị nwere ike ịmalite n'ịntanetị ma banye na TV n'onwe ha.",
    so: "Waxaad samayn kartaa dejinta firfircoonida qalabka cusub ee qaybtaan. Haddii aad hawlgeliso sanduuqa 'U oggolow isticmaalayaasha inay ku soo galaan codsiga,' isticmaalayaashaadu waxay si toos ah u hawlgelin karaan una galayaan TV-ga iyaga oo keliya.",
    ha: "Zaka yi saitin don kunna na'urori sabbi a wannan sashe. Idan ka kunna akwatin zaɓi na 'Ba wa masu amfani damar shiga daga aikace-aikacen,' masu amfani ɗinka za su iya kunna kansu ta atomatik kuma su shiga TV da kansu.",
    mnk: "Fɔlɔ lafi mburu tunbulo kafu cɛbɛdi nɲin nɛn haaf keje saama, njɛyɔ saamaa diya nyi masah nxɔ. Bɔɔ xéere fɔllɔrɔ kɔ bambi yɔɔrɔ bɛn dɔlɔ.",
    ff: "On jooɗii ka safey firtiy fulɓe!",
    yo: "O le ṣe agbekalẹ eto fun ije awọn ẹrọ tuntun ni apakan yii. Ti o ba mu apoti aṣayan 'Jẹ ki awọn olumulo wọle lati inu ohun elo,' awọn olumulo rẹ le jẹ ohun elo laifọwọyi ati wọle si TV laifọwọyi."
  },
  "Your account is not approved yet by your admin. Contact your admin to get approved.":
    {
      en: "Your account is not approved yet by your admin. Contact your admin to get approved.",
      pt: "Sua conta ainda não foi aprovada pelo seu administrador. Entre em contato com seu administrador para ser aprovado.",
      de: "Ihr Konto wurde noch nicht von Ihrem Administrator genehmigt. Kontaktieren Sie Ihren Administrator, um genehmigt zu werden.",
      es: "Tu cuenta aún no ha sido aprobada por tu administrador. Contacta a tu administrador para ser aprobado.",
      fr: "Votre compte n'a pas encore été approuvé par votre administrateur. Contactez votre administrateur pour être approuvé.",
      it: "Il tuo account non è ancora stato approvato dal tuo amministratore. Contatta il tuo amministratore per essere approvato.",
      sq: "Llogaria juaj ende nuk është aprovuar nga administratori juaj. Kontaktoni administratorin tuaj për të qenë i aprovuar.",
      tr: "Hesabınız henüz yöneticiniz tarafından onaylanmamıştır. Onay almak için yöneticinizle iletişime geçin.",
      sw: "Hesabu yako haijathibitishwa bado na msimamizi wako. Wasiliana na msimamizi wako ili kupitishwa.",
      am: "መለኪያዎ እስከምንም እስክሳስ አልተረጋገጠም። ለማግኘት እባኮትን እባኮትን እባኮትን ላኪ።",
      om: "Account kee garuu hin eyyamamin hanga ammaatti. Bulchaan kee waliin walqunnamtii godhi.",
      ig: "Akaụnt gị adịghị akwadozi site na onye njikwa gị. Kpọtụrụ onye njikwa gị ka akwadoro ya.",
      so: "Xisaabtaada weli ma aysan ansixin maamulkaaga. La xiriir maamulkaaga si aad u ansixiso.",
      ha: "Hesabinka ba a amince da shi har yanzu ta mai gudanarwa. Tuntuɓi mai gudanarwa don a amince.",
      mnk: "Aye futa konto lafi ndi xali. Fisii kafu.",
      ff: "Roko e lengerɗe ba",
      yo: "Akaunti rẹ ko ti ni ifọwọsi nipasẹ alakoso rẹ. Kan si alakoso rẹ ki o gba ifọwọsi."
    },
  "You can't select yourself": {
    en: "You can't select yourself",
    pt: "Você não pode se selecionar",
    de: "Du kannst dich nicht selbst auswählen",
    es: "No puedes seleccionarte a ti mismo",
    fr: "Vous ne pouvez pas vous sélectionner vous-même",
    it: "Non puoi selezionarti da solo",
    sq: "Nuk mund të zgjidhni veten",
    tr: "Kendinizi seçemezsiniz",
    sw: "Huwezi kuchagua mwenyewe",
    am: "እራስዎን አልተመረጡም",
    om: "Ofii kee hin filatamne",
    ig: "Ị nweghị ike ị họrọ onwe gị",
    so: "Ma waxaad dooran karin naftaada",
    ha: "Ba za ka iya zaɓar kanka ba",
    mnk: "Aye futa",
    ff: "Iyi haɗo e foo",
    yo: "O ko le yan ara rẹ"
  },
  "You can sync playlist by clicking the 'Sync' button or wait for the device to be logged in, after which it will sync automatically.":
    {
      en: "You can sync playlist by clicking the 'Sync' button or wait for the device to be logged in, after which it will sync automatically.",
      pt: "Você pode sincronizar a playlist clicando no botão 'Sincronizar' ou aguardar o dispositivo fazer login, após o qual ele será sincronizado automaticamente.",
      de: "Sie können die Playlist synchronisieren, indem Sie auf die Schaltfläche 'Synchronisieren' klicken oder darauf warten, dass das Gerät angemeldet ist, woraufhin es automatisch synchronisiert wird.",
      es: "Puede sincronizar la lista de reproducción haciendo clic en el botón 'Sincronizar' o esperar a que el dispositivo inicie sesión, después de lo cual se sincronizará automáticamente.",
      fr: "Vous pouvez synchroniser la playlist en cliquant sur le bouton 'Synchroniser' ou attendre que le périphérique se connecte, après quoi il se synchronisera automatiquement.",
      it: "Puoi sincronizzare la playlist cliccando sul pulsante 'Sincronizza' o aspettare che il dispositivo effettui l'accesso, dopodiché si sincronizzerà automaticamente.",
      sq: "Mund të sinkronizoni listën e përgjegjësive duke klikuar në butonin 'Sinkronizo' ose prisni që pajisja të hyjë në sistem, pas së cilës do të sinkronizohet automatikisht.",
      tr: "Oynatma listesini 'Senkrone' düğmesine tıklayarak senkronize edebilir veya cihazın oturum açmasını bekleyebilirsiniz; ardından otomatik olarak senkronize olacaktır.",
      sw: "Unaweza kuunganisha orodha ya nyimbo kwa kubonyeza kitufe cha 'Sync' au kungoja kifaa kiingizwe, baada ya hapo kitajiunganisha kiotomatiki.",
      am: "በ'ሲንክ' ቁልፍ እንዲጠቀሙ የፕሌይስት እንዲሰንክ በመጫን ወይም እባኮትን ማሳሰብ ለምንጭ እንደ ተግባር ሲንክ አድርግ",
      om: "Playlist geejjibuu cuunfaa jedhuun 'Sync' tuqanii yookiin meeshaan galmee dhiyaachuu eeguu, booda si'achi ottoomaatiikii walitti fufi.",
      ig: "Ị nwere ike ijikọ ndepụta egwu site na pịa bọtịnụ 'Sync' ma ọ bụ chere ka ngwaọrụ jikọọ, mgbe nke ahụ gasịrị, ọ ga-synk na-akpaghị aka.",
      so: "Waxaad ku xidhidhi kartaa liiska heesaha adigoo gujinaya batoonka 'Sync' ama sug qalabka inuu galo, ka dibna si toos ah ayuu isugu xidhi doonaa.",
      ha: "Zaka haɗa jerin waƙoƙi ta danna maɓallin 'Sync' ko jira na'ura ta shiga, bayan haka zai haɗu ta atomatik.",
      mnk: "Lɔngɔ kafu playlist la ko fi sync button la, a kɔ ɲɛmɛ dɔɔnde, la bɔɔ lɛ fanxɔ.",
      ff: "On geejji playlist dumeede ngooɗi, jooɗirɗe maɓɓe 'Sync' ɗum ko hubɓe e nder ndiyam ɗum ngol fow.",
      yo: "O le ṣe atunṣe atokọ orin nipa titẹ bọtini 'Sync' tabi duro fun ẹrọ naa lati wọle, lẹhinna yoo tunto laifọwọyi."
    },
  //o
  "Open for devices": {
    en: "Open for devices",
    pt: "Aberto para dispositivos",
    de: "Offen für Geräte",
    es: "Abierto para dispositivos",
    fr: "Ouvert pour les appareils",
    it: "Aperto per dispositivi",
    sq: "I hapur për pajisjet",
    tr: "Cihazlar için açık",
    sw: "Fungua kwa vifaa",
    am: "ለመሳሪያዎች ክፍት ነው",
    om: "Qulqulluu uumuuf meeshaalee",
    ig: "Mepee maka ngwaọrụ",
    so: "Fur fur qalabka",
    ha: "Buɗe don na'urori",
    mnk: "Nye Fɔlɔ kafu lɔngɔ",
    ff: "Oopnu e nder ndiyam",
    yo: "Ṣí fún awọn ẹrọ"
  },
  or: {
    en: "or",
    pt: "ou",
    de: "oder",
    es: "o",
    fr: "ou",
    it: "o",
    sq: "ose",
    tr: "veya",
    sw: "au",
    am: "ወይም",
    om: "yookiin",
    ig: "ma",
    so: "ama",
    ha: "ko",
    mnk: "a",
    ff: "wala",
    yo: "tabi"
  },
  Os: {
    en: "Os",
    pt: "Sistema Operacional",
    de: "Betriebssystem",
    es: "Sistema Operativo",
    fr: "Système d'exploitation",
    it: "Sistema Operativo",
    sq: "Sistemi Operativ",
    tr: "İşletim Sistemi",
    sw: "Mfumo wa Uendeshaji",
    am: "ስርዓተ ኦፕሬሽን",
    om: "Sistama Hojii",
    ig: "Sistemu Ịrụ Ọrụ",
    so: "Nidaamka Hawlgalka",
    ha: "Tsarin Aiki",
    mnk: "Fɔlɔ ɲɛnbɛ",
    ff: "Sistimngol Janngo",
    yo: "Ẹ̀rọ Iṣiṣẹ"
  },
  Online: {
    en: "Online",
    pt: "Online",
    de: "Online",
    es: "En línea",
    fr: "En ligne",
    it: "Online",
    sq: "Online",
    tr: "Çevrimiçi",
    sw: "Mtandaoni",
    am: "ኦንላይን",
    om: "Marsaa",
    ig: "N'ịntanetị",
    so: "Khadka internet-ka",
    ha: "A kan layi",
    mnk: "Sankooro",
    ff: "Internet ɗe",
    yo: "Lọ́ọ̀nà"
  },
  "Old password": {
    en: "Old password",
    pt: "Senha antiga",
    de: "Altes Passwort",
    es: "Contraseña antigua",
    fr: "Ancien mot de passe",
    it: "Vecchia password",
    sq: "Fjalëkalimi i vjetër",
    tr: "Eski şifre",
    sw: "Neno la siri la zamani",
    am: "ቀድሞ ፓስዎርድ",
    om: "Paasword durii",
    ig: "Okwuntugharị ochie",
    so: "Furaha hore",
    ha: "Tsohon kalmar wucewa",
    mnk: "Pasaasi la fɛ",
    ff: "Ɓe hotooɗo password",
    yo: "Ọrọ aṣínà atijọ"
  },
  "OTP Config": {
    en: "OTP Config",
    pt: "Configuração de OTP",
    de: "OTP-Konfiguration",
    es: "Configuración de OTP",
    fr: "Configuration OTP",
    it: "Configurazione OTP",
    sq: "Konfigurimi OTP",
    tr: "OTP Yapılandırması",
    sw: "Mikakati ya OTP",
    am: "ቅንብር OTP",
    om: "Qindeessa OTP",
    ig: "Ntọala OTP",
    so: "Dejinta OTP",
    ha: "Saitin OTP",
    mnk: "OTP Jëgëyoo",
    ff: "OTP Kofinɗe",
    yo: "Atunto OTP"
  },
  "On the 'Reset Requests' page, there are reset requests that are pending. To make use of this functionality, please reset or cancel them":
    {
      en: "On the 'Reset Requests' page, there are reset requests that are pending. To make use of this functionality, please reset or cancel them.",
      pt: "Na página 'Solicitações de Redefinição', há solicitações de redefinição pendentes. Para utilizar esta funcionalidade, por favor redefina ou cancele-as.",
      de: "Auf der Seite 'Zurücksetzungsanfragen' gibt es ausstehende Anfragen. Um diese Funktionalität zu nutzen, setzen Sie sie bitte zurück oder stornieren Sie sie.",
      es: "En la página 'Solicitudes de Restablecimiento', hay solicitudes de restablecimiento pendientes. Para utilizar esta funcionalidad, por favor restablézcalas o cancélelas.",
      fr: "Sur la page 'Demandes de réinitialisation', il y a des demandes en attente. Pour utiliser cette fonctionnalité, veuillez les réinitialiser ou les annuler.",
      it: "Nella pagina 'Richieste di Ripristino', ci sono richieste di ripristino in sospeso. Per utilizzare questa funzionalità, ripristina o annulla tali richieste.",
      sq: "Në faqen 'Kërkesat për Rivendosje', ka kërkesa të pa përfunduara. Për të përdorur këtë funksionalitet, ju lutemi rivendosni ose anuloni ato.",
      tr: "'Sıfırlama Talepleri' sayfasında bekleyen sıfırlama talepleri var. Bu işlevi kullanmak için lütfen talepleri sıfırlayın veya iptal edin.",
      sw: "Katika ukurasa wa 'Maombi ya Kurekebisha', kuna maombi ya kurekebisha yanayosubiri. Ili kutumia kipengele hiki, tafadhali rekebisha au ghairi maombi hayo.",
      am: "በ'ማስተካከያ መጠየቂያዎች' ገፅ ላይ በመከላከል የሚጠበቁ ማስተካከያ መጠየቂያዎች አሉ። እባኮትን ይህን ተግባር ለማጠቃለያ እባኮትን መተካተን ወይም ማስተካከያ ይምጡ።",
      om: "Fuula 'Gaaffii Deebii' irratti, gaaffileen deebii kan eegaa jiran jiru. Faayidaa tajaajilaa kana fayyadamuuf, gaaffiiwwan sana deebisuu yookaan akka hin deebi'in gochuu qabda.",
      ig: "Na ibe 'Arịrịọ Reset', e nwere arịrịọ reset nke na-echere. Iji jiri ọrụ a, biko rụọ ọrụ ma ọ bụ kwụsị arịrịọ ndị ahụ.",
      so: "Bogga 'Codsiga Dib-u-hagaajinta', waxaa jira codsiyo dib-u-hagaajin oo sugaya. Si aad u isticmaasho shaqadan, fadlan dib u hagaaji ama kansal codsiyadaas.",
      ha: "Akan 'Buƙatun Sabuntawa', akwai buƙatun sabuntawa waɗanda suke jiran. Don amfani da wannan fasalin, don Allah sabunta ko soke su.",
      mnk: "Xali ɲafo Reset Requests la, fɔ maɓɓe reset la fo lafo. Ɓe ko fanxɔ le ɲafo, fi saaba ko cancella maɓɓe.",
      ff: "E nder 'Sariireeje Faatinore', ɗum ɓuri sariireeje. Haa ɗum kadi laawol e nder, ndiyam ngol reset walla jooɗirɗe.",
      yo: "Ní ojúewé 'Ìbéèrè Ìdàgbàsókè', àwọn ìbéèrè ìdàgbàsókè tó ń dúró de àdéhùn wà. Lati lo iṣẹ́ yìí, jọwọ ṣe ìdàgbàsókè tàbí fagilé wọn."
    },
  // x
  xui: {
    en: "xui",
    pt: "xui",
    de: "xui",
    es: "xui",
    fr: "xui",
    it: "xui",
    sq: "xui",
    tr: "xui",
    sw: "xui",
    am: "xui",
    om: "xui",
    ig: "xui",
    so: "xui",
    ha: "xui",
    mnk: "xui",
    ff: "xui",
    yo: "xui"
  },
  "X Cloud Admin Panel": {
    en: "X Cloud Admin Panel",
    pt: "Painel de Administração XCloud",
    de: "X Cloud Admin-Panel",
    es: "Panel de Administración de X Cloud",
    fr: "Panneau d'administration X Cloud",
    it: "Pannello di amministrazione di X Cloud",
    sq: "Paneli i Administratorit të X Cloud",
    tr: "X Cloud Yönetim Paneli",
    sw: "Paneli ya Msimamizi ya X Cloud",
    am: "ኤክስ ክላውድ አስተዳደር ፓነል",
    om: "Paaneelii Bulchinsa X Cloud",
    ig: "Panel Njikwa X Cloud",
    so: "Guddi Maamulka X Cloud",
    ha: "Panel Gudanarwa na X Cloud",
    mnk: "X Cloud Jëgëyoo Panel",
    ff: "X Cloud Panel ɗaŋgal",
    yo: "Panẹli Alakoso X Cloud"
  },
  //v
  "Videos must be less than ten": {
    en: "Videos must be less than ten",
    es: "Los videos deben ser menos de diez",
    fr: "Les vidéos doivent être inférieures à dix",
    de: "Videos müssen weniger als zehn sein",
    pt: "Os vídeos devem ser menos de dez",
    it: "I video devono essere meno di dieci",
    sq: "Videot duhet të jenë më pak se dhjetë",
    tr: "Videolar ondan az olmalıdır",
    sw: "Video lazima ziwe chini ya kumi",
    am: "ቪዲዮዎች በአስር በታች መሆን አለባቸው",
    om: "Viidiyoonni kudha 10 oli hin ta'an",
    ig: "Vidio ga-adị obere karịa iri",
    so: "Muuqaaladu waa inay ka yar yihiin toban",
    ha: "Bidiyo dole ne su kasance ƙasa da goma",
    mnk: "Video ɲafo 10 kafu",
    ff: "Video ɗeena less ɗum tii",
    yo: "Fidio gbọdọ jẹ kere ju mẹwa"
  },
  "View more": {
    en: "View more",
    pt: "Ver mais",
    de: "Mehr anzeigen",
    es: "Ver más",
    fr: "Voir plus",
    it: "Visualizza altro",
    sq: "Shiko më shumë",
    tr: "Daha fazla görüntüle",
    sw: "Tazama zaidi",
    am: "ተጨማሪ አሳይ",
    om: "Ilaali dabalata",
    ig: "Lee ọzọ",
    so: "Eeg wax badan",
    ha: "Duba ƙari",
    mnk: "Lɔngɔ le kafu",
    ff: "Dikkini e nder",
    yo: "Wo siwaju"
  },
  Video: {
    en: "Video",
    es: "Vídeo",
    fr: "Vidéo",
    de: "Video",
    pt: "Vídeo",
    it: "Video",
    sq: "Video",
    tr: "Video",
    sw: "Video",
    am: "ቪዲዮ",
    om: "Viidiyoo",
    ig: "Vidio",
    so: "Muuqaal",
    ha: "Bidiyo",
    mnk: "Video",
    ff: "Video",
    yo: "Fidio"
  },
  "View the guide created by images": {
    en: "View the guide created by images",
    pt: "Visualize o guia criado por imagens",
    de: "Ansehen des von Bildern erstellten Leitfadens",
    es: "Ver la guía creada por imágenes",
    fr: "Voir le guide créé par des images",
    it: "Visualizza la guida creata dalle immagini",
    sq: "Shiko udhëzuesin e krijuar nga imazhet",
    tr: "Görüntülerle oluşturulan kılavuzu görüntüle",
    sw: "Tazama mwongozo ulioumbwa kwa picha",
    am: "መምሪያን በምስሎች የተፈጠረ ይመልከቱ",
    om: "Gorsa suuraatiin uumuudhaan ilaali",
    ig: "Lee akwụkwọ nduzi nke e kere site na onyonyo",
    so: "Eeg haggaajin lagu sameeyay sawirro",
    ha: "Duba jagorar da aka ƙirƙira ta hotuna",
    mnk: "Lɔngɔ kafu jaŋɛnɔ",
    ff: "Dikkini jawɗe faɲi dumeede ɓe ngooɗi",
    yo: "Wo itọsọna ti a ṣẹda nipasẹ awọn aworan"
  },
  "Verify code must be 6 characters": {
    en: "Verify code must be 6 characters",
    pt: "O código de verificação deve ter 6 caracteres",
    de: "Der Überprüfungscode muss 6 Zeichen lang sein",
    es: "El código de verificación debe tener 6 caracteres",
    fr: "Le code de vérification doit comporter 6 caractères",
    it: "Il codice di verifica deve essere lungo 6 caratteri",
    sq: "Kodi i verifikimit duhet të jetë 6 karaktere",
    tr: "Doğrulama kodu 6 karakter olmalıdır",
    sw: "Nambari ya uthibitisho inapaswa kuwa herufi 6",
    am: "ኮድ ማረጋገጫ በ6 ፊደላት መሆን አለበት",
    om: "Koodiin mirkaneessuu mallattoo 6 qabaachuu qaba",
    ig: "Koodu nyocha ga-enwerịrị mkpụrụedemede 6",
    so: "Koodhka xaqiijinta waa inuu noqdaa 6 xaraf",
    ha: "Lambar tabbatarwa dole ne ta kasance haruffa 6",
    mnk: "Kodoo ceefo 6 kafu",
    ff: "Code ngooɗo fow 6",
    yo: "Koodu ayẹwo gbọdọ jẹ awọn ohun kikọ 6"
  },
  //u
  "Unsupported file type": {
    en: "Unsupported file type",
    es: "Tipo de archivo no compatible",
    fr: "Type de fichier non pris en charge",
    de: "Nicht unterstützter Dateityp",
    pt: "Tipo de arquivo não suportado",
    it: "Tipo di file non supportato",
    sq: "Lloji i skedarit i pambështetur",
    tr: "Desteklenmeyen dosya türü",
    sw: "Aina ya faili isiyoungwa mkono",
    am: "ያልተደገፈ ፋይል አይነት",
    om: "Faayiliin hin deeggaramne",
    ig: "Ụdị faịlụ enweghị nkwado",
    so: "Nooca fayl aan la taageerin",
    ha: "Nau'in fayil ɗin da ba a goyon baya ba",
    mnk: "Aata fanɛn njɔɔnɛ",
    ff: "Nooɗin fayil ɗum",
    yo: "Iru faili ti ko ni atilẹyin"
  },
  Upload: {
    en: "Upload",
    es: "Subir",
    fr: "Téléverser",
    de: "Hochladen",
    pt: "Carregar",
    it: "Carica",
    sq: "Ngarko",
    tr: "Yükle",
    sw: "Pakua",
    am: "አስገባ",
    om: "Galchaa",
    ig: "Bulite",
    so: "Soo rar",
    ha: "Dora",
    mnk: "Mɔɔngɛ",
    ff: "Gollu",
    yo: "Po si"
  },
  "Upload image": {
    en: "Upload image",
    es: "Subir imagen",
    fr: "Télécharger l'image",
    de: "Bild hochladen",
    pt: "Carregar imagem",
    it: "Carica immagine",
    sq: "Ngarko imazh",
    tr: "Görüntü yükle",
    sw: "Pakua picha",
    am: "ምስልን አስገባ",
    om: "Suura galchaa",
    ig: "Bulite onyonyo",
    so: "Soo rar sawir",
    ha: "Dora hoto",
    mnk: "Mɔɔngɛ ɲamɔɔrɔ",
    ff: "Gollu ɓiɓɓe",
    yo: "Po si aworan"
  },
  "Upload video": {
    en: "Upload video",
    es: "Subir video",
    fr: "Télécharger la vidéo",
    de: "Video hochladen",
    pt: "Carregar vídeo",
    it: "Carica video",
    sq: "Ngarko video",
    tr: "Video yükle",
    sw: "Pakua video",
    am: "ቪዲዮን አስገባ",
    om: "Viidiyoo galchaa",
    ig: "Bulite vidio",
    so: "Soo rar muuqaalka",
    ha: "Dora bidiyo",
    mnk: "Mɔɔngɛ video",
    ff: "Gollu video",
    yo: "Po si fidio"
  },
  UUID: {
    en: "UUID",
    pt: "UUID",
    de: "UUID",
    es: "UUID",
    fr: "UUID",
    it: "UUID",
    sq: "UUID",
    tr: "UUID",
    sw: "UUID",
    am: "UUID",
    om: "UUID",
    ig: "UUID",
    so: "UUID",
    ha: "UUID",
    mnk: "UUID",
    ff: "UUID",
    yo: "UUID"
  },
  User: {
    en: "User",
    pt: "Usuário",
    de: "Benutzer",
    es: "Usuario",
    fr: "Utilisateur",
    it: "Utente",
    sq: "Përdoruesi",
    tr: "Kullanıcı",
    sw: "Mtumiaji",
    am: "ተጠቃሚ",
    om: "Fayyadamaa",
    ig: "Onye ọrụ",
    so: "Isticmaalaha",
    ha: "Mai amfani",
    mnk: "Jɛgɛ",
    ff: "Innaama",
    yo: "Olumulo"
  },
  Unblock: {
    en: "Unblock",
    pt: "Desbloquear",
    de: "Entsperren",
    es: "Desbloquear",
    fr: "Débloquer",
    it: "Sblocca",
    sq: "Çaktivizo bllokimin",
    tr: "Engeli kaldır",
    sw: "Fungua",
    am: "ከማስገደድ አስቀምጥ",
    om: "Balleessuu",
    ig: "Wepụ nke bloke",
    so: "Ka furfur",
    ha: "Cire toshe",
    mnk: "Sankilɛ la",
    ff: "Koyi ƴam",
    yo: "Yẹ ẹlẹsẹ"
  },

  "Updated date": {
    en: "Updated date",
    pt: "Data atualizada",
    de: "Aktualisiertes Datum",
    es: "Fecha actualizada",
    fr: "Date mise à jour",
    it: "Data aggiornata",
    sq: "Data e përditësimit",
    tr: "Güncellenme tarihi",
    sw: "Tarehe iliyo sasishwa",
    am: "ቀን ተሻሽለ",
    om: "Guyyaa haaromfame",
    ig: "Ụbọchị emelitere",
    so: "Taariikhda la cusboonaysiiyey",
    ha: "Ranar da aka sabunta",
    mnk: "Dɔɔye ɲɛɛnɛn",
    ff: "Ñaam kadi",
    yo: "Ọjọ́ ti a ṣe imudojuiwọn"
  },
  Username: {
    en: "Username",
    pt: "Nome de usuário",
    de: "Benutzername",
    es: "Nombre de usuario",
    fr: "Nom d'utilisateur",
    it: "Nome utente",
    sq: "Emri i përdoruesit",
    tr: "Kullanıcı adı",
    sw: "Jina la mtumiaji",
    am: "ስም ተጠቃሚ",
    om: "Maqaa fayyadamaa",
    ig: "Aha onye ọrụ",
    so: "Magaca isticmaale",
    ha: "Sunan mai amfani",
    mnk: "Nɔɔma jɛgɛ",
    ff: "Innaama",
    yo: "Oruko olumulo"
  },
  "Upload logo": {
    en: "Upload logo",
    pt: "Carregar logotipo",
    de: "Logo hochladen",
    es: "Cargar logotipo",
    fr: "Télécharger le logo",
    it: "Carica logo",
    sq: "Ngarko logon",
    tr: "Logo yükle",
    sw: "Pakua nembo",
    am: "ሎጎን አስገባ",
    om: "Logo galchaa",
    ig: "Bulite logo",
    so: "Soo rar calaamadda",
    ha: "Dora tambari",
    mnk: "Mɔɔngɛ logo",
    ff: "Gollu logo",
    yo: "Po si aami"
  },
  "Resize logo": {
    en: "Resize logo",
    pt: "Redimensionar logotipo",
    de: "Logo anpassen",
    es: "Redimensionar logotipo",
    fr: "Redimensionner le logo",
    it: "Ridimensiona logo",
    sq: "Ndrysho përmasat e logos",
    tr: "Logoyu yeniden boyutlandır",
    sw: "Badilisha ukubwa wa nembo",
    am: "ሎጎን አቀማመጥ",
    om: "Saayizi logo fooyya’i",
    ig: "Gbanwee nha logo",
    so: "Cabir cabbirka calaamadda",
    ha: "Canza girman tambari",
    mnk: "Fɔ nɔɔma logo",
    ff: "Golle logo moƴƴi",
    yo: "Tun iwọn aami"
  },
  "Upload background": {
    en: "Upload background",
    pt: "Carregar Plano de Fundo",
    de: "Hintergrund hochladen",
    es: "Cargar fondo",
    fr: "Télécharger l'arrière-plan",
    it: "Carica sfondo",
    sq: "Ngarko sfondin",
    tr: "Arka plan yükle",
    sw: "Pakua mandhari",
    am: "እትም አንድ ወተል",
    om: "Bakkalcha bu’uurra galchaa",
    ig: "Bulite azụ",
    so: "Soo rar galka hore",
    ha: "Dora hoto na baya",
    mnk: "Mbɛɛri bɛmin",
    ff: "Gollu eeŋɗo",
    yo: "Po si abẹlẹ"
  },
  "Users will be able to reset the device automatically if this field is enabled. If it isn't, they won't be able to reset it unless you confirm it from the Devices page / Reset requests":
    {
      en: "Users will be able to reset the device automatically if this field is enabled. If it isn't, they won't be able to reset it unless you confirm it from the Devices page / Reset requests.",
      pt: "Os usuários poderão redefinir o dispositivo automaticamente se este campo estiver habilitado. Caso contrário, eles não poderão redefini-lo a menos que você o confirme na página Dispositivos / Solicitações de redefinição.",
      de: "Benutzer können das Gerät automatisch zurücksetzen, wenn dieses Feld aktiviert ist. Andernfalls können sie es nicht zurücksetzen, es sei denn, Sie bestätigen es auf der Seite Geräte / Zurücksetzungsanfragen.",
      es: "Los usuarios podrán restablecer el dispositivo automáticamente si este campo está habilitado. De lo contrario, no podrán restablecerlo a menos que lo confirme desde la página de Dispositivos / Solicitudes de restablecimiento.",
      fr: "Les utilisateurs pourront réinitialiser le périphérique automatiquement si ce champ est activé. Sinon, ils ne pourront pas le réinitialiser à moins que vous ne le confirmiez depuis la page Appareils / Demandes de réinitialisation.",
      it: "Gli utenti potranno ripristinare automaticamente il dispositivo se questo campo è abilitato. In caso contrario, non potranno ripristinarlo a meno che tu non lo confermi dalla pagina Dispositivi / Richieste di ripristino.",
      sq: "Përdoruesit do të jenë në gjendje të rivendosin automatikisht pajisjen nëse ky fushë është e aktivizuar. Nëse nuk është, ata nuk do të jenë në gjendje ta rivendosin atë përveç nëse ju e konfirmoni atë nga faqja e Pajisjeve / Kërkesat për rivendosje.",
      tr: "Kullanıcılar, bu alan etkinse cihazı otomatik olarak sıfırlayabileceklerdir. Değilse, cihazı sıfırlayamazlar, cihazı Sıfırlama talepleri / Cihazlar sayfasından onaylamadığınız sürece.",
      sw: "Watumiaji wataweza kurekebisha kifaa kiotomatiki ikiwa sehemu hii itahifadhiwa. Ikiwa haitahifadhiwa, hawataweza kukirekebisha isipokuwa uthibitishe kutoka kwa ukurasa wa Vifaa / Maombi ya Kurekebisha.",
      am: "ተጠቃሚዎች እንደእንቅስቃሴ ስር እንደሚቀመጡት ስለሆነ እንደ ምንም መሳሪያዎች ይክብብቱ። ምንምንም እንኳን ከሁሉም በተቀባ በማሽከል እንዲሞሉ",
      om: "Osoo bakka kana ibsuun dandeessisu ta’ee, fayyadamtoonni meeshaa ofumaan deebisuu ni dandeessu. Yoo hin ibsamne ta’e garuu, waan san hin deebisan yoo hin mirkaneessineef.",
      ig: "Ụfọdụ na ikike iwepụ ngwaọrụ a na-eme na ụzọ iziga ya oge niile ma ọ bụrụ na mpaghara a bụ arụ ọrụ. Ọ bụrụ na ọ bụghị, ha agaghị enwe ike iwepu ya ma ọ bụ mgbe ị kwadoro ya site na peeji nke Ngwaọrụ / Arịrịọ Nchịkọta.",
      so: "Isticmaalayaashu waxay awoodi doonaan inay si toos ah u dejistaan qalabka haddii goobtan la dhaqaajiyo. Haddii aysan dhicin, ma awoodi doonaan inay dib u dejiyaan ilaa aad ka xaqiijiso bogga qalabka / Codsiyada dib u dejinta.",
      ha: "Masu amfani za su iya sake saitin na'urar ta atomatik idan an kunna wannan fage. Idan ba haka ba, ba za su iya sake saitin ta ba, sai dai idan ka tabbatar da shi daga shafin Na'urorin / Bukatun sake saitin.",
      mnk: "I sufu mbarafo lɔngɔnɔ la deviceo la bɔrɔ ya, baro efi yɛrɛ ntɔrɔ. I mɛ dɛnkili ya, baro i nnɔng nɔng nɔ fo fana i kɔrɔ.",
      ff: "Bannge mun jogi ko rewti gaal heeri to, ɓe naɗi e ko yimɓe. Nde ɓe njiɗi, ko ndiyannde tawa lamɗo ko feere.",
      yo: "Awọn olumulo yoo ni anfani lati tun ẹrọ naa ṣe laifọwọyi ti aaye yii ba jẹ amọ. Ti ko ba jẹ bẹ, wọn kii yoo ni anfani lati tun ṣe ayẹwo ayafi ti o ba fọwọsi lati oju-iwe Ẹrọ / Awọn ibeere atunṣe."
    },
  "Use port": {
    en: "Use port",
    pt: "Usar porta",
    de: "Port verwenden",
    es: "Usar puerto",
    fr: "Utiliser le port",
    it: "Usa porta",
    sq: "Përdorni portin",
    tr: "Portu kullan",
    sw: "Tumia bandari",
    am: "አገልግሎት አገልግሎት ተጠቀሙ",
    om: "Meeshaa fayyadami",
    ig: "Jiri ọdụ",
    so: "Isticmaal dekedda",
    ha: "Yi amfani da tashar",
    mnk: "A siloo",
    ff: "Naantude ngooɗi",
    yo: "Lo ibùdó"
  },
  //v
  Visibly: {
    en: "Visibly",
    pt: "Visivelmente",
    de: "Sichtbar",
    es: "Visiblemente",
    fr: "Visiblement",
    it: "Visibilmente",
    sq: "Vizualisht",
    tr: "Görünür şekilde",
    sw: "Kwa uwazi",
    am: "በግልጽ",
    om: "Ifaajee",
    ig: "N'ụzọ doro anya",
    so: "Si cad",
    ha: "A bayyane",
    mnk: "Ninɔɔya la",
    ff: "Haala beeɗi",
    yo: "Ní kedere"
  },
  Viewed: {
    en: "Viewed",
    pt: "Visualizado",
    de: "Angesehen",
    es: "Visto",
    fr: "Vu",
    it: "Visualizzato",
    sq: "Shikuar",
    tr: "Görüntülendi",
    sw: "Imeonekana",
    am: "ታይቷል",
    om: "Muuffame",
    ig: "Ahụrụ",
    so: "La arkay",
    ha: "An gani",
    mnk: "Ye dɔɔ",
    ff: "Ɗaɗtaama",
    yo: "Ti wo"
  },

  //w
  "We’ve identified a technical issue with your account. Please contact us as soon as possible to resolve it and prevent any service disruptions":
    {
      en: "We’ve identified a technical issue with your account. Please contact us as soon as possible to resolve it and prevent any service disruptions.",
      pt: "Identificamos um problema técnico com sua conta. Por favor, entre em contato conosco o mais rápido possível para resolvê-lo e evitar qualquer interrupção de serviço.",
      de: "Wir haben ein technisches Problem mit Ihrem Konto festgestellt. Bitte kontaktieren Sie uns so schnell wie möglich, um es zu beheben und Unterbrechungen des Dienstes zu vermeiden.",
      es: "Hemos identificado un problema técnico con su cuenta. Póngase en contacto con nosotros lo antes posible para resolverlo y evitar cualquier interrupción del servicio.",
      fr: "Nous avons identifié un problème technique avec votre compte. Veuillez nous contacter dès que possible pour le résoudre et éviter toute interruption de service.",
      it: "Abbiamo identificato un problema tecnico con il tuo account. Contattaci il prima possibile per risolverlo ed evitare interruzioni del servizio.",
      sq: "Ne kemi identifikuar një problem teknik me llogarinë tuaj. Ju lutemi na kontaktoni sa më shpejt të jetë e mundur për ta zgjidhur atë dhe për të parandaluar ndërprerjet e shërbimit.",
      tr: "Hesabınızda teknik bir sorun tespit ettik. Sorunu çözmek ve hizmet kesintilerini önlemek için lütfen en kısa sürede bizimle iletişime geçin.",
      sw: "Tumegundua tatizo la kiufundi kwenye akaunti yako. Tafadhali wasiliana nasi haraka iwezekanavyo ili kulitatua na kuzuia usumbufu wa huduma.",
      am: "በመለያዎ ላይ የቴክኒካዊ ችግር እንዳለ አግኝተናል። እባክዎን ችግሩን ለመፍታት እና እንደሆነ አገልግሎት ቁርጠኝነትን ለመከላከል በተቻለ ፍጥነት ያነጋግሩን።",
      om: "Rakkoo teekinikaa waa’ee account keetiin arginee jirra. Rakkoo kana furuuf fi tajaajila addaan cituu ittisuuf battalumatti nu quunnamaa.",
      ig: "Anyị achọpụtala nsogbu teknụzụ na akaụntụ gị. Biko kpọtụrụ anyị ozugbo o kwere mee ka anyị dozie ya ma gbochie mkparịta ụka ọ bụla na ọrụ gị.",
      so: "Waxaan ogaanay cilad farsamo oo la xidhiidha koontadaada. Fadlan nala soo xidhiidh sida ugu dhakhsaha badan si aan u xallino oo aan uga hortagno dhibaatooyin adeeg.",
      ha: "Mun gano matsalar fasaha tare da asusun ka. Da fatan za ka tuntube mu da wuri don gyara shi da kuma hana katsewar sabis.",
      mnk: "N se a di kɛlɛya da kɛlɛma accounto la. I lɔ a fɔ a yɛrɛ, a di ka tara kɛ i su di faa.",
      ff: "Min jogori jonti e burtal tekkinaa adunaade e jamirde maa. Seeda fow a jooɗii min laato no jay, ndi hanndude jibinaade e tuma.",
      yo: "A ti rí ìṣòro ìmọ̀ ẹ̀rọ kan nínú àkàǹtì rẹ. Jọwọ kan sí wa kíákíá láti ṣe ìtọ́jú rẹ̀ àti láti ṣe é kó má bàa dá ìṣẹ̀ṣẹ̀ padà."
    },
  WARNING: {
    en: "WARNING",
    pt: "AVISO",
    de: "WARNUNG",
    es: "ADVERTENCIA",
    fr: "AVERTISSEMENT",
    it: "AVVERTENZA",
    sq: "KUJDES",
    tr: "UYARI",
    sw: "ONYO",
    am: "አስጊ ጥንቃቄ",
    om: "KAAYYOO",
    ig: "IHE NTỊA NTỊA",
    so: "DIGNIIN",
    ha: "GARGAƊI",
    mnk: "KILE",
    ff: "ƊAƁBIN",
    yo: "IKILỌ"
  },
  "Welcome to the": {
    en: "Welcome to the",
    pt: "Bem-vindo ao",
    de: "Willkommen bei",
    es: "Bienvenido a",
    fr: "Bienvenue sur",
    it: "Benvenuti su",
    sq: "Mirë se vini në",
    tr: "Hoş geldiniz",
    sw: "Karibu kwenye",
    am: "እንኳን ወደ",
    om: "Baga nagaan gara",
    ig: "Nnọọ na",
    so: "Ku soo dhowow",
    ha: "Barka da zuwa",
    mnk: "I ni sɔrɔ la",
    ff: "Nii jaraama",
    yo: "Ẹ káàbọ̀ sí"
  },
  welcome: {
    en: "Welcome",
    pt: "Bem-vindo",
    de: "Willkommen",
    es: "Bienvenido",
    fr: "Bienvenue",
    it: "Benvenuto",
    sq: "Mirë se vini",
    tr: "Hoş geldiniz",
    sw: "Karibu",
    am: "እንኳን ደህና መጣህ",
    om: "Baga nagaan dhuftan",
    ig: "Nnọọ",
    so: "Ku soo dhowow",
    ha: "Barka da zuwa",
    mnk: "I ni sɔrɔ",
    ff: "Nii jaraama",
    yo: "Ẹ káàbọ̀"
  },
  "when migration will be finished": {
    en: "when migration will be finished!",
    pt: "quando a migração estiver concluída!",
    de: "wenn die Migration abgeschlossen ist!",
    es: "cuando la migración haya terminado!",
    fr: "quand la migration sera terminée !",
    it: "quando la migrazione sarà completata!",
    sq: "kur migrimi do të përfundojë!",
    tr: "göç tamamlandığında!",
    sw: "Wakati uhamisho utakapokamilika",
    am: "ስደት በተጠናቀቀ ጊዜ",
    om: "Yeroo jijjiirraa xumuramu",
    ig: "Mgbe mbubata ga-agwụcha",
    so: "Marka guuritaanka la dhammeeyo",
    ha: "Lokacin da hijira za ta ƙare",
    mnk: "Dɔŋɔ kɛ ka safulu taraŋ kɔrɔya",
    ff: "Hol ko haa darugol daweeɗo",
    yo: "Nígbà tí ìrìnàjò yóò parí"
  },

  "With save button you can save your changes": {
    en: "With save button you can save your changes.",
    pt: "Com o botão de salvar, você pode salvar suas alterações.",
    de: "Mit der Speichertaste können Sie Ihre Änderungen speichern.",
    es: "Con el botón de guardar, puede guardar sus cambios.",
    fr: "Avec le bouton de sauvegarde, vous pouvez enregistrer vos modifications.",
    it: "Con il pulsante Salva puoi salvare le tue modifiche.",
    sq: "Me butonin ruaj, mund të ruani ndryshimet tuaja.",
    tr: "Kaydet butonuyla değişikliklerinizi kaydedebilirsiniz.",
    sw: "Kwa kutumia kitufe cha kuhifadhi, unaweza kuhifadhi mabadiliko yako.",
    am: "በመያዣ አዝራር ለውጦቹን መያዝ ይችላሉ።",
    om: "Buttonii kuusaatiin jijjiirama kee kuuftee ni dandeessa.",
    ig: "Site na bọtịnụ nchekwa, ị nwere ike chekwaa mgbanwe gị.",
    so: "Adigto badbaadinta waxaad ku kaydin kartaa wax ka beddelkaaga.",
    ha: "Da maɓallin ajiya, zaka iya ajiye canje-canjenka.",
    mnk: "Savetango buttono le n na a siifɔra i jukula.",
    ff: "E maanto sago button mo, aɗa mbaɗi cimol maa laɓɓa.",
    yo: "Pẹ̀lú bútìnnì fipamọ́, o lè fipamọ́ àwọn àtúnṣe rẹ."
  },
  "With save button you can save your changes and the new device will be added successfully":
    {
      en: "With save button you can save your changes and the new device will be added successfully.",
      pt: "Com o botão de salvar, você pode salvar suas alterações e o novo dispositivo será adicionado com sucesso.",
      de: "Mit der Speichertaste können Sie Ihre Änderungen speichern, und das neue Gerät wird erfolgreich hinzugefügt.",
      es: "Con el botón de guardar, puede guardar sus cambios y el nuevo dispositivo se agregará con éxito.",
      fr: "Avec le bouton de sauvegarde, vous pouvez enregistrer vos modifications et le nouveau dispositif sera ajouté avec succès.",
      it: "Con il pulsante Salva puoi salvare le tue modifiche e il nuovo dispositivo verrà aggiunto con successo.",
      sq: "Me butonin ruaj, mund të ruani ndryshimet tuaja dhe pajisja e re do të shtohet me sukses.",
      tr: "Kaydet butonuyla değişikliklerinizi kaydedebilir ve yeni cihaz başarıyla eklenecektir.",
      sw: "Kwa kutumia kitufe cha kuhifadhi, unaweza kuhifadhi mabadiliko yako na kifaa kipya kitaongezwa kwa mafanikio.",
      am: "በመያዣ አዝራር ለውጦቹን መያዝ እና አዲሱን መሳሪያ በተሳካ ሁኔታ ማክለት ይችላሉ።",
      om: "Buttonii kuusaatiin jijjiirama kee kuuftee, meeshaa haarawaa milkaa’inaan ida’uun ni danda’ama.",
      ig: "Site na bọtịnụ nchekwa, ị nwere ike chekwaa mgbanwe gị na ngwaọrụ ọhụrụ ga-agbakwunye nke ọma.",
      so: "Adigto badbaadinta waxaad ku kaydin kartaa wax ka beddelkaaga, waxaana lagu dari doonaa aaladda cusub si guul leh.",
      ha: "Da maɓallin ajiya, zaka iya ajiye canje-canjenka kuma sabon na'urar za a ƙara shi cikin nasara.",
      mnk: "Savetango buttono le n na a siifɔra i jukula, dɔŋ dɔŋ na dekelo sinye yɛrɛ si taraŋ ka kɛ!",
      ff: "E maanto sago button mo, aɗa mbaɗi cimol maa laɓɓa, kadi a haɗi nden wonndude caggal ngam.",
      yo: "Pẹ̀lú bútìnnì fìfọ́nfàní, o lè fipamọ́ àwọn àtúnṣe rẹ, àti ẹrọ tuntun yóò fi ṣàtúnṣe náà fọkanfàní."
    },
  "We have sent a verification code to your provided email. Please verify the confirmation code you have received in your email.":
    {
      en: "We have sent a verification code to your provided email. Please verify the confirmation code you have received in your email.",
      pt: "Enviamos um código de verificação para o seu e-mail fornecido. Por favor, verifique o código de confirmação que você recebeu em seu e-mail.",
      de: "Wir haben einen Bestätigungscode an Ihre angegebene E-Mail-Adresse gesendet. Bitte überprüfen Sie den Bestätigungscode, den Sie in Ihrer E-Mail erhalten haben.",
      es: "Hemos enviado un código de verificación a su correo electrónico proporcionado. Por favor, verifique el código de confirmación que ha recibido en su correo electrónico.",
      fr: "Nous avons envoyé un code de vérification à votre adresse e-mail fournie. Veuillez vérifier le code de confirmation que vous avez reçu dans votre e-mail.",
      it: "Abbiamo inviato un codice di verifica alla tua email fornita. Verifica il codice di conferma che hai ricevuto nella tua email.",
      sq: "Kemi dërguar një kod verifikimi në emailin tuaj të dhënë. Ju lutemi verifikoni kodin e konfirmimit që keni marrë në emailin tuaj.",
      tr: "Sağladığınız e-posta adresinize bir doğrulama kodu gönderdik. Lütfen e-posta kutunuzda aldığınız onay kodunu doğrulayın.",
      sw: "Tumetuma msimbo wa uthibitisho kwa barua pepe uliyopeana. Tafadhali thibitisha msimbo wa uthibitisho uliopewa kwenye barua pepe yako.",
      am: "ወደ ቀረበው ኢሜል የማረጋገጥ ኮድ ልከንልልዎታል። እባክዎን በኢሜልዎ የተላከውን የማረጋገጥ ኮድ ያረጋግጡ።",
      om: "Lakkaddaa mirkaneessaa gara email ati kenname erginee jirra. Mee, lakkaddaa mirkaneessaa argite kana mirkaneessi.",
      ig: "Anyị ezitela koodu nkwenye na email ị nyere. Biko lelee koodu nkwenye ị nwetara na email gị.",
      so: "Waxaan kuu soo dirnay lambarka xaqiijinta emailka aad bixisay. Fadlan xaqiiji lambarka xaqiijinta ee aad ka heshay emailkaaga.",
      ha: "Mun aika maka da lambar tabbatarwa zuwa imel ɗin da ka bayar. Da fatan za ka tabbatar da lambar tabbatarwa da ka samu a imel ɗinka.",
      mnk: "N bɛ a dɛnkili la codeo emailo la i yɛnnee. I nɔng a dɛnkili la codeo ye n'a la emailo ka dɔɔnin mɛ.",
      ff: "Min sendinoo codee ngooɗɗo e-maylu maa wonande. Seeda kaa jom ngooɗɗo maa e e-maylu maa jango.",
      yo: "A ti fi koodu ìmúdájú ranṣẹ sí ẹ̀míàílì tí o pèsè. Jọ̀wọ́ ṣàyẹ̀wò koodu ìmúdájú tí o ti gba ní ẹ̀míàílì rẹ."
    },
  "We have sent a verification code to your email.": {
    en: "We have sent a verification code to your email.",
    pt: "Enviamos um código de verificação para o seu e-mail.",
    de: "Wir haben einen Bestätigungscode an Ihre E-Mail gesendet.",
    es: "Hemos enviado un código de verificación a su correo electrónico.",
    fr: "Nous avons envoyé un code de vérification à votre adresse e-mail.",
    it: "Abbiamo inviato un codice di verifica alla tua email.",
    sq: "Ju kemi dërguar një kod verifikimi në email-in tuaj.",
    tr: "E-posta adresinize bir doğrulama kodu gönderdik.",
    sw: "Tumetuma msimbo wa uthibitisho kwa barua pepe yako.",
    am: "እንደምንምልክት ኮድ ወደ ኢሜልዎ እንዲሄድ አላክነው።",
    om: "Lakkaddaa mirkaneessaa gara email kee erginee jirra.",
    ig: "Anyị ezitela koodu nkwenye na email gị.",
    so: "Waxaan kuu soo dirnay lambarka xaqiijinta emailkaaga.",
    ha: "Mun aika maka da lambar tabbatarwa zuwa imel ɗinka.",
    mnk: "N bɛ a dɛnkili la codeo emailo la a yanneng.",
    ff: "Min sendinoo codee ngooɗɗo e-maylu maa.",
    yo: "A ti fi koodu ìmúdájú ranṣẹ sí ẹ̀míàílì rẹ."
  },
  "Wrong password": {
    en: "Wrong password",
    pt: "Senha incorreta",
    de: "Falsches Passwort",
    es: "Contraseña incorrecta",
    fr: "Mot de passe incorrect",
    it: "Password errata",
    sq: "Fjalëkalim i gabuar",
    tr: "Yanlış şifre",
    sw: "nywila si sahihi",
    am: "የተሳሳተ ፓስወርድ",
    om: "jecha icciitii dogoggora",
    ig: "okwuigodo ezighi ezi",
    so: "eray sir ah",
    ha: "kalmar sirri ba daidai ba",
    mnk: "bataa ta passwordo",
    ff: "finndin juɗɗam eɓa",
    yo: "ọrọìpamọ́ kò tọ́"
  },
  Week: {
    en: "Week",
    es: "Semana",
    fr: "Semaine",
    de: "Woche",
    pt: "Semana",
    it: "Settimana",
    sq: "Java",
    tr: "Hafta",
    sw: "wiki",
    am: "ሳምንት",
    om: "torban",
    ig: "izu",
    so: "usbuuc",
    ha: "mako",
    mnk: "luwa",
    ff: "jiida",
    yo: "ọ̀sẹ̀"
  },

  show: {
    en: "Show",
    es: "Mostrar",
    fr: "Afficher",
    de: "Anzahl",
    pt: "Mostrar",
    it: "Mostra",
    sq: "Shfaq",
    tr: "Sayımı",
    sw: "Onyesha",
    am: "በቁጥር",
    om: "muujin",
    ig: "nweta",
    so: "muuji",
    ha: "nuna",
    mnk: "nunɔ",
    ff: "muuɗin",
    yo: "fi ọkọ"
  },

  "please wait": {
    en: "Please wait",
    es: "Por favor, espere",
    fr: "Veuillez patienter",
    de: "Bitte warten",
    pt: "Por favor, aguarde",
    it: "Attendere prego",
    sq: "Ju lutem prisni",
    tr: "Lütfen bekleyin",
    sw: "Tafadhali subiri",
    am: "እባክዎ ጠብቋላችሁ",
    om: "Fadlan sug",
    ig: "Biko chere",
    so: "Fadlan sug",
    ha: "Da fatan jira",
    mnk: "Da fatan jira",
    ff: "Jokkondiraa",
    yo: "Jọwọ dẹkun"
  },

  "try later": {
    en: "Try later",
    es: "Inténtalo más tarde",
    fr: "Réessayer plus tard",
    de: "Später versuchen",
    pt: "Tente mais tarde",
    it: "Prova più tardi",
    sq: "Provo më vonë",
    tr: "Daha sonra tekrar deneyin",
    sw: "Jaribu baadaye",
    am: "በቀን እንደገና ይሞክሩ",
    om: "Hadii aad noqon lahayd",
    ig: "Jiọ ka ọ bụla",
    so: "Isku day",
    ha: "To yi daidai",
    mnk: "To yi daidai",
    ff: "To yi daidai",
    yo: "To yi daidai"
  },

  "This server has no devices": {
    en: "This server has no devices",
    es: "Este servidor no tiene dispositivos",
    fr: "Ce serveur n'a pas de périphériques",
    de: "Dieser Server hat keine Geräte",
    pt: "Este servidor não tem dispositivos",
    it: "Questo server non ha dispositivi",
    sq: "Ky server nuk ka pajisje",
    tr: "Bu sunucuda cihaz yok",
    sw: "Hakuna vifaa kwenye seva hii",
    am: "ይህ ሰርቨር አይነት አካውንት የለም",
    om: "Serveriin kun dandeessa",
    ig: "Ọrụ a na-eme dị ọhụrụ",
    so: "Serverkaan ma jiro qalab",
    ha: "Wannan sabuwar ba ake da kudin",
    mnk: "Servero la a kɔrɔ",
    ff: "Server bi ko waawde ngooɗi",
    yo: "Server yii kò ní àwọn ẹrọ"
  }
};

export default translations;
