import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { setProfile } from "../../store/features/profileSlice";

import {
  Input,
  message,
  Card,
  Badge,
  Empty,
  Button,
  Tooltip,
  Modal,
  Space,
} from "antd";

import { ExclamationCircleFilled, InfoCircleOutlined } from "@ant-design/icons";

import REQUESTS from "../../api/requests";

import useTranslate from "../../hooks/translator";

import { parseFullDate } from "../../config/formats";

import {
  PageComponent,
  LongText,
  CopyText,
  confirmModal,
} from "../../components";
import { ICONS } from "../../config";

import DevicesDrawer from "./DevicesDrawer";
import HistoryDeactivated from "./HistoryDeactivated";

export default function DeviceInfo() {
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  const translate = useTranslate();

  const { profile } = useSelector((store) => store.profile);

  const canUseFree =
    profile?.parent_id && profile?.status === "CAN_USE_FREE_PARTNER_CREDITS";

  const [deviceKey, setDeviceKey] = useState("");
  const [search, setSearch] = useState("");
  const [messageErr, setMessageErr] = useState("");

  const [loading, setLoading] = useState(false);
  const [dataList, setDataList] = useState(null);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [myActivate, setMyActivate] = useState(false);

  const currentKey = location?.search?.split("=")[1];

  useEffect(() => {
    if (currentKey) {
      setDeviceKey(currentKey);
    }

    if (!search) {
      getDevice(currentKey);
    }
  }, [currentKey, search, deviceKey]);

  useEffect(() => {
    getDevice(search);
  }, [search]);

  const getProfile = () => {
    REQUESTS.PROFILE()
      .then((data) => {
        dispatch(setProfile(data));
      })
      .catch((err) => {});
  };

  const getDevice = (key) => {
    setLoading(true);

    if (!key && !deviceKey) {
      return;
    }

    const query = {
      filter: { device_key: key ? key : deviceKey },
    };

    REQUESTS.DEVICES({ query: JSON.stringify(query) })
      .then((data) => {
        if (data) {
          setLoading(false);
          setIsDrawerOpen(false);
          setMyActivate(false);

          if (search) {
            if (data?.rows?.length > 0 && data?.rows[0]) {
              setMessageErr("");
              setDataList(data.rows[0]);
              navigate(`/dashboard/device?key=${data?.rows[0]?.device_key}`);
            } else {
              setMessageErr(translate("Device not found"));
              setDataList(null);
            }
          } else if (data?.rows?.length > 0) {
            setDataList(data.rows[0]);
          } else {
            setDataList(null);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        if (search && err) {
          setSearch("");
        }
      });
  };

  const onSearch = (value) => {
    if (value && value.length == 6) {
      setSearch(value);
    } else {
      setMessageErr(translate("Device key must be 6 characters"));
    }
    if (value == "") {
      setSearch("");
      setMessageErr("");
    }
  };

  const onEditPlaylist = () => {
    setIsDrawerOpen(true);
    setMyActivate(false);
  };

  const onActivateDevice = () => {
    setMyActivate(true);
    setIsDrawerOpen(true);
  };

  const onDeactivateDevice = () => {
    confirmModal({
      title:
        profile?.is_partner || profile?.parent_id
          ? translate("Do you want to deactivate this device")
          : translate("device activation info"),
      okText: translate("Yes"),
      cancelText: translate("Cancel"),
      action: () => {
        REQUESTS.DEACTIVATE_DEVICE({ id: dataList?.id })
          .then((res) => {
            message.success(res);
            getProfile();
            getDevice();
          })
          .catch((err) => {
            message.error(err);
          });
      },
    });
  };

  const onSyncPlaylist = (item) => {
    let txt = item?.playlist;

    // Extract value between "username=" and "&"
    let usernameMatch = txt.match(/username=([^&]*)/);
    let usernameValue = usernameMatch ? usernameMatch[1] : null;

    // Extract value after "password="
    let passwordMatch = txt.match(/password=([^&]*)/);
    let passwordValue = passwordMatch ? passwordMatch[1] : null;

    const body = {
      device_id: item?.id,
      username: usernameValue && usernameValue,
      password: passwordValue && passwordValue,
    };

    if (usernameValue && passwordValue) {
      REQUESTS.SYNC_PLAYLIST(body)
        .then((res) => {
          message.success(res);
          getDevice();
        })
        .catch((err) => {
          message.error(err);
        });
    }
  };

  const syncPlaylist = (item) => {
    Modal.confirm({
      title: translate("Sync playlist"),
      icon: <ExclamationCircleFilled />,
      content: translate(
        `You can sync playlist by clicking the "Sync" button or wait for the device to be logged in, after which it will sync automatically.`
      ),
      onOk() {
        onSyncPlaylist(item);
      },
      onCancel() {},
    });
  };

  const onUnblockDevice = () => {
    confirmModal({
      title: translate("Do you want to unblock this device"),
      okText: translate("Yes"),
      cancelText: translate("Cancel"),
      action: () => {
        REQUESTS.DEVICE_BLACK_LIST.DELETE({ id: dataList?.black_list?.id })
          .then((res) => {
            message.success(res);
            getProfile();
            getDevice();
          })
          .catch((err) => {
            message.error(err);
          });
      },
    });
  };

  const onBlockDevice = () => {
    confirmModal({
      title: translate("Do you want to blocked this device"),
      okText: translate("Yes"),
      cancelText: translate("Cancel"),
      action: () => {
        REQUESTS.DEVICE_BLACK_LIST.POST({ device_id: dataList?.id })
          .then((res) => {
            message.success(res);
            getProfile();
            getDevice();
          })
          .catch((err) => {
            message.error(err);
          });
      },
    });
  };

  const checkPlaylistForError = (playlist_status) => {
    switch (playlist_status) {
      case "wrong":
        return translate(
          "Please check the playlist as it is incorrectly filled in."
        );

      case "expired":
        return translate(
          "Please check this playlist as its expiration date has expired."
        );
      default:
        return "";
    }
  };

  return (
    <PageComponent>
      <div className="head-page">
        <h3>
          <Link to={"/dashboard/devices"}>
            {"< "}
            {translate("Devices")}
          </Link>{" "}
          {"/"} {dataList?.device_key || "N/A"}
        </h3>
      </div>
      <div className="search-device-info">
        <Input.Search
          placeholder={translate("Device key")}
          allowClear
          enterButton
          onSearch={onSearch}
          style={{ width: 230 }}
        />
        {messageErr && <p style={{ color: "red" }}>{messageErr}</p>}
      </div>
      <div className="about-device">
        <div className="part-1">
          <Badge.Ribbon
            text={
              dataList
                ? dataList?.is_active
                  ? translate("Active")
                  : !dataList?.is_active && dataList?.is_trial
                  ? translate("Trial")
                  : dataList?.black_list
                  ? translate("Blocked")
                  : translate("Deactive")
                : translate("Device not found")
            }
            color={
              dataList
                ? dataList?.is_active
                  ? "green"
                  : !dataList?.is_active && dataList?.is_trial
                  ? "gold"
                  : "red"
                : "grey"
            }
          >
            <Card
              title={translate("Device")}
              loading={loading}
              className="card"
              extra={
                <div className="card-extra" style={{ marginTop: "25px" }}>
                  {dataList && !dataList.is_active && !dataList.black_list && (
                    <Button
                      size="small"
                      disabled={dataList?.is_active}
                      onClick={onActivateDevice}
                      style={{ borderColor: "green", color: "green" }}
                    >
                      {translate("Activate")}
                    </Button>
                  )}
                  {dataList && dataList.is_active && !dataList.black_list && (
                    <Button
                      size="small"
                      disabled={!dataList?.is_active}
                      onClick={onDeactivateDevice}
                      type="primary"
                      danger
                    >
                      {" "}
                      {translate("Deactivate")}
                    </Button>
                  )}
                  {dataList && (
                    <Button
                      size="small"
                      onClick={
                        dataList.black_list ? onUnblockDevice : onBlockDevice
                      }
                      style={{
                        borderColor: dataList.black_list ? "green" : "red",
                        color: dataList.black_list ? "green" : "red",
                      }}
                    >
                      {dataList.black_list
                        ? translate("Unblock")
                        : translate("Block")}
                    </Button>
                  )}
                </div>
              }
            >
              {dataList ? (
                <ul className="card-info">
                  <li>
                    <span>{translate("Device key")}</span>
                    {dataList?.device_key ? (
                      <CopyText text={dataList?.device_key} />
                    ) : (
                      "N/A"
                    )}
                  </li>
                  <li>
                    <span>{translate("Platform")}</span>
                    <strong>{dataList?.device_os || "N/A"}</strong>
                  </li>
                  <li>
                    <span>{translate("Device UUID")}</span>
                    <strong>
                      {<LongText children={dataList?.device_uuid} /> || "N/A"}
                    </strong>
                  </li>
                  <li>
                    <span>{translate("App name")}</span>
                    <strong>{dataList?.app_name || "N/A"}</strong>
                  </li>
                  <li>
                    <span>{translate("Activated by")}</span>
                    <strong>{dataList?.activated_by || "N/A"}</strong>
                  </li>

                  <li>
                    <span>{translate("Blocked")}</span>
                    <strong>
                      {dataList?.black_list ? ICONS.TRUE : ICONS.FALSE}
                    </strong>
                  </li>

                  <li>
                    <span>{translate("Is active")}</span>
                    <strong>
                      {dataList?.is_active ? ICONS.TRUE : ICONS.FALSE}
                    </strong>
                  </li>

                  <li>
                    <span>{translate("Is online")}</span>
                    <strong>
                      {dataList?.is_online ? ICONS.TRUE : ICONS.FALSE}
                    </strong>
                  </li>

                  <li>
                    <span>{translate("Created date")}</span>
                    <strong>
                      {parseFullDate(dataList?.createdAt) || "N/A"}
                    </strong>
                  </li>
                  <li>
                    <span>{translate("Updated date")}</span>
                    <strong>
                      {parseFullDate(dataList?.updatedAt) || "N/A"}
                    </strong>
                  </li>
                  <li>
                    <span>{translate("App version")}</span>
                    <strong>{dataList?.app_version || "N/A"}</strong>
                  </li>
                  <li>
                    <span>{translate("Model")}</span>
                    <strong>{dataList?.model || "N/A"}</strong>
                  </li>
                  <li>
                    <span>{translate("Browser version")}</span>
                    <strong>{dataList?.browser_version || "N/A"}</strong>
                  </li>
                </ul>
              ) : (
                <Empty />
              )}
            </Card>
          </Badge.Ribbon>
        </div>

        <div className="part-2">
          <Card
            title={translate("Playlist")}
            loading={loading}
            extra={
              (dataList?.is_active || dataList?.is_trial) && (
                <div className="card-extra">
                  {profile?.sync_playlist &&
                    (profile?.is_partner || canUseFree) && (
                      <Tooltip
                        title={
                          !dataList?.is_active && !dataList?.is_trial
                            ? translate("Deactive device")
                            : null
                        }
                      >
                        <Button
                          size="small"
                          disabled={!dataList?.is_active && !dataList?.is_trial}
                          onClick={() => syncPlaylist(dataList)}
                          style={{ borderColor: "blue", color: "blue" }}
                        >
                          {translate("Sync")}
                        </Button>
                      </Tooltip>
                    )}
                  <Button
                    size="small"
                    style={{ borderColor: "green", color: "green" }}
                    onClick={onEditPlaylist}
                  >
                    {translate("Edit")}
                  </Button>
                </div>
              )
            }
          >
            {dataList ? (
              <ul className="card-info">
                <li>
                  <span>{translate("IP address")}</span>
                  {dataList.ip_address ? (
                    <CopyText text={dataList.ip_address} />
                  ) : (
                    "N/A"
                  )}
                </li>

                <li>
                  <span>{translate("Is trial")}</span>
                  <strong>
                    {dataList.is_trial ? ICONS.TRUE : ICONS.FALSE}
                  </strong>
                </li>
                <li>
                  <span>{translate("Is lifetime")}</span>
                  <strong>
                    {dataList.is_lifetime ? ICONS.TRUE : ICONS.FALSE}
                  </strong>
                </li>
                <li>
                  <span>{translate("Auto renew")}</span>
                  <strong>
                    {dataList.auto_renew ? ICONS.TRUE : ICONS.FALSE}
                  </strong>
                </li>
                <li>
                  <span>{translate("Expired date")}</span>
                  <strong>
                    {parseFullDate(dataList.expired_date) || "N/A"}
                  </strong>
                </li>

                <li>
                  <span>{translate("Free trial expired")}</span>
                  <strong>{parseFullDate(dataList.free_trial_expired)}</strong>
                </li>
                <li>
                  <span>{translate("Playlist")}</span>
                  {dataList.playlist ? (
                    <div>
                      {(() => {
                        const playlistStatus =
                          dataList.playlist_status?.toLowerCase();

                        if (
                          playlistStatus === "wrong" ||
                          playlistStatus === "expired"
                        ) {
                          const { errorMessage } =
                            checkPlaylistForError(playlistStatus);
                          return (
                            <>
                              <Tooltip title={errorMessage}>
                                <Button
                                  icon={<InfoCircleOutlined />}
                                  type="link"
                                  className="card-info-icon"
                                  style={{
                                    color: "#cf1222",
                                  }}
                                />
                              </Tooltip>
                              <Space>
                                <CopyText
                                  text={
                                    <LongText
                                      children={dataList.playlist}
                                      style={{
                                        color: "#cf1222",
                                      }}
                                    />
                                  }
                                  style={{ color: "#cf1222" }}
                                />
                              </Space>
                            </>
                          );
                        }

                        return (
                          <CopyText
                            text={<LongText children={dataList.playlist} />}
                          />
                        );
                      })()}
                    </div>
                  ) : (
                    "N/A"
                  )}
                </li>
              </ul>
            ) : (
              <Empty />
            )}
          </Card>
        </div>
      </div>
      {dataList && <HistoryDeactivated filterBy={deviceKey} />}
      {isDrawerOpen && (
        <DevicesDrawer
          open={isDrawerOpen}
          setOpen={() => setIsDrawerOpen(false)}
          getData={getDevice}
          getDeviceInfo={() => {}}
          selected={dataList}
          myActivate={myActivate}
        />
      )}
    </PageComponent>
  );
}
