import { useEffect, useState } from "react";
import { Input, Form, Divider, Switch, message } from "antd";

import REQUESTS from "../../api/requests";

import useTranslate from "../../hooks/translator";

import { ButtonComponent } from "../../components";

export default function ContactsPage() {
  const translate = useTranslate();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [currentData, setCurrentData] = useState(null);

  useEffect(() => {
    getCurrentData();
  }, []);

  const getCurrentData = () => {
    REQUESTS.SETTINGS_CONTACTS.GET({})
      .then((data) => {
        setCurrentData(data);
        if (data) {
          form.setFields([
            { name: "is_visibale", value: data.is_visibale },
            { name: "phone", value: data.phone },
            { name: "email", value: data.email },
            { name: "web_page", value: data.web_page },
            { name: "whatsapp", value: data.whatsapp },
            { name: "telegram", value: data.telegram },
            { name: "facebook", value: data.facebook },
            { name: "tiktok", value: data.tiktok },
            { name: "join_telegram_group", value: data.join_telegram_group },
            { name: "join_whatsapp_group", value: data.join_whatsapp_group },
            { name: "notes", value: data.notes },
          ]);
        }
      })
      .catch(() => {});
  };

  const onFinish = async (values) => {
    if (loading) return;

    setLoading(true);

    const body = {};

    if (values.is_visibale !== currentData.is_visibale) {
      body.is_visibale = values.is_visibale;
    }
    if (values.email !== currentData.email) {
      body.email = values.email || "";
    }
    if (values.phone !== currentData.phone) {
      body.phone = values.phone || "";
    }
    if (values.web_page !== currentData.web_page) {
      body.web_page = values.web_page || "";
    }
    if (values.whatsapp !== currentData.whatsapp) {
      body.whatsapp = values.whatsapp || "";
    }
    if (values.telegram !== currentData.telegram) {
      body.telegram = values.telegram || "";
    }
    if (values.facebook !== currentData.facebook) {
      body.facebook = values.facebook || "";
    }
    if (values.tiktok !== currentData.tiktok) {
      body.tiktok = values.tiktok || "";
    }
    if (values.join_telegram_group !== currentData.join_telegram_group) {
      body.join_telegram_group = values.join_telegram_group || "";
    }
    if (values.join_whatsapp_group !== currentData.join_whatsapp_group) {
      body.join_whatsapp_group = values.join_whatsapp_group || "";
    }
    if (values.notes !== currentData.notes) {
      body.notes = values.notes || "";
    }

    if (currentData && Object.keys(body).length > 0) {
      body.id = currentData.id;

      await REQUESTS.SETTINGS_CONTACTS.PUT(body)
        .then((res) => {
          getCurrentData();
          message.success(translate("success"));
          setTimeout(() => {
            if (res) {
              setLoading(false);
            }
          }, 1000);
        })
        .catch((error) => {
          message.error(error);
          setTimeout(() => {
            if (error) {
              setLoading(false);
            }
          }, 1000);
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      initialValues={{
        is_visibale: true,
      }}
    >
      <Divider
        orientation="left"
        style={{ fontSize: "15px", display: "flex", alignItems: "baseline" }}
      >
        <div style={{ display: "flex", gap: "12px", lineHeight: 2 }}>
          <h4> {translate("Contacts")}</h4>
          <Form.Item name="is_visibale" valuePropName="checked">
            <Switch />
          </Form.Item>
        </div>
      </Divider>

      <div className="settings-contacts">
        <Form.Item label={translate("Phone")} name="phone">
          <Input />
        </Form.Item>
        <Form.Item
          label={translate("Email")}
          name="email"
          rules={[
            {
              required: false,
              message: translate(""),
            },
            {
              type: "email",
              message: translate("E-mail isn't valid"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={translate("Web page")}
          name="web_page"
          rules={[
            {
              required: false,
              message: translate(""),
            },
            {
              type: "url",
              message: translate("That's not a valid URL"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="WhatsApp"
          name="whatsapp"
          rules={[
            {
              required: false,
              message: translate(""),
            },
            {
              type: "url",
              message: translate("That's not a valid URL"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Telegram"
          name="telegram"
          rules={[
            {
              required: false,
              message: translate(""),
            },
            {
              type: "url",
              message: translate("That's not a valid URL"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Facebook"
          name="facebook"
          rules={[
            {
              required: false,
              message: translate(""),
            },
            {
              type: "url",
              message: translate("That's not a valid URL"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="TikTok"
          name="tiktok"
          rules={[
            {
              required: false,
              message: translate(""),
            },
            {
              type: "url",
              message: translate("That's not a valid URL"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={translate("Join Telegram group")}
          name="join_telegram_group"
          rules={[
            {
              required: false,
              message: translate(""),
            },
            {
              type: "url",
              message: translate("That's not a valid URL"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={translate("Join WhatsApp group")}
          name="join_whatsapp_group"
          rules={[
            {
              required: false,
              message: translate(""),
            },
            {
              type: "url",
              message: translate("That's not a valid URL"),
            },
          ]}
        >
          <Input />
        </Form.Item>
      </div>

      <Form.Item label={translate("Notes")} name="notes">
        <Input.TextArea rows={4} style={{ maxWidth: "350px" }} />
      </Form.Item>

      <Form.Item>
        <ButtonComponent
          loading={loading}
          htmlType="submit"
          style={{ maxWidth: "350px" }}
        >
          {translate("Save")}
        </ButtonComponent>
      </Form.Item>
    </Form>
  );
}
